import axios from "axios";
import { displayNotification } from "../../../store/action";
export const GET_PROJECT_VOTE_YES = "GET_PROJECT_VOTE_YES";
export const GET_PROJECT_VOTE_NO = "GET_PROJECT_VOTE_NO";
export const CREATE_PROJECT_VOTE = "CREATE_PROJECT_VOTE";
export const DELETE_PROJECT_VOTE = "DELETE_PROJECT_VOTE";
export const UPDATE_PROJECT_VOTE = "UPDATE_PROJECT_VOTE";

export const getProjectVoteData = payload => ({
  type: GET_PROJECT_VOTE_YES,
  payload
});

export const getProjectVote = (body, vote) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/projects/${body}/votes`;
  return dispatch => {
    return axios
      .get(apiUrl, {
        params: { vote: vote },
        headers: {
          Authorization: `Bearer ${window.localStorage.userToken}`
        }
      })
      .then(response => {
        dispatch(getProjectVoteData(response.data.votes));
      })
      .catch(error => {
        throw error;
      });
  };
};

export const getProjectVoteNoData = payload => ({
  type: GET_PROJECT_VOTE_NO,
  payload
});

export const getProjectNoVote = (body, vote) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/projects/${body}/votes`;
  // const config = {
  //     headers: {
  //         Authorization: `Bearer ${window.localStorage.userToken}`,
  //     }
  // };
  return dispatch => {
    return axios
      .get(apiUrl, {
        params: { vote: vote },
        headers: {
          Authorization: `Bearer ${window.localStorage.userToken}`
        }
      })
      .then(response => {
        dispatch(getProjectVoteNoData(response.data.votes));
      })
      .catch(error => {
        throw error;
      });
  };
};

export const createProjectData = payload => ({
  type: CREATE_PROJECT_VOTE,
  payload
});

export const createProject = (body, id, callback) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/projects/${id}/votes`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    return axios
      .post(apiUrl, body, config)
      .then(response => {
        dispatch(createProjectData(response.data.vote));
        callback(response.data.vote);
        dispatch(
          displayNotification({
            open: true,
            message:
              response && response.data
                ? "Thank you for your vote."
                : "Something went wrong",
            duration: 3000,
            severity: "success"
          })
        );
      })
      .catch(error => {
        throw error;
      });
  };
};

export const deleteProjectData = payload => ({
  type: DELETE_PROJECT_VOTE,
  payload
});

export const deleteProject = (id, callback) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/projects/${id}/deleteVote`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    return axios
      .delete(apiUrl, config)
      .then(response => {
        dispatch(deleteProjectData(response.data.vote));
        callback(response.data.vote);
      })
      .catch(error => {
        throw error;
      });
  };
};

export const updateVoteData = payload => ({
  type: UPDATE_PROJECT_VOTE,
  payload
});

export const updateVote = (id, owner, vote, callback) => {
  const url = new URL(
    process.env.REACT_APP_API_HOST + `/api/v1/projects/${id}/updateVote`
  );
  const param1 = url.searchParams.set("owner", owner);
  const param2 = url.searchParams.set("vote", vote);
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    return axios
      .put(url, { param1, param2 }, config)
      .then(response => {
        dispatch(updateVoteData(response.data.vote));
        callback(response.data.vote);
      })
      .catch(error => {
        throw error;
      });
  };
};
