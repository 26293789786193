import { GET_PLACE, GET_PLACE_ARRAY,EMPTY_PLACE } from "./placeAction";
const initialState = {
  items: [],
  itemsData: null
};

export default function placeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PLACE:
      return {
        ...state,
        items: action.payload
      };
    case GET_PLACE_ARRAY:
      return {
        ...state,
        itemsData: action.payload
      };
    case EMPTY_PLACE:
      return {
        ...state,
        items: [],
      };
    default:
      return state;
  }
}
