import {
  GET_PROJECT_VOTE_YES,
  GET_PROJECT_VOTE_NO,
  CREATE_PROJECT_VOTE,
  DELETE_PROJECT_VOTE,
  UPDATE_PROJECT_VOTE
} from "./projectVoteAction";
const initialState = {
  itemsYes: [],
  itemsNo: [],
  create: null,
  delete: null,
  update: null
};

export default function citizenReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROJECT_VOTE_YES:
      return {
        ...state,
        itemsYes: action.payload
      };
    case GET_PROJECT_VOTE_NO:
      return {
        ...state,
        itemsNo: action.payload
      };
    case CREATE_PROJECT_VOTE:
      return {
        ...state,
        create: action.payload
      };
    case DELETE_PROJECT_VOTE:
      return {
        ...state,
        delete: action.payload
      };
    case UPDATE_PROJECT_VOTE:
      return {
        ...state,
        update: action.payload
      };
    // case EDIT_PROJECT:
    //     return {
    //         ...state,
    //         items: action.payload
    //     }
    default:
      return state;
  }
}
