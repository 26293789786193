import { GET_ANSWER, GET_ANSWER_REQUEST, GET_ANSWER_FALILURE, CREATE_ANSWER,CREATE_ANSWER_FAILURE,CREATE_ANSWER_REQUEST } from "./answerAction";
const initialState = {
  items: [],
  create: [],
  loading:false,
  gLoading:false,
  error:null
};

export default function citizenReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ANSWER:
      return {
        ...state,
        gLoading:false,
        items: action.payload
      };
    case GET_ANSWER_REQUEST:
      return {
        ...state,
        gLoading:true,
      };
    case GET_ANSWER_FALILURE:
      return {
        ...state,
        gLoading:false,
        error: action.payload
      };
    case CREATE_ANSWER:
      return {
        ...state,
        loading:false,
        create: action.payload
      };
    case CREATE_ANSWER_FAILURE:
      return {
        ...state,
        loading:false,
        error: action.payload
      };
    case CREATE_ANSWER_REQUEST:
      return {
        ...state,
        loading:true
      };
    default:
      return state;
  }
}
