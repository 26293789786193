import { GET_COMMENT, DELETE_COMMENT, ADD_COMMENT } from "./commentAction";
const initialState = {
  items: [],
  delete: null,
  add: null
};

export default function projectCommentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMMENT:
      return {
        ...state,
        items: action.payload
      };
    case DELETE_COMMENT:
      return {
        ...state,
        delete: action.payload
      };
    case ADD_COMMENT:
      return {
        ...state,
        add: action.payload
      };
    default:
      return state;
  }
}
