import axios from "axios";

export const TOGGLE_LANG = "TOGGLE_LANG";

export const onLangChange = (payload) => ({
  type: TOGGLE_LANG,
  payload,
});

export const displayNotification = (data) => ({
  type: "DISPLAY_NOTIFICATION",
  data,
});

export const getProjectCategories = () => async (dispatch) => {
  try {
    const apiUrl =
      process.env.REACT_APP_API_HOST + `/api/v1/projects/get/category`;
    const response = await axios.get(apiUrl);
    dispatch({
      type: "SET_PROJECT_CATEGORIES",
      payload: response?.data?.data || [],
    });
  } catch (error) {
  }
};
