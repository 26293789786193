import axios from "axios";
import Token from "@util/token";
import request from "@util/request";
import { displayNotification, onLangChange } from "../../store/action";
export const LOGIN_SUCCESS = "USER_LOGIN";
export const LOGIN_ERROR = "USER_ERROR";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const FORGOT_SUCCESS = "FORGOT_SUCCES";
export const FORGOT_ERROR = "FORGOT_ERROR";
export const FORGOT_REQUEST = "FORGOT_REQUES";
export const GET_RESET = "GET_RESET";
export const SET_RESET_REQUEST = "SET_RESET_REQUEST";
export const SET_RESET_SUCCESS = "SET_RESET_SUCCESS";
export const SET_RESET_ERROR = "SET_RESET_ERROR";

export const loginRequest = () => ({
  type: LOGIN_REQUEST
});

export const loginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload
});

export const loginError = payload => ({
  type: LOGIN_ERROR,
  payload
});
export const forgotRequest = () => ({
  type: FORGOT_REQUEST
});

export const forgotSuccess = payload => ({
  type: FORGOT_SUCCESS,
  payload
});

export const forgotError = payload => ({
  type: FORGOT_ERROR,
  payload
});
export const getResetData = payload => ({
  type: GET_RESET,
  payload
});
export const setResetData = payload => ({
  type: SET_RESET_SUCCESS,
  payload
});
export const setResetDataRequest = () => ({
  type: SET_RESET_REQUEST,
});
export const setResetDataError = payload => ({
  type: SET_RESET_ERROR,
  payload
});

export const userAuth = (body, successCB) => {
  return dispatch => {
    dispatch(loginRequest());
    request("post", "/api/v1/auth/login", body)
      .then(response => {
        if (response.status === 200) {
          Token.setToken(response.data.token);
          Token.setCookie("userToken", response.data.token, 1);
          // Store cookie for the lang
          if(Token.getCookie("closeCookiePopup")==="allow") {
            Token.setUserType(response.data.user.loginType)
            if (Token && Token.getCookie("lan")) {
              var langGet = Token.getCookie("lan");
              var mLang = JSON.parse(langGet);

              if (response.data.user._id === mLang.id) {
                dispatch(onLangChange(mLang.lan));
                let obj = { id: response.data.user._id, lan: mLang.lan };
                let jsonString = JSON.stringify(obj);
                document.cookie = "lan=" + jsonString;
              } else {
                let obj = {
                  id: response.data.user._id,
                  lan: window && window.localStorage.getItem("lan")
                };
                let jsonString = JSON.stringify(obj);
                document.cookie = "lan=" + jsonString;
              }
            } else {
              var obj = {
                id: response.data.user._id,
                lan: window && window.localStorage.getItem("lan")
              };
              var jsonString = JSON.stringify(obj);
              document.cookie = "lan=" + jsonString;
            }
          }
          // End Cookie

          if (response.data.organization) {
            response.data.organization.token = response.data.token;
          } else {
            response.data.user.token = response.data.token;
          }
          dispatch(loginSuccess(response.data));
          successCB(response.data.user);
        } else {
          dispatch(loginError("The email and/or password was incorrect."));
          throw new Error();
        }
      })
      .catch(error => {
        dispatch(
          displayNotification({
            open: true,
            message:
              error.response && error.response.data
                ? error.response.data.message
                : "Something went wrong",
            duration: 3000,
            severity: "error"
          })
        );
        dispatch(loginError());
      });
  };
};

export const forgotPassword = body => {
  const apiUrladd =
    process.env.REACT_APP_API_HOST + `/api/v1/auth/forgotPassword`;
  return dispatch => {
    dispatch(forgotRequest())
    return axios
      .post(apiUrladd, body)
      .then(response => {
        dispatch(forgotSuccess(response.data));
        dispatch(
          displayNotification({
            open: true,
            message:
              response && response.data
                ? response.data.message
                : "Something went wrong",
            duration: 3000,
            severity: "success"
          })
        );
      })
      .catch(error => {
        dispatch(forgotError(error))
        dispatch(
          displayNotification({
            open: true,
            message:
              error.response && error.response.data
                ? error.response.data.message
                : "Something went wrong",
            duration: 3000,
            severity: "error"
          })
        );
      });
  };
};

export const getResetPassword = body => {
  const apiUrladd =
    process.env.REACT_APP_API_HOST + `api/v1/auth/validateResetToken`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    return axios
      .post(apiUrladd, body, config)
      .then(response => {
        dispatch(getResetData(response.data));
      })
      .catch(error => {
        throw error;
      });
  };
};

export const setResetPassword = (body, successCB) => {
  const apiUrladd =
    process.env.REACT_APP_API_HOST + `/api/v1/auth/resetPassword`;
  return dispatch => {
    dispatch(setResetDataRequest())
    return axios
      .post(apiUrladd, body)
      .then(response => {
        dispatch(setResetData(response));
        dispatch(
          displayNotification({
            open: true,
            message:
              response && response.data
                ? "Password reset succesful."
                : "Something went wrong",
            duration: 3000,
            severity: "success"
          })
        );
        successCB(response);
      })
      .catch(error => {
        dispatch(setResetDataError(error))
        dispatch(
          displayNotification({
            open: true,
            message:
              error.response && error.response.data
                ? "This link has expired. Please visit Login and click on Forgot Password to reset your password"
                : "Something went wrong",
            duration: 3000,
            severity: "error"
          })
        );
      });
  };
};
