import request from "@util/request";
import axios from "axios";
export const QR_CODE_SUCCESS = "QR_CODE_SUCCESS";
export const QR_CODE_ERROR = "QR_CODE_ERROR";
export const QR_CODE_REQUEST = "QR_CODE_REQUEST";

export const getQRCodeList = (payload) => ({
  type: QR_CODE_SUCCESS,
  payload,
});

export const getQRCodeListError = (payload) => ({
  type: QR_CODE_ERROR,
  payload,
});

export const getQRCodeListRequest = () => ({
  type: QR_CODE_REQUEST,
});

export const getQRCode = () => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + "/api/v1/projects/orgQRCodeProjects";
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getQRCodeListRequest());
    return axios
      .post(apiUrl, "", config)
      .then((response) => {
        dispatch(getQRCodeList(response.data));
      })
      .catch((error) => {
        dispatch(getQRCodeListError("Something Went Wrong"));
        throw error;
      });
  };
};
