import {
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  REGISTER_REQUEST,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_ERROR,
  GET_COUNTRY_REQUEST,
  GET_STATE_SUCCESS,
  GET_STATE_ERROR,
  GET_STATE_REQUEST,
  GET_CITY_SUCCESS,
  GET_CITY_ERROR,
  GET_CITY_REQUEST,
  GET_ZIPCODE_SUCCESS,
  GET_ZIPCODE_ERROR,
  GET_ZIPCODE_REQUEST,
  GET_MODIFY_ZIP_REQUEST,
  GET_MODIFY_ZIP_SUCCESS,
  GET_MODIFY_ZIP_ERROR,
  SET_LOGO_REQUEST,
  SET_LOGO,
  SET_LOGO_ERROR
} from "./action";
const initialState = {
  items: [],
  loading: false,
  mloading: false,
  stateLoading: false,
  cityLoading: false,
  zipLoading: false,
  countryItems: {},
  stateItems: {},
  cityItems: {},
  zipcodeItems: {},
  modifyZip: {},
  image: null
};

export default function registerReducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload
      };
    case REGISTER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_COUNTRY_ERROR:
      return {
        ...state,
        loading: true
      };
    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countryItems: action.payload
      };
    case GET_COUNTRY_REQUEST:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_STATE_REQUEST:
      return {
        ...state,
        stateLoading: true
      };
    case GET_STATE_SUCCESS:
      return {
        ...state,
        stateLoading: false,
        stateItems: action.payload
      };
    case GET_STATE_ERROR:
      return {
        ...state,
        stateLoading: false,
        error: action.payload
      };
    case GET_CITY_REQUEST:
      return {
        ...state,
        cityLoading: true
      };
    case GET_CITY_SUCCESS:
      return {
        ...state,
        cityLoading: false,
        cityItems: action.payload
      };
    case GET_CITY_ERROR:
      return {
        ...state,
        cityLoading: false,
        error: action.payload
      };
    case GET_ZIPCODE_REQUEST:
      return {
        ...state,
        zipLoading: true
      };
    case GET_ZIPCODE_SUCCESS:
      return {
        ...state,
        zipLoading: false,
        zipcodeItems: action.payload
      };
    case GET_ZIPCODE_ERROR:
      return {
        ...state,
        zipLoading: false,
        error: action.payload
      };
    case GET_MODIFY_ZIP_REQUEST:
      return {
        ...state,
        mloading: true
      };
    case GET_MODIFY_ZIP_SUCCESS:
      return {
        ...state,
        mloading: false,
        modifyZip: action.payload
      };
    case GET_MODIFY_ZIP_ERROR:
      return {
        ...state,
        mloading: false,
        modifyZip: {},
        error: action.payload
      };
    case SET_LOGO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_LOGO:
      return {
        ...state,
        loading: false,
        image: action.payload
      };
    case SET_LOGO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
