const setToken = (token) => {
  window.localStorage.userToken = token;
};

const setUserType=(uType)=>{
  window.localStorage.uType = btoa(uType);
}

const getToken = () => {
  return window.localStorage.userToken;
};

const getUserRole = () => {
  return window.localStorage.uType && atob(window.localStorage.uType)
};

const resetToken = () => {
  delete window.localStorage.userToken;
  delete window.localStorage.uType;
};

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 3600 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
const eraseCookie = name => {
  document.cookie = name + "=; Max-Age=-99999999;";
  if (!getCookie("userToken")) {
    resetToken();
  }
};
if (!getCookie("userToken") || !getToken()) {
  resetToken();
  eraseCookie("userToken");
}

export default {
  setToken,
  getToken,
  resetToken,
  getUserRole,
  setCookie,
  getCookie,
  setUserType,
  eraseCookie
};
