import request from "@util/request";
import axios from "axios";
import Token from "@util/token";
import { displayNotification } from "../../store/action";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_ERROR = "GET_COUNTRY_ERROR";
export const GET_COUNTRY_REQUEST = "GET_COUNTRY_REQUEST";
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS";
export const GET_STATE_ERROR = "GET_STATE_ERROR";
export const GET_STATE_REQUEST = "GET_STATE_REQUEST";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_ERROR = "GET_CITY_ERROR";
export const GET_CITY_REQUEST = "GET_CITY_REQUEST";
export const GET_ZIPCODE_SUCCESS = "GET_ZIPCODE_SUCCESS";
export const GET_ZIPCODE_ERROR = "GET_ZIPCODE_ERROR";
export const GET_ZIPCODE_REQUEST = "GET_ZIPCODE_REQUEST";

export const GET_MODIFY_ZIP_REQUEST = "GET_MODIFY_ZIP_REQUEST";
export const GET_MODIFY_ZIP_SUCCESS = "GET_MODIFY_ZIP_SUCCESS";
export const GET_MODIFY_ZIP_ERROR = "GET_MODIFY_ZIP_ERROR";

export const SET_LOGO_REQUEST = "SET_LOGO_REQUEST";
export const SET_LOGO = "SET_LOGO";
export const SET_LOGO_ERROR = "SET_LOGO_ERROR";

export const registerRequest = () => ({
  type: REGISTER_REQUEST
});

export const RegisterationError = payload => ({
  type: REGISTER_ERROR,
  payload
});

export const RegisterationSuccess = payload => ({
  type: REGISTER_SUCCESS,
  payload
});

export const getCountryRequest = () => ({
  type: GET_COUNTRY_REQUEST
});

export const getCountrySuccess = payload => ({
  type: GET_COUNTRY_SUCCESS,
  payload
});

export const getCountryError = payload => ({
  type: GET_COUNTRY_ERROR,
  payload
});

export const getStateRequest = () => ({
  type: GET_STATE_REQUEST
});

export const getStateSuccess = payload => ({
  type: GET_STATE_SUCCESS,
  payload
});

export const getStateError = payload => ({
  type: GET_STATE_ERROR,
  payload
});

export const getCityRequest = () => ({
  type: GET_CITY_REQUEST
});

export const getCitySuccess = payload => ({
  type: GET_CITY_SUCCESS,
  payload
});

export const getCityError = payload => ({
  type: GET_CITY_ERROR,
  payload
});

export const getZipcodeRequest = () => ({
  type: GET_ZIPCODE_REQUEST
});

export const getZipcodeSuccess = payload => ({
  type: GET_ZIPCODE_SUCCESS,
  payload
});

export const getZipcodeError = payload => ({
  type: GET_ZIPCODE_ERROR,
  payload
});

export const getModifyZipRequest = () => ({
  type: GET_MODIFY_ZIP_REQUEST
});

export const getModifyZipSuccess = payload => ({
  type: GET_MODIFY_ZIP_SUCCESS,
  payload
});

export const getModifyZipError = payload => ({
  type: GET_MODIFY_ZIP_ERROR,
  payload
});

export const setLogoRequest = payload => ({
  type: SET_LOGO_REQUEST,
  payload
});
export const setLogo = payload => ({
  type: SET_LOGO,
  payload
});
export const setLogoError = payload => ({
  type: SET_LOGO_ERROR,
  payload
});

export const registerUserData = (body, successCB, errorCB) => {
  return dispatch => {
    dispatch(registerRequest());
    body = { ...body };
    return request("post", "/api/v1/auth/register", body)
      .then(response => {
        if (response.status === 200) {
          if (response.data.user.loginType === "Resident") {
            Token.setToken(response.data.token)
            Token.setCookie("userToken", response.data.token, 1);
            if(Token.getCookie("closeCookiePopup")==="allow") {
              Token.setUserType(response.data.user.loginType)
            }
          }
          dispatch(RegisterationSuccess(response.data.organization));
          successCB(response.data);
        } else {
          dispatch(RegisterationError(response.data.message));
          errorCB(response.data.message);
        }
      })
      .catch(
        error =>
          dispatch(
            displayNotification({
              open: true,
              message:
                error.response && error.response.data
                  ? error.response.data.message
                  : "Something went wrong",
              duration: 3000,
              severity: "error"
            })
          ),
        dispatch(RegisterationError())
      );
  };
};

export const registerCountryData = () => {
  return dispatch => {
    dispatch(getCountryRequest());
    return request("GET", "/api/v1/zipcode/countrys")
      .then(response => {
        if (response.status === 200) {
          dispatch(getCountrySuccess(response.data));
        } else {
          dispatch(getCountryError(response.data.message));
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const registerStateData = body => {
  return dispatch => {
    dispatch(getStateRequest());
    return request("GET", `/api/v1/zipcode/states/${body}`)
      .then(response => {
        if (response.status === 200) {
          dispatch(getStateSuccess(response.data));
        } else {
          dispatch(getStateError(response.data.message));
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const registerCityData = body => {
  return dispatch => {
    dispatch(getCityRequest());

    return request("GET", `/api/v1/zipcode/citys/${body}`)
      .then(response => {
        if (response.status === 200) {
          dispatch(getCitySuccess(response.data));
        } else {
          dispatch(getCityError(response.data.message));
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const registerZipcodeData = body => {
  return dispatch => {
    dispatch(getZipcodeRequest());
    return request("GET", `/api/v1/zipcode/${body}`, body)
      .then(response => {
        if (response.status === 200) {
          dispatch(getZipcodeSuccess(response.data));
        } else {
          dispatch(getZipcodeError(response.data.message));
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const registerModifyZip = body => {
  return dispatch => {
    dispatch(getModifyZipRequest());
    return request("POST", `/api/v1/zipcode/countyDataAndMofify`, body)
      .then(response => {
        dispatch(getModifyZipSuccess(response.data));
      })
      .catch(error => {
        dispatch(getModifyZipError(error));
        throw error;
      });
  };
};

export const addImage = (body, imge_id, callback) => {
  const id = imge_id.organization._id;
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/organizations/${id}/logo`;
  return dispatch => {
    dispatch(setLogoRequest());
    return axios
      .put(apiUrl, body)
      .then(response => {
        dispatch(setLogo(response.data));
        callback(response.data);
      })
      .catch(error => {
        dispatch(setLogoError());
      });
  };
};
