import axios from "axios";
import { displayNotification } from "../../../store/action";
export const GET_ORGANIZATION = "GET_ORGANIZATION";
export const GET_MEMBER_LIST_SUCCESS = "GET_MEMBER_LIST_SUCCESS";
export const GET_MEMBER_LIST_ERROR = "GET_MEMBER_LIST_ERROR";
export const GET_MEMBER_LIST_REQUEST = "GET_MEMBER_LIST_REQUEST";
export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const ADD_MEMBER_SUCCESS = "ADD_MEMBER_SUCCESS";
export const ADD_MEMBER_REQUEST = "ADD_MEMBER_REQUEST";
export const ADD_MEMBER_ERROR = "ADD_MEMBER_ERROR";

export const getOrganizationData = payload => ({
  type: GET_ORGANIZATION,
  payload
});
export const getMemberListRequest = () => ({
  type: GET_MEMBER_LIST_REQUEST
});

export const getMemberListSuccess = payload => ({
  type: GET_MEMBER_LIST_SUCCESS,
  payload
});

export const getMemberListError = payload => ({
  type: GET_MEMBER_LIST_ERROR,
  payload
});

export const setPasswordRequest = () => ({
  type: SET_PASSWORD_REQUEST
});

export const registerRequest = () => ({
  type: ADD_MEMBER_REQUEST
});

export const RegisterationError = payload => ({
  type: ADD_MEMBER_ERROR,
  payload
});

export const RegisterationSuccess = payload => ({
  type: ADD_MEMBER_SUCCESS,
  payload
});

export const getOrganization = body => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/organizations/${body}`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    return axios
      .get(apiUrl, config)
      .then(response => {
        dispatch(getOrganizationData(response.data));
      })
      .catch(error => {
        throw error;
      });
  };
};

export const addMember = (body, callback) => {
  const apiUrl = process.env.REACT_APP_API_HOST + "/api/v1/auth/register";
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    dispatch(registerRequest());
    return axios
      .post(apiUrl, body, config)
      .then(response => {
        if (response.status === 200) {
          dispatch(RegisterationSuccess(response.data.user));
          callback(response.data.user);
          dispatch(
            displayNotification({
              open: true,
              message:
                response && response.data
                  ? "Colleagues created successfully"
                  : "Something went wrong",
              duration: 3000,
              severity: "success"
            })
          );
        } else {
          dispatch(RegisterationError(response.data.message));
        }
      })
      .catch(error => {
        dispatch(
          displayNotification({
            open: true,
            message:
              error.response && error.response.data
                ? error.response.data.message
                : "Something went wrong",
            duration: 3000,
            severity: "error"
          })
        );
      });
  };
};

export const getMemberList = body => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/members?owner=${body}`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    dispatch(getMemberListRequest());
    return axios
      .get(apiUrl, config)
      .then(response => {
        if (response.status === 200) {
          dispatch(getMemberListSuccess(response.data.users));
        } else {
          dispatch(getMemberListError(response.data.message));
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const setMemberPassword = (isChange, body, successCB) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST +
    `/api/v1/${isChange ? `users/change-passw` : `members/setpassword`}`;
  const config = {
    headers: {
      Authorization: isChange ? `Bearer ${window.localStorage.userToken}` : ""
    }
  };
  return dispatch => {
    dispatch(setPasswordRequest());
    return axios
      .post(apiUrl, body, config)
      .then(response => {
        if (response.status === 200) {
          successCB(response.data);
          dispatch(
            displayNotification({
              open: true,
              message:
                response && response.data
                  ? response.data.message
                  : "Something went wrong",
              duration: 3000,
              severity: "success"
            })
          );
        } else {
          dispatch(
            displayNotification({
              open: true,
              message:
                response && response.data
                  ? response.data.message
                  : "Something went wrong",
              duration: 3000,
              severity: "error"
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          displayNotification({
            open: true,
            message:
              error.response && error.response.data
                ? error.response.data.message
                : "Something went wrong",
            duration: 3000,
            severity: "error"
          })
        );
      });
  };
};

export const deleteMemberById = (memberId, callback) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/users/delete/?id=${memberId}`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return (dispatch, getState) => {
    const { memberList } = getState().member;
    const deleteIndex = memberList.findIndex(v => v._id === memberId);
    memberList.splice(deleteIndex, 1);
    return axios
      .delete(apiUrl, config)
      .then(response => {
        if (response.status === 200) {
          dispatch(getMemberListSuccess([...memberList]));
          callback();
          dispatch(
            displayNotification({
              open: true,
              message:
                response && response.data
                  ? "Colleagues delete successfully"
                  : "Something went wrong",
              duration: 3000,
              severity: "success"
            })
          );
        }
      })
      .catch(error => {
        throw error;
      });
  };
};
