import {
  GET_PROJECT_DETAILS_BEGIN,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_ERROR,
  GET_PROJECT_BEGIN,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_ERROR,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT,
  CREATE_PROJECT_ERROR,
  ADD_IMAGE_REQUEST,
  ADD_IMAGE,
  ADD_IMAGE_ERROR,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT,
  EDIT_PROJECT_ERROR,
  GET_LATEST_PROJECT,
  DELETE_PROJECT_DATA,
  REMOVE_LAST_DATA,
  EXPORT_TO_EXCEL_SUCCESS,
  EXPORT_TO_EXCEL_FAILURE,
  EXPORT_TO_EXCEL_REQUEST,
  EXPORT_PROJECT_LIST_SUCCESS,
  EXPORT_PROJECT_LIST_FAILURE,
  EXPORT_PROJECT_LIST_REQUEST,
  EXPORT_MULTIPLE_PROJECT_SUCCESS,
  EXPORT_MULTIPLE_PROJECT_FAILURE,
  EXPORT_MULTIPLE_PROJECT_REQUEST,
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILURE,
  DELETE_IMAGE_REQUEST,
  DELETE_IMAGE,
  DELETE_IMAGE_ERROR,
  DELETE_FILE_REQUEST,
  DELETE_FILE,
  DELETE_FILE_ERROR,
} from "./projectAction";
const initialState = {
  projectDetails: {},
  items: [],
  newProject: null,
  loading: false,
  listLoader: false,
  latest: null,
  errorDesc: null,
  deleteProject: null,
  exportData: null,
  multiExportData: null,
  exProject: {},
  fileupload: null,
  imageLoader: false,
  imageDelete: null,
  imageDeleteError: null,
  fileLoader: false,
  fileDelete: null,
  fileDeleteError: null,
};
export default function projectReducer(state = initialState, action) {
  switch (action.type) {
    case REMOVE_LAST_DATA:
      return {
        ...state,
      };
    case GET_PROJECT_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        projectDetails: action.payload,
      };
    case GET_PROJECT_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        errorDesc: action.payload,
      };
    case GET_PROJECT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };
    case GET_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PROJECT:
      return {
        ...state,
        loading: false,
        newProject: action.payload,
      };
    case CREATE_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EXPORT_TO_EXCEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EXPORT_TO_EXCEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EXPORT_TO_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false,
        exportData: action.payload,
      };
    case EXPORT_MULTIPLE_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EXPORT_MULTIPLE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EXPORT_MULTIPLE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        multiExportData: action.payload,
      };
    case EXPORT_PROJECT_LIST_REQUEST:
      return {
        ...state,
        listLoader: true,
      };
    case EXPORT_PROJECT_LIST_FAILURE:
      return {
        ...state,
        listLoader: false,
        error: action.payload,
      };
    case EXPORT_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        listLoader: false,
        exProject: action.payload,
      };
    case ADD_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_IMAGE:
      return {
        ...state,
        loading: false,
        addImage: action.payload,
      };
    case ADD_IMAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FILE_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        fileupload: action.payload,
      };
    case FILE_UPLOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EDIT_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_PROJECT:
      return {
        ...state,
        loading: false,
        editProject: action.payload,
      };
    case EDIT_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_LATEST_PROJECT:
      return {
        ...state,
        latest: action.payload,
      };
    case DELETE_PROJECT_DATA:
      return {
        ...state,
        delete: action.payload,
      };

    case DELETE_IMAGE_REQUEST:
      return {
        ...state,
        imageLoader: true,
      };
    case DELETE_IMAGE:
      return {
        ...state,
        imageLoader: false,
        imageDelete: action.payload,
      };
    case DELETE_IMAGE_ERROR:
      return {
        ...state,
        imageLoader: false,
        imageDeleteError: action.payload,
      };

    case DELETE_FILE_REQUEST:
      return {
        ...state,
        fileLoader: true,
      };
    case DELETE_FILE:
      return {
        ...state,
        fileLoader: false,
        fileDelete: action.payload,
      };
    case DELETE_FILE_ERROR:
      return {
        ...state,
        fileLoader: false,
        fileDeleteError: action.payload,
      };

    default:
      return state;
  }
}
