import axios from "axios";
import request from "@util/request";
import { displayNotification } from "../../../store/action";
export const BLOGS_SUCCESS = "BLOGS_SUCCESS";
export const BLOGS_ERROR = "BLOGS_ERROR";
export const BLOGS_REQUEST = "BLOGS_REQUEST";

export const BLOG_DETAILS_SUCCESS = "BLOG_DETAILS_SUCCESS";
export const BLOG_DETAILS_REQUEST = "BLOG_DETAILS_REQUEST";
export const BLOG_DETAILS_ERROR = "BLOG_DETAILS_ERROR";

export const ADD_BLOG_REQUEST = "ADD_BLOG_REQUEST";
export const ADD_BLOG_DETAILS = "ADD_BLOG_DETAILS";
export const ADD_BLOG_ERROR = "ADD_BLOG_ERROR";

export const ADD_IMAGE_REQUEST = "ADD_IMAGE_REQUEST";
export const ADD_IMAGE = "ADD_IMAGE";
export const ADD_IMAGE_ERROR = "ADD_IMAGE_ERROR";

export const BLOGS_EDIT_REQUEST = "BLOGS_EDIT_REQUEST";
export const BLOGS_EDIT_SUCCESS = "BLOGS_EDIT_SUCCESS";
export const BLOGS_EDIT_ERROR = "BLOGS_EDIT_ERROR";

export const BLOGS_DELETE_REQUEST = "BLOGS_DELETE_REQUEST";
export const BLOGS_DELETE_SUCCESS = "BLOGS_DELETE_SUCCESS";
export const BLOGS_DELETE_ERROR = "BLOGS_DELETE_ERROR";
export const REMOVE_LAST_DATA = "REMOVE_LAST_DATA";

export const getBlogsDataRequest = () => ({
  type: BLOGS_REQUEST
});

export const getBlogsData = payload => ({
  type: BLOGS_SUCCESS,
  payload
});

export const BlogsDataError = payload => ({
  type: BLOGS_ERROR,
  payload
});

export const BlogDetailsRequest = () => ({
  type: BLOG_DETAILS_REQUEST
});

export const getBlogDetails = payload => ({
  type: BLOG_DETAILS_SUCCESS,
  payload
});

export const BlogDetailsError = payload => ({
  type: BLOG_DETAILS_ERROR,
  payload
});

export const addBlogRequest = () => ({
  type: ADD_BLOG_REQUEST
});
export const addBlogDetails = payload => ({
  type: ADD_BLOG_DETAILS,
  payload
});
export const addBlogError = payload => ({
  type: ADD_BLOG_ERROR,
  payload
});

export const addImageDataRequest = () => ({
  type: ADD_IMAGE_REQUEST
});
export const addImageData = payload => ({
  type: ADD_IMAGE,
  payload
});
export const addImageDataError = payload => ({
  type: ADD_IMAGE_ERROR,
  payload
});

export const removeLastData = () => ({
  type: REMOVE_LAST_DATA
});

// export const editBlogDataRequest = () => ({
//   type: BLOGS_EDIT_REQUEST
// });
export const editBlogData = payload => ({
  type: BLOGS_EDIT_SUCCESS,
  payload
});
export const editBlogDataError = payload => ({
  type: BLOGS_EDIT_ERROR,
  payload
});

export const deleteBlogDataRequest = () => ({
  type: BLOGS_DELETE_REQUEST
});
export const deleteBlogData = payload => ({
  type: BLOGS_DELETE_SUCCESS,
  payload
});
export const deleteBlogDataError = payload => ({
  type: BLOGS_DELETE_ERROR,
  payload
});

export const getBlogs = () => {
  return dispatch => {
    dispatch(getBlogsDataRequest());
    return request("GET", "/api/v1/blogs")
      .then(response => {
        dispatch(getBlogsData(response.data.blogs));
      })
      .catch(error => {
        dispatch(BlogsDataError("Something Went Wrong"));
        throw error;
      });
  };
};

export const getBlog = id => {
  return dispatch => {
    dispatch(removeLastData());
    dispatch(BlogDetailsRequest());
    return request("GET", `/api/v1/blogs/${id}`)
      .then(response => {
        dispatch(getBlogDetails(response.data.blog));
      })
      .catch(error => {
        dispatch(BlogDetailsError("Something Went Wrong"));
        dispatch(
          displayNotification({
            open: true,
            message:
              error.response && error.response.data
                ? error.response.data.message
                : "Something went wrong",
            duration: 3000,
            severity: "error"
          })
        );
      });
  };
};

export const addBlog = (body, callback) => {
  const apiUrl = process.env.REACT_APP_API_HOST + `/api/v1/blogs`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    dispatch(addBlogRequest());
    return axios
      .post(apiUrl, body, config)
      .then(response => {
        const blog = response.data.blog;
        dispatch(addBlogDetails(response.data.blog));
        callback(blog._id);
      })
      .catch(error => {
        dispatch(addBlogError());
      });
  };
};

export const addImage = (body, imge_id, callback) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/blogs/${imge_id}/images`;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    dispatch(addImageDataRequest());
    return axios
      .put(apiUrl, body, config)
      .then(response => {
        dispatch(addImageData(response.data.blog));
        callback(response.data.blog);
      })
      .catch(error => {
        dispatch(addImageDataError());
      });
  };
};

export const editBlog = (body, id) => {
  const apiUrl = process.env.REACT_APP_API_HOST + `/api/v1/blogs/${id}`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    return axios
      .put(apiUrl, body, config)
      .then(response => {
        dispatch(editBlogData(response.data.blog));
      })
      .catch(error => {
        dispatch(editBlogDataError());
      });
  };
};

export const deleteBlog = id => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/blogs/removeBlog/${id}`;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return (dispatch, getState) => {
    dispatch(deleteBlogDataRequest());
    const { items } = getState().blogs;
    const deleteIndex = items.findIndex(v => v._id === id);
    items.splice(deleteIndex, 1);
    return axios
      .delete(apiUrl, config)
      .then(response => {
        if (response.status === 200) {
          dispatch(getBlogsData([...items]));
          dispatch(
            displayNotification({
              open: true,
              message:
                response && response.data
                  ? "Your article was succesfully deleted."
                  : "Something went wrong",
              duration: 3000,
              severity: "success"
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          displayNotification({
            open: true,
            message:
              error.response && error.response.data
                ? error.response.data.message
                : "Something went wrong",
            duration: 3000,
            severity: "error"
          })
        );
        dispatch(deleteBlogDataError());
      });
  };
};
