import { EXPORT_USER } from "./exportUserAction";
const initialState = {
  items: []
};

export default function citizenReducer(state = initialState, action) {
  switch (action.type) {
    case EXPORT_USER:
      return {
        ...state,
        items: action.payload
      };
    default:
      return state;
  }
}
