export const define_it = {
  //landing page casestudies component

  landing_caseStudies1:
    "Civy ha testato beta un programma di abbellimento delle strade a Glendale, LA. Sulla base dei commenti ricevuti dalla città, è stato confermato che i residenti non sono stati completamente consultati sul progetto fino a quando il progetto non è iniziato e non è diventato visibile. Civy mira a eliminare questo scenario da tutti i progetti attuali e futuri. ",

  landing_caseStudies2:
    "Una grande agenzia di infrastrutture governative prevede di utilizzare Civy per condurre il loro prossimo programma di sensibilizzazione esterno. L'agenzia farà ai residenti che servono, domande sui nuovi progetti che stanno proponendo. Allo stesso tempo, useranno anche Civy per tenersi in contatto con le persone che stanno segnalando problemi per tutti gli attuali progetti infrastrutturali in corso, ritenendo che una piattaforma indipendente abilitata per dispositivi mobili migliorerà il coinvolgimento e il fatto che Civy funzioni in modo semplicistico, con varie opzioni linguistiche relative alla maggior parte delle persone.",

  landing_caseStudies3:
    "Uno scenario recente per offrirti una prospettiva migliore, immagina di essere un funzionario cittadino o un'agenzia governativa o un residente interessato. Hai sede a Los Angeles. Puoi digitare il nome della tua città nel Dashboard di Civy o in qualsiasi altra città di fronte il mondo (diciamo Sydney, Australia). Sarai in grado di vedere cosa sta facendo Sydney, puoi applicare i filtri per categoria di progetto. Promuoverà l'istruzione e gli apprendimenti globali.",

  landing_caseStudies4:
    "Come residente a Newport Beach in California, ho notato la mancanza di stazioni fitness all'aperto. Questi sono molto comuni in molti altri paesi in cui sole, oceano e vegetazione sono in abbondanza. Usando il mio smartphone, ho creato un nuovo progetto in Civy (intitolato Outdoor Fitness Station), contrassegnato la posizione, aggiunto alcune foto di esempio, aggiunta una breve descrizione del caso del progetto e condiviso con il mio dipartimento locale della città e la rete Civy. Lo scopo è far sì che i residenti approvino l'idea o forniscano commenti o altri suggerimenti; e nel frattempo, il dipartimento della città sta ricevendo il feedback di questi residenti. Questo dà loro l'impulso di agire e portarlo avanti con le persone alla prossima campagna di sensibilizzazione della comunità, ottenere l'approvazione della gente e costruirla.",

  landing_caseStudies_content:
    "Alcuni esempi pratici di come Civy può essere utile nella tua media giornaliera.",

  landing_caseStudies_title: "CASI STUDIO",

  //landing page statistics component

  landing_statistics_title: "STATISTICA",

  landing_statistics_content:
    "Per realizzare completamente lo sviluppo sostenibile, dobbiamo compiere progressi equi e di ampia portata e consentire alle persone di diventare partecipanti attivi in ​​tale processo.",

  landing_statistics48:
    "degli adulti prende direttamente parte a un gruppo o un'attività civica",

  landing_statistics68:
    "da 18 a 34 anni residenti hanno riferito di sentirsi disconnessi dal governo.",

  //landing page testimonial component

  landing_testimonial_title: "Testimonianze",

  landing_testimonial_content:
    "Leggi le testimonianze dei nostri ultimi clienti.",

  landing_testimonial1:
    "Mi piacerebbe averlo avuto quando abbiamo lavorato su progetti che richiedevano la sensibilizzazione delle parti interessate a livello statale e locale. Ciò avrebbe tolto un po 'di stress e ci avrebbe aiutato a raggiungere efficacemente le comunità.",

  landing_testimonial1_author: "Alana",

  landing_testimonial1_postname:
    "- Ex direttore delle comunicazioni e delle comunicazioni, Autorità statale per i servizi pubblici",

  landing_testimonial2:
    "Civy è in grado di affrontare tre ruoli nel processo di coinvolgimento in una sola volta: affiancare il tuo lavoro in streaming.",

  landing_testimonial2_author: "Eric",

  landing_testimonial2_postname:
    "- Assistente legislativo a Freeholder Township, New Jersey",

  landing_testimonial3:
    "Civy è uno dei primi entusiasmanti sviluppi da molto tempo per i software governativi e il modo in cui i comuni interagiscono con il pubblico. Freeholder è un comune importante",

  landing_testimonial3_author: "Scott",

  landing_testimonial3_postname: "- Capo di stato maggiore, Assemblea statale",

  landing_testimonial4:
    "La piattaforma di coinvolgimento online di Civy è facile da usare e progettata pensando a città, governi e residenti. Sta affrontando i principali punti deboli che sperimentiamo come città ed eletti e rende più facile per i nostri residenti interagire con noi in un ambiente sicuro e protetto. La piattaforma integra e migliora le nostre strategie di sensibilizzazione esistenti e progettata per aumentare i tassi di partecipazione e l'equità.",

  landing_testimonial4_author: "Theresa Branella",

  landing_testimonial4_postname: "-Mayor, Brooklawn, New Jersey",

  //landing page services component

  landing_servicesA_header:
    "Maggiore sensibilizzazione pubblica per i progetti.",

  landing_servicesA_content:
    "Un'unica piattaforma che offre la più ampia portata, più lingue e profondità demografica per progetti civici.",

  landing_servicesB_header: "Privacy e sicurezza dei dati.",

  landing_servicesB_content:
    "La nostra piattaforma include ulteriori misure sulla privacy per proteggere le risposte dei residenti, che sono visibili solo ai funzionari cittadini e governativi.",

  landing_servicesC_header: "Efficiente e conveniente.",

  landing_servicesC_content:
    "Il feedback su Civy per un progetto è facile come inviare un messaggio di testo.",

  landing_servicesD_header: "Analisi completa.",

  landing_servicesD_content:
    "Analizza il feedback dei residenti su diversi dati demografici e applica analisi per processi decisionali più rapidi, approvazioni e pianificazione futura del progetto.",

  landing_service_title: "SERVIZI",

  landing_service_content:
    "Civy aiuta città e governi a migliorare l'impegno civico.",

  landing_ServiceSection_maincontent:
    "Civy mostra i progetti di governi e città elencati per stato, città, codice postale; e porre domande a cui i residenti possono rispondere.",

  //landing page project component

  landing_project_title: "progetti",

  landing_project_content:
    "Un elenco dei nostri progetti attuali in varie città.",

  landing_project_short_description: "Breve descrizione del progetto",

  landing_project_description_link: "Vedi di più",

  landing_project_startdate: "Data d'inizio ",

  landing_project_enddate: "Data di fine",

  //landing page benefit component

  landing_benefit1_title: "Piattaforma mobile",

  landing_benefit1_content:
    "Civy è una comoda piattaforma mobile facilmente accessibile da qualsiasi luogo.",

  landing_benefit2_title: "Gratuito per i residenti",

  landing_benefit2_content:
    "Civy è gratuito per i residenti per registrarsi, rispondere a domande e sondaggi e aiutare città e governi a migliorare la pianificazione e la consegna dei loro progetti.",

  landing_benefit3_title: "Link ai social media",

  landing_benefit3_content:
    "Collegamenti civici con piattaforme di social media per aumentare i tassi di partecipazione e la profondità demografica.",

  landing_benefit4_title: "Sicuro e protetto",

  landing_benefit4_content:
    "Le risposte dei residenti sono confidenziali e Civy non condivide i dati dei residenti con terze parti.",

  landing_benefit_title: "BENEFICI",

  landing_benefit_content:
    "Civy offre molteplici funzionalità per sfruttare l'impegno e la partecipazione del pubblico diffuso che contribuiscono a migliorare i risultati di governance e sviluppo.",

  //landing page about component

  landing_about_title: "A proposito di CIVY",

  landing_about_content:
    "Aiutiamo le città e i governi a migliorare il coinvolgimento delle parti interessate e la sensibilizzazione del pubblico nei progetti civici.",

  landing_about_maincontent:
    "Civy è un modo semplice e sicuro per i residenti di partecipare e condividere feedback su progetti pubblici attuali e futuri da parte di città e governi. Una piattaforma online dedicata che raggiunge un pubblico più ampio. Le parti interessate di tutti i dati demografici si impegnano e forniscono feedback alle loro città e agenzie governative, con praticità ed efficienza.",

  landing_readmore: "Leggi di più",

  //landing page contact us component

  landing_contactus_address_title: "Indirizzo",

  landing_contactus_address: "Civy Inc.",

  landing_contactus_address_post:
    "160 S Cypress St, Orange, CA, 92866 USA",

  contactus_address_street: "160 S Cypress St,",

  contactus_address_code: "Orange, CA, 92866",

  contactus_address_use: "Stati Uniti d'America",

  landing_contactus_address_tel: "Tel:",

  landing_contactus_usertype1: "Residente",

  landing_contactus_usertype2: "Città",

  landing_contactus_usertype3: "Agenzia governativa",

  landing_contactus_usertype4: "Università",

  landing_contactus_usertype5: "Altra",

  landing_contactus_title: "ENTRA IN CONTATTO",

  landing_contactus_name: "Nome*",

  landing_contactus_email: "E-mail*",

  landing_contactus_select: "Tu sei*",

  landing_contactus_feedback: "Tipo di feedback*",

  landing_contactus_feedback_good: "Buona",

  landing_contactus_feedback_bad: "Cattiva",

  landing_contactus_feedback_other: "Altra",

  landing_contactus_feedback_message: "Il tuo messaggio*",

  landing_contactus_send_button: "Invia messaggio",

  landing_contactus_name_error: "* Nome richiesto",

  landing_contactus_email_error: "* È richiesta l'email",

  landing_contactus_email_error2: "*Inserisci indirizzo email valido",

  landing_contactus_select_error: "* Seleziona un'opzione",

  landing_contactus_message_error: "* È richiesto un messaggio",

  landing_contactus_capcha_error:
    "* Per favore, conferma la casella di controllo captcha Non sono un robot",

  select_survey_visibility: "Seleziona Visibilità sondaggio",

  select_survey_visibility_private: "Privato",

  select_survey_visibility_public: "Pubblica",

  // services page

  services_casestudy1_title: "CITTÀ E GOVERNI",

  services_casestudy1_content:
    "Una piattaforma sicura di facile utilizzo per coinvolgere i residenti e accelerare lo sviluppo più intelligente di progetti civici.",

  services_casestudy2_title: "RESIDENTI",

  services_casestudy2_content:
    "Una piattaforma mobile per ricevere notifiche e condividere feedback con le tue città per guidare la progettazione e lo sviluppo di progetti civici.",

  services_casestudy_title: "ARGOMENTO DI STUDIO",

  services_casestudy_content:
    "Come le città e i governi si collegano con Residencts via Civy",

  services_integratedservices_title: "Servizi integrati:",

  services_introservice1_title: "COSA RENDE DIVERSI CIVY",

  services_introservice1_point1:
    "Consente alle città e ai governi di creare i propri dati, aggregare i dati e controllare la propria narrativa.",

  services_introservice1_point2:
    "Aumenta e migliora la divulgazione pubblica collegandoti con altri canali di social media come Facebook, Twitter e LinkedIn.",

  services_introservice1_point3:
    "Completa riservatezza delle risposte dei residenti, che sono visibili solo alle città o ai governi che si abbonano a CIvy. I dati sono di proprietà di città o governi e Civy non si occupa di vendite di terze parti.",

  services_introservice1_point4:
    "Scarica e archivia i dati di progetto sui tuoi server. Per rispettare la normativa sulla privacy, Civy rimuove i dati 3 mesi dopo che il progetto è stato contrassegnato come completato dai clienti.",

  services_introservice1_point5:
    "Opzione per white label Civy come piattaforma personalizzata con il tuo marchio unico. ",

  services_introservice1_point6:
    "Civy offre anche servizi aggiuntivi come 3D Holographic e GIS Mapping con applicazioni nella pianificazione, progettazione e marketing di progetti e sviluppi di città.",

  services_introservice2_title:
    "Funzionalità del prodotto per città e governi.",

  services_introservice2_point1:
    "Città e governi creano un account sulla piattaforma Civy e collegano i propri canali di social media.",

  services_introservice2_point2:
    "Aggiungi più utenti all'account con ruoli utente definiti come Direttore dei lavori pubblici.",

  services_introservice2_point3:
    "Crea progetti, carica descrizioni e immagini relative alla visione del progetto.",

  services_introservice2_point4:
    "Condividi su canali diversi e fai domande ai residenti per rispondere.",

  services_introservice2_point5:
    "Raccogli e analizza le risposte, acquisisci più dati demografici nella community supportati dalle nostre capacità in più lingue.",

  services_introservice2_point6:
    "Utilizza le analisi per un processo decisionale, un'esecuzione e un risultato più rapidi.",

  services_introservice1_content:
    "Civy è una piattaforma di coinvolgimento per città, governi e residenti e ti consente di creare, aggregare e archiviare i tuoi dati.",

  services_introservice2_content:
    "Civy offre una piattaforma di facile utilizzo per favorire l'impegno civico.",

  // about page

  about_history1_title: "Ideazione",

  about_history1_content:
    "Dopo aver realizzato più progetti incentrati su infrastrutture, energia e tecnologia in tutto il mondo, ci siamo resi conto che i governi, i funzionari delle città e le comunità, nonostante le loro migliori intenzioni, si sono sempre lamentati della mancanza di consultazione; mentre gli interessi privati ​​come gli sviluppatori temono che i loro obiettivi commerciali avranno un impatto negativo se interagiscono troppo con le comunità. Le comunità, d'altra parte, sentivano di essere state fornite di piani aziendali ricoperti di zucchero e di proposte di investimento di progetti, ma non venivano consultate in fase di progettazione o spesso trattate come non esperti che non sanno di cosa stanno parlando. È un evento comune per i residenti sentirsi inascoltati durante i progetti di sviluppo civico nelle loro comunità.",

  about_history2_title: "Iniziazione",

  about_history2_content:
    "Per risolvere questo problema, abbiamo visualizzato una piattaforma comune in cui le idee di progetto provengono da funzionari cittadini e governativi e sono state presentate direttamente ai residenti e alle parti interessate per un feedback. Il primo può quindi impegnarsi, analizzare e restringere il miglior corso d'azione basato sul feedback delle parti interessate (residenti). Il governo e le autorità cittadine potrebbero spiegare direttamente alle persone cosa si può realizzare e cosa no, elencare i potenziali e le complicazioni che potrebbero essere affrontate a breve termine, elaborare i dati finanziari coinvolti nello sviluppo e previa adeguata consultazione da tutti i collaboratori agiscono e realizzano rapidamente i progetti.",

  about_history3_title: "Formulazione",

  about_history3_content:
    "Con chiaro scopo e risolutezza, abbiamo creato la piattaforma concettuale concentrandosi sull'affrontare i punti dolenti durante la nostra fase di scoperta con città e governi (sindaci, consiglieri, membri dell'assemblea legislativa, direttori di città, direttori di dipartimento, team di marketing, team tecnologici e gruppi di comunità). La piattaforma concettuale che combina lo strumento di analisi delle informazioni con gli aspetti dei social media per fornire un ampio raggio d'azione e interagire con i residenti per tutti gli sviluppi del progetto civico, ha ottenuto la convalida del cliente. Gli obiettivi chiave erano ridurre i tempi, i costi e le risorse a disposizione per un progetto civico e aumentare la sensibilizzazione del pubblico e aumentare l'inclusività per tutte le parti interessate, mantenendo al contempo la privacy e la sicurezza dei dati.",

  about_history4_title: "Implementazione",

  about_history4_content:
    "A partire dal 2019, abbiamo implementato Civy, una piattaforma per aiutare le città e i governi a migliorare la sensibilizzazione del pubblico in tutti i progetti civici. La piattaforma sollecita il feedback coinvolgendo i residenti dai millennial agli anziani di tutti i dati demografici, riducendo nel contempo la necessità di utilizzare metodi obsoleti e legacy come municipi vecchio stile. La piattaforma offre una comodità senza precedenti per apprendere, coinvolgere e fornire un feedback onesto alle parti interessate per tutti i progetti civici. La condivisione incorporata con un clic della piattaforma su più canali di social media, la sicurezza informatica e le soluzioni di cloud hosting offre anche la privacy dei dati, la produttività del back-office aumenta e consente alle città e ai governi di concentrarsi sul pensiero strategico.",

  about_history_title: "Storia della civiltà",

  about_history_since: "da",

  about_vision_title: "La nostra visione",

  about_vision_content:
    "Creare un circuito di coinvolgimento che catturi città, governi, residenti e parti interessate e costruire un pianeta più intelligente.",

  about_mission_title: "La nostra missione",

  about_mission_content1: `Civy aiuta le città e i governi a migliorare la sensibilizzazione del pubblico e l'impegno delle parti interessate in tutti i progetti civili, comprendendo i loro punti deboli, comprendendo cosa può essere migliorato e aiutando a facilitare una discussione migliore utilizzando la nostra piattaforma proprietaria. La nostra filosofia per raggiungere questo obiettivo è un approccio di "ritorno alle origini" di chiedere alle persone, ascoltare il loro feedback e aiutare i nostri clienti (città e governi) ad agire su questo feedback.`,

  about_interview_title: "colloquio",

  about_interview_content:
    "Amar Flora, fondatrice di Civy è stata intervistata in diretta TV per discutere di Civy e del suo obiettivo di aiutare città e governi",

  about_interview_maincontent:
    "Guarda il fondatore di Civy, Amar Flora, spiegare in diretta televisiva in che modo Civy consente ai responsabili delle decisioni di utilizzare i dati degli utenti per prendere decisioni più rapide, agire in base alle informazioni e connettersi semplicemente nel pensiero del 'cielo blu' con i loro residenti; il tutto in tempo reale, senza filtri e su una piattaforma indipendente.",

  about_ourteam_title: "LA NOSTRA SQUADRA",

  about_ourteam_content:
    "Siamo un gruppo di persone appassionate di GovTech, Smart Cities e coinvolgimento degli stakeholder.",

  about_ourteam_person1_name: "Amar Flora (MBA, GAICD, GCCM, CPA)",

  about_ourteam_person1_title: "Co-Fondatore e CEO",

  about_ourteam_person1_content:
    "Un leader visionario e un imprenditore con la passione per l'istruzione superiore, le città intelligenti e le operazioni del governo. Amar ha co-fondato due start-up di proprietà privata, costruendo team e collaboratori globali, ha fatto parte di consigli di amministrazione e ha guidato la creazione e le operazioni di una startup universitaria con un finanziamento privato di $ 20 milioni. Amar ha venduto servizi e consultato i clienti in vari settori, offrendo un impatto significativo e la creazione di valore. Amar possiede una spiccata capacità di identificare opportunità strategiche e operative e guidare l'innovazione. Amar ha conseguito un MBA, post-laurea in Change Management e Corporate Governance e certificazione CPA dalle migliori istituzioni negli Stati Uniti e in Australia.",

  about_ourteam_person2_name: "Sabrina Uppal (MS, PMP)",

  about_ourteam_person2_title: "Co-Fondatore e COO",

  about_ourteam_person2_content:
    "Sabrina si unisce a Civy con esperienze di qualità e realizza con successo molteplici progetti di avvio e ricerca in settori privati ​​e governativi. Il suo talento creativo unito a una mentalità innovativa, le consente di progettare e offrire valore ai clienti. Inoltre, Sabrina possiede una vasta esperienza nella gestione di contratti governativi per organizzazioni contratte dal Dipartimento della Difesa degli Stati Uniti. Sabrina ha esperienza diretta nel cercare di coinvolgere la sua città o un'agenzia governativa per ottenere un risultato. Queste esperienze guidano la sua passione e creatività per aiutare le città e i governi a favorire l'impegno di alto impatto con i residenti. Sabrina ha conseguito il Bachelor of Science, Neuroscience presso la U.C. Davis; Master of Science, Biologia di Cal State Northridge ed è certificato PMP.",

  about_ourteam_person3_name: "Jinhong Zhang (CPA, MBA, CGMA)",

  about_ourteam_person3_title: "CFO",

  about_ourteam_person3_content:
    "Jinhong Zhang ha oltre 20 anni di esperienza nella pianificazione e gestione aziendale e finanziaria, ricoprendo ruoli dirigenziali come presidente CFO, direttore finanziario e consulente in grandi organizzazioni imprenditoriali. Jinhong ha iniziato la sua carriera con una società Fortune Global 500, dove ha gestito progetti finanziati da Banca Mondiale e Asia Development ciascuno e ha supervisionato l'auditing e l'impegno fiscale con PWC e KPMG sul lato client.",

  about_ourteam_person4_name: "Suketu Modi",

  about_ourteam_person4_title: "CTO",

  about_ourteam_person4_content: `Riconosciuto tra i 100 Great People Managers of India 2019 da Forbes India e premiato come migliore infrastruttura per la progettazione e e-governance intelligente per le città, Suketu è un esperto leader tecnologico e tecnocrate con una vasta esperienza nella consulenza ICT per le principali società di consulenza multinazionali, governi e Alimentatori che funge da CTO per Civy. Suketu vanta oltre 19 anni di esperienza nella consulenza ICT e nell'implementazione di ICT Infrastructure Networks Infrastructure e Smart Services per la progettazione di grandi città / campus e Smart Building Services. Suketu ha lavorato con clienti e clienti negli Stati Uniti, Medio Oriente, Singapore e India.`,

  about_ourteam_description1:
    "Siamo appassionati di aiutare città e governi a promuovere l'innovazione per il miglioramento dei residenti.",

  about_ourteam_description2:
    "Un partner esclusivo di Civy, Infixzon è una società di servizi di consulenza, cloud e sicurezza informatica guidata da veterani del settore con esperienza pluridecennale. Infixzon serve in tutte le regioni di USA, APAC e Medio Oriente, progettando soluzioni per settori verticali come settore pubblico, sanità, servizi finanziari, prodotti farmaceutici e manifatturieri.",

  about_ourteam_description3:
    "Twish Technologies è un team di professionisti con anni di leadership nelle politiche infrastrutturali, studi di fattibilità del progetto, strategia di integrazione e implementazione per infrastrutture a livello di città, comunità intelligenti e città intelligenti. Il loro team comprende uno psicologo organizzativo incentrato sull'impegno della comunità per conto di città e governi.",

  //Terms and conditions page

  ourpolicies_term_of_use_title: "CONDIZIONI D'USO",

  ourpolicies_terms_title: "Termini e condizioni",

  ourpolicies_terms_last_updated: "Ultimo aggiornamento: {day} {month} {year}",

  ourpolicies_terms_content1: `Leggere attentamente i presenti Termini e condizioni ("Termini", "Termini e condizioni") prima di utilizzare il sito Web https://www.civy.co e l'applicazione mobile Civy (insieme o singolarmente, il "Servizio") gestito da Civy Inc ("noi", "noi" o "nostro").`,

  ourpolicies_terms_content2:
    "L'accesso e l'utilizzo del Servizio sono subordinati all'accettazione e al rispetto di questi Termini. Le presenti Condizioni si applicano a tutti i visitatori, utenti e altri che desiderano accedere o utilizzare il Servizio.",

  ourpolicies_terms_content3:
    "Accedendo o utilizzando il Servizio, l'utente accetta di essere vincolato da questi Termini. Se non sei d'accordo con qualsiasi parte dei termini, non hai l'autorizzazione per accedere al Servizio.",

  ourpolicies_terms_Communications_title: "comunicazioni",

  ourpolicies_terms_Communications_content1:
    "Creando un account sul nostro servizio, accetti di iscriverti a newsletter, materiale di marketing o promozionale e altre informazioni che possiamo inviare. Tuttavia, puoi scegliere di non ricevere alcuna o tutte queste comunicazioni da noi seguendo il link di annullamento dell'iscrizione o le istruzioni fornite in qualsiasi e-mail che inviamo.",

  ourpolicies_terms_subscription_title: "Sottoscrizioni",

  ourpolicies_terms_subscription_content1: `Alcune parti del servizio vengono fatturate in base all'iscrizione ("Abbonamento / i"). Ti verrà addebitato in anticipo su base periodica e periodica ("Ciclo di fatturazione"). I cicli di fatturazione vengono impostati su base mensile o annuale, a seconda del tipo di piano di abbonamento selezionato al momento dell'acquisto di un abbonamento.`,

  ourpolicies_terms_subscription_content2:
    "Alla fine di ogni ciclo di fatturazione, l'abbonamento si rinnoverà automaticamente nelle stesse identiche condizioni, a meno che non venga annullato o Civy Inc lo annulli. È possibile annullare il rinnovo dell'abbonamento tramite la pagina di gestione dell'account online o contattando il team di assistenza clienti di Civy Inc.",

  ourpolicies_terms_subscription_content3:
    "È necessario un metodo di pagamento valido, compresa la carta di credito o PayPal, per elaborare il pagamento per l'abbonamento. Dovrai fornire a Civy Inc informazioni di fatturazione accurate e complete, inclusi nome completo, indirizzo, stato, codice postale, numero di telefono e informazioni valide sul metodo di pagamento. Inviando tali informazioni di pagamento, autorizzi automaticamente Civy Inc a addebitare tutte le commissioni di sottoscrizione sostenute attraverso il tuo account a tali strumenti di pagamento.",

  ourpolicies_terms_subscription_content4:
    "Se la fatturazione automatica non dovesse avvenire per qualsiasi motivo, Civy Inc emetterà una fattura elettronica che indica che è necessario procedere manualmente, entro una determinata data di scadenza, con il pagamento completo corrispondente al periodo di fatturazione indicato sulla fattura.",

  ourpolicies_terms_feechanges_title: "Modifiche alle commissioni",

  ourpolicies_terms_feechanges_content1:
    "Civy Inc, a sua esclusiva discrezione e in qualsiasi momento, può modificare le commissioni di abbonamento per gli abbonamenti. Qualsiasi modifica della quota di abbonamento diventerà effettiva alla fine del ciclo di fatturazione in vigore.",

  ourpolicies_terms_feechanges_content2:
    "Civy Inc ti fornirà un ragionevole preavviso di qualsiasi modifica delle commissioni di abbonamento per darti l'opportunità di terminare l'abbonamento prima che tale modifica diventi effettiva.",

  ourpolicies_terms_feechanges_content3:
    "L'uso continuato del Servizio dopo l'entrata in vigore della modifica della Commissione di sottoscrizione costituisce l'accordo per il pagamento dell'importo della Commissione di sottoscrizione modificato.",

  ourpolicies_terms_refund_title: "rimborsi",

  ourpolicies_terms_refund_content:
    "Salvo quando richiesto dalla legge, le quote di abbonamento pagate non sono rimborsabili.",

  ourpolicies_terms_content_title: "Soddisfare",

  ourpolicies_terms_content_description1: `Il nostro servizio consente di pubblicare, collegare, archiviare, condividere e altrimenti rendere disponibili determinate informazioni, testo, grafica, video o altro materiale ("Contenuto"). Sei responsabile per il Contenuto che pubblichi sul o tramite il Servizio, inclusa la sua legalità, affidabilità e adeguatezza.`,

  ourpolicies_terms_content_description2:
    "Pubblicando Contenuti sul o tramite il Servizio, dichiari e garantisci che: (i) il Contenuto è tuo (di tua proprietà) e / o hai il diritto di utilizzarlo e il diritto di concederci i diritti e la licenza come previsto in questi Termini e (ii) che la pubblicazione dei Contenuti sul o tramite il Servizio non viola i diritti alla privacy, i diritti di pubblicità, i diritti d'autore, i diritti di contratto o altri diritti di qualsiasi persona o entità. Ci riserviamo il diritto di chiudere l'account di chiunque abbia violato un copyright.",

  ourpolicies_terms_content_description3:
    "Mantenete tutti i vostri diritti su qualsiasi Contenuto inviato, pubblicato o visualizzato sul o tramite il Servizio e siete responsabili della protezione di tali diritti. Non ci assumiamo alcuna responsabilità e non ci assumiamo alcuna responsabilità per i contenuti che l'utente o qualsiasi post di terzi sul o tramite il Servizio. Tuttavia, pubblicando il Contenuto tramite il Servizio ci concedi il diritto e la licenza per utilizzare, modificare, eseguire, visualizzare, riprodurre e distribuire tale Contenuto sul e tramite il Servizio.",

  ourpolicies_terms_content_description4:
    "Civy Inc ha il diritto ma non l'obbligo di monitorare e modificare tutti i Contenuti forniti dagli utenti.",

  ourpolicies_terms_content_description5:
    "Inoltre, i contenuti trovati su o tramite questo servizio sono di proprietà di Civy Inc o utilizzati con l'autorizzazione. Non è possibile distribuire, modificare, trasmettere, riutilizzare, scaricare, ripubblicare, copiare o utilizzare tali Contenuti, in tutto o in parte, per scopi commerciali o per guadagno personale, senza espressa autorizzazione scritta da parte nostra.",

  ourpolicies_terms_account_title: "conti",

  ourpolicies_terms_account_content1:
    "Quando crei un account con noi, garantisci di avere più di 18 anni e che le informazioni che ci fornisci sono accurate, complete e aggiornate in ogni momento. Informazioni inaccurate, incomplete o obsolete possono comportare la chiusura immediata del tuo account sul Servizio.",

  ourpolicies_terms_account_content2:
    "Sei responsabile del mantenimento della riservatezza del tuo account e della tua password, incluso ma non limitato alla limitazione dell'accesso al tuo computer e / o account. Accetti di accettare la responsabilità per tutte le attività o azioni che si verificano sotto il tuo account e / o password, sia che la password sia con il nostro Servizio o un servizio di terze parti. È necessario avvisarci immediatamente dopo essere venuti a conoscenza di qualsiasi violazione della sicurezza o utilizzo non autorizzato del proprio account.",

  ourpolicies_terms_account_content3:
    "Non è possibile utilizzare come nome utente il nome di un'altra persona o entità o che non è legalmente disponibile per l'uso, un nome o un marchio soggetto a diritti di un'altra persona o entità diversa da te, senza un'autorizzazione appropriata. Non è possibile utilizzare come nome utente alcun nome offensivo, volgare o osceno.",

  ourpolicies_terms_Intellectual_title: "Proprietà intellettuale",

  ourpolicies_terms_Intellectual_content1:
    "Il servizio e il suo contenuto originale (esclusi i contenuti forniti dagli utenti), le caratteristiche e le funzionalità sono e rimarranno di proprietà esclusiva di Civy Inc e dei suoi licenziatari. Il servizio è protetto da copyright, marchi e altre leggi degli Stati Uniti e di paesi stranieri. I nostri marchi e abiti commerciali non possono essere utilizzati in relazione a prodotti o servizi senza il previo consenso scritto di Civy Inc.",

  ourpolicies_terms_links_title: "Collegamenti ad altri siti Web",

  ourpolicies_terms_links_content1:
    "Il nostro servizio può contenere collegamenti a siti Web o servizi di terzi che non sono di proprietà o controllati da Civy Inc",

  ourpolicies_terms_links_content2:
    "Civy Inc non ha alcun controllo e non si assume alcuna responsabilità per il contenuto, le politiche sulla privacy o le pratiche di siti Web o servizi di terze parti. Non garantiamo le offerte di nessuna di queste entità / persone o dei loro siti Web.",

  ourpolicies_terms_links_content3:
    "Riconosci e accetti che Civy Inc non sarà responsabile o responsabile, direttamente o indirettamente, per eventuali danni o perdite causati o presumibilmente causati da o in connessione con l'uso o l'affidamento a tali contenuti, beni o servizi disponibili su o attraverso tali siti Web o servizi di terzi.",

  ourpolicies_terms_links_content4:
    "Ti consigliamo vivamente di leggere i termini e le condizioni e le politiche sulla privacy di qualsiasi sito Web o servizio di terze parti che visiti.",

  ourpolicies_terms_termination_title: "fine",

  ourpolicies_terms_termination_content1:
    "Possiamo chiudere o sospendere il tuo account e vietare l'accesso al Servizio immediatamente, senza preavviso o responsabilità, a nostra esclusiva discrezione, per qualsiasi motivo e senza limitazione, incluso ma non limitato a una violazione dei Termini.",

  ourpolicies_terms_termination_content2:
    "Se desideri chiudere il tuo account, puoi semplicemente interrompere l'utilizzo del Servizio.",

  ourpolicies_terms_termination_content3:
    "Tutte le disposizioni dei Termini che per loro natura dovrebbero sopravvivere alla risoluzione sopravvivranno alla risoluzione, inclusi, a titolo esemplificativo, disposizioni sulla proprietà, dichiarazioni di non responsabilità, indennità e limitazioni di responsabilità.",

  ourpolicies_terms_Indemnification_title: "Indennizzo",

  ourpolicies_terms_Indemnification_content1:
    "Accetti di difendere, indennizzare e tenere innocui Civy Inc e i suoi licenziatari e licenziatari e i loro dipendenti, appaltatori, agenti, funzionari e direttori, da e contro qualsiasi rivendicazione, danno, obbligazione, perdita, responsabilità, costo o debito, e le spese (incluse, a titolo esemplificativo e non esaustivo, le spese legali), derivanti o derivanti da a) il tuo uso e accesso al Servizio, da parte tua o di qualsiasi persona che utilizza il tuo account e la tua password; b) una violazione di questi Termini, o c) Contenuto pubblicato sul Servizio.",

  ourpolicies_terms_limitation_title: "Limitazione di responsabilità",

  ourpolicies_terms_limitation_content1:
    "In nessun caso Civy Inc, né i suoi amministratori, dipendenti, partner, agenti, fornitori o affiliati, saranno ritenuti responsabili per eventuali danni indiretti, incidentali, speciali, consequenziali o punitivi, inclusi, a titolo esemplificativo, perdita di profitti, dati, utilizzo, buona volontà o altre perdite immateriali, derivanti da",

  ourpolicies_terms_limitation_list1:
    "L'accesso o l'utilizzo o l'impossibilità di accedere o utilizzare il Servizio",

  ourpolicies_terms_limitation_list2:
    "Qualsiasi comportamento o contenuto di terze parti sul Servizio",

  ourpolicies_terms_limitation_list3:
    "Qualsiasi contenuto ottenuto dal servizio",

  ourpolicies_terms_limitation_list4:
    "Accesso, uso o alterazione non autorizzati delle vostre trasmissioni o contenuti, basati su garanzia, contratto, illecito (inclusa negligenza) o qualsiasi altra teoria legale, indipendentemente dal fatto che siamo stati informati della possibilità di tale danno, e anche se un rimedio impostato si è riscontrato che il presente documento non ha rispettato il suo scopo essenziale.",

  ourpolicies_terms_disclaimer_title: "disconoscimento",

  ourpolicies_terms_disclaimer_content1: `L'utilizzo del servizio è a rischio esclusivo dell'utente. Il servizio è fornito "COSÌ COM'È" e "COME DISPONIBILE". Il Servizio è offerto senza garanzie di alcun tipo, esplicite o implicite, incluse, a titolo esemplificativo, garanzie implicite di commerciabilità, idoneità per uno scopo particolare, non violazione o corso della prestazione.`,

  ourpolicies_terms_disclaimer_content2:
    "Civy Inc non è garantito dalle sue sussidiarie, affiliate e dai suoi licenziatari",

  ourpolicies_terms_disclaimer_list1:
    "Il servizio funzionerà ininterrottamente, sicuro o disponibile in qualsiasi momento o luogo particolare",

  ourpolicies_terms_disclaimer_list2:
    "Eventuali errori o difetti saranno corretti",

  ourpolicies_terms_disclaimer_list3:
    "Il servizio è privo di virus o altri componenti dannosi",

  ourpolicies_terms_disclaimer_list4:
    "I risultati dell'utilizzo del Servizio soddisferanno le tue esigenze.",

  ourpolicies_terms_exclusion_title: "esclusioni",

  ourpolicies_terms_exclusion_content1:
    "Alcune giurisdizioni non consentono l'esclusione di determinate garanzie o l'esclusione o la limitazione di responsabilità per danni consequenziali o incidentali, pertanto le limitazioni di cui sopra potrebbero non essere applicabili.",

  ourpolicies_terms_governing_title: "Legge governativa",

  ourpolicies_terms_governing_content1:
    "Le presenti Condizioni saranno regolate e interpretate in conformità con le leggi della California, negli Stati Uniti, indipendentemente dalle disposizioni sul conflitto di leggi.",

  ourpolicies_terms_governing_content2:
    "La nostra incapacità di far valere qualsiasi diritto o disposizione di questi Termini non sarà considerata una rinuncia a tali diritti. Se una disposizione di questi Termini è ritenuta non valida o non applicabile da un tribunale, le restanti disposizioni di questi Termini rimarranno in vigore. I presenti Termini costituiscono l'intero accordo tra noi in merito al nostro Servizio e sostituiscono e sostituiscono eventuali accordi precedenti che potremmo aver avuto tra noi in merito al Servizio.",

  ourpolicies_terms_changes_title: "I cambiamenti",

  ourpolicies_terms_changes_content1:
    "Ci riserviamo il diritto, a nostra esclusiva discrezione, di modificare o sostituire questi Termini in qualsiasi momento. Se una revisione è rilevante, forniremo un preavviso di almeno 30 giorni prima dell'entrata in vigore di eventuali nuovi termini. Ciò che costituisce un cambiamento sostanziale sarà determinato a nostra esclusiva discrezione.",

  ourpolicies_terms_changes_content2:
    "Continuando ad accedere o utilizzare il nostro Servizio dopo che le revisioni diventano effettive, l'utente accetta di essere vincolato dai termini rivisti. Se non si accettano i nuovi termini, non si è più autorizzati a utilizzare il Servizio.",

  ourpolicies_terms_contactus_title: "Contattaci",

  ourpolicies_terms_contactus_content:
    "In caso di domande su questi Termini, vi preghiamo di contattarci all'indirizzo",

  //Privacy policies page

  ourpolicies_policy_title: "politica sulla riservatezza",

  ourpolicies_policy_date: "Data di entrata in vigore: {day} {month} {year}",

  ourpolicies_policy_content1: `Civy Inc. ("noi", "noi" o "nostro") gestisce il sito https://www.civy.co	
    sito Web e l'applicazione mobile Civy (di seguito denominata "	
    "Servizio"). `,

  ourpolicies_policy_content2:
    " Questa pagina ti informa delle nostre politiche riguardanti la raccolta, l'uso e la divulgazione dei dati personali quando usi il nostro Servizio e le scelte che hai associato a tali dati.",

  ourpolicies_policy_content3:
    " Utilizziamo i tuoi dati per fornire e migliorare il Servizio. Utilizzando il Servizio, accetti la raccolta e l'utilizzo delle informazioni in conformità con questa politica. Salvo quanto diversamente definito nella presente Informativa sulla privacy, i termini utilizzati nella presente Informativa sulla privacy hanno gli stessi significati dei nostri Termini e condizioni.",

  ourpolicies_definitions_title: "definizioni",

  ourpolicies_service_title: "Servizio",

  ourpolicies_service_content: `Servizio indica il sito Web https://www.civy.co e il cellulare Civy	
    applicazione gestita da Civy Inc.`,

  ourpolicies_personaldata_title: "Dati personali",

  ourpolicies_personaldata_content:
    "Dati personali significa dati su un individuo vivente che possono essere identificati da tali dati (o da quelle e altre informazioni in nostro possesso o che potrebbero venire in nostro possesso).",

  ourpolicies_usagedata_title: "Dati di utilizzo",

  ourpolicies_usagedata_content:
    "I dati di utilizzo sono dati raccolti automaticamente generati dall'uso del servizio o dall'infrastruttura del servizio stesso (ad esempio, la durata di una visita alla pagina).",

  ourpolicies_cookie_title: "Cookies",

  ourpolicies_cookie_content:
    "I cookie sono piccoli file memorizzati sul tuo dispositivo (computer o dispositivo mobile).",

  ourpolicies_datacontroller_title: "Titolare del trattamento",

  ourpolicies_datacontroller_content1:
    "Titolare del trattamento indica la persona fisica o giuridica che (da sola o congiuntamente o in comune con altre persone) determina le finalità per le quali e il modo in cui qualsiasi informazione personale deve o deve essere elaborata.",

  ourpolicies_datacontroller_content2:
    "Ai fini della presente Informativa sulla privacy, siamo un Titolare del trattamento dei dati personali dell'utente.",

  ourpolicies_dataprocessor_title:
    "Responsabili del trattamento (o fornitori di servizi)",

  ourpolicies_dataprocessor_content1:
    "Responsabile del trattamento (o fornitore di servizi) indica qualsiasi persona fisica o giuridica che elabora i dati per conto del Titolare.",

  ourpolicies_dataprocessor_content2:
    "Potremmo utilizzare i servizi di vari fornitori di servizi al fine di elaborare i dati in modo più efficace.",

  ourpolicies_datasubject_title: "Interessato (o utente)",

  ourpolicies_datasubject_content:
    "L'interessato è qualsiasi individuo vivente che utilizza il nostro servizio ed è soggetto di dati personali.",

  ourpolicies_information_title: "Raccolta e utilizzo delle informazioni",

  ourpolicies_information_content:
    "Raccogliamo diversi tipi di informazioni per vari scopi per fornire e migliorare il nostro servizio.",

  ourpolicies_typeofdata_title: "Tipi di dati raccolti",

  ourpolicies_typeofdata_personal_title: "Dati personali",

  ourpolicies_typeofdata_personal_content1: `Durante l'utilizzo del nostro servizio, potremmo chiederti di fornirci determinati	
    informazioni di identificazione personale che possono essere utilizzate per contattare o	
    identificarti ("Dati personali"). Informazioni identificabili personalmente possono	
    includere, ma non è limitato a:`,

  ourpolicies_typeofdata_personal_list1: `Indirizzo email`,

  ourpolicies_typeofdata_personal_list2: `Nome e cognome`,

  ourpolicies_typeofdata_personal_list3: `Numero di telefono`,

  ourpolicies_typeofdata_personal_list4: `Indirizzo, stato, provincia, codice postale, città`,

  ourpolicies_typeofdata_personal_list5: `Cookie e dati di utilizzo`,

  ourpolicies_typeofdata_personal_content2: `Possiamo utilizzare i tuoi dati personali per contattarti con newsletter, materiale di marketing o promozionale e altre informazioni che potrebbero interessarti. Puoi scegliere di non ricevere nessuna o tutte queste comunicazioni da noi seguendo il link di annullamento dell'iscrizione o le istruzioni fornite in qualsiasi e-mail che inviamo.`,

  ourpolicies_typeofdata_usage_title: `Dati di utilizzo`,

  ourpolicies_typeofdata_usage_content1: `Potremmo anche raccogliere informazioni che il tuo browser invia ogni volta che visiti il ​​nostro Servizio o quando accedi al Servizio da o tramite un dispositivo mobile ("Dati di utilizzo").`,

  ourpolicies_typeofdata_usage_content2: `Questi dati di utilizzo possono includere informazioni come l'indirizzo del protocollo Internet del tuo computer (ad es. Indirizzo IP), tipo di browser, versione del browser, le pagine del nostro servizio che visiti, l'ora e la data della tua visita, il tempo trascorso su tali pagine, unico identificatori del dispositivo e altri dati diagnostici.`,

  ourpolicies_typeofdata_usage_content3: `Quando si accede al Servizio con un dispositivo mobile, questi Dati di utilizzo possono includere informazioni come il tipo di dispositivo mobile utilizzato, l'ID univoco del dispositivo mobile, l'indirizzo IP del dispositivo mobile, il sistema operativo mobile, il tipo di Internet mobile browser utilizzato, identificatori univoci del dispositivo e altri dati diagnostici.`,

  ourpolicies_typeofdata_location_title: `Dati sulla posizione`,

  ourpolicies_typeofdata_location_content1: `Potremmo utilizzare e archiviare informazioni sulla tua posizione se ci autorizzi a farlo ("Dati sulla posizione"). Utilizziamo questi dati per fornire funzionalità del nostro Servizio, per migliorare e personalizzare il nostro Servizio.`,

  ourpolicies_typeofdata_location_content2: `È possibile abilitare o disabilitare i servizi di localizzazione quando si utilizza il nostro servizio in qualsiasi momento tramite le impostazioni del dispositivo.`,

  ourpolicies_typeofdata_tracking_title: `Tracciamento dei dati sui cookie`,

  ourpolicies_typeofdata_tracking_content1: `Utilizziamo cookie e tecnologie di tracciamento simili per tracciare l'attività sul nostro Servizio e conserviamo determinate informazioni.`,

  ourpolicies_typeofdata_tracking_content2: `I cookie sono file con una piccola quantità di dati che possono includere un identificativo univoco anonimo. I cookie vengono inviati al tuo browser da un sito Web e memorizzati sul tuo dispositivo. Vengono utilizzate anche altre tecnologie di tracciamento come beacon, tag e script per raccogliere e tenere traccia delle informazioni e migliorare e analizzare il nostro Servizio.`,

  ourpolicies_typeofdata_tracking_content3: `Puoi indicare al tuo browser di rifiutare tutti i cookie o di indicare quando viene inviato un cookie. Tuttavia, se non si accettano i cookie, potrebbe non essere possibile utilizzare alcune parti del nostro Servizio.`,

  ourpolicies_typeofdata_cookiesex_title: `Esempi di cookie che utilizziamo:`,

  ourpolicies_typeofdata_cookiesex_session: "Cookie di sessione.",

  ourpolicies_typeofdata_cookiesex_session_content: `Utilizziamo i cookie di sessione per gestire il nostro servizio.`,

  ourpolicies_typeofdata_cookiesex_preference: "Cookie di preferenza.",

  ourpolicies_typeofdata_cookiesex_preference_content: `Utilizziamo i cookie di preferenza per ricordare le tue preferenze e varie impostazioni.`,

  ourpolicies_typeofdata_cookiesex_security: `Cookie di sicurezza`,

  ourpolicies_typeofdata_cookiesex_security_content: `Utilizziamo i cookie di sicurezza a fini di sicurezza.`,

  ourpolicies_typeofdata_use_title: `Utilizzo dei dati`,

  ourpolicies_typeofdata_use_content: `Civy Inc. utilizza i dati raccolti per vari scopi:`,

  ourpolicies_typeofdata_use_list1: `Per fornire e mantenere il nostro servizio`,

  ourpolicies_typeofdata_use_list2: `Per informarti delle modifiche al nostro Servizio`,

  ourpolicies_typeofdata_use_list3: `Consentirti di partecipare alle funzionalità interattive del nostro Servizio quando scegli di farlo`,

  ourpolicies_typeofdata_use_list4: `Per fornire assistenza clienti`,

  ourpolicies_typeofdata_use_list5: `Per raccogliere analisi o informazioni preziose in modo che possiamo migliorare il nostro servizio`,

  ourpolicies_typeofdata_use_list8: `Per monitorare l'utilizzo del nostro servizio`,

  ourpolicies_typeofdata_use_list6: `Per rilevare, prevenire e risolvere problemi tecnici`,

  ourpolicies_typeofdata_use_list7: `Per fornirti notizie, offerte speciali e informazioni generali su altri beni, servizi ed eventi che offriamo simili a quelli che hai già acquistato o richiesto a meno che tu non abbia scelto di non ricevere tali informazioni`,

  ourpolicies_typeofdata_legalbasis_title: `Base giuridica per l'elaborazione dei dati personali ai sensi del Regolamento generale sulla protezione dei dati (GDPR)`,

  ourpolicies_typeofdata_legalbasis_content1: `Se l'utente proviene dallo Spazio economico europeo (SEE), la base giuridica di Civy Inc. per la raccolta e l'utilizzo delle informazioni personali descritte nella presente Informativa sulla privacy dipende dai Dati personali che raccogliamo e dal contesto specifico in cui li raccogliamo.`,

  ourpolicies_typeofdata_legalbasis_content2: `Civy Inc. può elaborare i dati personali dell'utente in quanto:`,

  ourpolicies_typeofdata_legalbasis_list1: `Dobbiamo eseguire un contratto con te`,

  ourpolicies_typeofdata_legalbasis_list2: `Ci hai dato il permesso di farlo`,

  ourpolicies_typeofdata_legalbasis_list3: `Il trattamento è nei nostri legittimi interessi e non è sovrascritto dai tuoi diritti`,

  ourpolicies_typeofdata_legalbasis_list4: `Ai fini dell'elaborazione dei pagamenti`,

  ourpolicies_typeofdata_legalbasis_list5: `Per rispettare la legge`,

  ourpolicies_typeofdata_retention_title: `Conservazione dei dati`,

  ourpolicies_typeofdata_retention_content1: `Civy Inc. conserverà i tuoi dati personali solo per il tempo necessario agli scopi indicati nella presente Informativa sulla privacy. Conserveremo e utilizzeremo i tuoi Dati personali nella misura necessaria per ottemperare ai nostri obblighi legali (ad esempio, se ci viene richiesto di conservare i tuoi dati per ottemperare alle leggi applicabili), risolvere controversie e far rispettare i nostri accordi e politiche legali.`,

  ourpolicies_typeofdata_retention_content2: `Civy Inc. conserverà inoltre i Dati di utilizzo a fini di analisi interna. I dati di utilizzo vengono generalmente conservati per un periodo di tempo più breve, tranne quando questi dati vengono utilizzati per rafforzare la sicurezza o migliorare la funzionalità del nostro Servizio, o siamo legalmente obbligati a conservare questi dati per periodi più lunghi.`,

  ourpolicies_typeofdata_transfer_title: `Trasferimento di dati`,

  ourpolicies_typeofdata_transfer_content1: `Le tue informazioni, inclusi i Dati personali, possono essere trasferite e conservate su computer situati al di fuori del tuo stato, provincia, paese o altra giurisdizione governativa in cui le leggi sulla protezione dei dati potrebbero differire da quelle della tua giurisdizione.`,

  ourpolicies_typeofdata_transfer_content2: `Se risiedi al di fuori degli Stati Uniti e scegli di fornirci informazioni, ti preghiamo di notare che trasferiamo i dati, inclusi i Dati personali, negli Stati Uniti e li elaboriamo lì.`,

  ourpolicies_typeofdata_transfer_content3: `Il tuo consenso alla presente Informativa sulla privacy seguito dalla presentazione di tali informazioni rappresenta il tuo consenso a tale trasferimento.`,

  ourpolicies_typeofdata_transfer_content4: `Civy Inc. prenderà tutte le misure ragionevolmente necessarie per garantire che i tuoi dati vengano trattati in modo sicuro e in conformità con la presente Informativa sulla privacy e che nessun trasferimento dei tuoi dati personali avrà luogo a un'organizzazione o un paese a meno che non siano in atto controlli adeguati, tra cui il sicurezza dei tuoi dati e altre informazioni personali.`,

  ourpolicies_disclosure_title: `Divulgazione dei dati`,

  ourpolicies_disclosure_law_title: `Divulgazione per l'applicazione della legge`,

  ourpolicies_disclosure_law_content: `In determinate circostanze, Civy Inc. potrebbe essere tenuta a divulgare i tuoi Dati personali se richiesto dalla legge o in risposta a richieste valide da parte di autorità pubbliche (ad esempio un tribunale o un ente governativo).`,

  ourpolicies_disclosure_requirement_title: `Requisiti legali`,

  ourpolicies_disclosure_requirement_content: `Civy Inc. può divulgare i dati personali dell'utente in buona fede ritenendo che tale azione sia necessaria per:`,

  ourpolicies_disclosure_requirement_list1: `Per ottemperare a un obbligo legale`,

  ourpolicies_disclosure_requirement_list2: `Per proteggere e difendere i diritti o la proprietà di Civy Inc.`,

  ourpolicies_disclosure_requirement_list3: `Prevenire o indagare su possibili illeciti in relazione al Servizio`,

  ourpolicies_disclosure_requirement_list4: `Per proteggere la sicurezza personale degli utenti del Servizio o del pubblico`,

  ourpolicies_disclosure_requirement_list5: `Per proteggere dalla responsabilità legale`,

  ourpolicies_security_title: `Sicurezza dei dati`,

  ourpolicies_security_content: `La sicurezza dei tuoi dati è importante per noi, ma ricorda che nessun metodo di trasmissione su Internet o di archiviazione elettronica è sicuro al 100%. Sebbene ci impegniamo a utilizzare mezzi commercialmente accettabili per proteggere i tuoi dati personali, non possiamo garantirne la sicurezza assoluta.`,

  ourpolicies_security_policy: `La nostra politica - Applicazione generale in tutti gli Stati Uniti d'America, comprese le politiche specifiche per lo Stato della California.`,

  ourpolicies_security_track: `Segnali "Do Not Track" ai sensi della California Online Protection Act (CalOPPA)`,

  ourpolicies_security_track_content1: `Non supportiamo Do Not Track ("DNT"). Non tenere traccia è una preferenza che è possibile impostare nel browser Web per informare i siti Web che non si desidera monitorare.`,

  ourpolicies_security_track_content2: `Puoi abilitare o disabilitare Do Not Track visitando la pagina Preferenze o Impostazioni del tuo browser web.`,

  ourpolicies_security_privacy_policy: `La nostra politica di conformità al California Consumer Privacy Act (CCPA)`,

  ourpolicies_security_privacy_policy1: `Diritto di sapere:`,

  ourpolicies_security_privacy_policy1_content: `I residenti hanno il diritto di richiedere la divulgazione delle informazioni personali raccolte, utilizzate, condivise o vendute da noi.`,

  ourpolicies_security_privacy_policy2: `Diritto di cancellare:`,

  ourpolicies_security_privacy_policy2_content: `I residenti hanno il diritto di richiedere che noi e i nostri fornitori di servizi cancelliamo le informazioni personali che abbiamo su di te.`,

  ourpolicies_security_privacy_policy3: `Diritto di recesso:`,

  ourpolicies_security_privacy_policy3_content: `I residenti hanno il diritto di indirizzarci a cessare la vendita delle loro informazioni personali, nel caso in cui "vendiamo" informazioni personali in quanto tale termine è definito dalla CCPA. Tieni presente che i dati raccolti sulla piattaforma di Civy sono accessibili solo dai clienti di Civy, comprese le aree governative degli Stati Uniti (città, municipali, consigli, agenzie governative, distretti speciali e residenti registrati). Civy non vende o non intende vendere dati a terzi come inserzionisti o privati aziende.`,

  ourpolicies_security_privacy_policy4: `Diritto alla non discriminazione:`,

  ourpolicies_security_privacy_policy4_content: `Non possiamo discriminare i residenti in termini di prezzo o servizio quando esercitano i loro diritti sulla privacy ai sensi della CCPA.`,

  ourpolicies_security_protection_title: `I tuoi diritti di protezione dei dati ai sensi del Regolamento generale sulla protezione dei dati (GDPR)`,

  ourpolicies_security_protection_content1: `Se risiedi nello Spazio economico europeo (SEE), hai determinati diritti di protezione dei dati. Civy Inc. intende adottare misure ragionevoli per consentire all'utente di correggere, modificare, eliminare o limitare l'utilizzo dei propri dati personali.`,

  ourpolicies_security_protection_content2: `Se desideri essere informato su quali Dati personali deteniamo su di te e se desideri che vengano rimossi dai nostri sistemi, ti preghiamo di contattarci.`,

  ourpolicies_security_protection_content3: `In determinate circostanze, hai i seguenti diritti di protezione dei dati:`,

  ourpolicies_security_access_title: `Il diritto di accedere, aggiornare o eliminare le informazioni che abbiamo su di te.`,

  ourpolicies_security_access_content1: `Quando possibile, puoi accedere, aggiornare o richiedere la cancellazione dei tuoi dati personali direttamente nella sezione delle impostazioni del tuo account. Se non sei in grado di eseguire queste azioni da solo, ti preghiamo di contattarci per aiutarti.`,

  ourpolicies_security_rectification_title: `Il diritto di rettifica.`,

  ourpolicies_security_rectification_content: `hai il diritto di correggere le tue informazioni se tali informazioni sono inaccurate o incomplete.`,

  ourpolicies_security_object_title: `Il diritto di opporsi.`,

  ourpolicies_security_object_content: `Hai il diritto di opporti al nostro trattamento dei tuoi dati personali.`,

  ourpolicies_security_restriction_title: `Il diritto di restrizione.`,

  ourpolicies_security_restriction_content: `Hai il diritto di richiedere che limitiamo il trattamento dei tuoi dati personali.`,

  ourpolicies_security_portability_title: `Il diritto alla portabilità dei dati.`,

  ourpolicies_security_portability_content: `Hai il diritto di ricevere una copia delle informazioni che abbiamo su di te in un formato strutturato, leggibile da una macchina e di uso comune.`,

  ourpolicies_security_withdraw_title: `Il diritto di revocare il consenso.`,

  ourpolicies_security_withdraw_content: `Hai anche il diritto di revocare il tuo consenso in qualsiasi momento in cui Civy Inc. ha fatto affidamento sul tuo consenso per elaborare le tue informazioni personali.`,

  ourpolicies_security_protection_content4: `Ti preghiamo di notare che potremmo chiederti di verificare la tua identità prima di rispondere a tali richieste.`,

  ourpolicies_security_protection_content5: `Hai il diritto di presentare reclamo a un'autorità di protezione dei dati sulla nostra raccolta e utilizzo dei tuoi dati personali. Per ulteriori informazioni, si prega di contattare l'autorità locale per la protezione dei dati nello Spazio economico europeo (SEE).`,

  ourpolicies_serviceprovider: `Fornitori di servizi`,

  ourpolicies_serviceprovider_content1: `Possiamo impiegare società e soggetti terzi per facilitare il nostro servizio ("fornitori di servizi"), fornire il servizio per nostro conto, eseguire servizi relativi al servizio o aiutarci ad analizzare come viene utilizzato il nostro servizio.`,

  ourpolicies_serviceprovider_content2: `Queste terze parti hanno accesso ai Dati personali dell'utente solo per eseguire queste attività per nostro conto e sono obbligate a non divulgare o utilizzare tali dati per altri scopi.`,

  ourpolicies_serviceprovider_analytics: `analitica`,

  ourpolicies_serviceprovider_analytics_content: `È possibile che vengano utilizzati fornitori di servizi di terze parti per monitorare e analizzare l'utilizzo del nostro servizio.`,

  ourpolicies_serviceprovider_ganalytics: `statistiche di Google`,

  ourpolicies_serviceprovider_ganalytics_content1: `Google Analytics è un servizio di analisi web offerto da Google che tiene traccia e segnala il traffico del sito Web. Google utilizza i dati raccolti per tracciare e monitorare l'utilizzo del nostro Servizio. Questi dati sono condivisi con altri servizi di Google. Google può utilizzare i dati raccolti per contestualizzare e personalizzare gli annunci della propria rete pubblicitaria.`,

  ourpolicies_serviceprovider_ganalytics_content2: `Per ulteriori informazioni sulle pratiche sulla privacy di Google, visitare la pagina Web dei Termini sulla privacy di Google: https://policies.google.com/privacy?hl=it`,

  ourpolicies_serviceprovider_firebase: `Firebase`,

  ourpolicies_serviceprovider_firebase_content1: `Firebase è un servizio di analisi fornito da Google Inc.`,

  ourpolicies_serviceprovider_firebase_content4: `Puoi disattivare determinate funzionalità di Firebase tramite le impostazioni del tuo dispositivo mobile, come le impostazioni di pubblicità del tuo dispositivo o seguendo le istruzioni fornite da Google nella loro Informativa sulla privacy: https://policies.google.com/privacy?hl=it`,

  ourpolicies_serviceprovider_firebase_content2: `Ti invitiamo inoltre a rivedere le norme di Google per la protezione dei tuoi dati: https://support.google.com/analytics/answer/6004245.`,

  ourpolicies_serviceprovider_firebase_content3: `Per ulteriori informazioni su quale tipo di informazioni Firebase raccoglie, si prega di visitare la pagina web dei Termini sulla privacy di Google: https://policies.google.com/privacy?hl=it`,

  ourpolicies_serviceprovider_payments: `pagamenti`,

  ourpolicies_serviceprovider_payment_content1: `Potremmo fornire prodotti e / o servizi a pagamento all'interno del Servizio. In tal caso, utilizziamo servizi di terze parti per l'elaborazione dei pagamenti (ad esempio i processori di pagamento).`,

  ourpolicies_serviceprovider_payment_content2: `Non memorizzeremo o raccoglieremo i dettagli della tua carta di pagamento. Tali informazioni vengono fornite direttamente ai nostri processori di pagamento di terze parti il ​​cui utilizzo delle informazioni personali è regolato dalla loro Informativa sulla privacy. Questi processori di pagamento aderiscono agli standard stabiliti da PCI-DSS come gestiti dal PCI Security Standards Council, che è uno sforzo congiunto di marchi come Visa MasterCard, American Express e Discover. I requisiti PCI-DSS aiutano a garantire la gestione sicura delle informazioni di pagamento.`,

  ourpolicies_serviceprovider_payment_content3: `I processori di pagamento con cui lavoriamo sono:`,

  ourpolicies_serviceprovider_stripe: `Stripe`,

  ourpolicies_serviceprovider_stripe_content: `La loro Informativa sulla privacy può essere visualizzata su https://stripe.com/us privacy`,

  ourpolicies_linksto_title: `Collegamenti ad altri siti`,

  ourpolicies_linksto_content1: `Il nostro servizio può contenere collegamenti ad altri siti che non sono gestiti da noi. Se fai clic su un link di terze parti, verrai indirizzato al sito di tale terza parte. Ti consigliamo vivamente di rivedere l'informativa sulla privacy di ogni sito che visiti.`,

  ourpolicies_linksto_content2: `Non abbiamo alcun controllo e non ci assumiamo alcuna responsabilità per il contenuto, le politiche sulla privacy o le pratiche di siti o servizi di terze parti.`,

  ourpolicies_children_title: `Privacy dei minori`,

  ourpolicies_children_content1: `Il nostro servizio non si rivolge a nessuno di età inferiore ai 18 anni ("Bambini").`,

  ourpolicies_children_content2: `Non raccogliamo consapevolmente informazioni di identificazione personale da persone di età inferiore ai 18 anni. Se sei un genitore o tutore e sei consapevole che tuo Figlio ci ha fornito i Dati personali, ti preghiamo di contattarci. Se veniamo a conoscenza del fatto che abbiamo raccolto dati personali da minori senza la verifica del consenso dei genitori, adottiamo misure per rimuovere tali informazioni dai nostri server.`,

  ourpolicies_changes_title: `Modifiche alla presente Informativa sulla privacy`,

  ourpolicies_changes_content1: `Di tanto in tanto potremmo aggiornare la nostra Informativa sulla privacy. Ti informeremo di eventuali modifiche pubblicando la nuova Informativa sulla privacy in questa pagina.`,

  ourpolicies_changes_content2: `Ti informeremo via e-mail e / o un avviso di rilievo sul nostro Servizio prima che la modifica diventi effettiva e aggiorneremo la "data di validità" nella parte superiore della presente Informativa sulla privacy.`,

  ourpolicies_changes_content3: `Si consiglia di rivedere periodicamente la presente Informativa sulla privacy per eventuali modifiche. Le modifiche alla presente Informativa sulla privacy sono efficaci quando vengono pubblicate su questa pagina.`,

  ourpolicies_contactus_title: `Contattaci`,

  ourpolicies_contactus_content1: `In caso di domande sulla presente Informativa sulla privacy, non esitate a contattarci:`,

  ourpolicies_contactus_content2: `Per e-mail:`,

  //Registration page

  dob_validation: "L'età dovrebbe essere almeno di 16 anni",

  register_regi_title: "Registra account",

  register_organization_label: "Nome dell'organizzazione{star}",
  profile_name_label:"nome{star}",

  register_dob_error: "*È richiesta la data di nascita",

  register_age_error: "*Seleziona la tua fascia d'età.",

  date_invalid_error: "Inserisci la data valida.",

  register_organization_error: "* È richiesto il nome dell'organizzazione",
  profile_name_error:"*Il nome è obbligatorio",

  register_organization_type_label: "Tipo di Organizzazione{star}",
  profile_type_label: "Tipo{star}",

  register_organization_title_label: "Titolo dell'organizzazione{star}",
  profile_title_label: "titolo{star}",

  register_organization_title_error: "* È richiesto il titolo",

  register_browse_label: "Navigare...",

  register_upload_label: "Carica il logo",

  register_upload_error: "* Seleziona il logo",

  register_are_you_label: "Sei un{star}",

  register_are_you_resident: "Residente negli Stati Uniti",

  register_are_you_citizen: "Cittadino statunitense",

  register_are_you_prefer: "Preferisco non rispondere",

  register_are_you_error: "* Seleziona Tipo",

  register_organization_type_error: "* Seleziona il tipo di organizzazione",
  profile_type_error: "*si prega di selezionare il tipo",

  register_your_title_label: "titolo{star}",

  register_your_title_list1: "Sig.",

  register_your_title_list2: "Perdere.",

  register_your_title_list3: "Sig.ra.",

  register_your_title_list4: "Dr.",

  register_your_title_list5: "SM.",

  register_your_title_error: "* Seleziona il tuo titolo",

  register_name_label: "nome{star}",

  register_name_error1: "* È richiesto il nome",

  register_name_error2:
    "Ci dovrebbe essere un limite di 15-20 di testo consentito.",

  register_last_name_label: "cognome{star}",

  register_last_name_error1: "* Cognome richiesto",

  register_last_name_error2:
    "Ci dovrebbe essere un limite di 15-20 di testo consentito.",

  register_ethinicicty_label: "Razza{star}",

  register_ethinicicty_error: "* Seleziona un'etinicità",

  register_email_label: "E-mail{star}",

  register_email_error1: "* Email obbligatoria ",

  register_email_error2: "Inserisci indirizzo email valido",

  register_mobile_label: "numero di cellulare{star}",

  register_mobile_error1: "* È richiesto il numero di cellulare",

  register_gender_label: "Genere{star}",

  register_gender_type1: "Maschio",

  register_gender_type2: "Femmina",

  register_gender_type3: "Altro",

  register_gender_error: "* Seleziona Sesso",

  register_birth_label: "Data di nascita{star}",

  register_street_label: "Indirizzo{star}",

  register_street_error: "* È richiesto Street Addess",

  register_apartment_label: "Appartamento / Suite{star}",

  register_apartment_error: "* È richiesto l'appartamento / suite",

  register_country_label: "Nazione{star}",

  register_country_error: "* Seleziona Paese",

  register_state_label: "Stato{star}",

  register_state_error: "* Seleziona lo stato",

  register_city_label: "Città{star}",

  register_county_label: "contea{star}",

  register_city_error: "* Seleziona una città",

  register_zip_label: "Cap{star}",

  register_zip_error: "* È richiesto il campo Codice postale",

  register_zip2_label: "+4 codice",

  register_password_label: "Parola d'ordine{star}",

  register_password_error1: "* Il campo password è obbligatorio",

  register_password_error2:
    "Le password devono contenere almeno 8 caratteri, inclusi lettere maiuscole e minuscole e numeri.",

  register_terms1_label: "sono d'accordo con",

  register_terms2_label: "Condizioni d'uso",

  register_terms3_label: "e",

  register_terms4_label: "politica sulla riservatezza",

  register_terms_error:
    "* Si prega di indicare che si accettano i Termini e la politica sulla privacy",

  register_already_account: "Hai già un account,",

  register_signin_label: "Registrati",

  register_or_signin_label: "o Accedi con",

  register_registerbutton_label: "Registrati",

  register_organization_type1: "Città",

  register_organization_type2: "Governo",

  register_organization_type3: "Comunale",

  register_organization_type4: "Distretti di miglioramento aziendale",

  register_organization_type5: "Think Tank",

  register_organization_type6: "Higher-Ed",

  register_ethinicity_type1: "Afroamericano",

  register_ethinicity_type2: "Indiano americano o nativo dell'Alaska",

  register_ethinicity_type3: "Isole asiatiche o del Pacifico",

  register_ethinicity_type4: "caucasico",

  register_ethinicity_type5: "ispanico o latino",

  register_ethinicity_type6:
    "Hawaiians nativi o altri abitanti delle isole del Pacifico",

  register_ethinicity_type7: "Multirazziale",

  register_ethinicity_type8: "Altro",

  register_ethinicity_type9: "Preferisco non rispondere",

  register_profile_change_password: "Cambia la password",

  register_create_password_change: "Cambia la tua password",

  register_create_password_create: "crea la tua password",

  register_create_password_old: "vecchia password*",

  register_create_password_new: "nuova password*",

  register_create_password_confirm: "conferma password*",

  register_create_password_not_match: "*La password non coincide",

  register_create_password_cotinue: "Continua",

  register_collegues_add_collegues: "Aggiungi colleghi",

  register_department_label:
    "Dipartimento{star}",

  register_department_error:
    "* Dipartimento è richiesto",

  register_member_no_colleagues: "Nessun collega aggiunto",

  register_member_colleagues_list: "Elenco dei colleghi",
  register_member_colleague_list: "Elenco dei colleghi",

  register_member_delete: "Elimina",

  register_profile_upload_resident: "Carica dati residenti",

  register_profile_batch_upload: "Caricamento in batch",

  register_profile_resident_details: "Modello dettagli residenti",

  //Login page

  login_signin_label: "registrati",

  login_email_label: "Email",

  login_email_error1: "* È richiesta l'email",

  login_email_error2: "*Inserisci indirizzo email valido",

  login_password_label: "Parola d'ordine",

  login_password_error1: "* Il campo password è obbligatorio",

  login_password_error2:
    "Le password devono contenere almeno 8 caratteri, inclusi lettere maiuscole e minuscole e numeri.",

  login_checkbox_label: "Ricordati di me",

  login_loginbutton_label: "Accesso",

  login_orsignin_label: "o Accedi con",

  login_forgot_password_label: "Ha dimenticato la password?",

  login_registration_label1: "Non hai un account?",

  login_resident_label1: "Crea un account residente",

  login_resident_label2: "Crea un account",

  login_organization_label1: "Crea un account dell'organizzazione",

  login_forgot_password_submit: "Invia",

  //404 Not found Page and thank you page

  notfound_thankyou_content1: "Per aver scelto Civy,",

  notfound_thankyou_content2:
    "Si prega di controllare la tua email. Abbiamo inviato a",

  notfound_thankyou_content3: "una e-mail di conferma.",

  notfound_thankyou_content4: "Tornare a casa",

  //header banner

  header_banner_about: "di",

  header_banner_contactus: "Contattaci",

  header_banner_service: "Servizi",

  header_banner_outpolicies: "Le nostre politiche",

  header_banner_project: "Progetto",

  header_banner_thought_leadership: "Leadership di pensiero",

  header_banner_how_to: "Come",

  header_home_banner:
    "COLLEGARE GOVERNO E COMUNITÀ ATTRAVERSO PIATTAFORME INNOVATIVE",

  header_banner_colleagues: "Colleghi",

  header_banner_change_password: "Cambia la password",

  header_banner_create_password: "Crea password",

  header_banner_survey: "Sondaggio",

  header_banner_what_is_civy: "CHE COS'È CIVY",

  header_banner_qr_code_management: "Gestione del codice QR",

  //header.........................

  header_home: "CASA",

  header_core: "Core 19",

  header_KIOSK: "Chiosco",

  header_service: "SERVIZI",

  header_project: "PROGETTI",

  header_project_engage: "ENGAGE",

  header_thought_leadership: "DIREZIONE DI PENSIERO",

  header_about: "DI",

  header_login: "ACCESSO",

  // header_language1: "inglese",

  // header_language2: "francese",

  // header_language3: "spagnolo",

  // header_language4: "hindi",

  // header_language5: "Mandarino",

  // header_language6: "italiano",

  // header_language7: "vietnamita",

  header_language1: "EN",

  header_language2: "FR",

  header_language3: "ES",

  header_language4: "HI",

  header_language5: "ZH",

  header_language6: "IT",

  header_language7: "VI",

  header_header_drawer_welcome: "benvenuto,",

  header_header_drawer_admin_profile: "Profilo amministratore",

  header_header_drawer_profile: "Profilo",

  header_header_drawer_colleagues: "Colleghi",

  header_header_drawer_op_ed: "OP-ED",

  header_header_drawer_how_to: "Come",

  header_header_drawer_logout: "Disconnettersi",

  header_what_is_civy: "CHE COS'È CIVY",

  header_qr_code_management: "Gestione del codice QR",

  //footer........................

  footer_contactus_title: "Contattaci",

  footer_contactus_content1:
    "Contatta Civy per sapere come abilitare un migliore impegno civico per i tuoi progetti di sviluppo della comunità per i residenti di tutti i gruppi demografici.",

  footer_media: "Media",

  footer_terms_title: "Condizioni d'uso",

  footer_privacy_title: "politica sulla riservatezza",

  footer_google_translate_message: `Civy utilizza Google Translate per convertire dall'inglese in altre lingue disponibili nella piattaforma. A volte, Google Translate potrebbe non fornire una traduzione esatta. Per qualsiasi domanda, non esitate a contattarci all'indirizzo`,

  // blog details page..............

  blog_details_author_title: "Autore",

  blog_details_author_title_star: "Autore{star}",

  blog_details_posted_title: "Pubblicato in::",

  blog_details_latest_article_title: "Ultimo articolo",

  blog_details_op_ed_title: "Civy OP-ED",

  blog_addblog_new_blog: "Aggiungi nuovo OP-ED",

  blog_addblog_title: "Titolo",

  blog_addblog_description_title: "Descrizione",

  blog_addblog_browse_title: "Navigare...",

  blog_addblog_addimage_title: "Aggiungi immagine..",

  blog_addblog_submit_title: "Aggiungi OP-ED",

  blog_editblog_op_ed_title: "Modifica OP-ED",

  blog_common_error: "*Questo campo è obbligatorio",

  blog_title_error: "*È richiesto il titolo",

  blog_desc_error: "*È richiesta la descrizione",

  blog_author_error: "*È richiesto l'autore",

  blog_image_error: "*L'immagine è obbligatoria",

  valid_image_error: "*Le immagini devono essere in formato .png, .jpeg, .jpg",

  //project page.........................

  project_filter_project_type1: "Nuovo progetto",

  project_filter_project_type2: "Progetto esistente",

  project_filter_project_type3: "Riparazioni / Maintainence",

  project_filter_project_type4: "Evento",

  project_filter_project_type5: "Disastro",

  project_filter_project_category1: "verdura",

  project_filter_project_category2: "Pedaggi",

  project_filter_project_category3: "Utilità",

  project_filter_project_category4: "Strade",

  project_filter_project_category5: "autostrade",

  project_filter_project_category6: "Edificio commerciale",

  project_filter_project_category7: "Palazzo residenziale",

  project_filter_project_category8: "Terra natia",

  project_filter_project_category9: "Edifici di club comunitari",

  project_filter_project_category10: "Opere minori",

  project_filter_city_zipcode: "CONTEA, CITTÀ & cerniera lampo",

  project_filter_range: "Gamma",

  project_filter_range1: "miglia",

  project_filter_project_type: "Tipo di progetto",

  project_filter_project_type_star: "Tipo di progetto{star}",

  project_filter_project_category: "Categoria del progetto",

  project_filter_project_category_star: "Categoria del progetto{star}",

  project_location: "Posizione{star}",

  project_street_address: "Indirizzo",

  project_filter_add_new_project: "Aggiungi nuovo progetto",

  project_filter_reset: "Ripristina",

  project_newproject_organization_label: "Dipartimento dell'organizzazione",

  project_newproject_organization_label_star:
    "Dipartimento dell'organizzazione{star}",

  project_newproject_common_error: "*Questo campo è obbligatorio",

  project_newproject_street_label: "strada",

  project_newproject_country_label: "Nazione",

  project_newproject_state_label: "Stato",

  project_newproject_city_label: "Città",

  project_newproject_zipcode_label: "Cap",

  project_newproject_areacode_label: "+4 codice",

  project_newproject_start_date: "Data di inizio del progetto",

  project_newproject_end_date: "Data di fine del progetto",

  project_newproject_cancel: "Annulla",

  project_projectfom_add_project: "Aggiungi progetto",

  publish: "Pubblicare",

  project_newproject_continue: "Continua",

  project_projectlist_category_label: "Categoria:",

  project_projectlist_type_label: "genere:",

  project_projectlist_view_label: "Visualizza progetto",

  project_projectlist_no_project: "Nessun progetto aggiunto",

  project_editproject_edit: "Modifica progetto",

  project_newproject_title_label: "titolo del progetto",

  project_newproject_description: "descrizione del progetto",

  project_newproject_description_star: "descrizione del progetto{star}",

  project_newproject_add_images: "Aggiungi immagini ..",

  project_newproject_add_File: "Carica file PDF...",

  project_commentbox_join_label: "Unisciti alla conversazione",

  project_commentbox_nocomment_label: "Nessun commento aggiunto",

  project_commentbox_comment_label: "{commentlength} Commenti",

  project_details_location: "Posizione",

  project_details_posted: "Postato sopra",

  project_details_share: "Condividi attraverso:",

  project_details_last_update: "Ultimo aggiornamento il",

  project_details_likes: "Mi Piace totali:",

  project_details_question: "Aggiungi domanda",

  project_details_question_list: "Elenco delle domande",

  project_details_no_question: "Nessuna domanda aggiunta",

  project_details_add_answer: "Aggiungi risposta",

  project_details_add_answer_here: "Aggiungi la tua risposta qui ...",

  project_facts_and_figures_label:
    "Aggiornamenti dal personale della città e del governo",

  project_gallery_label: "Galleria del progetto",

  qr_code_label: "Genera codice QR",

  //Survey page............

  survey_select_questionType: "Seleziona il tipo di domanda",

  survey_questionType_content:
    "I dettagli per il tuo progetto sono stati caricati con successo su Civy. Seleziona il tipo di domande che desideri porre ai residenti.",

  survey_questionType_type_a: "Digitare un",

  survey_questionType_type_que1:
    "1. Aggiungi la tua recensione per l'area giochi per bambini.",

  survey_write_here_label: "scrivi qualcosa qui ...",

  survey_questionType_create_survey: "Crea sondaggio",

  survey_questionType_type_b: "Domande a scelta multipla",

  survey_questionType_type_que2:
    "1. Desideri aggiungere un'area giochi per bambini nel giardino pubblico?",

  survey_questionType_type_b_yes: "sì",

  survey_questionType_type_b_no: "No",

  survey_comment_box: "Domanda a risposta aperta (casella di commento)",

  survey_radio_question_label: "Domande",

  survey_discard_label: "Scartare",

  survey_submit_label: "Aggiungi",

  survey_add_question_label: "Aggiungi domanda",

  survey_back_label: "Indietro",

  survey_question_list_label: "Elenco domande",

  dialog_delete_message: "Sei sicuro di voler cancellare",

  delete_lable: "Annulla",

  cancel_label: "Elimina",

  comment: "Commento",

  /* Validation message */

  departmaent_required: "*Se requiere el nombre del departamento",

  project_type_required: "*Por favor seleccione el tipo de proyecto",

  project_category_required: "*Por favor seleccione Categoría de proyecto",

  location_required: "*Se requiere ubicación",

  full_address_required: "*Por favor ingrese la dirección completa",

  only_usa_required: "*Agregue una dirección solo para EE. UU.",

  project_title_requied: "*Titolo del progetto richiesto",

  project_desc_required: "*Descrizione del progetto richiesta",

  project_image_required: "*L'immagine è obbligatoria",

  commonn_update_button: "Aggiornare",

  export_button: "esportare in Excel",

  export_project: "Esporta progetto",

  export_project_data: "Esporta dati progetto",

  from: "A partire dal",

  to: "Per",

  project_required: "*Seleziona Progetto",

  date_range: "Intervallo di date",

  edit_question: "Modifica domanda",

  quetion_labele: "Domanda",

  qustion_required: "*Il campo domanda è obbligatorio",

  click_edit: "Fai clic per modificare il profilo",

  back_label: "Indietro",

  save_label: "Salva",

  draft_label: "Brutta copia",

  upload_label: "Indietro",

  attachment_label: "allegati",

  rating_type_question: "Domande sul tipo di valutazione",

  survey_questionType_type_que3: "Si prega di valutare questo progetto",

  allow_cookie_text: "Utilizziamo i cookie per ottimizzare la tua esperienza, analizzare il traffico e personalizzare i contenuti. Per saperne di più, consulta la nostra Informativa sui cookie. Utilizzando il nostro sito Web senza disabilitare i cookie, acconsenti al nostro utilizzo di essi.",

  allow_btn: "permettere",

  decline_btn: "Declino",

  cookie_policy: "Gestione dei Cookie",

  /* What is civy page */

  civy_intro_title1: "Caratteristiche della piattaforma",

  civy_intro1_point1: "Città e governi accedono alla piattaforma tramite un URL dedicato, ad esempio {link}",

  civy_intro1_point2: "Il cliente ha la possibilità di collegare la nostra piattaforma alle sue pagine dei social media (LinkedIn, Facebook e Twitter) per consentire una maggiore profondità e portata per aumentare la partecipazione della comunità.",

  civy_intro1_point3: "La piattaforma include più lingue.",

  civy_intro1_point4: "I dati vengono archiviati sul servizio cloud di Civy e la sicurezza e la privacy dei dati vengono mantenute con controlli informatici semestrali in conformità con le normative statunitensi su dati e privacy.",

  civy_intro1_point5: "Civy non interagisce con inserzionisti di terze parti, pertanto sia i clienti che le città possono mantenere la certezza di non ricevere e-mail o promozioni non richieste.",

  civy_intro1_point6: "La piattaforma è accessibile da dispositivi mobili, ovunque nel mondo; e Notifiche e-mail e SMS per tutti gli utenti registrati.",

  civy_intro1_point7: "Scansioni di codici QR per progetti (scansionate dai residenti per fornire risposte rapide).",

  civy_intro1_point8: "Accesso a report scaricabili relativi alla propria città, per tutti i progetti o progetti selezionati.",

  civy_intro2_title: "Motivi per cui le organizzazioni devono registrarsi",

  civy_intro2_point1: "Crea un account amministratore con il logo della città e aggiungi fino a 20 colleghi.",

  civy_intro2_point2: "I dati creati dal cliente e il feedback dei residenti sono di proprietà del cliente.",

  civy_intro2_point3: "Il feedback del residente viene fornito direttamente al creatore del progetto; ad esempio, il feedback dei residenti per il progetto creato da Lavori pubblici viene reindirizzato ai lavori pubblici.",

  civy_intro2_point4: "I progetti possono essere creati utilizzando uno o più codici postali; livello di contea a livello di città locale; e condiviso tramite Civy e i tuoi canali di social media collegati. Ciò garantisce che il feedback dei residenti sia direttamente collegato al codice postale del residente (i progetti pubblicati dai progetti della città di New York possono ricevere risposta solo dai residenti di New York).",

  civy_intro2_point5: "Possibilità di scaricare rapporti.",

  civy_intro3_title: "Motivi per la registrazione dei residenti",

  civy_intro3_point1: "Per assicurarti che il tuo profilo sia collegato al codice postale in cui vivi e sarai in grado di rispondere alle domande pubblicate dalla tua città.",

  civy_intro3_point2: "Per assicurarti che le tue risposte siano fornite direttamente da te. Civy non collega, raccoglie o aggrega dati da nessuno dei tuoi altri account di social media. Civy registra solo le tue risposte che hai fornito nella piattaforma di Civy e queste risposte sono visibili solo per la revisione da parte dei funzionari della città che utilizzano la piattaforma.",

  civy_intro3_point3: "Le tue risposte o commenti non sono visibili ai tuoi compagni residenti.",

  civy_intro3_point4: 'Non devi registrarti su Civy come "anonimo" poiché le tue risposte sono riservate e visibili solo dal funzionario cittadino responsabile del progetto.',

  what_is_civy_policies_title: "POLITICHE",

  what_is_civy_policies_content: "PRIVACY E INFORMATIVA SUI DATI",

  //QR code Management page

  qr_code_svg: "Genera come SVG",

  qr_code_png: "Genera come PNG",

  qr_code_jpeg: "Genera come JPEG",

  qr_code: "QR Code",
};
