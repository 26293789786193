import axios from "axios";
import { registerModifyZip } from "../../Registration/action";
export const GET_PLACE = "GET_PLACE";
export const EMPTY_PLACE = "EMPTY_PLACE";
export const GET_ZIP = "GET_ZIP";
export const GET_PLACE_ARRAY = "GET_PLACE_ARRAY";
export const getPlaceData = payload => ({
  type: GET_PLACE,
  payload
});
export const getZipCode = payload => ({
  type: GET_ZIP,
  payload
});

export const getPlace = (body, countyFlag) => {
  const apiUrl =
    "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDHMZZRpftV_4CeKQX46ZeFXEnGH9UfyeM";
  return dispatch => {
    return axios
      .get(apiUrl, {
        params: { address: body }
      })
      .then(response => {
        if (response.data.results[0]) {
          dispatch(getPlaceData(response.data.results));
        }
        const places = response.data.results;
        const getCo =
          places &&
          places[0] &&
          places[0].address_components.map(item =>
            item.types.includes("country") ? item.long_name : undefined
          );
        let filteredCountry =
          getCo &&
          getCo.filter(function (el) {
            return el != null;
          });
        const getSt =
          places &&
          places[0] &&
          places[0].address_components.map(item =>
            item.types.includes("administrative_area_level_1")
              ? item.short_name
              : undefined
          );
        let filteredState =
          getSt &&
          getSt.filter(function (el) {
            return el != null;
          });

        const getCounty =
          places &&
          places[0] &&
          places[0].address_components.map(item =>
            item.types.includes("administrative_area_level_2")
              ? item.long_name
              : undefined
          );
        let filteredCounty =
          getCounty &&
          getCounty.filter(function (el) {
            return el != null;
          });

        const getci =
          places &&
          places[0] &&
          places[0].address_components.map(item =>
            item.types.includes("locality") ? item.long_name : undefined
          );
        let filteredCity =
          getci &&
          getci.filter(function (el) {
            return el != null;
          });

        const getZipcode =
          places &&
          places[0] &&
          places[0].address_components.map(item =>
            item.types.includes("postal_code") ? item.long_name : undefined
          );
        let filteredZipcode =
          getZipcode &&
          getZipcode.filter(function (el) {
            return el != null;
          });

        const filterBody = {
          country:
            filteredCountry && filteredCountry.length === 0
              ? undefined
              : filteredCountry && filteredCountry[0],
          state:
            filteredState && filteredState.length === 0
              ? undefined
              : filteredState && filteredState[0],
          county:
            filteredCounty && filteredCounty.length === 0
              ? undefined
              : filteredCounty && filteredCounty[0],
          city:
            filteredCity && filteredCity.length === 0
              ? undefined
              : filteredCity && filteredCity[0],
          zipcode: filteredZipcode && filteredZipcode.length === 0
            ? undefined
            : filteredZipcode && filteredZipcode[0],
        };
        if (countyFlag) {
          if (filterBody) {
            dispatch(registerModifyZip(filterBody));
          }
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const getPlaceArrayData = payload => ({
  type: GET_PLACE_ARRAY,
  payload
});

export const getArrayPlace = (body, callback) => {
  const apiUrl =
    "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDHMZZRpftV_4CeKQX46ZeFXEnGH9UfyeM";
  return dispatch => {
    return axios
      .get(apiUrl, {
        params: { address: body }
      })
      .then(response => {
        if (response.data.results[0]) {
          dispatch(getPlaceArrayData(response.data.results[0]));
          callback(response.data.results[0]);
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const emptyPlace = payload => ({
  type: EMPTY_PLACE,
  payload
});