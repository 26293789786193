import { TOGGLE_LANG } from "./action";
import Token from "@util/token";
// const locale =
//   window && window.localStorage.getItem("lan")
//     ? window.localStorage.getItem("lan")
//     : "en";
if (Token && Token.getCookie("lan")) {
  var langGet = Token.getCookie("lan");
  var mLang = JSON.parse(langGet);
}
let locale = "en";
if (Token.getCookie("closeCookiePopup") === "allow") {
  if (Token && Token.getCookie("lan")) {
    if (window && window.localStorage.getItem("userToken")) {
      if (mLang && mLang.lan) {
        locale = mLang.lan;
      } else if (window && window.localStorage.getItem("lan")) {
        locale = window.localStorage.getItem("lan");
      }
    } else if (window && window.localStorage.getItem("lan")) {
      locale = window.localStorage.getItem("lan");
    }
  } else {
    if (window && window.localStorage.getItem("lan")) {
      locale = window.localStorage.getItem("lan");
    }
  }
} else {
  if (window && window.localStorage.getItem("lan")) {
    locale = window.localStorage.getItem("lan");
  }
}

const initialstate = {
  no: 0,
  notifyData: {
    open: false,
    message: "",
    duration: 2000,
    severity: "success",
  },
  projectCategories: [],
  locale,
};

// reducers.js
const commonReducer = (state = initialstate, action) => {
  switch (action.type) {
    case "DISPLAY_NOTIFICATION":
      const { open, message, duration, severity } = action.data;
      return {
        ...state,
        notifyData: {
          ...state.notifyData,
          open: open,
          message: message ? message : state.notifyData.message,
          duration: duration ? duration : state.notifyData.duration,
          severity: severity ? severity : state.notifyData.severity,
        },
      };
    case "SET_PROJECT_CATEGORIES":
      return {
        ...state,
        projectCategories: action.payload,
      };
    case TOGGLE_LANG:
      return {
        ...state,
        locale: action.payload,
      };

    default:
      return state;
  }
};

export default commonReducer;
