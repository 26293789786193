import axios from "axios";
import { displayNotification } from "../../../../store/action";
export const GET_ORGANIZATION = "GET_ORGANIZATION";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_REQUEST = "EDIT_PROFILE_REQUEST";
export const EDIT_PROFILE_ERROR = "EDIT_PROFILE_ERROR";
export const EDIT_ORGANIZATION_SUCCESS = "EDIT_ORGANIZATION_SUCCESS";
export const EDIT_ORGANIZATION_REQUEST = "EDIT_ORGANIZATION_REQUEST";
export const EDIT_ORGANIZATION_ERROR = "EDIT_ORGANIZATION_ERROR";
export const EDIT_ORGANIZATION_LOGO_SUCCESS = "EDIT_ORGANIZATION_SUCCESS";
export const EDIT_ORGANIZATION_LOGO_REQUEST = "EDIT_ORGANIZATION__LOGO_REQUEST";
export const EDIT_ORGANIZATION_LOGO_ERROR = "EDIT_ORGANIZATION_LOGO_ERROR";

export const getOrganizationData = payload => ({
  type: GET_ORGANIZATION,
  payload
});
export const editProfileDataRequest = () => ({
  type: EDIT_PROFILE_REQUEST
});
export const editProfileDataSuccess = payload => ({
  type: EDIT_PROFILE_SUCCESS,
  payload
});
export const editProfileDataFailure = payload => ({
  type: EDIT_PROFILE_ERROR,
  payload
});
export const editOrganizationDataRequest = () => ({
  type: EDIT_ORGANIZATION_REQUEST
});
export const editOrganizationDataSuccess = payload => ({
  type: EDIT_ORGANIZATION_SUCCESS,
  payload
});
export const editOrganizationDataFailure = payload => ({
  type: EDIT_ORGANIZATION_ERROR,
  payload
});

export const editOrganizationLogoRequest = () => ({
  type: EDIT_ORGANIZATION_LOGO_REQUEST
});
export const editOrganizationLogoSuccess = payload => ({
  type: EDIT_ORGANIZATION_LOGO_SUCCESS,
  payload
});
export const editOrganizationLogoFailure = payload => ({
  type: EDIT_ORGANIZATION_LOGO_ERROR,
  payload
});

export const getOrganization = body => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/organizations/${body}`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    return axios
      .get(apiUrl, config)
      .then(response => {
        dispatch(getOrganizationData(response.data));
      })
      .catch(error => {
        throw error;
      });
  };
};


export const editProfile = (body, id,callback) => {
  const apiUrl = process.env.REACT_APP_API_HOST + `/api/v1/users/${id}`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    dispatch(editProfileDataRequest());
    return axios
      .put(apiUrl, body, config)
      .then(response => {
        dispatch(editProfileDataSuccess(response.data));
        window.localStorage.user = JSON.stringify(response.data.user);
        callback(response.data.user)
        dispatch(
          displayNotification({
            open: true,
            message:
              response && response.data
                ? "Your profile has successfully updated"
                : "Something went wrong",
            duration: 3000,
            severity: "success"
          })
        );
      })
      .catch(error => {
        dispatch(editProfileDataFailure());
        dispatch(
          displayNotification({
            open: true,
            message:
              // error.response && error.response.data
              //   ? error.response.data.message.message ||
              //     error.response.data.message
              //   :
                "Something went wrong",
            duration: 3000,
            severity: "error"
          })
        );
      });
  };
};


export const editOrganization = (body, id,callback) => {
  const apiUrl = process.env.REACT_APP_API_HOST + `/api/v1/organizations/${id}`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    dispatch(editOrganizationDataRequest());
    return axios
      .put(apiUrl, body, config)
      .then(response => {
        dispatch(editOrganizationDataSuccess(response.data));
        callback(response.data.user)
        window.localStorage.user = JSON.stringify(response.data.user);
        dispatch(
          displayNotification({
            open: true,
            message:
              response && response.data
                ? "Your profile has successfully updated"
                : "Something went wrong",
            duration: 3000,
            severity: "success"
          })
        );
      })
      .catch(error => {
        dispatch(editOrganizationDataFailure());
        dispatch(
          displayNotification({
            open: true,
            message:
              // error.response && error.response.data
              //   ? error.response.data.message.message ||
              //     error.response.data.message
              //   :
                "Something went wrong",
            duration: 3000,
            severity: "error"
          })
        );
      });
  };
};

export const editLogo = (body, id, callback) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/organizations/${id}/logo`;
  return dispatch => {
    dispatch(editOrganizationLogoRequest());
    return axios
      .put(apiUrl, body)
      .then(response => {
        dispatch(editOrganizationLogoSuccess(response.data));
        callback(response.data);
        dispatch(
          displayNotification({
            open: true,
            message:
              response && response.data
                ? "Logo is successfully updated"
                : "Something went wrong",
            duration: 3000,
            severity: "success"
          })
        );
      })
      .catch(error => {
        dispatch(editOrganizationLogoFailure());
        dispatch(
          displayNotification({
            open: true,
            message:
              // error.response && error.response.data
              //   ? error.response.data.message.message ||
              //     error.response.data.message
              //   :
                "Something went wrong",
            duration: 3000,
            severity: "error"
          })
        );
      });
  };
};
