import { QR_CODE_SUCCESS, QR_CODE_ERROR, QR_CODE_REQUEST } from "./action";

const initialState = {
  items: [],
  loading: false,
};

export default function qrCodeManagementReducer(state = initialState, action) {
  switch (action.type) {
    case QR_CODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case QR_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };
    case QR_CODE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
