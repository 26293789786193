import axios from "axios";
import request from "@util/request";
import { displayNotification } from "../../../store/action";
export const GET_PROJECT_DETAILS_BEGIN = "GET_PROJECT_DETAILS_BEGIN";
export const GET_PROJECT_DETAILS_SUCCESS = "GET_PROJECT_DETAILS_SUCCESS";
export const GET_PROJECT_DETAILS_ERROR = "GET_PROJECT_DETAILS_ERROR";
export const GET_PROJECT_BEGIN = "GET_PROJECT_BEGIN";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_ERROR = "GET_PROJECT_ERROR";

export const CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const CREATE_PROJECT_ERROR = "CREATE_PROJECT_ERROR";

export const EDIT_PROJECT_REQUEST = "EDIT_PROJECT_REQUEST";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const EDIT_PROJECT_ERROR = "EDIT_PROJECT_ERROR";

export const ADD_IMAGE_REQUEST = "ADD_IMAGE_REQUEST";
export const ADD_IMAGE = "ADD_IMAGE";
export const ADD_IMAGE_ERROR = "ADD_IMAGE_ERROR";

export const DELETE_IMAGE_REQUEST = "DELETE_IMAGE_REQUEST";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const DELETE_IMAGE_ERROR = "DELETE_IMAGE_ERROR";

export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const DELETE_FILE = "DELETE_FILE";
export const DELETE_FILE_ERROR = "DELETE_FILE_ERROR";

export const GET_LATEST_PROJECT = "GET_LATEST_PROJECT";
export const DELETE_PROJECT_DATA = "DELETE_PROJECT_DATA";

export const REMOVE_LAST_DATA = "REMOVE_LAST_DATA";

export const EXPORT_TO_EXCEL_SUCCESS = "EXPORT_TO_EXCEL_SUCCESS";
export const EXPORT_TO_EXCEL_FAILURE = "EXPORT_TO_EXCEL_FAILURE";
export const EXPORT_TO_EXCEL_REQUEST = "EXPORT_TO_EXCEL_REQUEST";

export const EXPORT_PROJECT_LIST_SUCCESS = "EXPORT_PROJECT_LIST_SUCCESS";
export const EXPORT_PROJECT_LIST_FAILURE = "EXPORT_PROJECT_LIST_FAILURE";
export const EXPORT_PROJECT_LIST_REQUEST = "EXPORT_PROJECT_LIST_REQUEST";

export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAILURE = "FILE_UPLOAD_FAILURE";
export const FILE_UPLOAD_REQUEST = "FILE_UPLOAD_REQUEST";

export const EXPORT_MULTIPLE_PROJECT_SUCCESS =
  "EXPORT_MULTIPLE_PROJECT_SUCCESS";
export const EXPORT_MULTIPLE_PROJECT_FAILURE =
  "EXPORT_MULTIPLE_PROJECT_FAILURE";
export const EXPORT_MULTIPLE_PROJECT_REQUEST =
  "EXPORT_MULTIPLE_PROJECT_REQUEST";

export const projectExportRequest = () => ({
  type: EXPORT_TO_EXCEL_REQUEST
});
export const projectExportSuccess = payload => ({
  type: EXPORT_TO_EXCEL_SUCCESS,
  payload
});
export const projectExportFailure = payload => ({
  type: EXPORT_TO_EXCEL_FAILURE,
  payload
});

export const exportProjectListRequest = () => ({
  type: EXPORT_PROJECT_LIST_REQUEST
});
export const exportProjectListSuccess = payload => ({
  type: EXPORT_PROJECT_LIST_SUCCESS,
  payload
});
export const exportProjectListFailure = payload => ({
  type: EXPORT_PROJECT_LIST_FAILURE,
  payload
});

export const exportMultipleProjectRequest = () => ({
  type: EXPORT_MULTIPLE_PROJECT_REQUEST
});
export const exportMultipleProjectSuccess = payload => ({
  type: EXPORT_MULTIPLE_PROJECT_SUCCESS,
  payload
});
export const exportMultipleProjectFailure = payload => ({
  type: EXPORT_MULTIPLE_PROJECT_FAILURE,
  payload
});

export const projectDetailsRequest = () => ({
  type: GET_PROJECT_DETAILS_BEGIN
});

export const projectDetailsSuccess = payload => ({
  type: GET_PROJECT_DETAILS_SUCCESS,
  payload
});
export const projectDetailsFailure = payload => ({
  type: GET_PROJECT_DETAILS_ERROR,
  payload
});

export const projectRequest = () => ({
  type: GET_PROJECT_BEGIN
});

export const projectSuccess = payload => ({
  type: GET_PROJECT_SUCCESS,
  payload
});
export const projectFailure = payload => ({
  type: GET_PROJECT_ERROR,
  payload
});
export const latestProject = payload => ({
  type: GET_LATEST_PROJECT,
  payload
});
export const deleteProject = payload => ({
  type: DELETE_PROJECT_DATA,
  payload
});
export const removeLastData = () => ({
  type: REMOVE_LAST_DATA
});

export const fileUploadRequest = () => ({
  type: FILE_UPLOAD_REQUEST
});

export const fileUploadSuccess = payload => ({
  type: FILE_UPLOAD_SUCCESS,
  payload
});
export const fileUploadFailure = payload => ({
  type: FILE_UPLOAD_FAILURE,
  payload
});

export const getProjectExport = body => {
  const apiUrl =
    process.env.REACT_APP_API_HOST +
    `/api/v1/projects/projectDataExportIntoSheet/${body}`;
  const config = {
    responseType: "arraybuffer"
  };
  return dispatch => {
    dispatch(projectExportRequest());
    return axios
      .get(apiUrl, config)
      .then(response => {
        if (response.status === 200) {
          dispatch(projectExportSuccess(response));
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Project Survey.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
          // var blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          // var reader = new FileReader();
          // reader.onloadend = function(e) {
          //     window.open(reader.result);
          // }
          // reader.readAsDataURL(blob);
          dispatch(
            displayNotification({
              open: true,
              message:
                response && response.data
                  ? "Project has been exported successfully"
                  : "Something went wrong",
              duration: 3000,
              severity: "success"
            })
          );
        }
      })
      .catch(error => {
        dispatch(projectExportFailure());
      });
  };
};

export const getExportProjectList = () => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/projects/ownerProject`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };

  return dispatch => {
    dispatch(exportProjectListRequest());
    return axios
      .post(apiUrl, "", config)
      .then(response => {
        dispatch(exportProjectListSuccess(response.data));
      })
      .catch(error => {
        dispatch(exportProjectListFailure());
        throw error;
      });
  };
};

export const getExportMultiProjec = body => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/projects/projectAllDataExport`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    },
    responseType: "arraybuffer"
  };

  return dispatch => {
    dispatch(exportMultipleProjectRequest());
    return axios
      .post(apiUrl, body, config)
      .then(response => {
        if (response.status === 200) {
          dispatch(exportMultipleProjectSuccess(response));
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Project Survey.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();

          // var blob = new Blob([response.data], {type: 'application/vnd.ms-excel'});
          // var reader = new FileReader();
          // reader.onloadend = function(e) {
          //      window.open(reader.result);
          // }
          // reader.readAsDataURL(blob);
          dispatch(
            displayNotification({
              open: true,
              message:
                response && response.data
                  ? "Project has been exported successfully"
                  : "Something went wrong",
              duration: 3000,
              severity: "success"
            })
          );
        }
      })
      .catch(error => {
        dispatch(exportMultipleProjectFailure());
        throw error;
      });
  };
};
export const getLatestProject = () => {
  return dispatch => {
    request("get", `/api/v1/projects/latestProject/single`)
      .then(response => {
        dispatch(latestProject(response.data.project));
      })
      .catch(error => {
        throw error;
      });
  };
};
export const projectDetails = body => {
  return dispatch => {
    dispatch(removeLastData());
    dispatch(projectDetailsRequest());
    request("get", `/api/v1/projects/${body}`)
      .then(response => {
        dispatch(projectDetailsSuccess(response.data));
      })
      .catch(error => {
        dispatch(projectDetailsFailure(error && error.response && error.response.status));
        throw error
        // dispatch(
        //   displayNotification({
        //     open: true,
        //     message:
        //       // error.response && error.response.data
        //       //   ? error.response.data.message.message ||
        //       //     error.response.data.message
        //       //   :
        //         "Something went wrong",
        //     duration: 3000,
        //     severity: "error"
        //   })
        // );
      });
  };
};

export const getProjectData = (projectType, filter, body, owner,isPin,type) => {
  const apiUrl = process.env.REACT_APP_API_HOST + "/api/v1/projects";
  return (dispatch,getState) => {
    dispatch(removeLastData());
    dispatch(projectRequest());
    return axios
      .get(apiUrl, {
        params: {
          // radius: radius ? radius : "",
          projectType: projectType ? projectType : undefined,
          infrastructureCategories: filter ? filter : undefined,
          lat: body ? body.lat : 33.6188829,
          lng: body ? body.lng : -117.9298493,
          owner: owner,
          isPin:isPin ? isPin: undefined,
          loginType: type
        }
      })
      .then(response => {
        response.data.projects.forEach(projectsData => {
          if (projectsData.location && projectsData.location.coordinates) {
            const [lng, lat] = projectsData.location.coordinates.coordinates;
            projectsData.location.coordinates.lat = lat;
            projectsData.location.coordinates.lng = lng;
          }
        });
        dispatch(projectSuccess(response.data.projects));
      })
      .catch(error => {
        dispatch(projectFailure());
        throw error;
      });
  };
};

export const createProjectDataRequest = () => ({
  type: CREATE_PROJECT_REQUEST
});
export const createProjectData = payload => ({
  type: CREATE_PROJECT,
  payload
});
export const createProjectDataError = payload => ({
  type: CREATE_PROJECT_ERROR,
  payload
});

export const createProject = (body, callback) => {
  const apiUrl = process.env.REACT_APP_API_HOST + "/api/v1/projects";
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    dispatch(createProjectDataRequest());
    return axios
      .post(apiUrl, body, config)
      .then(response => {
        dispatch(createProjectData(response.data.project));
        callback(response.data.project);
      })
      .catch(error => {
        dispatch(createProjectDataError());
      });
  };
};

export const addImageDataRequest = () => ({
  type: ADD_IMAGE_REQUEST
});
export const addImageData = payload => ({
  type: ADD_IMAGE,
  payload
});
export const addImageDataError = payload => ({
  type: ADD_IMAGE_ERROR,
  payload
});

export const deleteImageDataRequest = () => ({
  type: DELETE_IMAGE_REQUEST
});
export const deleteImageData = payload => ({
  type: DELETE_IMAGE,
  payload
});
export const deleteImageDataError = payload => ({
  type: DELETE_IMAGE_ERROR,
  payload
});

export const deleteFileDataRequest = () => ({
  type: DELETE_FILE_REQUEST
});
export const deleteFileData = payload => ({
  type: DELETE_FILE,
  payload
});
export const deleteFileDataError = payload => ({
  type: DELETE_FILE_ERROR,
  payload
});

export const addImage = (body, imge_id, callback,errorCallback) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/projects/${imge_id}/images`;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    dispatch(addImageDataRequest());
    return axios
      .put(apiUrl, body, config)
      .then(response => {
        dispatch(addImageData(response.data));
        callback(response.data.project);
      })
      .catch(error => {
        dispatch(addImageDataError());
        errorCallback(imge_id)
        dispatch(
          displayNotification({
            open: true,
            message:"Image not uploaded",
            duration: 3000,
            severity: "error"
          })
        );
      });
  };
};

export const deleteImage = (body, proj_id, callback) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/projects/${proj_id}/delete/images`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    dispatch(deleteImageDataRequest());
    return axios
      .delete(apiUrl, { data: body,...config })
      .then(response => {
        dispatch(deleteImageData(response.data));
        callback(response.data.project);
      })
      .catch(error => {
        dispatch(deleteImageDataError());
        dispatch(
          displayNotification({
            open: true,
            message:"Image not removed",
            duration: 3000,
            severity: "error"
          })
        );
      });
  };
};

export const deleteFile = (body, proj_id, callback) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/projects/${proj_id}/delete/documents`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    dispatch(deleteFileDataRequest());
    return axios
      .delete(apiUrl, { data: body,...config })
      .then(response => {
        dispatch(deleteFileData(response.data));
        callback(response.data.project);
      })
      .catch(error => {
        dispatch(deleteFileDataError());
        dispatch(
          displayNotification({
            open: true,
            message:"File not removed",
            duration: 3000,
            severity: "error"
          })
        );
      });
  };
};

export const editProjectDataRequest = () => ({
  type: EDIT_PROJECT_REQUEST
});
export const editProjectData = payload => ({
  type: EDIT_PROJECT,
  payload
});
export const editProjectDataError = payload => ({
  type: EDIT_PROJECT_ERROR,
  payload
});

export const editProject = (body, p_id,callback) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST +
    `/api/v1/projects/${body._id ? body._id : p_id}`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    dispatch(editProjectDataRequest());
    return axios
      .put(apiUrl, body, config)
      .then(response => {
        dispatch(editProjectData(response.data.project));
        callback(response.data.project)
      })
      .catch(error => {
        dispatch(editProjectDataError());
      });
  };
};

export const getDeleteProject = id => {
  const apiUrl = process.env.REACT_APP_API_HOST + `/api/v1/projects/${id}/delete`;
  // const apiUrl = process.env.REACT_APP_API_HOST + `/api/v1/projects/removeProject/${id}`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return (dispatch, getState) => {
    const { items } = getState().projects;
    const deleteIndex = items.findIndex(v => v._id === id);
    items.splice(deleteIndex, 1);
    return axios
      .delete(apiUrl, config)
      .then(response => {
        if (response.status === 200) {
          dispatch(projectSuccess([...items]));
          dispatch(
            displayNotification({
              open: true,
              message:
                response && response.data
                  ? "Your project was successfully deleted."
                  : "Something went wrong",
              duration: 3000,
              severity: "success"
            })
          );
        }
      })
      .catch(error => {
        throw error;
      });
  };
};


export const fileUploadData = (body, id,callBackFile) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/projects/${id}/documentFiles`;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    dispatch(fileUploadRequest());
    return axios
      .put(apiUrl, body, config)
      .then(response => {
        dispatch(fileUploadSuccess(response.data));
        callBackFile(response.data.project);
      })
      .catch(error => {
        dispatch(fileUploadFailure());
      });
  };
};
