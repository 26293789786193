import axios from "axios";
import { displayNotification } from "../../../store/action";
export const GET_ANSWER = "GET_ANSWER";
export const GET_ANSWER_REQUEST = "GET_ANSWER_REQUEST";
export const GET_ANSWER_FALILURE = "GET_ANSWER_FALILURE";
export const CREATE_ANSWER = "CREATE_ANSWER";
export const CREATE_ANSWER_REQUEST = "CREATE_ANSWER_REQUEST";
export const CREATE_ANSWER_FAILURE = "CREATE_ANSWER_FAILURE";

export const getAnswerData = payload => ({
  type: GET_ANSWER,
  payload
});
export const getAnswerDataRequest = () => ({
  type: GET_ANSWER_REQUEST
});
export const getAnswerDataError = payload => ({
  type: GET_ANSWER_FALILURE,
  payload
});

export const getAnswer = (body,ownerId) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/answers/${body}/answers`;

  return dispatch => {
    dispatch(getAnswerDataRequest())
    return axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${window.localStorage.userToken}`
        },
        params:{
          owner:ownerId
        }
      })
      .then(response => {
        dispatch(getAnswerData(response.data.answers));
      })
      .catch(error => {
        dispatch(getAnswerDataError())
        throw error;
      });
  };
};

export const createAnswerData = payload => ({
  type: CREATE_ANSWER,
  payload
});
export const createAnswerDataRequest = () => ({
  type: CREATE_ANSWER_REQUEST
});
export const createAnswerDataFailure = payload => ({
  type: CREATE_ANSWER_FAILURE,
  payload
});

export const createAnswer = (body, id,callback) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/answers/${id}/createNew`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    dispatch(createAnswerDataRequest())
    return axios
      .post(apiUrl, body, config)
      .then(response => {
          dispatch(createAnswerData(response.data.answer));
          callback(response.data.answer)
        dispatch(
          displayNotification({
            open: true,
            message:
              "Thanks for taking part in the survey, your answer has been succesfully submitted.",
            severity: "success"
          })
        );
        // callback(response.data)
      })
      .catch(error => {
        dispatch(createAnswerDataFailure())
        dispatch(
          displayNotification({
            open: true,
            message:
              error.response && error.response.data.message === "User already answer this question"
                ? "You can only add one unique answer for each question."
                : "Something went wrong",
            duration: 3000,
            severity: "error"
          })
        );
      });
  };
};
