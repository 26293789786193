import axios from "axios";
export const GET_IP_PLACE = "GET_IP_PLACE";

export const getIpPlaceData = payload => ({
  type: GET_IP_PLACE,
  payload
});

export const getIpPlace = callbackData => {
  const apiUrl = "https://ipapi.co/json";
  return dispatch => {
    return axios
      .get(apiUrl)
      .then(response => {
        dispatch(getIpPlaceData(response.data));
        callbackData(response.data);
      })
      .catch(error => {
        throw error;
      });
  };
};
