import {
  GET_ORGANIZATION,
  GET_MEMBER_LIST_REQUEST,
  GET_MEMBER_LIST_SUCCESS,
  GET_MEMBER_LIST_ERROR,
  SET_PASSWORD_REQUEST
} from "./MemberAction";
const initialState = {
  loading: false,
  items: [],
  memberList: []
};

export default function citizenReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORGANIZATION:
      return {
        ...state,
        items: action.payload
      };
    case GET_MEMBER_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        memberList: action.payload
      };
    case GET_MEMBER_LIST_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case SET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
