export const define_es = {
  //landing page casestudies component

  landing_caseStudies1:
    "Civy probó en beta un programa de embellecimiento de calles en Glendale, LA. Según los comentarios que recibió la ciudad, se confirmó que los residentes no fueron consultados por completo sobre el proyecto hasta que este comenzó y se hizo visible. Civy tiene como objetivo eliminar este escenario de todos los proyectos actuales y futuros.",

  landing_caseStudies2:
    "Una gran agencia gubernamental de infraestructura planea usar Civy para llevar a cabo su próximo programa de alcance externo. La agencia les hará preguntas a los residentes que atienden, sobre los nuevos proyectos que proponen. Al mismo tiempo, también usarán Civy para mantenerse en contacto con las personas que informan problemas para todos los proyectos de infraestructura actuales en curso. Consideraron que una plataforma independiente habilitada para dispositivos móviles mejorará el compromiso y el hecho de que Civy funcione de manera simplificada, con varias opciones de idioma, se relaciona con la mayoría de las personas.",

  landing_caseStudies3:
    "Un escenario reciente para ofrecerle una mejor perspectiva, imagine que es un funcionario de la ciudad o una agencia gubernamental o un residente interesado. Te encuentras en Los Angeles. Puede escribir el nombre de su ciudad en el Panel de control de Civy, o en cualquier otra ciudad del mundo (digamos Sydney, Australia). Podrá ver lo que Sydney está haciendo, puede aplicar filtros por categoría de proyecto. Fomentará la educación y los aprendizajes mundiales.",

  landing_caseStudies4:
    "Como residente de Newport Beach en California, noté la falta de estaciones de ejercicios al aire libre. Estos son muy comunes en muchos otros países donde el sol, el océano y la vegetación abundan. Con mi teléfono inteligente, creé un nuevo proyecto en Civy (titulado Outdoor Fitness Station), marqué la ubicación, agregué algunas fotos de ejemplo, agregué una breve descripción del caso del proyecto y lo compartí con el departamento de mi ciudad local y la red de Civy. El objetivo es que los residentes respalden la idea o proporcionen comentarios u otras sugerencias; y mientras tanto, el departamento de la ciudad está recibiendo los comentarios de estos residentes. Esto les da el ímpetu para actuar y mencionarlo con las personas en su próximo acercamiento comunitario, obtener el respaldo de las personas y construirlo.",

  landing_caseStudies_content:
    "Algunos ejemplos prácticos de cómo Civy puede ser beneficioso en su día a día promedio.",

  landing_caseStudies_title: "ESTUDIOS DE CASO",

  //landing page statistics component

  landing_statistics_title: "ESTADÍSTICA",

  landing_statistics_content:
    "Para lograr un desarrollo sostenible por completo, debemos avanzar de manera equitativa y de base amplia y permitir que las personas se conviertan en participantes activos en ese proceso.",

  landing_statistics48:
    "de adultos participan directamente en un grupo o actividad cívica",

  landing_statistics68:
    "de los residentes de 18 a 34 años informaron que se sintieron desconectados del gobierno.",

  //landing page testimonial component

  landing_testimonial_title: "Testimonios",

  landing_testimonial_content: "Leer testimonios de nuestras últimas clientes.",

  landing_testimonial1:
    "Ojalá hubiera tenido esto cuando trabajamos en proyectos que requerían alcance de los interesados ​​a nivel estatal y local. Esto habría aliviado un poco el estrés y nos habría ayudado a llegar efectivamente a las comunidades.",

  landing_testimonial1_author: "Alana",

  landing_testimonial1_postname:
    "- Ex Director de Alcance y Comunicaciones, Autoridad Estatal de Servicios Públicos",

  landing_testimonial2:
    "Civy puede abordar tres roles en el proceso de participación a la vez: transmitir el trabajo en línea",

  landing_testimonial2_author: "Eric",

  landing_testimonial2_postname:
    "- Asistente legislativa al municipio de Freeholder, Nueva Jersey",

  landing_testimonial3:
    "Civy es uno de los primeros desarrollos emocionantes en mucho tiempo para el software del gobierno y cómo los municipios interactúan con el público. Freeholder es un municipio importante",

  landing_testimonial3_author: "Scott",

  landing_testimonial3_postname: "- Jefe de personal, asambleísta estatal",

  landing_testimonial4:
    "La plataforma de participación en línea de Civy es fácil de usar y está diseñada teniendo en cuenta las ciudades, los gobiernos y los residentes. Está abordando los principales puntos débiles que experimentamos como ciudades y funcionarios electos, y facilita a nuestros residentes la participación con nosotros en un entorno seguro y protegido. La plataforma complementa y mejora nuestras estrategias de divulgación existentes, y está diseñada para aumentar las tasas de participación y la equidad.",

  landing_testimonial4_author: "Theresa Branella",

  landing_testimonial4_postname: "-Mayor, Brooklawn, Nueva Jersey",

  //landing page services component

  landing_servicesA_header: "Alcance público más amplio para proyectos.",

  landing_servicesA_content:
    "Una plataforma única que ofrece el alcance más amplio, múltiples idiomas y profundidad demográfica para proyectos cívicos.",

  landing_servicesB_header: "Privacidad y seguridad de datos.",

  landing_servicesB_content:
    "Nuestra plataforma incluye medidas de privacidad adicionales para proteger las respuestas de los residentes, que solo son visibles para los funcionarios de la ciudad y del gobierno.",

  landing_servicesC_header: "Eficiente y conveniente.",

  landing_servicesC_content:
    "La retroalimentación sobre Civy para un proyecto es tan fácil como enviar un mensaje de texto.",

  landing_servicesD_header: "Análisis comprensivo.",

  landing_servicesD_content:
    "Analice los comentarios de los residentes en diferentes grupos demográficos y aplique análisis para una toma de decisiones, aprobaciones y planificación de proyectos futuros más rápidas.",

  landing_service_title: "SERVICIOS",

  landing_service_content:
    "Civy ayuda a las ciudades y gobiernos a mejorar el compromiso cívico.",

  landing_ServiceSection_maincontent:
    "Civy muestra proyectos de gobiernos y ciudades enumerados por estado, ciudad, código postal; y hacer preguntas que los residentes puedan responder.",

  //landing page project component

  landing_project_title: "Proyectos",

  landing_project_content:
    "Una lista de nuestros proyectos actuales en varias ciudades.",

  landing_project_short_description: "Descripción breve del proyecto",

  landing_project_description_link: "Ver más",

  landing_project_startdate: "Fecha de inicio",

  landing_project_enddate: "Fecha final",

  //landing page benefit component

  landing_benefit1_title: "Plataforma móvil",

  landing_benefit1_content:
    "Civy es una plataforma móvil conveniente de fácil acceso desde cualquier lugar.",

  landing_benefit2_title: "Gratis para residentes",

  landing_benefit2_content:
    "Civy es gratuito para que los residentes se registren, respondan preguntas y encuestas, y ayuden a las ciudades y gobiernos a mejorar la planificación y ejecución de sus proyectos.",

  landing_benefit3_title: "Enlaces de redes sociales",

  landing_benefit3_content:
    "Civy se vincula con las plataformas de redes sociales para aumentar las tasas de participación y la profundidad demográfica.",

  landing_benefit4_title: "Segura y protegida",

  landing_benefit4_content:
    "Las respuestas de los residentes son confidenciales y Civy no comparte los datos de los residentes con terceros.",

  landing_benefit_title: "Beneficios",

  landing_benefit_content:
    "Civy ofrece múltiples funciones para aprovechar el compromiso público y la participación generalizados que contribuyen a mejorar la gobernanza y los resultados de desarrollo.",

  //landing page about component

  landing_about_title: "Sobre CIVY",

  landing_about_content:
    "Ayudamos a las ciudades y gobiernos a mejorar la participación de las partes interesadas y el alcance público a través de proyectos cívicos.",

  landing_about_maincontent:
    "Civy es una manera fácil y segura para que los residentes participen y compartan comentarios sobre proyectos públicos actuales y futuros de ciudades y gobiernos. Una plataforma en línea dedicada que llega a un público más amplio. Las partes interesadas de todos los grupos demográficos se involucran y brindan retroalimentación a sus ciudades y agencias gubernamentales, con conveniencia y eficiencia.",

  landing_readmore: "Lee mas",

  //landing page contact us component

  landing_contactus_address_title: "Habla a",

  landing_contactus_address: "Civy Inc.",

  landing_contactus_address_post: "160 S Cypress St, Orange, CA, 92866 EE. UU.",

  contactus_address_street: "160 S Cypress St,",

  contactus_address_code: "Orange, CA, 92866",

  contactus_address_use: "Estados Unidos",

  landing_contactus_address_tel: "Tel:",

  landing_contactus_usertype1: "Residente",

  landing_contactus_usertype2: "Ciudad",

  landing_contactus_usertype3: "Agencia del gobierno",

  landing_contactus_usertype4: "Universidad",

  landing_contactus_usertype5: "Otra",

  landing_contactus_title: "PONERSE EN CONTACTO",

  landing_contactus_name: "Nombre*",

  landing_contactus_email: "Email*",

  landing_contactus_select: "Es usted*",

  landing_contactus_feedback: "Tipo de comentarios*",

  landing_contactus_feedback_good: "Buena",

  landing_contactus_feedback_bad: "Mala",

  landing_contactus_feedback_other: "Otra",

  landing_contactus_feedback_message: "Tu mensaje*",

  landing_contactus_send_button: "Enviar mensaje",

  landing_contactus_name_error: "*Se requiere el nombre",

  landing_contactus_email_error: "*Correo electronico es requerido",

  landing_contactus_email_error2:
    "*Por favor ingrese una dirección de correo electrónico válida",

  landing_contactus_select_error: "*Por favor selecciona una opcion",

  landing_contactus_message_error: "* Se requiere mensaje",

  landing_contactus_capcha_error:
    "* Valide la casilla de verificación captcha No soy un robot",

  select_survey_visibility: "Seleccionar visibilidad de la encuesta",

  select_survey_visibility_private: "Privada",

  select_survey_visibility_public: "Pública",

  // services page

  services_casestudy1_title: "CIUDADES Y GOBIERNOS",

  services_casestudy1_content:
    "Una plataforma segura fácil de usar para involucrar a los residentes y acelerar el desarrollo más inteligente para proyectos cívicos.",

  services_casestudy2_title: "RESIDENTES",

  services_casestudy2_content:
    "Una plataforma móvil para recibir notificaciones y compartir comentarios con sus ciudades para guiar el diseño y desarrollo de proyectos cívicos.",

  services_casestudy_title: "CASO DE ESTUDIO",

  services_casestudy_content:
    "Cómo las ciudades y los gobiernos se conectan con los residentes a través de Civy",

  services_integratedservices_title: "Servicios integrados:",

  services_introservice1_title: "LO QUE HACE CIVY DIFERENTE",

  services_introservice1_point1:
    "Permite a las ciudades y gobiernos crear sus propios datos, agregar datos y controlar su propia narrativa.",

  services_introservice1_point2:
    "Aumente y mejore el alcance público mediante la vinculación con otros canales de redes sociales como Facebook, Twitter y LinkedIn.",

  services_introservice1_point3:
    "Confidencialidad completa de las respuestas de los residentes, que solo son visibles para la ciudad o los gobiernos que se suscriben a CIvy. Los datos son propiedad de ciudades o gobiernos y Civy no realiza ventas a terceros.",

  services_introservice1_point4:
    "Descargue y almacene datos del proyecto en sus propios servidores. Para cumplir con la regulación de privacidad, Civy elimina los datos 3 meses después de que el proyecto esté marcado como completado por los clientes.",

  services_introservice1_point5:
    "Opción de marca blanca Civy como su propia plataforma con su marca única.",

  services_introservice1_point6:
    "Civy también ofrece servicios adicionales tales como 3D Holográfico y Mapeo GIS con aplicaciones en planificación, diseño y comercialización de proyectos y desarrollos urbanos.",

  services_introservice2_title:
    "Funcionalidad del producto para ciudades y gobiernos.",

  services_introservice2_point1:
    "Las ciudades y los gobiernos crean una cuenta en la plataforma Civy y vinculan sus propios canales de redes sociales.",

  services_introservice2_point2:
    "Agregue múltiples usuarios a la cuenta con roles de usuario definidos, como Director de Obras Públicas.",

  services_introservice2_point3:
    "Cree proyectos, cargue descripciones, e imágenes relacionadas con la visión del proyecto.",

  services_introservice2_point4:
    "Comparta en diferentes canales y haga preguntas para que los residentes respondan.",

  services_introservice2_point5:
    "Reúna y analice las respuestas, capture más datos demográficos en la comunidad respaldados por nuestras capacidades de idiomas múltiples.",

  services_introservice2_point6:
    "Utilice análisis para una toma de decisiones, ejecución y resultados más rápidos.",

  services_introservice1_content:
    "Civy es una plataforma de participación para ciudades, gobiernos y residentes, y le permite crear, agregar y almacenar sus datos.",

  services_introservice2_content:
    "Civy proporciona una plataforma fácil de usar para fomentar el compromiso cívico.",

  // about page

  about_history1_title: "Ideación",

  about_history1_content:
    "Después de ejecutar múltiples proyectos centrados en infraestructura, energía y tecnología en todo el mundo, nos dimos cuenta de que los gobiernos, los funcionarios de las ciudades y las comunidades, a pesar de sus mejores intenciones, siempre se quejaban de la falta de consulta; mientras que los intereses privados como los desarrolladores temen que sus objetivos comerciales se vean afectados negativamente si se involucran demasiado con las comunidades. Por otro lado, las comunidades consideraron que se les proporcionaron planes de negocios recubiertos de azúcar y propuestas de inversión para proyectos, pero no se les consulta en la fase de diseño o a menudo se los trata como no expertos que no saben de qué están hablando. Es común que los residentes se sientan desconocidos durante los proyectos de desarrollo cívico en sus comunidades.",

  about_history2_title: "Iniciación",

  about_history2_content:
    "Para resolver esto, visualizamos una plataforma común donde las ideas de proyectos provienen de funcionarios de la ciudad y del gobierno, y se presentan directamente a los residentes y partes interesadas para recibir comentarios. Luego, el primero puede involucrar, analizar y reducir el mejor curso de acción basado en los comentarios de los interesados ​​(residentes). El gobierno y las autoridades de la ciudad podrían explicar directamente a las personas qué se puede lograr y qué no, enumerar los potenciales y las complicaciones que podrían enfrentarse a corto plazo, elaborar los aspectos financieros involucrados en el desarrollo y previa consulta adecuada de Todos los colaboradores actúan e implementan los proyectos rápidamente.",

  about_history3_title: "Formulación",

  about_history3_content:
    "Con un propósito y una resolución claros, creamos la plataforma conceptual centrada en abordar los puntos críticos durante nuestra fase de descubrimiento con ciudades y gobiernos (alcaldes, concejales, asambleístas legislativos, gerentes de ciudades, directores de departamento, equipos de marketing, equipos tecnológicos y grupos comunitarios). La plataforma de concepto que combina la herramienta de análisis de información con los aspectos de las redes sociales para proporcionar un alcance extenso e involucrar a los residentes para todos los desarrollos de proyectos cívicos, ha obtenido la validación del cliente. Los objetivos clave eran reducir el tiempo, el costo y los recursos disponibles para un proyecto cívico y aumentar el alcance público y una mayor inclusión para todas las partes interesadas, manteniendo la privacidad y la seguridad de los datos.",

  about_history4_title: "Implementación",

  about_history4_content:
    "A partir de 2019, implementamos Civy, una plataforma de plataforma para ayudar a las ciudades y los gobiernos a mejorar el alcance público a través de proyectos cívicos. La plataforma solicita retroalimentación al involucrar a los residentes de la generación del milenio a los adultos mayores de todos los datos demográficos, mientras que reduce la necesidad de utilizar métodos obsoletos y heredados como ayuntamientos pasados ​​de moda. La plataforma proporciona una comodidad sin precedentes para aprender, participar y proporcionar una retroalimentación honesta a las partes interesadas para todos los proyectos cívicos. El recurso compartido integrado con un clic en múltiples canales de redes sociales, seguridad cibernética y soluciones de alojamiento en la nube también proporciona privacidad de datos, La productividad del back-office aumenta y permite a las ciudades y gobiernos centrarse en el pensamiento estratégico.",

  about_history_title: "Historia de civy",

  about_history_since: "ya que",

  about_vision_title: "Nuestra visión",

  about_vision_content:
    "Para crear un ciclo de compromiso que capture ciudades, gobiernos, residentes y partes interesadas, y construir un planeta más inteligente.",

  about_mission_title: "Nuestra misión",

  about_mission_content1: `Civy ayuda a las ciudades y gobiernos a mejorar el alcance público y la participación de las partes interesadas en los proyectos cívicos al comprender sus puntos débiles, comprender qué se puede mejorar y ayudar a facilitar una mejor discusión utilizando nuestra plataforma patentada. Nuestra filosofía para lograr esto es un enfoque de "vuelta a lo básico" de preguntar a las personas, escuchar sus comentarios y ayudar a nuestros clientes (ciudades y gobiernos) a actuar sobre estos comentarios.`,

  about_interview_title: "entrevista",

  about_interview_content:
    "Amar Flora, fundador de Civy, fue entrevistado en televisión en vivo para hablar sobre Civy y su objetivo de ayudar a las ciudades y los gobiernos.",

  about_interview_maincontent:
    "Mire al fundador de Civy, Amar Flora, explicar en televisión en vivo cómo Civy permite a los tomadores de decisiones utilizar los datos de los usuarios para tomar decisiones más rápidas, actuar con inteligencia y simplemente conectarse en el pensamiento de 'cielo azul' con sus residentes; Todo en tiempo real, sin filtro y en una plataforma independiente.",

  about_ourteam_title: "NUESTRO EQUIPO",

  about_ourteam_content:
    "Somos un grupo de personas apasionadas por GovTech, Smart Cities y la participación de las partes interesadas.",

  about_ourteam_person1_name: "Amar Flora (MBA, GAICD, GCCM, CPA)",

  about_ourteam_person1_title: "Cofundadora y CEO",

  about_ourteam_person1_content:
    "Un líder visionario y un emprendedor apasionado por la educación superior, las ciudades inteligentes y las operaciones gubernamentales. Amar fue cofundador de dos startups privadas, formando equipos y colaboradores globales, prestó servicios en juntas directivas y dirigió el establecimiento y las operaciones de una startup universitaria sembrada con fondos privados de $ 20 millones. Amar ha vendido servicios y consultado con clientes en varias industrias, entregando un impacto significativo y creación de valor. Amar posee una gran habilidad para identificar oportunidades estratégicas y operativas e impulsar la innovación. Amar tiene un MBA, postgraduados en Gestión del Cambio y Gobierno Corporativo, y certificación de CPA de las principales instituciones de los EE. UU. Y Australia.",

  about_ourteam_person2_name: "Sabrina Uppal (MS, PMP)",

  about_ourteam_person2_title: "Cofundadora y Directora de Operaciones",

  about_ourteam_person2_content:
    "Sabrina se une a Civy con experiencias de calidad y ejecuta con éxito múltiples proyectos de inicio y de investigación en sectores privados y gubernamentales. Su talento creativo, junto con una mentalidad innovadora, le permite diseñar y ofrecer valor a los clientes. Además, Sabrina posee una amplia experiencia en la gestión de contratos gubernamentales para organizaciones contratadas por el Departamento de Defensa de los Estados Unidos. Sabrina tiene experiencia de primera mano al tratar de involucrar a su ciudad o una agencia gubernamental para lograr un resultado. Estas experiencias impulsan su pasión y creatividad para ayudar a las ciudades y gobiernos a fomentar un compromiso de alto impacto con los residentes. Sabrina tiene una Licenciatura en Ciencias, Neurociencia de la U.C. Davis; Master of Science, Biology de Cal State Northridge y tiene certificación PMP.",

  about_ourteam_person3_name: "Jinhong Zhang (CPA, MBA, CGMA)",

  about_ourteam_person3_title: "director de Finanzas",

  about_ourteam_person3_content:
    "Jinhong Zhang tiene más de 20 años de experiencia en planificación y gestión empresarial y financiera, desempeña funciones de liderazgo como presidente CFO, director de finanzas y consultor en grandes organizaciones empresariales. Jinhong comenzó su carrera en una compañía Fortune Global 500, donde administró proyectos financiados por el Banco Mundial y Asia Development cada uno y supervisó la auditoría y el compromiso fiscal con PWC y KPMG en el lado del cliente.",

  about_ourteam_person4_name: "Suketu Modi",

  about_ourteam_person4_title: "CTO",

  about_ourteam_person4_content: `Reconocido entre 100 Great People Managers of India 2019 por Forbes India, y galardonado con el Mejor diseño de infraestructura y Smart e-Governance for Cities, Suketu es un experimentado líder empresarial y tecnócrata con una vasta experiencia en consultoría de TIC para las principales firmas de consultoría multinacionales, gobiernos y PSUs que sirven como CTO para Civy. Suketu tiene más de 19 años de experiencia en consultoría e implementación de TIC de infraestructura de redes de infraestructura de TIC y diseño de servicios inteligentes para servicios de edificios inteligentes y de ciudad / campus a gran escala. Suketu ha trabajado con clientes y clientes en los Estados Unidos, Oriente Medio, Singapur e India.`,

  about_ourteam_description1:
    "Nos apasiona ayudar a las ciudades y gobiernos a fomentar la innovación para mejorar a los residentes.",

  about_ourteam_description2:
    "Un socio exclusivo de Civy, Infixzon es una empresa de servicios de consultoría, nube y seguridad cibernética dirigida por veteranos de la industria con múltiples décadas de experiencia. Infixzon presta servicios en las regiones de EE. UU., APAC y Medio Oriente, diseñando soluciones para sectores verticales como el sector público, la atención médica, los servicios financieros, los productos farmacéuticos y la fabricación.",

  about_ourteam_description3:
    "Twish Technologies es un equipo de profesionales con años de liderazgo en políticas de infraestructura, estudios de factibilidad de proyectos, estrategia de integración e implementación para infraestructuras a nivel de ciudad, comunidades inteligentes y ciudades inteligentes. Su equipo incluye un psicólogo organizacional centrado en la participación de la comunidad en nombre de las ciudades y los gobiernos.",

  //Terms and conditions page

  ourpolicies_term_of_use_title: "TÉRMINOS DE USO",

  ourpolicies_terms_title: "Términos y Condiciones",

  ourpolicies_terms_last_updated:
    "Última actualización: {day} de {month} de {year}",

  ourpolicies_terms_content1: `Lea estos Términos y condiciones ("Términos", "Términos y condiciones") detenidamente antes de utilizar el sitio web https://www.civy.co y la aplicación móvil Civy (conjuntamente o individualmente, el "Servicio") operado por Civy Inc ("nosotros", "nosotros" o "nuestro").`,

  ourpolicies_terms_content2:
    "Su acceso y uso del Servicio está condicionado a su aceptación y cumplimiento de estos Términos. Estos Términos se aplican a todos los visitantes, usuarios y otras personas que deseen acceder o utilizar el Servicio.",

  ourpolicies_terms_content3:
    "Al acceder o utilizar el Servicio, usted acepta estar sujeto a estos Términos. Si no está de acuerdo con alguna parte de los términos, no tiene permiso para acceder al Servicio.",

  ourpolicies_terms_Communications_title: "Comunicaciones",

  ourpolicies_terms_Communications_content1:
    "Al crear una cuenta en nuestro servicio, acepta suscribirse a boletines informativos, materiales de marketing o promocionales y otra información que podamos enviar. Sin embargo, puede optar por no recibir nuestras comunicaciones, o todas, siguiendo el enlace para cancelar la suscripción o las instrucciones proporcionadas en cualquier correo electrónico que le enviemos.",

  ourpolicies_terms_subscription_title: "Suscripciones",

  ourpolicies_terms_subscription_content1: `Algunas partes del Servicio se facturan por suscripción ("Suscripciones"). Se le facturará por adelantado de forma periódica y periódica ("Ciclo de facturación"). Los ciclos de facturación se establecen mensualmente o anualmente, según el tipo de plan de suscripción que seleccione al comprar una suscripción.`,

  ourpolicies_terms_subscription_content2:
    "Al final de cada Ciclo de facturación, su Suscripción se renovará automáticamente bajo las mismas condiciones, a menos que lo cancele o Civy Inc lo cancele. Puede cancelar la renovación de su suscripción a través de la página de administración de su cuenta en línea o comunicándose con el equipo de atención al cliente de Civy Inc.",

  ourpolicies_terms_subscription_content3:
    "Se requiere un método de pago válido, que incluya una tarjeta de crédito o PayPal, para procesar el pago de su Suscripción. Deberá proporcionar a Civy Inc información de facturación precisa y completa que incluya nombre completo, dirección, estado, código postal, número de teléfono y una información de método de pago válida. Al enviar dicha información de pago, usted autoriza automáticamente a Civy Inc a cobrar todas las tarifas de suscripción incurridas a través de su cuenta a dichos instrumentos de pago.",

  ourpolicies_terms_subscription_content4:
    "En caso de que no se produzca la facturación automática por algún motivo, Civy Inc emitirá una factura electrónica que indica que debe proceder manualmente, dentro de una fecha límite determinada, con el pago completo correspondiente al período de facturación indicado en la factura.",

  ourpolicies_terms_feechanges_title: "Cambios de tarifa",

  ourpolicies_terms_feechanges_content1:
    "Civy Inc, a su exclusivo criterio y en cualquier momento, puede modificar las tarifas de Suscripción para las Suscripciones. Cualquier cambio en la tarifa de suscripción entrará en vigencia al final del ciclo de facturación actual.",

  ourpolicies_terms_feechanges_content2:
    "Civy Inc le proporcionará un aviso previo razonable de cualquier cambio en las tarifas de Suscripción para darle la oportunidad de cancelar su Suscripción antes de que dicho cambio entre en vigencia.",

  ourpolicies_terms_feechanges_content3:
    "Su uso continuado del Servicio después de que el cambio en la tarifa de Suscripción entre en vigencia constituye su aceptación de pagar el monto de la tarifa de Suscripción modificada.",

  ourpolicies_terms_refund_title: "Reembolsos",

  ourpolicies_terms_refund_content:
    "Excepto cuando lo exija la ley, las tarifas de suscripción pagadas no son reembolsables.",

  ourpolicies_terms_content_title: "Contenido",

  ourpolicies_terms_content_description1: `Nuestro Servicio le permite publicar, vincular, almacenar, compartir y de otra manera poner a disposición cierta información, texto, gráficos, videos u otro material ("Contenido"). Usted es responsable del Contenido que publica en oa través del Servicio, incluida su legalidad, confiabilidad y adecuación.`,

  ourpolicies_terms_content_description2:
    "Al publicar Contenido en oa través del Servicio, usted declara y garantiza que: (i) el Contenido es suyo (usted lo posee) y / o tiene el derecho de usarlo y el derecho de otorgarnos los derechos y la licencia según lo dispuesto en estos Términos, y (ii) que la publicación de su Contenido en oa través del Servicio no viola los derechos de privacidad, derechos de publicidad, derechos de autor, derechos contractuales o cualquier otro derecho de cualquier persona o entidad. Nos reservamos el derecho de cancelar la cuenta de cualquier persona que infrinja los derechos de autor.",

  ourpolicies_terms_content_description3:
    "Usted conserva todos y cada uno de sus derechos sobre cualquier Contenido que envíe, publique o muestre en oa través del Servicio y usted es responsable de proteger esos derechos. No nos hacemos responsables ni asumimos ninguna responsabilidad por el Contenido que usted o cualquier tercero publique en oa través del Servicio. Sin embargo, al publicar Contenido usando el Servicio, nos otorga el derecho y la licencia para usar, modificar, realizar, mostrar, reproducir y distribuir dicho Contenido en el Servicio y a través de él.",

  ourpolicies_terms_content_description4:
    "Civy Inc tiene el derecho pero no la obligación de monitorear y editar todo el Contenido proporcionado por los usuarios.",

  ourpolicies_terms_content_description5:
    "Además, el Contenido que se encuentra en este Servicio oa través de este es propiedad de Civy Inc o se utiliza con permiso. No puede distribuir, modificar, transmitir, reutilizar, descargar, volver a publicar, copiar o usar dicho Contenido, ya sea en su totalidad o en parte, con fines comerciales o para beneficio personal, sin nuestro permiso previo por escrito.",

  ourpolicies_terms_account_title: "Cuentas",

  ourpolicies_terms_account_content1:
    "Cuando crea una cuenta con nosotros, garantiza que es mayor de 18 años y que la información que nos proporciona es precisa, completa y actualizada en todo momento. La información inexacta, incompleta u obsoleta puede resultar en la terminación inmediata de su cuenta en el Servicio.",

  ourpolicies_terms_account_content2:
    "Usted es responsable de mantener la confidencialidad de su cuenta y contraseña, incluidas, entre otras, las restricciones de acceso a su computadora o cuenta. Usted acepta aceptar la responsabilidad de todas y cada una de las actividades o acciones que ocurran bajo su cuenta y / o contraseña, ya sea que su contraseña esté en nuestro Servicio o en un servicio de un tercero. Debe notificarnos de inmediato al darse cuenta de cualquier violación de seguridad o uso no autorizado de su cuenta.",

  ourpolicies_terms_account_content3:
    "No puede usar como nombre de usuario el nombre de otra persona o entidad o que no esté legalmente disponible para su uso, un nombre o marca registrada que esté sujeta a los derechos de otra persona o entidad que no sea usted, sin la autorización correspondiente. No puede usar como nombre de usuario ningún nombre que sea ofensivo, vulgar u obsceno.",

  ourpolicies_terms_Intellectual_title: "Propiedad intelectual",

  ourpolicies_terms_Intellectual_content1:
    "El Servicio y su contenido original (excluyendo el Contenido proporcionado por los usuarios), las características y la funcionalidad son y seguirán siendo propiedad exclusiva de Civy Inc y sus licenciantes. El Servicio está protegido por derechos de autor, marcas registradas y otras leyes tanto de los Estados Unidos como de países extranjeros. Nuestras marcas comerciales y nuestra imagen comercial no pueden utilizarse en relación con ningún producto o servicio sin el consentimiento previo por escrito de Civy Inc.",

  ourpolicies_terms_links_title: "Enlaces a otros sitios web",

  ourpolicies_terms_links_content1:
    "Nuestro Servicio puede contener enlaces a sitios web o servicios de terceros que no son propiedad ni están controlados por Civy Inc",

  ourpolicies_terms_links_content2:
    "Civy Inc no tiene control ni asume ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios web o servicios de terceros. No garantizamos las ofertas de ninguna de estas entidades / individuos o sus sitios web.",

  ourpolicies_terms_links_content3:
    "Usted reconoce y acepta que Civy Inc no será responsable, directa o indirectamente, de ningún daño o pérdida causada o supuestamente causada por o en relación con el uso o la dependencia de dicho contenido, bienes o servicios disponibles en oa través de cualquiera de dichos sitios web o servicios de terceros.",

  ourpolicies_terms_links_content4:
    "Le recomendamos encarecidamente que lea los términos y condiciones y las políticas de privacidad de los sitios web o servicios de terceros que visite.",

  ourpolicies_terms_termination_title: "Terminación",

  ourpolicies_terms_termination_content1:
    "Podemos rescindir o suspender su cuenta y prohibir el acceso al Servicio de inmediato, sin previo aviso o responsabilidad, a nuestro exclusivo criterio, por cualquier motivo y sin limitación, incluido, entre otros, el incumplimiento de los Términos.",

  ourpolicies_terms_termination_content2:
    "Si desea cancelar su cuenta, simplemente puede dejar de usar el Servicio.",

  ourpolicies_terms_termination_content3:
    "Todas las disposiciones de los Términos que, por su naturaleza, deberían sobrevivir a la terminación, seguirán vigentes, incluidas, entre otras, las disposiciones de propiedad, las renuncias de garantía, la indemnización y las limitaciones de responsabilidad.",

  ourpolicies_terms_Indemnification_title: "Indemnización",

  ourpolicies_terms_Indemnification_content1:
    "Usted acepta defender, indemnizar y eximir de responsabilidad a Civy Inc y sus licenciatarios y licenciantes, y sus empleados, contratistas, agentes, funcionarios y directores, de y contra todos y cada uno de los reclamos, daños, obligaciones, pérdidas, responsabilidades, costos o deudas, y gastos (incluidos, entre otros, los honorarios de abogados), resultantes o derivados de a) su uso y acceso al Servicio, por usted o cualquier persona que use su cuenta y contraseña; b) un incumplimiento de estos Términos, o c) Contenido publicado en el Servicio.",

  ourpolicies_terms_limitation_title: "Limitación de responsabilidad",

  ourpolicies_terms_limitation_content1:
    "En ningún caso Civy Inc, ni sus directores, empleados, socios, agentes, proveedores o afiliados, serán responsables por daños indirectos, incidentales, especiales, consecuentes o punitivos, que incluyen, entre otros, pérdida de ganancias, datos, uso, buena voluntad u otras pérdidas intangibles, resultantes de",

  ourpolicies_terms_limitation_list1:
    "Su acceso o uso o incapacidad para acceder o usar el Servicio",

  ourpolicies_terms_limitation_list2:
    "Cualquier conducta o contenido de un tercero en el Servicio",

  ourpolicies_terms_limitation_list3:
    "Cualquier contenido obtenido del Servicio",

  ourpolicies_terms_limitation_list4:
    "Acceso no autorizado, uso o alteración de sus transmisiones o contenido, ya sea basado en garantía, contrato, agravio (incluyendo negligencia) o cualquier otra teoría legal, ya sea que se nos haya informado o no de la posibilidad de tal daño, e incluso si se establece un remedio se encuentra aquí que ha fallado su propósito esencial.",

  ourpolicies_terms_disclaimer_title: "Descargo de responsabilidad",

  ourpolicies_terms_disclaimer_content1:
    'Su uso del Servicio es bajo su propio riesgo. El Servicio se proporciona "TAL CUAL" y "SEGÚN DISPONIBILIDAD". El Servicio se proporciona sin garantías de ningún tipo, ya sean expresas o implícitas, incluidas, entre otras, garantías implícitas de comerciabilidad, idoneidad para un propósito particular, no infracción o curso de ejecución.',

  ourpolicies_terms_disclaimer_content2:
    "Civy Inc, sus subsidiarias, afiliadas y sus licenciantes no garantizan que",

  ourpolicies_terms_disclaimer_list1:
    "El Servicio funcionará de manera ininterrumpida, segura o disponible en cualquier momento o lugar en particular",

  ourpolicies_terms_disclaimer_list2:
    "Cualquier error o defecto será corregido",

  ourpolicies_terms_disclaimer_list3:
    "El Servicio está libre de virus u otros componentes dañinos.",

  ourpolicies_terms_disclaimer_list4:
    "Los resultados del uso del Servicio satisfarán sus requisitos.",

  ourpolicies_terms_exclusion_title: "Exclusiones",

  ourpolicies_terms_exclusion_content1:
    "Algunas jurisdicciones no permiten la exclusión de ciertas garantías o la exclusión o limitación de responsabilidad por daños consecuentes o incidentales, por lo que las limitaciones anteriores pueden no aplicarse en su caso.",

  ourpolicies_terms_governing_title: "Ley que rige",

  ourpolicies_terms_governing_content1:
    "Estos Términos se regirán e interpretarán de conformidad con las leyes de California, Estados Unidos, sin tener en cuenta sus disposiciones sobre conflictos de leyes.",

  ourpolicies_terms_governing_content2:
    "Nuestro incumplimiento de cualquier derecho o disposición de estos Términos no se considerará una renuncia a esos derechos. Si alguna disposición de estos Términos se considera inválida o inaplicable por un tribunal, las disposiciones restantes de estos Términos seguirán vigentes. Estos Términos constituyen el acuerdo completo entre nosotros con respecto a nuestro Servicio, y reemplazan y reemplazan cualquier acuerdo anterior que pudiéramos haber tenido entre nosotros con respecto al Servicio.",

  ourpolicies_terms_changes_title: "Cambios",

  ourpolicies_terms_changes_content1:
    "Nos reservamos el derecho, a nuestro exclusivo criterio, de modificar o reemplazar estos Términos en cualquier momento. Si una revisión es material, le avisaremos con al menos 30 días de anticipación antes de que entren en vigencia los nuevos términos. Lo que constituye un cambio material se determinará a nuestro exclusivo criterio.",

  ourpolicies_terms_changes_content2:
    "Al continuar accediendo o utilizando nuestro Servicio después de que cualquier revisión entre en vigencia, usted acepta estar sujeto a los términos revisados. Si no está de acuerdo con los nuevos términos, ya no está autorizado para usar el Servicio.",

  ourpolicies_terms_contactus_title: "Contacta con nosotras",

  ourpolicies_terms_contactus_content:
    "Si tiene alguna pregunta sobre estos Términos, contáctenos en",

  //Privacy policies page

  ourpolicies_policy_title: "Política de privacidad",

  ourpolicies_policy_date: "Fecha de vigencia: {day} de {month} de {year}",

  ourpolicies_policy_content1: `Civy Inc. ("nosotros", "nosotros" o "nuestro") opera https://www.civy.co
    sitio web y la aplicación móvil Civy (en adelante, el
    "Servicio").`,

  ourpolicies_policy_content2:
    " Esta página le informa sobre nuestras políticas con respecto a la recopilación, uso y divulgación de datos personales cuando utiliza nuestro Servicio y las opciones que ha asociado con esos datos.",

  ourpolicies_policy_content3:
    " Utilizamos sus datos para proporcionar y mejorar el Servicio. Al usar el Servicio, usted acepta la recopilación y el uso de información de acuerdo con esta política. A menos que se defina lo contrario en esta Política de privacidad, los términos utilizados en esta Política de privacidad tienen el mismo significado que en nuestros Términos y condiciones.",

  ourpolicies_definitions_title: "Definiciones",

  ourpolicies_service_title: "Servicio",

  ourpolicies_service_content: `Servicio significa el sitio web https://www.civy.co y el móvil Civy
    aplicación operada por Civy Inc.`,

  ourpolicies_personaldata_title: "Información personal",

  ourpolicies_personaldata_content:
    "Datos personales significa datos sobre una persona viva que puede identificarse a partir de esos datos (o de esa y otra información, ya sea en nuestra posesión o que puedan tenerla).",

  ourpolicies_usagedata_title: "Datos de uso",

  ourpolicies_usagedata_content:
    "Los Datos de uso son datos recopilados automáticamente, ya sea generados por el uso del Servicio o desde la propia infraestructura del Servicio (por ejemplo, la duración de una visita a la página).",

  ourpolicies_cookie_title: "Cookies",

  ourpolicies_cookie_content:
    "Las cookies son pequeños archivos almacenados en su dispositivo (computadora o dispositivo móvil).",

  ourpolicies_datacontroller_title: "Controlador de datos",

  ourpolicies_datacontroller_content1:
    "Controlador de datos significa la persona física o jurídica que (ya sea sola o conjuntamente o en común con otras personas) determina los propósitos para los cuales y la manera en que se procesa, o debe procesarse, cualquier información personal.",

  ourpolicies_datacontroller_content2:
    "A los fines de esta Política de privacidad, somos un controlador de datos de sus datos personales.",

  ourpolicies_dataprocessor_title:
    "Procesadores de datos (o proveedores de servicios)",

  ourpolicies_dataprocessor_content1:
    "Procesador de datos (o Proveedor de servicios) significa cualquier persona física o jurídica que procesa los datos en nombre del Controlador de datos.",

  ourpolicies_dataprocessor_content2:
    "Podemos utilizar los servicios de varios proveedores de servicios para procesar sus datos de manera más efectiva.",

  ourpolicies_datasubject_title: "Sujeta de datos (o usuario)",

  ourpolicies_datasubject_content:
    "El sujeto de datos es cualquier individuo vivo que está utilizando nuestro servicio y es sujeto de datos personales.",

  ourpolicies_information_title: "Recolección de información y uso",

  ourpolicies_information_content:
    "Recopilamos varios tipos diferentes de información con diversos fines para proporcionarle y mejorar nuestro Servicio.",

  ourpolicies_typeofdata_title: "Tipos de datos recopilados",

  ourpolicies_typeofdata_personal_title: "Información personal",

  ourpolicies_typeofdata_personal_content1: `Mientras usa nuestro Servicio, podemos pedirle que nos proporcione ciertos información de identificación personal que se puede utilizar para contactar o identificarte ("Datos personales"). La información de identificación personal puede incluye, pero no se limita a:`,

  ourpolicies_typeofdata_personal_list1: `Dirección de correo electrónico`,

  ourpolicies_typeofdata_personal_list2: `Nombre y apellido`,

  ourpolicies_typeofdata_personal_list3: `Número de teléfono`,

  ourpolicies_typeofdata_personal_list4: `Dirección, estado, provincia, código postal, ciudad`,

  ourpolicies_typeofdata_personal_list5: `Cookies y datos de uso`,

  ourpolicies_typeofdata_personal_content2: `Podemos utilizar sus Datos personales para contactarlo con boletines informativos, materiales de marketing o promocionales y otra información que pueda ser de su interés. Puede optar por no recibir ninguna o todas estas comunicaciones de nosotros siguiendo el enlace para cancelar la suscripción o las instrucciones proporcionadas en cualquier correo electrónico que le enviemos.`,

  ourpolicies_typeofdata_usage_title: `Datos de uso `,

  ourpolicies_typeofdata_usage_content1: `También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o cuando accede al Servicio a través de un dispositivo móvil ("Datos de uso").`,

  ourpolicies_typeofdata_usage_content2: `Estos Datos de uso pueden incluir información como la dirección del Protocolo de Internet de su computadora (por ejemplo, la dirección IP), el tipo de navegador, la versión del navegador, las páginas de nuestro Servicio que visita, la hora y la fecha de su visita, el tiempo dedicado a esas páginas, único identificadores de dispositivo y otros datos de diagnóstico.`,

  ourpolicies_typeofdata_usage_content3: `Cuando accede al Servicio con un dispositivo móvil, estos Datos de uso pueden incluir información como el tipo de dispositivo móvil que utiliza, la ID única de su dispositivo móvil, la dirección IP de su dispositivo móvil, su sistema operativo móvil, el tipo de Internet móvil navegador que utiliza, identificadores de dispositivo únicos y otros datos de diagnóstico.`,

  ourpolicies_typeofdata_location_title: `Datos de localización`,

  ourpolicies_typeofdata_location_content1: `Podemos usar y almacenar información sobre su ubicación si nos da permiso para hacerlo ("Datos de ubicación"). Utilizamos estos datos para proporcionar funciones de nuestro Servicio, para mejorar y personalizar nuestro Servicio.`,

  ourpolicies_typeofdata_location_content2: `Puede habilitar o deshabilitar los servicios de ubicación cuando usa nuestro Servicio en cualquier momento a través de la configuración de su dispositivo.`,

  ourpolicies_typeofdata_tracking_title: `Seguimiento de datos de cookies`,

  ourpolicies_typeofdata_tracking_content1: `Utilizamos cookies y tecnologías de seguimiento similares para rastrear la actividad en nuestro Servicio y tenemos cierta información.`,

  ourpolicies_typeofdata_tracking_content2: `Las cookies son archivos con una pequeña cantidad de datos que pueden incluir un identificador único anónimo. Las cookies se envían a su navegador desde un sitio web y se almacenan en su dispositivo. También se utilizan otras tecnologías de seguimiento, como balizas, etiquetas y scripts para recopilar y rastrear información y para mejorar y analizar nuestro Servicio.`,

  ourpolicies_typeofdata_tracking_content3: `Puede indicar a su navegador que rechace todas las cookies o que indique cuándo se envía una cookie. Sin embargo, si no acepta las cookies, es posible que no pueda usar algunas partes de nuestro Servicio.`,

  ourpolicies_typeofdata_cookiesex_title: `Ejemplos de cookies que utilizamos:`,

  ourpolicies_typeofdata_cookiesex_session: "Cookies de sesión",

  ourpolicies_typeofdata_cookiesex_session_content: `Utilizamos cookies de sesión para operar nuestro servicio.`,

  ourpolicies_typeofdata_cookiesex_preference: "Cookies de preferencia.",

  ourpolicies_typeofdata_cookiesex_preference_content: `Utilizamos cookies de preferencias para recordar sus preferencias y diversas configuraciones.`,

  ourpolicies_typeofdata_cookiesex_security: `Cookies de seguridad`,

  ourpolicies_typeofdata_cookiesex_security_content: `Utilizamos cookies de seguridad con fines de seguridad.`,

  ourpolicies_typeofdata_use_title: `Uso de datos`,

  ourpolicies_typeofdata_use_content: `Civy Inc. utiliza los datos recopilados para diversos fines:`,

  ourpolicies_typeofdata_use_list1: `Para proporcionar y mantener nuestro servicio`,

  ourpolicies_typeofdata_use_list2: `Para notificarle sobre cambios en nuestro Servicio`,

  ourpolicies_typeofdata_use_list3: `Para permitirle participar en funciones interactivas de nuestro Servicio cuando elija hacerlo`,

  ourpolicies_typeofdata_use_list4: `Para proporcionar soporte al cliente`,

  ourpolicies_typeofdata_use_list5: `Para recopilar análisis o información valiosa para que podamos mejorar nuestro Servicio`,

  ourpolicies_typeofdata_use_list8: `Para monitorear el uso de nuestro Servicio`,

  ourpolicies_typeofdata_use_list6: `Para detectar, prevenir y abordar problemas técnicos`,

  ourpolicies_typeofdata_use_list7: `Para proporcionarle noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos que sean similares a los que ya compró o solicitó, a menos que haya optado por no recibir dicha información.`,

  ourpolicies_typeofdata_legalbasis_title: `Base legal para el procesamiento de datos personales en virtud del Reglamento general de protección de datos (GDPR)`,

  ourpolicies_typeofdata_legalbasis_content1: `Si usted es del Espacio Económico Europeo (EEE), la base legal de Civy Inc. para recopilar y utilizar la información personal descrita en esta Política de privacidad depende de los Datos personales que recopilamos y del contexto específico en el que los recopilamos.`,

  ourpolicies_typeofdata_legalbasis_content2: `Civy Inc. puede procesar sus datos personales porque:`,

  ourpolicies_typeofdata_legalbasis_list1: `Necesitamos realizar un contrato con usted.`,

  ourpolicies_typeofdata_legalbasis_list2: `Nos has dado permiso para hacerlo`,

  ourpolicies_typeofdata_legalbasis_list3: `El procesamiento es en nuestro interés legítimo y no se anula por sus derechos.`,

  ourpolicies_typeofdata_legalbasis_list4: `Para fines de procesamiento de pagos`,

  ourpolicies_typeofdata_legalbasis_list5: `Para cumplir con la ley`,

  ourpolicies_typeofdata_retention_title: `Retención de datos`,

  ourpolicies_typeofdata_retention_content1: `Civy Inc. retendrá sus Datos personales solo durante el tiempo que sea necesario para los fines establecidos en esta Política de privacidad. Retendremos y utilizaremos sus Datos personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a conservar sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.`,

  ourpolicies_typeofdata_retention_content2: `Civy Inc. también conservará los Datos de uso para fines de análisis interno. Los datos de uso generalmente se retienen por un período de tiempo más corto, excepto cuando estos datos se utilizan para fortalecer la seguridad o para mejorar la funcionalidad de nuestro Servicio, o estamos legalmente obligados a retener estos datos por períodos más largos.`,

  ourpolicies_typeofdata_transfer_title: `Transferencia de datos`,

  ourpolicies_typeofdata_transfer_content1: `Su información, incluidos los Datos personales, puede transferirse a computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción.`,

  ourpolicies_typeofdata_transfer_content2: `Si se encuentra fuera de Estados Unidos y opta por proporcionarnos información, tenga en cuenta que transferimos los datos, incluidos los datos personales, a Estados Unidos y los procesamos allí.`,

  ourpolicies_typeofdata_transfer_content3: `Su consentimiento a esta Política de privacidad seguido de su envío de dicha información representa su aceptación de esa transferencia.`,

  ourpolicies_typeofdata_transfer_content4: `Civy Inc. tomará todas las medidas razonablemente necesarias para garantizar que sus datos se traten de forma segura y de acuerdo con esta Política de privacidad y no se realizará ninguna transferencia de sus Datos personales a una organización o país, a menos que existan controles adecuados, incluido el seguridad de sus datos y otra información personal.`,

  ourpolicies_disclosure_title: `Divulgación de datos`,

  ourpolicies_disclosure_law_title: `Divulgación para la aplicación de la ley`,

  ourpolicies_disclosure_law_content: `En determinadas circunstancias, se le puede solicitar a Civy Inc. que divulgue sus Datos personales si así lo exige la ley o en respuesta a solicitudes válidas de las autoridades públicas (por ejemplo, un tribunal o una agencia gubernamental).`,

  ourpolicies_disclosure_requirement_title: `Requerimientos legales`,

  ourpolicies_disclosure_requirement_content: `Civy Inc. puede divulgar sus Datos personales en la creencia de buena fe de que dicha acción es necesaria para:`,

  ourpolicies_disclosure_requirement_list1: `Para cumplir con una obligación legal`,

  ourpolicies_disclosure_requirement_list2: `Para proteger y defender los derechos o propiedad de Civy Inc.`,

  ourpolicies_disclosure_requirement_list3: `Para prevenir o investigar posibles irregularidades en relación con el Servicio`,

  ourpolicies_disclosure_requirement_list4: `Para proteger la seguridad personal de los usuarios del Servicio o del público.`,

  ourpolicies_disclosure_requirement_list5: `Para protegerse contra la responsabilidad legal`,

  ourpolicies_security_title: `Seguridad de datos`,

  ourpolicies_security_content: `La seguridad de sus datos es importante para nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus Datos personales, no podemos garantizar su seguridad absoluta.`,

  ourpolicies_security_policy: `Nuestra política: aplicación general en los Estados Unidos de América, incluidas las políticas específicas del estado de California.`,

  ourpolicies_security_track: `Señales de "No rastrear" según la Ley de protección en línea de California (CalOPPA)`,

  ourpolicies_security_track_content1: `No admitimos Do Not Track ("DNT"). No rastrear es una preferencia que puede configurar en su navegador web para informar a los sitios web que no desea que se rastree.`,

  ourpolicies_security_track_content2: `Puede habilitar o deshabilitar No rastrear visitando la página de Preferencias o Configuración de su navegador web.`,

  ourpolicies_security_privacy_policy: `Nuestra política para cumplir con la Ley de Privacidad del Consumidor de California (CCPA)`,

  ourpolicies_security_privacy_policy1: `Derecho a saber:`,

  ourpolicies_security_privacy_policy1_content: `Los residentes tienen derecho a solicitar que divulguemos qué información personal recopilamos, usamos, compartimos o vendemos.`,

  ourpolicies_security_privacy_policy2: `Derecho a eliminar:`,

  ourpolicies_security_privacy_policy2_content: `Los residentes tienen derecho a solicitar que nosotros y nuestros proveedores de servicios eliminemos la información personal que tenemos sobre usted.`,

  ourpolicies_security_privacy_policy3: `Derecho de exclusión voluntaria:`,

  ourpolicies_security_privacy_policy3_content: `Los residentes tienen el derecho de indicarnos que cesemos la venta de su información personal, en el caso de que "vendamos" información personal según el término definido por la CCPA. Tenga en cuenta que solo los clientes de Civy pueden acceder a los datos recopilados en la plataforma de Civy, incluidas las áreas gubernamentales de los Estados Unidos (ciudades, municipios, consejos, agencias gubernamentales, distritos especiales y residentes registrados). Civy no vende o no tiene la intención de vender datos a terceros, como anunciantes o privados compañías.`,

  ourpolicies_security_privacy_policy4: `Derecho a la no discriminación:`,

  ourpolicies_security_privacy_policy4_content: `No podemos discriminar a los residentes en términos de precio o servicio cuando ejercen sus derechos de privacidad bajo la CCPA.`,

  ourpolicies_security_protection_title: `Sus derechos de protección de datos bajo el Reglamento general de protección de datos (GDPR)`,

  ourpolicies_security_protection_content1: `Si es residente del Espacio Económico Europeo (EEE), tiene ciertos derechos de protección de datos. Civy Inc. tiene como objetivo tomar medidas razonables para permitirle corregir, modificar, eliminar o limitar el uso de sus Datos personales.`,

  ourpolicies_security_protection_content2: `Si desea recibir información sobre los Datos personales que tenemos sobre usted y si desea que se eliminen de nuestros sistemas, contáctenos.`,

  ourpolicies_security_protection_content3: `En determinadas circunstancias, tiene los siguientes derechos de protección de datos:`,

  ourpolicies_security_access_title: `El derecho a acceder, actualizar o eliminar la información que tenemos sobre usted.`,

  ourpolicies_security_access_content1: `Siempre que sea posible, puede acceder, actualizar o solicitar la eliminación de sus Datos personales directamente en la sección de configuración de su cuenta. Si no puede realizar estas acciones usted mismo, contáctenos para ayudarlo.`,

  ourpolicies_security_rectification_title: `El derecho de rectificación.`,

  ourpolicies_security_rectification_content: `tiene derecho a que se rectifique su información si esa información es inexacta o incompleta.`,

  ourpolicies_security_object_title: `El derecho a objetar.`,

  ourpolicies_security_object_content: `Tiene derecho a oponerse a nuestro procesamiento de sus Datos personales.`,

  ourpolicies_security_restriction_title: `El derecho de restricción.`,

  ourpolicies_security_restriction_content: `Tiene derecho a solicitar que restrinjamos el procesamiento de su información personal.`,

  ourpolicies_security_portability_title: `El derecho a la portabilidad de datos.`,

  ourpolicies_security_portability_content: `Tiene derecho a recibir una copia de la información que tenemos sobre usted en un formato estructurado, legible por máquina y de uso común.`,

  ourpolicies_security_withdraw_title: `El derecho a retirar el consentimiento.`,

  ourpolicies_security_withdraw_content: `También tiene derecho a retirar su consentimiento en cualquier momento en que Civy Inc. se haya basado en su consentimiento para procesar su información personal.`,

  ourpolicies_security_protection_content4: `Tenga en cuenta que podemos pedirle que verifique su identidad antes de responder a tales solicitudes.`,

  ourpolicies_security_protection_content5: `Tiene derecho a presentar una queja ante una Autoridad de Protección de Datos sobre nuestra recopilación y uso de sus Datos personales. Para obtener más información, comuníquese con su autoridad local de protección de datos en el Espacio Económico Europeo (EEE).`,

  ourpolicies_serviceprovider: `Proveedores de servicio`,

  ourpolicies_serviceprovider_content1: `Podemos emplear a empresas e individuos de terceros para facilitar nuestro Servicio ("Proveedores de servicios"), proporcionar el Servicio en nuestro nombre, realizar servicios relacionados con el Servicio o ayudarnos a analizar cómo se utiliza nuestro Servicio.`,

  ourpolicies_serviceprovider_content2: `Estos terceros tienen acceso a sus Datos personales solo para realizar estas tareas en nuestro nombre y están obligados a no divulgarlos ni usarlos para ningún otro propósito.`,

  ourpolicies_serviceprovider_analytics: `Analítica`,

  ourpolicies_serviceprovider_analytics_content: `Podemos utilizar proveedores de servicios externos para monitorear y analizar el uso de nuestro servicio.`,

  ourpolicies_serviceprovider_ganalytics: `Google analitico`,

  ourpolicies_serviceprovider_ganalytics_content1: `Google Analytics es un servicio de análisis web ofrecido por Google que rastrea e informa el tráfico del sitio web. Google utiliza los datos recopilados para rastrear y controlar el uso de nuestro Servicio. Estos datos se comparten con otros servicios de Google. Google puede utilizar los datos recopilados para contextualizar y personalizar los anuncios de su propia red publicitaria.`,

  ourpolicies_serviceprovider_ganalytics_content2: `Para obtener más información sobre las prácticas de privacidad de Google, visite la página web de Términos de privacidad de Google: https://policies.google.com/privacy?hl=en`,

  ourpolicies_serviceprovider_firebase: `Firebase`,

  ourpolicies_serviceprovider_firebase_content1: `Firebase es un servicio de análisis proporcionado por Google Inc.`,

  ourpolicies_serviceprovider_firebase_content4: `Puede optar por no participar en ciertas funciones de Firebase a través de la configuración de su dispositivo móvil, como la configuración de publicidad de su dispositivo o siguiendo las instrucciones proporcionadas por Google en su Política de privacidad: https://policies.google.com/privacy?hl=en`,

  ourpolicies_serviceprovider_firebase_content2: `También le recomendamos que revise la política de Google para proteger sus datos: https://support.google.com/analytics/answer/6004245.`,

  ourpolicies_serviceprovider_firebase_content3: `Para obtener más información sobre qué tipo de información Firebase recopila, visite la página web de Términos de privacidad de Google: https://policies.google.com/privacy?hl=en`,

  ourpolicies_serviceprovider_payments: `Pagos`,

  ourpolicies_serviceprovider_payment_content1: `Podemos proporcionar productos y / o servicios pagos dentro del Servicio. En ese caso, utilizamos servicios de terceros para el procesamiento de pagos (por ejemplo, procesadores de pagos).`,

  ourpolicies_serviceprovider_payment_content2: `No almacenaremos ni recopilaremos los detalles de su tarjeta de pago. Esa información se proporciona directamente a nuestros procesadores de pagos de terceros cuyo uso de su información personal se rige por su Política de privacidad. Estos procesadores de pago se adhieren a los estándares establecidos por PCI-DSS, administrados por el PCI Security Standards Council, que es un esfuerzo conjunto de marcas como Visa MasterCard, American Express y Discover. Los requisitos de PCI-DSS ayudan a garantizar el manejo seguro de la información de pago.`,

  ourpolicies_serviceprovider_payment_content3: `Los procesadores de pago con los que trabajamos son:`,

  ourpolicies_serviceprovider_stripe: `Stripe`,

  ourpolicies_serviceprovider_stripe_content: `Su política de privacidad se puede ver en https://stripe.com/us privacy`,

  ourpolicies_linksto_title: `Enlaces a otros sitios`,

  ourpolicies_linksto_content1: `Nuestro servicio puede contener enlaces a otros sitios que no son operados por nosotros. Si hace clic en un enlace de un tercero, se lo dirigirá al sitio de ese tercero. Le recomendamos encarecidamente que revise la Política de privacidad de cada sitio que visite.`,

  ourpolicies_linksto_content2: `No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de sitios o servicios de terceros.`,

  ourpolicies_children_title: `Privacidad infantil`,

  ourpolicies_children_content1: `Nuestro Servicio no se dirige a menores de 18 años ("Niños").`,

  ourpolicies_children_content2: `No recopilamos a sabiendas información de identificación personal de ninguna persona menor de 18 años. Si usted es padre o tutor y sabe que su hijo nos ha proporcionado datos personales, contáctenos. Si nos damos cuenta de que hemos recopilado Datos personales de niños sin verificación del consentimiento de los padres, tomamos medidas para eliminar esa información de nuestros servidores.`,

  ourpolicies_changes_title: `Cambios a esta política de privacidad`,

  ourpolicies_changes_content1: `Podemos actualizar nuestra Política de privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de privacidad en esta página.`,

  ourpolicies_changes_content2: `Le informaremos por correo electrónico y / o un aviso destacado sobre nuestro Servicio antes de que el cambio entre en vigencia y actualizaremos la "fecha de vigencia" en la parte superior de esta Política de privacidad.`,

  ourpolicies_changes_content3: `Se recomienda que revise esta Política de privacidad periódicamente para detectar cualquier cambio. Los cambios a esta Política de privacidad son efectivos cuando se publican en esta página.`,

  ourpolicies_contactus_title: `Contáctenos`,

  ourpolicies_contactus_content1: `Si tiene alguna pregunta sobre esta Política de privacidad, contáctenos:`,

  ourpolicies_contactus_content2: `Por correo electrónico:`,

  //Registration page

  dob_validation: "La edad debe ser al menos 16",

  register_regi_title: "Registrar Cuenta",

  register_organization_label: "Nombre de la Organización{star}",
  profile_name_label: "Nombre{star}",

  register_dob_error: "*Se requiere fecha de nacimiento",

  register_age_error: "*Seleccione su rango de edad",

  date_invalid_error: "Por favor, introduzca la fecha válida.",

  register_organization_error: "* Se requiere el nombre de la organización",
  profile_name_error: "* Se requiere el nombre",

  register_organization_type_label: "Tipo de organización{star}",
  profile_type_label: "Tipo{star}",

  register_organization_type_error: "* Seleccione el tipo de organización",
  profile_type_error: "* Seleccione el tipo",

  register_organization_title_label: "Título de la organización{star}",
  profile_title_label: "Título{star}",

  register_organization_title_error: "* Se requiere título",

  register_browse_label: "Vistazo...",

  register_upload_label: "Subir logo",

  register_upload_error: "* Seleccione logotipo",

  register_are_you_label: "Eres un{star}",

  register_are_you_resident: "Residente de los Estados Unidos",

  register_are_you_citizen: "Ciudadano estadounidense",

  register_are_you_prefer: "Prefiero no responder",

  register_are_you_error: "* Por favor seleccione Tipo",

  register_your_title_label: "título{star}",

  register_your_title_list1: "Señor.",

  register_your_title_list2: "Pierda.",

  register_your_title_list3: "señora.",

  register_your_title_list4: "La doctora.",

  register_your_title_list5: "Sra.",

  register_your_title_error: "* Por favor seleccione su título",

  register_name_label: "Nombre del puño{star}",

  register_name_error1: "*Se requiere el primer nombre",

  register_name_error2: "Debe haber un límite de 15-20 texto permitido.",

  register_last_name_label: "Apellido{star}",

  register_last_name_error1: "* Se requiere apellido",

  register_last_name_error2: "Debe haber un límite de 15-20 texto permitido.",

  register_ethinicicty_label: "Etnicidad{star}",

  register_ethinicicty_error: "* Por favor seleccione un Ethinicity",

  register_email_label: "Email{star}",

  register_email_error1: "*Correo electronico es requerido",

  register_email_error2:
    "Por favor ingrese una dirección de correo electrónico válida",

  register_mobile_label: "Número de teléfono móvil{star}",

  register_mobile_error1: "* Se requiere número de móvil",

  register_gender_label: "género{star}",

  register_gender_type1: "Masculino",

  register_gender_type2: "Hembra",

  register_gender_type3: "Otro",

  register_gender_error: "*Por favor seleccione el género",

  register_birth_label: "Fecha de nacimiento{star}",

  register_street_label: "Dirección{star}",

  register_street_error: "* Se requiere Street Addess",

  register_apartment_label: "Suite del apartamento{star}",

  register_apartment_error: "* Se requiere apartamento / suite",

  register_country_label: "País{star}",

  register_country_error: "*Por favor seleccione país",

  register_state_label: "Estado{star}",

  register_state_error: "* Por favor seleccione estado",

  register_city_label: "Ciudad{star}",

  register_county_label: "Condado{star}",

  register_city_error: "* Por favor seleccione ciudad",

  register_zip_label: "Código postal{star}",

  register_zip_error: "* El campo del código postal es obligatorio",

  register_zip2_label: "Código +4",

  register_password_label: "Contraseña{star}",

  register_password_error1: "* Se requiere el campo de contraseña",

  register_password_error2:
    "Las contraseñas deben contener al menos 8 caracteres, incluidas letras mayúsculas, minúsculas y números.",

  register_terms1_label: "Estoy de acuerdo con la",

  register_terms2_label: "términos de Uso",

  register_terms3_label: "y",

  register_terms4_label: "política de privacidad",

  register_terms_error:
    "* Indique que acepta los Términos y la Política de privacidad",

  register_already_account: "Ya tienes una cuenta,",

  register_signin_label: "Registrarse",

  register_or_signin_label: "o Inicie sesión con",

  register_registerbutton_label: "Registrarse",

  register_organization_type1: "Ciudad",

  register_organization_type2: "Gobierno",

  register_organization_type3: "Municipal",

  register_organization_type4: "Distritos de mejora comercial",

  register_organization_type5: "Think Tank",

  register_organization_type6: "Educación superior",

  register_ethinicity_type1: "Afroamericano",

  register_ethinicity_type2: "Indio Americano o Nativo de Alaska",

  register_ethinicity_type3: "Isleño asiático o pacífico",

  register_ethinicity_type4: "caucásico",

  register_ethinicity_type5: "hispano o latino",

  register_ethinicity_type6: "Hawaianos nativos u otros isleños del Pacífico",

  register_ethinicity_type7: "Multirracial",

  register_ethinicity_type8: "Otro",

  register_ethinicity_type9: "Prefiero no responder",

  register_profile_change_password: "Cambia la contraseña",

  register_create_password_change: "cambia tu contraseña",

  register_create_password_create: "Crea tu contraseña",

  register_create_password_old: "Contraseña anterior*",

  register_create_password_new: "Nueva contraseña*",

  register_create_password_confirm: "Confirmar contraseña*",

  register_create_password_not_match: "*La contraseña no coincide",

  register_create_password_cotinue: "Seguir",

  register_collegues_add_collegues: "Agregar colega",

  register_department_label: "Departamento{star}",

  register_department_error: "* Se requiere departamento",

  register_member_no_colleagues: "No se agregaron colegas",

  register_member_colleagues_list: "Lista de colegas",
  register_member_colleague_list: "Lista de colega",

  register_member_delete: "Eliminar",

  register_profile_upload_resident: "Subir datos residentes",

  register_profile_batch_upload: "Carga por lotes",

  register_profile_resident_details: "Plantilla de detalles para residentes",

  //Login page

  login_signin_label: "Registrarse",

  login_email_label: "Email",

  login_email_error1: "*Correo electronico es requerido",

  login_email_error2:
    "*Por favor ingrese una dirección de correo electrónico válida",

  login_password_label: "Contraseña",

  login_password_error1: "* Se requiere el campo de contraseña",

  login_password_error2:
    "Las contraseñas deben contener al menos 8 caracteres, incluidas letras mayúsculas, minúsculas y números.",

  login_checkbox_label: "Recuérdame",

  login_loginbutton_label: "Iniciar sesión",

  login_orsignin_label: "o Inicie sesión con",

  login_forgot_password_label: "¿Se te olvidó tu contraseña?",

  login_registration_label1: "¿No tienes una cuenta?",

  login_resident_label1: "Crear cuenta de residente",

  login_resident_label2: "Crear una cuenta",

  login_organization_label1: "Crear cuenta de organización",

  login_forgot_password_submit: "Enviar",

  //404 Not found Page and thank you page

  notfound_thankyou_content1: "Para elegir Civy,",

  notfound_thankyou_content2:
    "Por favor revise su correo electrónico. Hemos enviado a",

  notfound_thankyou_content3: "Un correo electrónico para confirmación.",

  notfound_thankyou_content4: "De vuelta a casa",

  //header banner

  header_banner_about: "acerca de",

  header_banner_contactus: "Contáctenos",

  header_banner_service: "Servicios",

  header_banner_outpolicies: "Nuestras Políticas",

  header_banner_project: "Proyecto",

  header_banner_thought_leadership: "Liderazgo de pensamiento",

  header_banner_how_to: "Cómo",

  header_home_banner:
    "CONECTANDO GOBIERNO Y COMUNIDADES A TRAVÉS DE PLATAFORMAS INNOVADORAS",

  header_banner_colleagues: "Colega",

  header_banner_change_password: "Cambia la contraseña",

  header_banner_create_password: "Crear contraseña",

  header_banner_survey: "Encuesta",

  header_banner_what_is_civy: "QUE ES CIVY",

  header_banner_qr_code_management: "Gestión de códigos QR",

  //header.........................

  header_home: "HOGAR",

  header_core: "Núcleo 19",

  header_KIOSK: "Quiosco",

  header_service: "SERVICIOS",

  header_project: "Proyectos",

  header_project_engage: "CONTRATAR",

  header_thought_leadership: "PENSAMIENTO LIDERAZGO",

  header_about: "ACERCA DE",

  header_login: "INICIAR SESIÓN",

  // header_language1: "Inglés",

  // header_language2: "francés",

  // header_language3: "Español",

  // header_language4: "hindi",

  // header_language5: "mandarín",

  // header_language6: "italiano",

  // header_language7: "vietnamita",
  header_language1: "EN",

  header_language2: "FR",

  header_language3: "ES",

  header_language4: "HI",

  header_language5: "ZH",

  header_language6: "IT",

  header_language7: "VI",

  header_header_drawer_welcome: "Bienvenidos,",

  header_header_drawer_admin_profile: "Perfil de administrador",

  header_header_drawer_profile: "Perfil",

  header_header_drawer_colleagues: "Colegas",

  header_header_drawer_op_ed: "OP-ED",

  header_header_drawer_how_to: "Cómo",

  header_header_drawer_logout: "Cerrar sesión",

  header_what_is_civy: "QUE ES CIVY",

  header_qr_code_management: "Gestión de códigos QR",

  //footer........................

  footer_contactus_title: "Contáctenos",

  footer_contactus_content1:
    "Comuníquese con Civy para saber cómo puede habilitar una mejor participación cívica para sus proyectos de desarrollo comunitario para los residentes de todos los grupos demográficos.",

  footer_media: "Medios de comunicación",

  footer_terms_title: "Términos de Uso",

  footer_privacy_title: "Política de privacidad",

  footer_google_translate_message: `
Civy usa Google Translate para convertir del inglés a otros idiomas disponibles en la plataforma. A veces, Google Translate puede no proporcionar una traducción exacta. Para cualquier consulta, contáctenos en`,

  // blog details page..............

  blog_details_author_title: "Autor",

  blog_details_author_title_star: "Autor{star}",

  blog_details_posted_title: "Publicado en:",

  blog_details_latest_article_title: "último artículo",

  blog_details_op_ed_title: "Civy OP-ED",

  blog_addblog_new_blog: "Añadir nuevo artículo de opinión",

  blog_addblog_title: "Título",

  blog_addblog_description_title: "Descripción",

  blog_addblog_browse_title: "Vistazo...",

  blog_addblog_addimage_title: "Añadir imagen..",

  blog_addblog_submit_title: "Añadir artículo de opinión",

  blog_editblog_op_ed_title: "Editar artículo de opinión",

  blog_common_error: "*Este campo es requerido",

  commonn_update_button: "Actualizar",

  blog_title_error: "*Título es requerido",

  blog_desc_error: "*Descripción requerida",

  blog_author_error: "*Se requiere autor",

  blog_image_error: "*Se requiere imagen",

  valid_image_error: "*Las imágenes deben estar en .png, .jpeg, .jpg",

  //project page.........................

  project_filter_project_type1: "Nuevo proyecto",

  project_filter_project_type2: "Proyecto existente",

  project_filter_project_type3: "Reparaciones / Mantenimiento",

  project_filter_project_type4: "Evento",

  project_filter_project_type5: "Desastre",

  project_filter_project_category1: "Verdor",

  project_filter_project_category2: "Peajes",

  project_filter_project_category3: "Utilidades",

  project_filter_project_category4: "Carreteras",

  project_filter_project_category5: "Carreteras",

  project_filter_project_category6: "Edificio comercial",

  project_filter_project_category7: "Edificio residencial",

  project_filter_project_category8: "Tierra nativa",

  project_filter_project_category9: "Edificios del club comunitario",

  project_filter_project_category10: "Obras menores",

  project_filter_city_zipcode: "CONDADO, CIUDAD Y ZIP",

  project_filter_range: "Rango",

  project_filter_range1: "millas",

  project_filter_project_type: "Tipo de proyecto",

  project_filter_project_type_star: "Tipo de proyecto{star}",

  project_filter_project_category: "Categoría de proyecto",

  project_filter_project_category_star: "Categoría de proyecto{star}",

  project_filter_add_new_project: "Agregar nuevo proyecto",

  project_filter_reset: "Reiniciar",

  project_newproject_organization_label: "Departamento de organización",

  project_newproject_organization_label_star:
    "Departamento de organización{star}",

  project_location: "Ubicación{star}",

  project_street_address: "Dirección",

  project_newproject_common_error: "*Este campo es requerido",

  project_newproject_street_label: "Calle",

  project_newproject_country_label: "País",

  project_newproject_state_label: "Estado",

  project_newproject_city_label: "Ciudad",

  project_newproject_zipcode_label: "Código postal",

  project_newproject_areacode_label: "Código +4",

  project_newproject_start_date: "Fecha de inicio del proyecto",

  project_newproject_end_date: "Fecha de finalización del proyecto",

  project_newproject_cancel: "Cancelar",

  project_projectfom_add_project: "Agregar proyecto",

  publish: "Publicar",

  project_newproject_continue: "Seguir",

  project_projectlist_category_label: "Categoría:",

  project_projectlist_type_label: "Tipo:",

  project_projectlist_view_label: "ver Proyecto",

  project_projectlist_no_project: "No se agregaron proyectos",

  project_editproject_edit: "Editar proyecto",

  project_newproject_title_label: "Título del Proyecto",

  project_newproject_description: "Descripción del Proyecto",

  project_newproject_description_star: "Descripción del Proyecto{star}",

  project_newproject_add_images: "Añadir imágenes..",

  project_newproject_add_File: "Cargar archivos PDF...",

  project_commentbox_join_label: "Unirse a la conversación",

  project_commentbox_nocomment_label: "Ningún comentario agregado",

  project_commentbox_comment_label: "{commentlength} comentarios",

  project_details_location: "Ubicación",

  project_details_posted: "Publicado más de",

  project_details_share: "Compartir en:",

  project_details_last_update: "Ultima actualización en",

  project_details_likes: "Me gusta total:",

  project_details_question: "Añadir pregunta",

  project_details_question_list: "Lista de preguntas",

  project_details_no_question: "Ninguna pregunta agregada",

  project_details_add_answer: "Agregar respuesta",

  project_details_add_answer_here: "Agrega tu respuesta aquí ...",

  project_facts_and_figures_label:
    "Actualizaciones del personal de la ciudad y el gobierno",

  project_gallery_label: "Galería de proyectos",

  qr_code_label: "Generar código QR",

  //Survey page............

  survey_select_questionType: "Seleccionar tipo de pregunta",

  survey_questionType_content:
    "Los detalles de su proyecto se han cargado correctamente en Civy. Seleccione el tipo de preguntas que desea hacer a los residentes.",

  survey_questionType_type_a: "Escribe un",

  survey_questionType_type_que1:
    "1. Agregue su opinión para el área de juegos infantiles",

  survey_write_here_label: "escribe algo aquí ...",

  survey_questionType_create_survey: "Crear encuesta",

  survey_questionType_type_b: "Preguntas de respuestas múltiples",

  survey_questionType_type_que2:
    "1. ¿Le gustaría agregar un área de juegos para niños en el jardín público?",

  survey_questionType_type_b_yes: "si",

  survey_questionType_type_b_no: "No",

  survey_comment_box: "Pregunta de respuesta abierta (cuadro de comentarios)",

  survey_radio_question_label: "Preguntas",

  survey_discard_label: "Descarte",

  survey_submit_label: "Añadir",

  survey_add_question_label: "Añadir pregunta",

  survey_back_label: "Espalda",

  survey_question_list_label: "Lista de preguntas",

  dialog_delete_message: "¿Estás segura de que deseas eliminar",

  delete_lable: "Eliminar",

  cancel_label: "Cancelar",

  comment: "Comentario",

  /* Validation message */

  departmaent_required: "*Se requiere el nombre del departamento",

  project_type_required: "*Por favor seleccione el tipo de proyecto",

  project_category_required: "*Por favor seleccione la categoría del proyecto",

  location_required: "*Se requiere ubicación",

  full_address_required: "*Por favor ingrese la dirección completa",

  only_usa_required: "*Agregue una dirección solo para los Estados Unidos",

  project_title_requied: "*Título del proyecto requerido",

  project_desc_required: "*Descripción del proyecto requerida",

  project_image_required: "Se requiere imagen",

  export_button: "Exportar a Excel",

  export_project: "Proyecto de exportación",

  export_project_data: "Exportar datos del proyecto",

  from: "Desde",

  to: "A",

  project_required: "*Por favor seleccione Proyecto",

  date_range: "Rango de fechas",

  edit_question: "Editar pregunta",

  quetion_labele: "Pregunta",

  qustion_required: "*Se requiere un campo de preguntas",

  click_edit: "Haga clic para editar perfil",

  back_label: "Espalda",

  save_label: "Guardar",

  draft_label: "Reclutar",

  upload_label: "SUBIR",

  attachment_label: "Archivos adjuntos",

  rating_type_question: "Preguntas sobre el tipo de calificación",

  survey_questionType_type_que3: "Por favor califique este proyecto",

  allow_cookie_text:
    "Utilizamos cookies para optimizar su experiencia, analizar el tráfico y personalizar el contenido. Para obtener más información, visite nuestra Política de cookies. Al usar nuestro sitio web sin deshabilitar las cookies, acepta que las usemos.",

  allow_btn: "Permitir",

  decline_btn: "Disminución",

  cookie_policy: "Política de cookies",

  /* What is civy page */

  civy_intro_title1: "Características de la plataforma",

  civy_intro1_point1:
    "Las ciudades y los gobiernos acceden a la plataforma a través de una URL dedicada, por ejemplo, {link}",

  civy_intro1_point2:
    "El cliente tiene la opción de vincular nuestra plataforma a sus páginas de redes sociales (LinkedIn, Facebook y Twitter) para permitir una mayor profundidad y alcance para aumentar la participación de la comunidad.",

  civy_intro1_point3: "La plataforma incluye varios idiomas.",

  civy_intro1_point4:
    "Los datos se almacenan en el servicio en la nube de Civy, y la seguridad y privacidad de los datos se mantienen mediante auditorías cibernéticas semestrales de acuerdo con las regulaciones de privacidad y datos de EE. UU.",

  civy_intro1_point5:
    "Civy no se relaciona con anunciantes externos, por lo que tanto los clientes como las ciudades pueden mantener la confianza de que no recibirán correos electrónicos ni promociones no solicitados.",

  civy_intro1_point6:
    "La plataforma es accesible desde dispositivos móviles, en cualquier parte del mundo; y notificaciones por correo electrónico y SMS para todos los usuarios registrados.",

  civy_intro1_point7:
    "Escaneos de códigos QR para proyectos (escaneados por residentes para proporcionar respuestas rápidas).",

  civy_intro1_point8:
    "Acceso a informes descargables relacionados con su ciudad, para todos los proyectos o proyectos seleccionados.",

  civy_intro2_title: "Razones para que las organizaciones se registren",

  civy_intro2_point1:
    "Cree una cuenta de administrador con el logotipo de la ciudad y agregue hasta 20 colegas.",

  civy_intro2_point2:
    "Los datos creados por el cliente y los comentarios de los residentes son propiedad del cliente.",

  civy_intro2_point3:
    "Los comentarios del residente se envían directamente al creador del proyecto; por ejemplo, los comentarios de los residentes sobre un proyecto creado por Obras Públicas se dirigen a las obras públicas.",

  civy_intro2_point4:
    "Los proyectos se pueden crear utilizando códigos postales únicos o múltiples; nivel de condado a nivel de ciudad local; y compartido a través de Civy y sus canales de redes sociales vinculados. Esto asegura que los comentarios de los residentes estén directamente vinculados al código postal del residente (los proyectos publicados por los proyectos de la ciudad de Nueva York solo pueden ser respondidos por los residentes de Nueva York).",

  civy_intro2_point5: "Capaz de descargar informes.",

  civy_intro3_title: "Razones para que los residentes se registren",

  civy_intro3_point1:
    "Para asegurarse de que su perfil esté vinculado al código postal en el que vive, podrá responder las preguntas publicadas por su ciudad.",

  civy_intro3_point2:
    "Para asegurarse de que usted proporcione directamente sus respuestas. Civy no vincula, recopila ni agrega datos de ninguna de sus otras cuentas de redes sociales. Civy solo registra las respuestas que proporcionó en la plataforma de Civy, y estas respuestas solo son visibles para que las revisen los funcionarios de la ciudad que utilizan la plataforma.",

  civy_intro3_point3:
    "Sus respuestas o comentarios no son visibles para sus compañeros residentes.",

  civy_intro3_point4:
    'No es necesario que se registre en Civy como "anónimo", ya que sus respuestas son confidenciales y solo las puede ver el funcionario municipal responsable del proyecto.',

  what_is_civy_policies_title: "POLITICAS",

  what_is_civy_policies_content: "POLÍTICAS DE PRIVACIDAD Y DATOS",

  //QR code Management page

  qr_code_svg: "Generar como SVG",

  qr_code_png: "Generar como PNG",

  qr_code_jpeg: "Generar como JPEG",

  qr_code: "Código QR",
};
