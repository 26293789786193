import React from "react";
import IdleTimer from "react-idle-timer";
import { useHistory } from "react-router-dom";
import Token from "@util/token";
import { displayNotification } from "../store/action";
import { useDispatch } from "react-redux";
// const onAction = e => {
//   console.log("user did something", e);
// };

// const onActive = e => {
//   console.log("user is active", e);
// };

export default function IdealTimer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const onIdle = e => {
    // console.log("user is idle", e);
    if (Token.getToken()) {
      Token.resetToken();
      Token.eraseCookie("userToken");
      history.replace("/login");
      dispatch(
        displayNotification({
          open: true,
          message:
            "Sorry you have no inactivity for last 15 minute so token expire Please Re-login",
          duration: 3000,
          severity: "error"
        })
      );
    }
  };
  return (
    <>
      <IdleTimer
        // ref={ref => { Null = ref }}
        element={document}
        // onActive={onActive}
        onIdle={onIdle}
        // onAction={onAction}
        debounce={250}
        timeout={1000 * 1500}
      />
    </>
  );
}
