import { GET_IP_PLACE } from "./ipPlaceAction";
const initialState = {
  items: null
};

export default function ipPlaceReducer(state = initialState, action) {
  switch (action.type) {
    case GET_IP_PLACE:
      return {
        ...state,
        items: action.payload
      };
    default:
      return state;
  }
}
