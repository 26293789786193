import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import common from "./reducer";
import registerReducer from "../pages/Registration/reducer";
import authReducer from "../pages/Login/reducer";
import blogReducer from "../pages/Blogs/redux/blogReducer";
import projectReducer from "../pages/Project/redux/projectReducer";
import feedbackReducer from "../components/ContactUs/reducer";
import ipPlaceReducer from "../pages/Project/redux/ipPlaceReducer";
import placeReducer from "../pages/Project/redux/placeReducer";
import organizationReducer from "../pages/User/redux/Organization/organizationReducer";
import memberReducer from "../pages/Member/redux/MemberReducer";
import citizenReducer from "../pages/User/redux/citizen/citizenReducer";
import questionReducer from "../pages/Survey/redux/questionReducer";
import answerReducer from "../pages/Survey/redux/answerReducer";
import exportReducer from "../pages/User/redux/Organization/exportUserReducer";
import commentReducer from "../pages/Project/redux/commentReducer";
import projectVoteReducer from "../pages/Project/redux/projectVoteReducer";
import qrCodeManagementReducer from "../pages/QRCodeManagement/redux/reducer";
const reducer = combineReducers({
  common,
  register: registerReducer,
  auth: authReducer,
  blogs: blogReducer,
  projects: projectReducer,
  ipPlace: ipPlaceReducer,
  place: placeReducer,
  feedback: feedbackReducer,
  organization: organizationReducer,
  member: memberReducer,
  citizen: citizenReducer,
  questions: questionReducer,
  answer: answerReducer,
  exportUser: exportReducer,
  comments: commentReducer,
  projectVote: projectVoteReducer,
  qrCode: qrCodeManagementReducer,
});

const middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(...middleware))
);

export default store;
