import axios from "axios";
import { displayNotification } from "../../../../store/action";
export const EXPORT_USER = "EXPORT_USER";

export const exportUserData = payload => ({
  type: EXPORT_USER,
  payload
});

export const exportUser = data => {
  const apiUrl =
    process.env.REACT_APP_API_HOST +
    "/api/v1/users/excelSheetUploadAndDataInsertDB";
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${window.localStorage.userToken}`
  //     }
  //   };
  return dispatch => {
    return axios
      .post(apiUrl, data)
      .then(response => {
        dispatch(exportUserData(response.data));
        dispatch(
          displayNotification({
            open: true,
            message:
              response && response.data
                ? response.data.message
                : "Something went wrong",
            duration: 3000,
            severity: "success"
          })
        );
      })
      .catch(error => {
        dispatch(
          displayNotification({
            open: true,
            message:
              error && error.data ? error.data.message : "Something went wrong",
            duration: 3000,
            severity: "error"
          })
        );
      });
  };
};
