import axios from "axios";
import { displayNotification } from "../../../store/action";
export const GET_COMMENT = "GET_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const ADD_COMMENT = "ADD_COMMENT";
export const getCommentData = payload => ({
  type: GET_COMMENT,
  payload
});

export const getComment = id => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/projects/${id}/comments`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    return axios
      .get(apiUrl, config)
      .then(response => {
        dispatch(getCommentData(response.data.comments));
      })
      .catch(error => {
        throw error;
      });
  };
};

export const deleteCommentData = payload => ({
  type: DELETE_COMMENT,
  payload
});

export const deleteComment = id => {
  const apiUrl = process.env.REACT_APP_API_HOST + `/api/v1/comments/${id}`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return (dispatch, getState) => {
    const { items } = getState().comments;
    const deleteIndex = items.findIndex(v => v._id === id);
    items.splice(deleteIndex, 1);
    return axios
      .delete(apiUrl, config)
      .then(response => {
        if (response.status === 200) {
          dispatch(getCommentData([...items]));
          dispatch(
            displayNotification({
              open: true,
              message:
                response && response.data
                  ? "Comment Deleted Successfully"
                  : "Something went wrong",
              duration: 3000,
              severity: "success"
            })
          );
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const addCommentData = payload => ({
  type: ADD_COMMENT,
  payload
});

export const addComment = (body, id, callback) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/projects/${id}/comments`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    return axios
      .post(apiUrl, body, config)
      .then(response => {
        dispatch(addCommentData(response.data.comment));
        callback(response.data.comment, dispatch);
        dispatch(
          displayNotification({
            open: true,
            message:
              response && response.data
                ? "Thank you for adding your expert comment."
                : "Something went wrong",
            duration: 3000,
            severity: "success"
          })
        );
      })
      .catch(error => {
        throw error;
      });
  };
};
