export const define_vi = {
  //landing page casestudies component

  landing_caseStudies1:
    "Civily beta đã thử nghiệm một chương trình làm đẹp đường phố ở Glendale, LA. Dựa trên những ý kiến ​​mà thành phố nhận được, người ta đã xác nhận rằng người dân đã tham khảo đầy đủ về dự án cho đến khi dự án bắt đầu và được nhìn thấy. Civily nhằm mục đích loại bỏ kịch bản này khỏi tất cả các dự án hiện tại và sắp tới.",

  landing_caseStudies2:
    "Một cơ quan hạ tầng chính phủ lớn có kế hoạch sử dụng Civily để thực hiện chương trình tiếp cận bên ngoài tiếp theo của họ. Cơ quan này sẽ hỏi cư dân họ phục vụ, câu hỏi về các dự án mới mà họ đang đề xuất. Đồng thời, họ cũng sẽ sử dụng Civily để giữ liên lạc với các cá nhân đang báo cáo các vấn đề cho tất cả các dự án cơ sở hạ tầng hiện tại đang được tiến hành. Họ cảm thấy rằng một nền tảng hỗ trợ di động độc lập sẽ cải thiện sự tham gia và thực tế là Civily hoạt động đơn giản, với nhiều tùy chọn ngôn ngữ khác nhau liên quan đến đa số mọi người.",

  landing_caseStudies3:
    "Một kịch bản gần đây để cung cấp cho bạn một viễn cảnh tốt hơn, hãy tưởng tượng bạn là quan chức thành phố hoặc cơ quan chính phủ hoặc một cư dân quan tâm. Bạn có trụ sở tại Los Angeles. Bạn có thể nhập tên thành phố của bạn vào Bảng điều khiển của dân sự hoặc bất kỳ thành phố nào khác trên toàn thế giới (hãy nói với Sydney, Úc). Bạn sẽ có thể thấy những gì Sydney đang làm, bạn có thể áp dụng các bộ lọc theo thể loại dự án. Nó sẽ thúc đẩy giáo dục và học tập toàn cầu.",

  landing_caseStudies4:
    "Là một cư dân của Newport Beach ở California, tôi nhận thấy thiếu các trạm thể dục ngoài trời. Những điều này rất phổ biến ở nhiều quốc gia khác, nơi có nhiều ánh nắng mặt trời, đại dương và cây xanh. Sử dụng điện thoại thông minh của mình, tôi đã tạo một dự án mới trong Civily (có tên là Trạm thể dục ngoài trời), đánh dấu vị trí, thêm một số hình ảnh ví dụ, thêm mô tả ngắn gọn về trường hợp dự án và chia sẻ nó với bộ phận thành phố địa phương và mạng lưới City. Mục đích là để cư dân tán thành ý tưởng hoặc đưa ra ý kiến ​​hoặc đề xuất khác; và trong khi đó, bộ phận thành phố đang nhận được phản hồi của người dân này. Điều này mang lại cho họ động lực để hành động và đưa nó lên cùng với những người tiếp cận cộng đồng tiếp theo của họ, giúp mọi người chứng thực và xây dựng nó.",

  landing_caseStudies_content:
    "Một số ví dụ thực tế về cách thức City có thể có lợi trong ngày trung bình của bạn.",

  landing_caseStudies_title: "NGHIÊN CỨU TRƯỜNG HỢP",

  //landing page statistics component

  landing_statistics_title: "THỐNG KÊ",

  landing_statistics_content:
    "Để hoàn toàn mang lại sự phát triển bền vững, chúng ta phải đạt được tiến bộ công bằng và rộng khắp và cho phép mọi người trở thành những người tham gia tích cực trong quá trình đó.",

  landing_statistics48:
    "của người lớn trực tiếp tham gia vào một nhóm hoặc hoạt động dân sự",

  landing_statistics68:
    "cư dân từ 18 đến 34 tuổi báo cáo rằng họ cảm thấy bị ngắt kết nối với chính phủ.",

  //landing page testimonial component

  landing_testimonial_title: "Lời chứng thực",

  landing_testimonial_content:
    "Đọc lời chứng thực của khách hàng mới nhất của chúng tôi.",

  landing_testimonial1:
    "Tôi ước tôi đã có được điều này khi chúng tôi làm việc trong các dự án đòi hỏi sự tiếp cận của các bên liên quan trên toàn tiểu bang và địa phương. Điều này sẽ làm giảm căng thẳng và giúp chúng tôi tiếp cận cộng đồng một cách hiệu quả. ",

  landing_testimonial1_author: "Alana",

  landing_testimonial1_postname:
    "- Nguyên Giám đốc Truyền thông và Tiếp cận, Cơ quan Tiện ích Nhà nước",

  landing_testimonial2:
    "Civily có thể giải quyết ba vai trò trong quá trình tham gia cùng một lúc - sắp xếp công việc của bạn.",

  landing_testimonial2_author: "Eric",

  landing_testimonial2_postname:
    "- Trợ lý lập pháp cho Thị trấn Freeholder, New Jersey",

  landing_testimonial3:
    "Civily là một trong những phát triển thú vị đầu tiên trong một thời gian dài đối với phần mềm của chính phủ và cách các đô thị tương tác với công chúng. Freeholder là một đô thị lớn ",

  landing_testimonial3_author: "Scott",

  landing_testimonial3_postname: "- Tham mưu trưởng, Chủ tịch Quốc hội",

  landing_testimonial4:
    "Nền tảng tham gia trực tuyến của Civily, rất dễ sử dụng và được thiết kế dành cho các thành phố, chính phủ và người dân. Nó đang giải quyết những điểm đau lớn mà chúng ta gặp phải khi là thành phố và các quan chức được bầu, và giúp cư dân của chúng ta dễ dàng tham gia với chúng ta hơn trong một môi trường an toàn & an toàn. Nền tảng bổ sung và tăng cường các chiến lược tiếp cận hiện tại của chúng tôi và được thiết kế để tăng tỷ lệ chi tiết và vốn chủ sở hữu.",

  landing_testimonial4_author: "Theresa Branella",

  landing_testimonial4_postname: "-Mayor, Brooklawn, New Jersey",

  //landing page services component

  landing_servicesA_header: "Tiếp cận cộng đồng rộng hơn cho các dự án.",

  landing_servicesA_content:
    "Một nền tảng duy nhất cung cấp phạm vi tiếp cận rộng nhất, nhiều ngôn ngữ và độ sâu nhân khẩu học cho các dự án dân sự.",

  landing_servicesB_header: "Quyền riêng tư và bảo mật dữ liệu.",

  landing_servicesB_content:
    "Nền tảng của chúng tôi bao gồm các biện pháp bảo mật bổ sung để bảo vệ các phản hồi của người dân, vốn chỉ hiển thị đối với các quan chức thành phố và chính phủ.",

  landing_servicesC_header: "Hiệu quả & tiện lợi.",

  landing_servicesC_content:
    "Phản hồi về Civily cho một dự án dễ dàng như gửi tin nhắn văn bản.",

  landing_servicesD_header: "Phân tích toàn diện.",

  landing_servicesD_content:
    "Phân tích phản hồi cư dân trên các nhân khẩu học khác nhau và áp dụng phân tích để ra quyết định, phê duyệt và lập kế hoạch dự án trong tương lai nhanh hơn.",

  landing_service_title: "DỊCH VỤ",

  landing_service_content:
    "Công dân giúp các thành phố và chính phủ cải thiện sự tham gia của công dân.",

  landing_ServiceSection_maincontent:
    "Civily hiển thị các dự án của chính phủ và thành phố được liệt kê bởi nhà nước, thành phố, mã zip; và đặt câu hỏi mà cư dân có thể trả lời.",

  //landing page project component

  landing_project_title: "Dự án",

  landing_project_content:
    "Một danh sách các dự án hiện tại của chúng tôi tại các thành phố khác nhau.",

  landing_project_short_description: "Mô tả ngắn gọn của dự án",

  landing_project_startdate: "Ngày bắt đầu",

  landing_project_enddate: "Ngày cuối ",

  landing_project_description_link: "Xem thêm",

  //landing page benefit component

  landing_benefit1_title: "Nền tảng di động",

  landing_benefit1_content:
    "Civily là một nền tảng di động thuận tiện dễ dàng truy cập từ bất cứ đâu.",

  landing_benefit2_title: "Miễn phí cho cư dân",

  landing_benefit2_content:
    "Civily là miễn phí cho cư dân đăng ký, trả lời các câu hỏi và khảo sát, và giúp các thành phố và chính phủ cải thiện kế hoạch dự án và giao hàng của họ.",

  landing_benefit3_title: "Liên kết truyền thông xã hội",

  landing_benefit3_content:
    "Liên kết dân sự với các nền tảng truyền thông xã hội để tăng tỷ lệ tham gia và độ sâu nhân khẩu học.",

  landing_benefit4_title: "An toàn & bảo mật",

  landing_benefit4_content:
    "Phản hồi thường trú là bí mật và Civily không chia sẻ dữ liệu cư trú với bên thứ 3.",

  landing_benefit_title: "NHỮNG LỢI ÍCH",

  landing_benefit_content:
    "Civily cung cấp nhiều tính năng để thúc đẩy sự tham gia và tham gia rộng rãi của công chúng góp phần cải thiện kết quả quản trị và phát triển. ",

  //landing page about component

  landing_about_title: "Về CIVY",

  landing_about_content:
    "Chúng tôi giúp các thành phố và chính phủ cải thiện sự tham gia của các bên liên quan và tiếp cận cộng đồng trong các dự án dân sự.",

  landing_about_maincontent:
    "Civily là một cách dễ dàng và an toàn cho cư dân tham gia và chia sẻ phản hồi về các dự án công cộng hiện tại và sắp tới của các thành phố và chính phủ. Một nền tảng trực tuyến dành riêng tiếp cận đối tượng rộng hơn. Các bên liên quan từ tất cả các nhân khẩu học tham gia và cung cấp phản hồi cho các thành phố và cơ quan chính phủ của họ, với sự thuận tiện và hiệu quả. ",

  landing_readmore: "Đọc thêm",

  //landing page contact us component

  landing_contactus_address_title: "Địa chỉ",

  landing_contactus_address: "Công ty TNHH",

  landing_contactus_address_post: "160 S Cypress St, Màu cam, CA, 92866 Hoa Kỳ",

  contactus_address_street: "160 S Cypress St,",

  contactus_address_code: "Màu cam, CA, 92866",

  contactus_address_use: "Hoa Kỳ",

  landing_contactus_address_tel: "ĐT:",

  landing_contactus_usertype1: "Cư dân",

  landing_contactus_usertype2: "Thành phố",

  landing_contactus_usertype3: "Cơ quan chính phủ",

  landing_contactus_usertype4: "trường đại học",

  landing_contactus_usertype5: "Khác",

  landing_contactus_title: "LIÊN LẠC",

  landing_contactus_name: "Tên*",

  landing_contactus_email: "E-mail*",

  landing_contactus_select: "Bạn có phải*",

  landing_contactus_feedback: "Loại phản hồi*",

  landing_contactus_feedback_good: "Tốt",

  landing_contactus_feedback_bad: "Xấu",

  landing_contactus_feedback_other: "Khác",

  landing_contactus_feedback_message: "Tin nhắn của bạn*",

  landing_contactus_send_button: "Gửi tin nhắn",

  landing_contactus_name_error: "*Tên là bắt buộc",

  landing_contactus_email_error: "*Email thì cần thiết",

  landing_contactus_email_error2: "*Vui lòng nhập địa chỉ email hợp lệ",

  landing_contactus_select_error: "*Làm ơn chọn một giải pháp",

  landing_contactus_message_error: "*Tin nhắn là bắt buộc",

  landing_contactus_capcha_error:
    "*Vui lòng xác nhận hộp kiểm xác thực Tôi không phải là rô bốt",

  select_survey_visibility: "Chọn chế độ hiển thị khảo sát",

  select_survey_visibility_private: "Riêng tư",

  select_survey_visibility_public: "Công cộng",

  // services page

  services_casestudy1_title: "THÀNH PHỐ & CHÍNH PHỦ",

  services_casestudy1_content:
    "Một nền tảng an toàn dễ sử dụng để thu hút người dân và tăng tốc phát triển thông minh hơn cho các dự án dân sự.",

  services_casestudy2_title: "CƯ DÂN",

  services_casestudy2_content:
    "Một nền tảng di động để nhận thông báo và chia sẻ phản hồi với các thành phố của bạn để hướng dẫn thiết kế và phát triển các dự án dân sự.",

  services_casestudy_title: "NGHIÊN CỨU TRƯỜNG HỢP",

  services_casestudy_content:
    "Cách Thành phố & Chính phủ kết nối với Cư dân thông qua Dân sự",

  services_integratedservices_title: "Dịch vụ tích hợp:",

  services_introservice1_title: "NHỮNG GÌ KHI CIVY KHÁC NHAU",

  services_introservice1_point1:
    "Cho phép các thành phố và chính phủ tạo dữ liệu của riêng họ, tổng hợp dữ liệu và kiểm soát tường thuật của chính họ.",

  services_introservice1_point2:
    "Tăng và cải thiện khả năng tiếp cận cộng đồng bằng cách liên kết với các kênh truyền thông xã hội khác như Facebook, Twitter và LinkedIn.",

  services_introservice1_point3:
    "Hoàn toàn bảo mật các câu trả lời của cư dân, chỉ có thể nhìn thấy đối với thành phố hoặc chính phủ đăng ký Civy. Dữ liệu được sở hữu bởi các thành phố hoặc chính phủ và Civily không tham gia vào việc bán hàng của bên thứ ba.",

  services_introservice1_point4:
    "Tải xuống và lưu trữ dữ liệu dự án trên các máy chủ của riêng bạn. Để tuân thủ quy định về quyền riêng tư, Civily xóa dữ liệu 3 tháng sau khi dự án được đánh dấu bởi khách hàng.",

  services_introservice1_point5:
    "Lựa chọn nhãn trắng là nền tảng của riêng bạn với nhãn hiệu độc đáo của bạn.",

  services_introservice1_point6:
    "Civily cũng cung cấp các dịch vụ bổ sung như 3D Hologpson và Lập bản đồ GIS với các ứng dụng trong lập kế hoạch, thiết kế và tiếp thị dự án và phát triển thành phố.",

  services_introservice2_title:
    "Chức năng sản phẩm cho các thành phố và chính phủ.",

  services_introservice2_point1:
    "Các thành phố và chính phủ tạo một tài khoản trên nền tảng Civily và liên kết các kênh truyền thông xã hội của riêng họ.",

  services_introservice2_point2:
    "Thêm nhiều người dùng vào tài khoản với các vai trò người dùng được xác định, chẳng hạn như Giám đốc Công trình Công cộng.",

  services_introservice2_point3:
    "Tạo dự án, tải lên mô tả và hình ảnh liên quan đến tầm nhìn của dự án.",

  services_introservice2_point4:
    "Chia sẻ trên các kênh khác nhau và đặt câu hỏi để cư dân trả lời.",

  services_introservice2_point5:
    "Tập hợp và phân tích các câu trả lời, nắm bắt thêm nhân khẩu học trong cộng đồng được hỗ trợ bởi nhiều khả năng ngôn ngữ của chúng tôi.",

  services_introservice2_point6:
    "Sử dụng phân tích để ra quyết định, thực thi và kết quả nhanh hơn.",

  services_introservice1_content:
    "Civily là một nền tảng tương tác cho các thành phố, chính phủ và cư dân và cho phép bạn tạo, tổng hợp và lưu trữ dữ liệu của mình.",

  services_introservice2_content:
    "Civily cung cấp một nền tảng dễ sử dụng để thúc đẩy sự tham gia của công dân.",

  // about page

  about_history1_title: "Ý tưởng",

  about_history1_content:
    "Sau khi thực hiện nhiều dự án tập trung vào cơ sở hạ tầng, năng lượng và công nghệ trên toàn cầu, chúng tôi nhận ra rằng chính phủ, các quan chức thành phố và cộng đồng, mặc dù có ý định tốt nhất của họ, luôn phàn nàn về việc thiếu tham vấn; trong khi những lợi ích riêng tư như nhà phát triển lo ngại rằng các mục tiêu thương mại của họ sẽ bị ảnh hưởng tiêu cực nếu họ tham gia với cộng đồng quá nhiều. Mặt khác, cộng đồng cảm thấy rằng họ được cung cấp kế hoạch kinh doanh bọc đường và đề xuất đầu tư dự án, nhưng họ không được hỏi ý kiến ​​trong giai đoạn thiết kế hoặc thường được coi là những người không phải là chuyên gia không biết họ đang nói gì. Đó là một sự xuất hiện phổ biến cho cư dân cảm thấy không nghe thấy trong các dự án phát triển công dân trong cộng đồng của họ.",

  about_history2_title: "Bắt đầu",

  about_history2_content:
    "Để giải quyết vấn đề này, chúng tôi đã hình dung ra một nền tảng chung nơi các ý tưởng dự án được lấy từ các quan chức thành phố và chính phủ, và trực tiếp trình bày cho cư dân và các bên liên quan để phản hồi. Trước đây có thể tham gia, phân tích và thu hẹp quá trình hành động tốt nhất dựa trên phản hồi từ các bên liên quan (cư dân). Chính quyền và chính quyền thành phố có thể giải thích trực tiếp với người dân về những gì có thể đạt được và những gì không thể, liệt kê các tiềm năng cũng như các biến chứng có thể gặp phải trong ngắn hạn, xây dựng các tài chính liên quan đến sự phát triển và sau khi tham khảo ý kiến ​​chính xác tất cả các cộng tác viên hành động và thực hiện các dự án nhanh chóng.",

  about_history3_title: "Xây dựng",

  about_history3_content:
    "Với mục đích và quyết tâm rõ ràng, chúng tôi đã tạo ra nền tảng khái niệm tập trung vào việc giải quyết các điểm đau trong giai đoạn khám phá của chúng tôi với các thành phố và chính phủ (thị trưởng, ủy viên hội đồng, hội đồng lập pháp, quản lý thành phố, giám đốc bộ phận, nhóm tiếp thị, nhóm công nghệ và nhóm cộng đồng) Nền tảng khái niệm kết hợp công cụ phân tích thông tin với các khía cạnh truyền thông xã hội để cung cấp khả năng tiếp cận rộng rãi và tham gia với cư dân cho tất cả các phát triển dự án dân sự, đã đạt được xác nhận của khách hàng. dự án dân sự và tăng cường tiếp cận cộng đồng và tăng tính bao quát cho tất cả các bên liên quan trong khi duy trì quyền riêng tư và bảo mật dữ liệu. ",

  about_history4_title: "Thực hiện",

  about_history4_content:
    "Bắt đầu từ năm 2019, chúng tôi đã triển khai Civily, một nền tảng giúp các thành phố và chính phủ cải thiện khả năng tiếp cận cộng đồng qua các dự án dân sự. Nền tảng này thu hút phản hồi bằng cách thu hút cư dân từ hàng nghìn năm đến người cao niên trong tất cả các nhân khẩu học, đồng thời giảm nhu cầu sử dụng các phương pháp lỗi thời & cũ. Nền tảng kiểu cũ cung cấp sự tiện lợi chưa từng có để tìm hiểu, tham gia và cung cấp phản hồi trung thực cho các bên liên quan cho tất cả các dự án dân sự. Nền tảng nhúng chia sẻ một lần nhấp trên nhiều kênh truyền thông xã hội, an ninh mạng và giải pháp lưu trữ đám mây cũng cung cấp quyền riêng tư dữ liệu, ngược lại - tăng năng suất và cho phép các thành phố và chính phủ tập trung vào tư duy chiến lược. ",

  about_history_title: "Lịch sử công dân",

  about_history_since: "từ",

  about_vision_title: "Tầm nhìn của chúng tôi",

  about_vision_content:
    "Để tạo ra một vòng lặp tham gia nắm bắt các thành phố, chính phủ, cư dân và các bên liên quan, và xây dựng một hành tinh thông minh hơn.",

  about_mission_title: "Nhiệm vụ của chúng tôi",

  about_mission_content1:
    "Civily giúp các thành phố và chính phủ cải thiện khả năng tiếp cận cộng đồng và sự tham gia của các bên liên quan trong các dự án dân sự bằng cách hiểu điểm đau của họ, hiểu những gì có thể được cải thiện và giúp tạo điều kiện thảo luận tốt hơn bằng cách sử dụng nền tảng độc quyền của chúng tôi. Triết lý của chúng tôi để thực hiện điều này là một cách tiếp cận cơ bản của việc hỏi mọi người, lắng nghe phản hồi của họ và giúp khách hàng của chúng tôi (thành phố và chính phủ) hành động theo phản hồi này.",

  about_interview_title: "phỏng vấn",

  about_interview_content:
    "Amar Flora, Người sáng lập của Civily đã được phỏng vấn trên truyền hình trực tiếp để thảo luận về Civily và mục tiêu của nó là giúp đỡ các thành phố và chính phủ",

  about_interview_maincontent:
    "Hãy xem người sáng lập của Civily Amar Flora giải thích trên truyền hình trực tiếp về cách thức Civily cho phép những người ra quyết định sử dụng dữ liệu từ người dùng để đưa ra quyết định nhanh hơn, hành động theo intel và chỉ cần kết nối trong suy nghĩ 'bầu trời xanh' với cư dân của họ; , chưa được lọc và trên một nền tảng độc lập. ",

  about_ourteam_title: "ĐỘI NGŨ CỦA CHÚNG TÔI",

  about_ourteam_content:
    "Chúng tôi là một nhóm người đam mê GovTech, Thành phố thông minh và sự tham gia của các bên liên quan.",

  about_ourteam_person1_name: "Amar Flora (MBA, GAICD, GCCM, CPA)",

  about_ourteam_person1_title: "Đồng sáng lập và CEO",

  about_ourteam_person1_content:
    "Một nhà lãnh đạo có tầm nhìn và một doanh nhân có niềm đam mê giáo dục đại học, thành phố thông minh và hoạt động của chính phủ. Amar đã đồng sáng lập hai công ty khởi nghiệp tư nhân, xây dựng đội ngũ và cộng tác viên toàn cầu, phục vụ trong hội đồng quản trị, và lãnh đạo việc thành lập và điều hành Khởi nghiệp đại học với số tiền tài trợ là 20 triệu đô la. Amar đã bán dịch vụ và tư vấn cho khách hàng trong các ngành công nghiệp khác nhau, mang lại tác động có ý nghĩa và tạo ra giá trị. Amar có khả năng nhạy bén trong việc xác định các cơ hội chiến lược và hoạt động, và thúc đẩy đổi mới. sinh viên sau tốt nghiệp ngành Quản lý thay đổi và Quản trị doanh nghiệp và chứng nhận CPA từ các tổ chức hàng đầu ở Mỹ và Úc. ",

  about_ourteam_person2_name: "Sabrina Uppal (MS, PMP)",

  about_ourteam_person2_title: "Đồng sáng lập và COO",

  about_ourteam_person2_content:
    "Sabrina tham gia vào Civily với những trải nghiệm chất lượng và thực hiện thành công nhiều dự án nghiên cứu và khởi nghiệp trong các lĩnh vực tư nhân và chính phủ. Sự tinh tế sáng tạo của cô ấy cùng với tư duy đổi mới, cho phép cô ấy thiết kế và cung cấp giá trị cho khách hàng. Ngoài ra, Sabrina có kinh nghiệm sâu rộng trong việc quản lý các hợp đồng chính phủ. Đối với các tổ chức do Bộ Quốc phòng Hoa Kỳ ký hợp đồng. Sabrina có kinh nghiệm trực tiếp về việc cố gắng tham gia vào thành phố của mình hoặc một cơ quan chính phủ để đạt được kết quả. Những kinh nghiệm này thúc đẩy niềm đam mê và sáng tạo của cô ấy để giúp các thành phố và chính phủ thúc đẩy sự tham gia của người dân. Cử nhân Khoa học, Khoa học thần kinh từ UC Davis, Thạc sĩ Khoa học, Sinh học từ Cal State Northridge và được chứng nhận PMP. ",

  about_ourteam_person3_name: "Kim Cương Zhang (CPA, MBA, CGMA)",

  about_ourteam_person3_title: "Giám đốc tài chính",

  about_ourteam_person3_content:
    "Jinhong Zhang có hơn 20 năm kinh nghiệm trong quản lý và lập kế hoạch tài chính và kinh doanh, giữ vai trò chủ tịch CFO, Giám đốc Tài chính và cố vấn cho các tổ chức doanh nhân lớn. bởi Ngân hàng Thế giới và Phát triển Châu Á, từng giám sát kiểm toán và cam kết thuế với PWC và KPMG ở phía khách hàng. ",

  about_ourteam_person4_name: "Sologneu Modi",

  about_ourteam_person4_title: "CTO",

  about_ourteam_person4_content: `Được Forbes Ấn Độ công nhận trong số 100 nhà quản lý nhân dân vĩ đại của Ấn Độ 2019 và được trao giải Thiết kế cơ sở hạ tầng tốt nhất và Quản trị điện tử thông minh cho các thành phố, Sologneu là một nhà lãnh đạo kinh doanh và kỹ thuật dày dạn kinh nghiệm với tư vấn về CNTT cho các công ty tư vấn đa quốc gia hàng đầu, chính phủ và Các PSU đóng vai trò là CTO cho thường dân. Sologneu có hơn 19 năm kinh nghiệm tư vấn và triển khai về CNTT trong các cơ sở hạ tầng và dịch vụ thông minh của cơ sở hạ tầng CNTT được thiết kế cho các dịch vụ Thành phố / Khuôn viên và Tòa nhà thông minh quy mô lớn. Sologneu đã làm việc với khách hàng và khách hàng trên khắp Hoa Kỳ, Trung Đông, Singapore và Ấn Độ.`,

  about_ourteam_description1:
    "Chúng tôi nhiệt tình giúp các thành phố và chính phủ thúc đẩy đổi mới để cải thiện cư dân.",

  about_ourteam_description2:
    "Một đối tác độc quyền của Civily, Infixzon là một công ty dịch vụ tư vấn, đám mây và an ninh mạng do các cựu chiến binh trong ngành có nhiều thập kỷ kinh nghiệm lãnh đạo. Infixzon phục vụ trên khắp Hoa Kỳ, APAC và các khu vực Trung Đông, thiết kế các giải pháp cho ngành dọc như Khu vực công, Chăm sóc sức khỏe, Dịch vụ tài chính, Dược phẩm và Sản xuất.",

  about_ourteam_description3:
    "Twish Technologies là một nhóm các chuyên gia có nhiều năm lãnh đạo trong các chính sách cơ sở hạ tầng, nghiên cứu khả thi dự án, chiến lược tích hợp và triển khai cho cơ sở hạ tầng cấp thành phố, cộng đồng thông minh và thành phố thông minh. Nhóm của họ bao gồm một Nhà tâm lý học tổ chức tập trung vào sự tham gia của cộng đồng thay mặt cho các thành phố và chính phủ.",

  //Terms and conditions page

  ourpolicies_term_of_use_title: "ĐIỀU KHOẢN SỬ DỤNG",

  ourpolicies_terms_title: "Các điều khoản và điều kiện",

  ourpolicies_terms_last_updated:
    "Cập nhật mới nhất: ngày {day} tháng {monthNumber} năm {year}",

  ourpolicies_terms_content1:
    'Vui lòng đọc kỹ các Điều khoản và Điều kiện ("Điều khoản", "Điều khoản và điều kiện") trước khi sử dụng trang web https://www.civy.co và ứng dụng di động của City (cùng hoặc cá nhân, "Dịch vụ") được vận hành bởi Civily Inc ("chúng tôi", "chúng tôi" hoặc "của chúng tôi").',

  ourpolicies_terms_content2:
    "Việc bạn truy cập và sử dụng Dịch vụ dựa trên sự chấp nhận và tuân thủ các Điều khoản này. Các Điều khoản này áp dụng cho tất cả khách truy cập, người dùng và những người khác muốn truy cập hoặc sử dụng Dịch vụ.",

  ourpolicies_terms_content3:
    "Bằng cách truy cập hoặc sử dụng Dịch vụ, bạn đồng ý bị ràng buộc bởi các Điều khoản này. Nếu bạn không đồng ý với bất kỳ phần nào trong các điều khoản thì bạn không có quyền truy cập Dịch vụ.",

  ourpolicies_terms_Communications_title: "Truyền thông",

  ourpolicies_terms_Communications_content1:
    "Bằng cách tạo Tài khoản trên dịch vụ của chúng tôi, bạn đồng ý đăng ký nhận bản tin, tài liệu tiếp thị hoặc quảng cáo và các thông tin khác mà chúng tôi có thể gửi. Tuy nhiên, bạn có thể từ chối nhận bất kỳ hoặc tất cả các thông tin liên lạc này từ chúng tôi bằng cách theo liên kết hủy đăng ký hoặc hướng dẫn được cung cấp trong bất kỳ email nào chúng tôi gửi.",

  ourpolicies_terms_subscription_title: "Đăng ký",

  ourpolicies_terms_subscription_content1:
    'Một số phần của Dịch vụ được lập hóa đơn trên cơ sở đăng ký ("Đăng ký (s)"). Bạn sẽ được lập hóa đơn trước trên cơ sở định kỳ và định kỳ ("Chu kỳ thanh toán"). Chu kỳ thanh toán được đặt theo từng tháng hoặc hàng năm, tùy thuộc vào loại gói thuê bao bạn chọn khi mua Đăng ký.',

  ourpolicies_terms_subscription_content2:
    "Vào cuối mỗi Chu kỳ thanh toán, Đăng ký của bạn sẽ tự động gia hạn theo các điều kiện chính xác trừ khi bạn hủy bỏ nó hoặc Civily Inc hủy bỏ nó. Bạn có thể hủy gia hạn Đăng ký thông qua trang quản lý tài khoản trực tuyến của mình hoặc bằng cách liên hệ với nhóm hỗ trợ khách hàng của Civily Inc.",

  ourpolicies_terms_subscription_content3:
    "Phương thức thanh toán hợp lệ, bao gồm thẻ tín dụng hoặc PayPal, được yêu cầu xử lý khoản thanh toán cho Đăng ký của bạn. Bạn sẽ cung cấp cho City Inc thông tin thanh toán chính xác và đầy đủ bao gồm tên đầy đủ, địa chỉ, tiểu bang, mã zip, số điện thoại và thông tin phương thức thanh toán hợp lệ. Bằng cách gửi thông tin thanh toán như vậy, bạn sẽ tự động ủy quyền cho Civily Inc tính tất cả phí Đăng ký phát sinh thông qua tài khoản của bạn cho bất kỳ công cụ thanh toán nào như vậy.",

  ourpolicies_terms_subscription_content4:
    "Nếu việc thanh toán tự động không xảy ra vì bất kỳ lý do gì, Civily Inc sẽ phát hành hóa đơn điện tử cho biết bạn phải tiến hành thủ công, trong một ngày hạn chót nhất định, với thanh toán đầy đủ tương ứng với thời hạn thanh toán như được nêu trong hóa đơn.",

  ourpolicies_terms_feechanges_title: "Phí thay đổi",

  ourpolicies_terms_feechanges_content1:
    "Civily Inc, theo quyết định riêng của mình và bất cứ lúc nào, có thể sửa đổi phí Đăng ký cho các Đăng ký. Mọi thay đổi về phí đăng ký sẽ có hiệu lực vào cuối Chu kỳ thanh toán hiện tại.",

  ourpolicies_terms_feechanges_content2:
    "Civily Inc sẽ cung cấp cho bạn thông báo trước hợp lý về bất kỳ thay đổi nào về phí Đăng ký để cho bạn cơ hội chấm dứt Đăng ký trước khi thay đổi đó có hiệu lực.",

  ourpolicies_terms_feechanges_content3:
    "Việc bạn tiếp tục sử dụng Dịch vụ sau khi thay đổi phí Đăng ký có hiệu lực cấu thành thỏa thuận của bạn để trả số tiền lệ phí Đăng ký đã sửa đổi.",

  ourpolicies_terms_refund_title: "Hoàn tiền",

  ourpolicies_terms_refund_content:
    "Ngoại trừ khi pháp luật yêu cầu, phí đăng ký phải trả là không hoàn lại.",

  ourpolicies_terms_content_title: "Nội dung",

  ourpolicies_terms_content_description1:
    'Dịch vụ của chúng tôi cho phép bạn đăng, liên kết, lưu trữ, chia sẻ và mặt khác cung cấp thông tin nhất định, văn bản, đồ họa, video hoặc tài liệu khác ("Nội dung"). Bạn chịu trách nhiệm về Nội dung mà bạn đăng trên hoặc thông qua Dịch vụ, bao gồm tính hợp pháp, độ tin cậy và tính phù hợp của nó.',

  ourpolicies_terms_content_description2:
    "Bằng cách đăng Nội dung trên hoặc thông qua Dịch vụ, Bạn tuyên bố và bảo đảm rằng: (i) Nội dung là của bạn (bạn sở hữu nó) và / hoặc bạn có quyền sử dụng và quyền cấp cho chúng tôi các quyền và giấy phép như được cung cấp trong các Điều khoản này và (ii) rằng việc đăng Nội dung của bạn trên hoặc thông qua Dịch vụ không vi phạm quyền riêng tư, quyền công khai, bản quyền, quyền hợp đồng hoặc bất kỳ quyền nào khác của bất kỳ cá nhân hoặc tổ chức nào. Chúng tôi có quyền chấm dứt tài khoản của bất kỳ ai bị phát hiện vi phạm bản quyền.",

  ourpolicies_terms_content_description3:
    "Bạn giữ bất kỳ và tất cả các quyền của bạn đối với bất kỳ Nội dung nào bạn gửi, đăng hoặc hiển thị trên hoặc thông qua Dịch vụ và bạn có trách nhiệm bảo vệ các quyền đó. Chúng tôi không chịu trách nhiệm và không chịu trách nhiệm đối với Nội dung mà bạn hoặc bất kỳ bên thứ ba nào đăng trên hoặc thông qua Dịch vụ. Tuy nhiên, bằng cách đăng Nội dung bằng Dịch vụ, bạn cấp cho chúng tôi quyền và giấy phép sử dụng, sửa đổi, thực hiện, hiển thị, tái tạo và phân phối Nội dung đó trên và thông qua Dịch vụ.",

  ourpolicies_terms_content_description4:
    "Civily Inc có quyền nhưng không có nghĩa vụ giám sát và chỉnh sửa tất cả Nội dung do người dùng cung cấp.",

  ourpolicies_terms_content_description5:
    "Ngoài ra, Nội dung được tìm thấy trên hoặc thông qua Dịch vụ này là tài sản của Civily Inc hoặc được sử dụng với sự cho phép. Bạn không được phân phối, sửa đổi, truyền, tái sử dụng, tải xuống, đăng lại, sao chép hoặc sử dụng Nội dung nói trên, cho dù toàn bộ hay một phần, cho mục đích thương mại hoặc vì lợi ích cá nhân, mà không có sự cho phép trước bằng văn bản của chúng tôi.",

  ourpolicies_terms_account_title: "Tài khoản",

  ourpolicies_terms_account_content1:
    "Khi bạn tạo tài khoản với chúng tôi, bạn đảm bảo rằng bạn trên 18 tuổi và thông tin bạn cung cấp cho chúng tôi là chính xác, đầy đủ và hiện tại mọi lúc. Thông tin không chính xác, không đầy đủ hoặc quá cũ có thể dẫn đến việc chấm dứt ngay lập tức tài khoản của bạn trên Dịch vụ.",

  ourpolicies_terms_account_content2:
    "Bạn chịu trách nhiệm duy trì tính bảo mật của tài khoản và mật khẩu của bạn, bao gồm nhưng không giới hạn ở việc hạn chế quyền truy cập vào máy tính và / hoặc tài khoản của bạn. Bạn đồng ý chịu trách nhiệm cho bất kỳ và tất cả các hoạt động hoặc hành động xảy ra trong tài khoản và / hoặc mật khẩu của bạn, cho dù mật khẩu của bạn thuộc Dịch vụ của chúng tôi hay dịch vụ của bên thứ ba. Bạn phải thông báo cho chúng tôi ngay lập tức khi nhận thấy bất kỳ vi phạm bảo mật hoặc sử dụng trái phép tài khoản của bạn.",

  ourpolicies_terms_account_content3:
    "Bạn không được sử dụng làm tên người dùng tên của người hoặc tổ chức khác hoặc không có sẵn hợp pháp để sử dụng, tên hoặc nhãn hiệu có thể thuộc bất kỳ quyền nào của người hoặc tổ chức khác ngoài bạn, mà không có sự cho phép thích hợp. Bạn không được sử dụng làm tên người dùng bất kỳ tên nào gây khó chịu, thô tục hoặc tục tĩu.",

  ourpolicies_terms_Intellectual_title: "Sở hữu trí tuệ",

  ourpolicies_terms_Intellectual_content1:
    "Dịch vụ và nội dung ban đầu của nó (không bao gồm Nội dung do người dùng cung cấp), các tính năng và chức năng vẫn sẽ là tài sản độc quyền của Civily Inc và người cấp phép. Dịch vụ được bảo vệ bởi bản quyền, nhãn hiệu và các luật khác của cả Hoa Kỳ và nước ngoài. Các thương hiệu và trang phục thương mại của chúng tôi không được sử dụng liên quan đến bất kỳ sản phẩm hoặc dịch vụ nào mà không có sự đồng ý trước bằng văn bản của Civily Inc.",

  ourpolicies_terms_links_title: "Liên kết đến các trang web khác",

  ourpolicies_terms_links_content1:
    "Dịch vụ của chúng tôi có thể chứa các liên kết đến các trang web hoặc dịch vụ của bên thứ ba không thuộc sở hữu hoặc kiểm soát của Civily Inc",

  ourpolicies_terms_links_content2:
    "Civily Inc không kiểm soát và không chịu trách nhiệm về nội dung, chính sách bảo mật hoặc thông lệ của bất kỳ trang web hoặc dịch vụ bên thứ ba nào. Chúng tôi không đảm bảo các dịch vụ của bất kỳ thực thể / cá nhân nào hoặc trang web của họ.",

  ourpolicies_terms_links_content3:
    "Bạn thừa nhận và đồng ý rằng Civily Inc sẽ không chịu trách nhiệm hoặc chịu trách nhiệm trực tiếp hoặc gián tiếp đối với mọi thiệt hại hoặc mất mát gây ra hoặc bị cáo buộc là do hoặc liên quan đến việc sử dụng hoặc phụ thuộc vào bất kỳ nội dung, hàng hóa hoặc dịch vụ nào có sẵn trên hoặc thông qua bất kỳ trang web hoặc dịch vụ của bên thứ ba như vậy.",

  ourpolicies_terms_links_content4:
    "Chúng tôi khuyên bạn nên đọc các điều khoản và điều kiện và chính sách quyền riêng tư của bất kỳ trang web hoặc dịch vụ bên thứ ba nào mà bạn truy cập.",

  ourpolicies_terms_termination_title: "Chấm dứt",

  ourpolicies_terms_termination_content1:
    "Chúng tôi có thể chấm dứt hoặc đình chỉ quyền truy cập tài khoản và thanh của bạn vào Dịch vụ ngay lập tức mà không cần thông báo trước hoặc chịu trách nhiệm pháp lý vì bất kỳ lý do gì và không giới hạn, bao gồm nhưng không giới hạn vi phạm Điều khoản.",

  ourpolicies_terms_termination_content2:
    "Nếu bạn muốn chấm dứt tài khoản của mình, bạn chỉ cần ngừng sử dụng Dịch vụ.",

  ourpolicies_terms_termination_content3:
    "Tất cả các điều khoản của Điều khoản mà theo bản chất của họ nên tồn tại chấm dứt sẽ tồn tại chấm dứt, bao gồm, nhưng không giới hạn, quy định quyền sở hữu, từ chối bảo hành, bồi thường và giới hạn trách nhiệm.",

  ourpolicies_terms_Indemnification_title: "Sự bồi thường",

  ourpolicies_terms_Indemnification_content1:
    "Bạn đồng ý bảo vệ, bồi thường và giữ cho Civily Inc vô hại và người được cấp phép và người cấp phép, và nhân viên, nhà thầu, đại lý, cán bộ và giám đốc của họ, từ và chống lại mọi khiếu nại, thiệt hại, nghĩa vụ, mất mát, nợ, chi phí hoặc nợ, và chi phí (bao gồm nhưng không giới hạn ở phí luật sư), do hoặc phát sinh từ a) việc bạn sử dụng và truy cập Dịch vụ, bởi bạn hoặc bất kỳ người nào sử dụng tài khoản và mật khẩu của bạn; b) vi phạm các Điều khoản này hoặc c) Nội dung được đăng trên Dịch vụ.",

  ourpolicies_terms_limitation_title: "Trách nhiệm hữu hạn",

  ourpolicies_terms_limitation_content1:
    "Trong mọi trường hợp, Civily Inc, cũng như giám đốc, nhân viên, đối tác, đại lý, nhà cung cấp hoặc chi nhánh sẽ không chịu trách nhiệm cho bất kỳ thiệt hại gián tiếp, ngẫu nhiên, đặc biệt, hậu quả hoặc trừng phạt nào, bao gồm nhưng không giới hạn, mất lợi nhuận, dữ liệu, sử dụng, thiện chí hoặc các tổn thất vô hình khác, xuất phát từ",

  ourpolicies_terms_limitation_list1:
    "Quyền truy cập hoặc sử dụng hoặc không thể truy cập hoặc sử dụng Dịch vụ của bạn",

  ourpolicies_terms_limitation_list2:
    "Bất kỳ hành vi hoặc nội dung của bất kỳ bên thứ ba nào trên Dịch vụ",

  ourpolicies_terms_limitation_list3: "Mọi nội dung thu được từ Dịch vụ",

  ourpolicies_terms_limitation_list4:
    "Truy cập trái phép, sử dụng hoặc thay đổi truyền hoặc nội dung của bạn, cho dù dựa trên bảo hành, hợp đồng, lỗi (bao gồm cả sơ suất) hoặc bất kỳ lý thuyết pháp lý nào khác, cho dù chúng tôi đã được thông báo về khả năng thiệt hại đó hay chưa và ngay cả khi đã khắc phục ở đây được tìm thấy là đã thất bại của mục đích thiết yếu của nó.",

  ourpolicies_terms_disclaimer_title: "Khước từ",

  ourpolicies_terms_disclaimer_content1:
    'Việc bạn sử dụng Dịch vụ là nguy cơ của riêng bạn. Dịch vụ được cung cấp trên cơ sở "NHƯ VẬY" và "NHƯ SAILN CÓ". Dịch vụ được cung cấp mà không có sự bảo đảm dưới bất kỳ hình thức nào, dù rõ ràng hay ngụ ý, bao gồm, nhưng không giới hạn, bảo đảm ngụ ý về tính thương mại, sự phù hợp cho một mục đích cụ thể, không vi phạm hoặc quá trình thực hiện.',

  ourpolicies_terms_disclaimer_content2:
    "Civily Inc các công ty con, chi nhánh và người cấp phép của nó không đảm bảo rằng",

  ourpolicies_terms_disclaimer_list1:
    "Dịch vụ sẽ hoạt động không bị gián đoạn, an toàn hoặc khả dụng tại bất kỳ thời điểm hoặc địa điểm cụ thể nào",

  ourpolicies_terms_disclaimer_list2:
    "Bất kỳ lỗi hoặc khiếm khuyết sẽ được sửa chữa",

  ourpolicies_terms_disclaimer_list3:
    "Dịch vụ không có vi-rút hoặc các thành phần gây hại khác",

  ourpolicies_terms_disclaimer_list4:
    "Kết quả sử dụng Dịch vụ sẽ đáp ứng yêu cầu của bạn.",

  ourpolicies_terms_exclusion_title: "Loại trừ",

  ourpolicies_terms_exclusion_content1:
    "Một số khu vực pháp lý không cho phép loại trừ một số bảo đảm nhất định hoặc loại trừ hoặc giới hạn trách nhiệm đối với các thiệt hại do hậu quả hoặc ngẫu nhiên, do đó, các giới hạn trên có thể không áp dụng cho bạn.",

  ourpolicies_terms_governing_title: "Luật chi phối",

  ourpolicies_terms_governing_content1:
    "Các Điều khoản này sẽ được điều chỉnh và hiểu theo luật pháp của California, Hoa Kỳ, mà không liên quan đến xung đột của các quy định pháp luật.",

  ourpolicies_terms_governing_content2:
    "Việc chúng tôi không thực thi bất kỳ quyền hoặc quy định nào trong các Điều khoản này sẽ không được coi là từ bỏ các quyền đó. Nếu bất kỳ điều khoản nào của các Điều khoản này được coi là không hợp lệ hoặc không thể thi hành bởi một tòa án, các điều khoản còn lại của các Điều khoản này sẽ vẫn có hiệu lực. Các Điều khoản này cấu thành toàn bộ thỏa thuận giữa chúng tôi về Dịch vụ của chúng tôi và thay thế và thay thế mọi thỏa thuận trước đây mà chúng tôi có thể có giữa chúng tôi về Dịch vụ.",

  ourpolicies_terms_changes_title: "Thay đổi",

  ourpolicies_terms_changes_content1:
    "Chúng tôi bảo lưu quyền, theo quyết định riêng của chúng tôi, để sửa đổi hoặc thay thế các Điều khoản này bất cứ lúc nào. Nếu bản sửa đổi là tài liệu, chúng tôi sẽ cung cấp thông báo ít nhất 30 ngày trước khi bất kỳ điều khoản mới nào có hiệu lực. Những gì cấu thành một sự thay đổi vật chất sẽ được xác định theo quyết định riêng của chúng tôi.",

  ourpolicies_terms_changes_content2:
    "Bằng cách tiếp tục truy cập hoặc sử dụng Dịch vụ của chúng tôi sau khi bất kỳ sửa đổi nào có hiệu lực, bạn đồng ý bị ràng buộc bởi các điều khoản sửa đổi. Nếu bạn không đồng ý với các điều khoản mới, bạn không còn được phép sử dụng Dịch vụ.",

  ourpolicies_terms_contactus_title: "Liên hệ chúng tôi",

  ourpolicies_terms_contactus_content:
    "Nếu bạn có bất kỳ câu hỏi nào về các Điều khoản này, vui lòng liên hệ với chúng tôi tại",

  //Privacy policies page

  ourpolicies_policy_title: "Chính sách bảo mật",

  ourpolicies_policy_date:
    "Ngày có hiệu lực: ngày {day} tháng {monthNumber} năm {year}",

  ourpolicies_policy_content1: `Civily Inc. ("chúng tôi", "chúng tôi" hoặc "của chúng tôi") vận hành trang web https://www.civy.co và ứng dụng di động của City (sau đây gọi là "Dịch vụ").`,

  ourpolicies_policy_content2:
    " Trang này thông báo cho bạn các chính sách của chúng tôi về việc thu thập, sử dụng và tiết lộ dữ liệu cá nhân khi bạn sử dụng Dịch vụ của chúng tôi và các lựa chọn bạn đã liên kết với dữ liệu đó.",

  ourpolicies_policy_content3:
    " Chúng tôi sử dụng dữ liệu của bạn để cung cấp và cải thiện Dịch vụ. Bằng cách sử dụng Dịch vụ, bạn đồng ý với việc thu thập và sử dụng thông tin theo chính sách này. Trừ khi các quy định khác được quy định trong Chính sách bảo mật này, các điều khoản được sử dụng trong Chính sách quyền riêng tư này có cùng ý nghĩa như trong Điều khoản và Điều kiện của chúng tôi.",

  ourpolicies_definitions_title: "Định nghĩa",

  ourpolicies_service_title: "Dịch vụ",

  ourpolicies_service_content: `Dịch vụ có nghĩa là trang web https://www.civy.co và ứng dụng di động Civily được vận hành bởi Civily Inc.`,

  ourpolicies_personaldata_title: "Dữ liệu cá nhân",

  ourpolicies_personaldata_content:
    "Dữ liệu cá nhân có nghĩa là dữ liệu về một cá nhân sống có thể được xác định từ những dữ liệu đó (hoặc từ những thông tin đó và thông tin khác thuộc sở hữu của chúng tôi hoặc có khả năng thuộc sở hữu của chúng tôi).",

  ourpolicies_usagedata_title: "Dữ liệu sử dụng",

  ourpolicies_usagedata_content:
    "Dữ liệu sử dụng là dữ liệu được thu thập tự động bằng cách sử dụng Dịch vụ hoặc từ chính cơ sở hạ tầng Dịch vụ (ví dụ: thời lượng của một lần truy cập trang).",

  ourpolicies_cookie_title: "Bánh quy",

  ourpolicies_cookie_content:
    "Cookies là các tệp nhỏ được lưu trữ trên thiết bị của bạn (máy tính hoặc thiết bị di động).",

  ourpolicies_datacontroller_title: "Người điều khiển dữ liệu",

  ourpolicies_datacontroller_content1:
    "Kiểm soát dữ liệu có nghĩa là thể nhân hoặc pháp nhân (một mình hoặc chung hoặc chung với người khác) xác định các mục đích và cách thức mà bất kỳ thông tin cá nhân nào, hoặc sẽ được xử lý.",

  ourpolicies_datacontroller_content2:
    "Với mục đích của Chính sách bảo mật này, chúng tôi là Người kiểm soát dữ liệu dữ liệu cá nhân của bạn.",

  ourpolicies_dataprocessor_title:
    "Bộ xử lý dữ liệu (hoặc nhà cung cấp dịch vụ)",

  ourpolicies_dataprocessor_content1:
    "Bộ xử lý dữ liệu (hoặc Nhà cung cấp dịch vụ) có nghĩa là bất kỳ cá nhân hoặc pháp nhân nào xử lý dữ liệu thay mặt cho Bộ điều khiển dữ liệu.",

  ourpolicies_dataprocessor_content2:
    "Chúng tôi có thể sử dụng dịch vụ của các Nhà cung cấp dịch vụ khác nhau để xử lý dữ liệu của bạn hiệu quả hơn.",

  ourpolicies_datasubject_title: "Chủ đề dữ liệu (hoặc người dùng)",

  ourpolicies_datasubject_content:
    "Chủ thể dữ liệu là bất kỳ cá nhân sống nào đang sử dụng Dịch vụ của chúng tôi và là chủ đề của Dữ liệu cá nhân.",

  ourpolicies_information_title: "Thu thập và sử dụng thông tin",

  ourpolicies_information_content:
    "Chúng tôi thu thập một số loại thông tin khác nhau cho các mục đích khác nhau để cung cấp và cải thiện Dịch vụ của chúng tôi cho bạn.",

  ourpolicies_typeofdata_title: "Các loại dữ liệu được thu thập",

  ourpolicies_typeofdata_personal_title: "Dữ liệu cá nhân",

  ourpolicies_typeofdata_personal_content1: `Khi sử dụng Dịch vụ của chúng tôi, chúng tôi có thể yêu cầu bạn cung cấp cho chúng tôi thông tin nhận dạng cá nhân nhất định có thể được sử dụng để liên hệ hoặc nhận dạng bạn ("Dữ liệu cá nhân"). Thông tin nhận dạng cá nhân có thể bao gồm, nhưng không giới hạn ở:`,

  ourpolicies_typeofdata_personal_list1: `Địa chỉ email`,

  ourpolicies_typeofdata_personal_list2: `Tên và họ`,

  ourpolicies_typeofdata_personal_list3: `Số điện thoại`,

  ourpolicies_typeofdata_personal_list4: `Địa chỉ, tiểu bang, tỉnh, mã bưu chính / bưu điện, thành phố`,

  ourpolicies_typeofdata_personal_list5: `Cookies và dữ liệu sử dụng`,

  ourpolicies_typeofdata_personal_content2: `Chúng tôi có thể sử dụng Dữ liệu Cá nhân của bạn để liên hệ với bạn với các bản tin tiếp thị hoặc tài liệu quảng cáo và các thông tin khác có thể bạn quan tâm. Bạn có thể từ chối nhận bất kỳ hoặc tất cả các thông tin liên lạc này từ chúng tôi bằng cách theo liên kết hủy đăng ký hoặc hướng dẫn được cung cấp trong bất kỳ email nào chúng tôi gửi.`,

  ourpolicies_typeofdata_usage_title: `Dữ liệu sử dụng`,

  ourpolicies_typeofdata_usage_content1: `Chúng tôi cũng có thể thu thập thông tin mà trình duyệt của bạn gửi bất cứ khi nào bạn truy cập Dịch vụ của chúng tôi hoặc khi bạn truy cập Dịch vụ bằng hoặc thông qua thiết bị di động ("Dữ liệu sử dụng").`,

  ourpolicies_typeofdata_usage_content2: `Dữ liệu sử dụng này có thể bao gồm thông tin như địa chỉ Giao thức Internet của máy tính của bạn (ví dụ: địa chỉ IP), loại trình duyệt, phiên bản trình duyệt, các trang Dịch vụ của chúng tôi mà bạn truy cập, thời gian và ngày bạn truy cập, thời gian dành cho các trang đó, duy nhất định danh thiết bị và dữ liệu chẩn đoán khác.`,

  ourpolicies_typeofdata_usage_content3: `Khi bạn truy cập Dịch vụ bằng thiết bị di động, Dữ liệu sử dụng này có thể bao gồm các thông tin như loại thiết bị di động bạn sử dụng, ID duy nhất của thiết bị di động, địa chỉ IP của thiết bị di động, hệ điều hành di động của bạn, loại Internet di động trình duyệt bạn sử dụng, số nhận dạng thiết bị duy nhất và dữ liệu chẩn đoán khác.`,

  ourpolicies_typeofdata_location_title: `Dữ liệu vị trí`,

  ourpolicies_typeofdata_location_content1: `Chúng tôi có thể sử dụng và lưu trữ thông tin về vị trí của bạn nếu bạn cho phép chúng tôi làm như vậy ("Dữ liệu vị trí"). Chúng tôi sử dụng dữ liệu này để cung cấp các tính năng của Dịch vụ của mình, để cải thiện và tùy chỉnh Dịch vụ của chúng tôi.`,

  ourpolicies_typeofdata_location_content2: `Bạn có thể bật hoặc tắt dịch vụ định vị khi bạn sử dụng Dịch vụ của chúng tôi bất cứ lúc nào bằng cách cài đặt thiết bị của bạn.`,

  ourpolicies_typeofdata_tracking_title: `Theo dõi dữ liệu cookie`,

  ourpolicies_typeofdata_tracking_content1: `Chúng tôi sử dụng cookie và các công nghệ theo dõi tương tự để theo dõi hoạt động trên Dịch vụ của mình và chúng tôi giữ một số thông tin nhất định.`,

  ourpolicies_typeofdata_tracking_content2: `Cookies là các tệp có một lượng nhỏ dữ liệu có thể bao gồm một định danh duy nhất ẩn danh. Cookies được gửi đến trình duyệt của bạn từ một trang web và được lưu trữ trên thiết bị của bạn. Các công nghệ theo dõi khác cũng được sử dụng như đèn hiệu, thẻ và tập lệnh để thu thập và theo dõi thông tin cũng như cải thiện và phân tích Dịch vụ của chúng tôi.`,

  ourpolicies_typeofdata_tracking_content3: `Bạn có thể hướng dẫn trình duyệt của mình từ chối tất cả các cookie hoặc cho biết khi nào cookie được gửi. Tuy nhiên, nếu bạn không chấp nhận cookie, bạn không thể sử dụng một số phần của Dịch vụ của chúng tôi.`,

  ourpolicies_typeofdata_cookiesex_title: `Ví dụ về Cookies chúng tôi sử dụng:`,

  ourpolicies_typeofdata_cookiesex_session: "Bánh quy phiên.",

  ourpolicies_typeofdata_cookiesex_session_content: `Chúng tôi sử dụng Cookies phiên để vận hành Dịch vụ của mình.`,

  ourpolicies_typeofdata_cookiesex_preference: "Cookie ưu tiên.",

  ourpolicies_typeofdata_cookiesex_preference_content: `Chúng tôi sử dụng Cookie ưu tiên để ghi nhớ tùy chọn của bạn và các cài đặt khác nhau.`,

  ourpolicies_typeofdata_cookiesex_security: `Cookie bảo mật.`,

  ourpolicies_typeofdata_cookiesex_security_content: `Chúng tôi sử dụng Cookies bảo mật cho mục đích bảo mật.`,

  ourpolicies_typeofdata_use_title: `Sử dụng dữ liệu`,

  ourpolicies_typeofdata_use_content: `Civily Inc. sử dụng dữ liệu thu thập cho các mục đích khác nhau:`,

  ourpolicies_typeofdata_use_list1: `Cung cấp và duy trì Dịch vụ của chúng tôi`,

  ourpolicies_typeofdata_use_list2: `Để thông báo cho bạn về những thay đổi dịch vụ của chúng tôi`,

  ourpolicies_typeofdata_use_list3: `Để cho phép bạn tham gia các tính năng tương tác của Dịch vụ của chúng tôi khi bạn chọn làm như vậy`,

  ourpolicies_typeofdata_use_list4: `Cung cấp hỗ trợ khách hàng`,

  ourpolicies_typeofdata_use_list5: `Để thu thập phân tích hoặc thông tin có giá trị để chúng tôi có thể cải thiện Dịch vụ của mình`,

  ourpolicies_typeofdata_use_list8: `Để theo dõi việc sử dụng Dịch vụ của chúng tôi`,

  ourpolicies_typeofdata_use_list6: `Để phát hiện, ngăn chặn và giải quyết các vấn đề kỹ thuật`,

  ourpolicies_typeofdata_use_list7: `Để cung cấp cho bạn tin tức, ưu đãi đặc biệt và thông tin chung về các hàng hóa, dịch vụ và sự kiện khác mà chúng tôi cung cấp tương tự với những gì bạn đã mua hoặc hỏi về trừ khi bạn đã chọn không nhận thông tin đó`,

  ourpolicies_typeofdata_legalbasis_title: `Cơ sở pháp lý để xử lý dữ liệu cá nhân theo Quy định bảo vệ dữ liệu chung (GDPR)`,

  ourpolicies_typeofdata_legalbasis_content1: `Nếu bạn đến từ Khu vực Kinh tế Châu Âu (EEA), cơ sở pháp lý để thu thập và sử dụng thông tin cá nhân được mô tả trong Chính sách bảo mật này tùy thuộc vào Dữ liệu Cá nhân chúng tôi thu thập và bối cảnh cụ thể mà chúng tôi thu thập.`,

  ourpolicies_typeofdata_legalbasis_content2: `Civily Inc. có thể xử lý Dữ liệu Cá nhân của bạn vì:`,

  ourpolicies_typeofdata_legalbasis_list1: `Chúng tôi cần thực hiện hợp đồng với bạn`,

  ourpolicies_typeofdata_legalbasis_list2: `Bạn đã cho phép chúng tôi làm như vậy`,

  ourpolicies_typeofdata_legalbasis_list3: `Việc xử lý là vì lợi ích hợp pháp của chúng tôi và nó không bị chi phối bởi các quyền của bạn`,

  ourpolicies_typeofdata_legalbasis_list4: `Đối với mục đích xử lý thanh toán`,

  ourpolicies_typeofdata_legalbasis_list5: `Tuân thủ pháp luật`,

  ourpolicies_typeofdata_retention_title: `Lưu giữ dữ liệu`,

  ourpolicies_typeofdata_retention_content1: `Civily Inc. sẽ chỉ giữ lại Dữ liệu Cá nhân của bạn miễn là cần thiết cho các mục đích được nêu trong Chính sách bảo mật này. Chúng tôi sẽ giữ lại và sử dụng Dữ liệu Cá nhân của bạn trong phạm vi cần thiết để tuân thủ các nghĩa vụ pháp lý của chúng tôi (ví dụ: nếu chúng tôi được yêu cầu giữ lại dữ liệu của bạn để tuân thủ luật pháp hiện hành), giải quyết tranh chấp và thực thi các thỏa thuận và chính sách pháp lý của chúng tôi.`,

  ourpolicies_typeofdata_retention_content2: `Civily Inc. cũng sẽ giữ lại Dữ liệu sử dụng cho mục đích phân tích nội bộ. Dữ liệu sử dụng thường được lưu giữ trong một khoảng thời gian ngắn hơn, ngoại trừ khi dữ liệu này được sử dụng để tăng cường bảo mật hoặc cải thiện chức năng của Dịch vụ của chúng tôi hoặc chúng tôi có nghĩa vụ pháp lý để giữ lại dữ liệu này trong thời gian dài hơn.`,

  ourpolicies_typeofdata_transfer_title: `Chuyển dữ liệu`,

  ourpolicies_typeofdata_transfer_content1: `Thông tin của bạn, bao gồm Dữ liệu Cá nhân, có thể được chuyển đến - và được duy trì trên - các máy tính nằm ngoài tiểu bang, tỉnh, quốc gia hoặc khu vực tài phán chính phủ khác nơi luật bảo vệ dữ liệu có thể khác với thẩm quyền của bạn.`,

  ourpolicies_typeofdata_transfer_content2: `Nếu bạn ở bên ngoài Hoa Kỳ và chọn cung cấp thông tin cho chúng tôi, xin lưu ý rằng chúng tôi chuyển dữ liệu, bao gồm Dữ liệu Cá nhân sang Hoa Kỳ và xử lý dữ liệu đó tại đó.`,

  ourpolicies_typeofdata_transfer_content3: `Sự đồng ý của bạn với Chính sách quyền riêng tư này kèm theo việc bạn gửi thông tin đó thể hiện sự đồng ý của bạn đối với việc chuyển tiền đó.`,

  ourpolicies_typeofdata_transfer_content4: `Civily Inc. sẽ thực hiện tất cả các bước cần thiết một cách hợp lý để đảm bảo rằng dữ liệu của bạn được xử lý an toàn và tuân thủ Chính sách bảo mật này và việc chuyển dữ liệu cá nhân của bạn sẽ diễn ra cho một tổ chức hoặc quốc gia trừ khi có kiểm soát thích hợp bao gồm cả bảo mật dữ liệu của bạn và thông tin cá nhân khác.`,

  ourpolicies_disclosure_title: `Tiết lộ dữ liệu`,

  ourpolicies_disclosure_law_title: `Tiết lộ cho thực thi pháp luật `,

  ourpolicies_disclosure_law_content: `Trong một số trường hợp nhất định, Civily Inc. có thể được yêu cầu tiết lộ Dữ liệu Cá nhân của bạn nếu pháp luật yêu cầu hoặc đáp ứng các yêu cầu hợp lệ của các cơ quan công quyền (ví dụ: tòa án hoặc cơ quan chính phủ).`,

  ourpolicies_disclosure_requirement_title: `Yêu cầu pháp lý`,

  ourpolicies_disclosure_requirement_content: `Civily Inc. có thể tiết lộ Dữ liệu Cá nhân của bạn với niềm tin tốt rằng hành động đó là cần thiết để:`,

  ourpolicies_disclosure_requirement_list1: `Tuân thủ nghĩa vụ pháp lý`,

  ourpolicies_disclosure_requirement_list2: `Để bảo vệ và bảo vệ các quyền hoặc tài sản của Civily Inc.`,

  ourpolicies_disclosure_requirement_list3: `Để ngăn chặn hoặc điều tra các hành vi sai trái có thể liên quan đến Dịch vụ`,

  ourpolicies_disclosure_requirement_list4: `Để bảo vệ sự an toàn cá nhân của người dùng Dịch vụ hoặc cộng đồng`,

  ourpolicies_disclosure_requirement_list5: `Để bảo vệ chống lại trách nhiệm pháp lý`,

  ourpolicies_security_title: `Bảo mật dữ liệu`,

  ourpolicies_security_content: `Bảo mật dữ liệu của bạn rất quan trọng đối với chúng tôi nhưng hãy nhớ rằng không có phương thức truyền qua Internet hoặc phương pháp lưu trữ điện tử nào là an toàn 100%. Mặc dù chúng tôi cố gắng sử dụng các phương tiện thương mại có thể chấp nhận để bảo vệ Dữ liệu Cá nhân của bạn, chúng tôi không thể đảm bảo tính bảo mật tuyệt đối của nó.`,

  ourpolicies_security_policy: `Chính sách của chúng tôi - Áp dụng chung trên toàn Hoa Kỳ, bao gồm các chính sách dành riêng cho Bang California.`,

  ourpolicies_security_track: `Tín hiệu "Không theo dõi" theo Đạo luật bảo vệ trực tuyến California (CalOPPA)`,

  ourpolicies_security_track_content1: `Chúng tôi không hỗ trợ Không theo dõi ("DNT"). Không theo dõi là tùy chọn bạn có thể đặt trong trình duyệt web của mình để thông báo cho các trang web mà bạn không muốn bị theo dõi.`,

  ourpolicies_security_track_content2: `Bạn có thể bật hoặc tắt Không theo dõi bằng cách truy cập trang Tùy chọn hoặc Cài đặt của trình duyệt web của bạn.`,

  ourpolicies_security_privacy_policy: `Chính sách của chúng tôi tuân thủ Đạo luật bảo mật người tiêu dùng California (CCPA)`,

  ourpolicies_security_privacy_policy1: `Quyền được biết:`,

  ourpolicies_security_privacy_policy1_content: `Cư dân có quyền yêu cầu chúng tôi tiết lộ những thông tin cá nhân nào được chúng tôi thu thập, sử dụng, chia sẻ hoặc bán.`,

  ourpolicies_security_privacy_policy2: `Quyền xóa:`,

  ourpolicies_security_privacy_policy2_content: `Cư dân có quyền yêu cầu chúng tôi và các nhà cung cấp dịch vụ của chúng tôi xóa thông tin cá nhân chúng tôi có về bạn.`,

  ourpolicies_security_privacy_policy3: `Quyền từ chối:`,

  ourpolicies_security_privacy_policy3_content: `Cư dân có quyền chỉ đạo chúng tôi ngừng việc bán thông tin cá nhân của họ, trong trường hợp chúng tôi bán thông tin cá nhân vì điều khoản đó được xác định bởi CCPA. Xin lưu ý rằng dữ liệu được thu thập trên nền tảng của Civily, chỉ có thể truy cập bởi các khách hàng của Civily, bao gồm các khu vực chính phủ của Hoa Kỳ (thành phố, đô thị, hội đồng, cơ quan chính phủ, quận đặc biệt và cư dân đã đăng ký). Civily không bán hoặc không có ý định bán dữ liệu cho các bên thứ ba như nhà quảng cáo hoặc công ty tư nhân.`,

  ourpolicies_security_privacy_policy4: `Quyền không phân biệt đối xử:`,

  ourpolicies_security_privacy_policy4_content: `Chúng tôi không được phân biệt đối xử với người dân về giá cả hoặc dịch vụ khi họ thực hiện quyền riêng tư theo CCPA.`,

  ourpolicies_security_protection_title: `Quyền bảo vệ dữ liệu của bạn theo Quy định bảo vệ dữ liệu chung (GDPR)`,

  ourpolicies_security_protection_content1: `Nếu bạn là cư dân của Khu vực Kinh tế Châu Âu (EEA), bạn có quyền bảo vệ dữ liệu nhất định. Civily Inc. nhằm mục đích thực hiện các bước hợp lý để cho phép bạn sửa, sửa đổi, xóa hoặc giới hạn việc sử dụng Dữ liệu Cá nhân của bạn.`,

  ourpolicies_security_protection_content2: `Nếu bạn muốn được thông báo về Dữ liệu cá nhân nào chúng tôi lưu giữ về bạn và nếu bạn muốn xóa dữ liệu đó khỏi hệ thống của chúng tôi, vui lòng liên hệ với chúng tôi.`,

  ourpolicies_security_protection_content3: `Trong một số trường hợp nhất định, bạn có các quyền bảo vệ dữ liệu sau:`,

  ourpolicies_security_access_title: `Quyền truy cập, cập nhật hoặc xóa thông tin chúng tôi có về bạn.`,

  ourpolicies_security_access_content1: `Bất cứ khi nào có thể, bạn có thể truy cập, cập nhật hoặc yêu cầu xóa Dữ liệu Cá nhân ngay trong phần cài đặt tài khoản của mình. Nếu bạn không thể tự thực hiện những hành động này, vui lòng liên hệ với chúng tôi để hỗ trợ bạn.`,

  ourpolicies_security_rectification_title: `Quyền cải chính.`,

  ourpolicies_security_rectification_content: `bạn có quyền sửa thông tin của mình nếu thông tin đó không chính xác hoặc không đầy đủ.`,

  ourpolicies_security_object_title: `Quyền phản đối.`,

  ourpolicies_security_object_content: `Bạn có quyền phản đối việc chúng tôi xử lý Dữ liệu Cá nhân của bạn.`,

  ourpolicies_security_restriction_title: `Quyền hạn chế.`,

  ourpolicies_security_restriction_content: `Bạn có quyền yêu cầu chúng tôi hạn chế xử lý thông tin cá nhân của bạn.`,

  ourpolicies_security_portability_title: `Quyền chuyển đổi dữ liệu.`,

  ourpolicies_security_portability_content: `Bạn có quyền được cung cấp một bản sao thông tin chúng tôi có về bạn theo định dạng có cấu trúc, dễ đọc bằng máy và thường được sử dụng.`,

  ourpolicies_security_withdraw_title: `Quyền rút lại sự đồng ý.`,

  ourpolicies_security_withdraw_content: `Bạn cũng có quyền rút lại sự đồng ý của bạn bất cứ lúc nào khi Civily Inc. dựa vào sự đồng ý của bạn để xử lý thông tin cá nhân của bạn.`,

  ourpolicies_security_protection_content4: `Xin lưu ý rằng chúng tôi có thể yêu cầu bạn xác minh danh tính của bạn trước khi trả lời các yêu cầu đó.`,

  ourpolicies_security_protection_content5: `Bạn có quyền khiếu nại với Cơ quan bảo vệ dữ liệu về việc chúng tôi thu thập và sử dụng Dữ liệu cá nhân của bạn. Để biết thêm thông tin, xin vui lòng liên hệ với cơ quan bảo vệ dữ liệu địa phương của bạn trong Khu vực kinh tế châu Âu (EEA).`,

  ourpolicies_serviceprovider: `Các nhà cung cấp dịch vụ`,

  ourpolicies_serviceprovider_content1: `Chúng tôi có thể thuê các công ty và cá nhân bên thứ ba để tạo điều kiện cho Dịch vụ của chúng tôi ("Nhà cung cấp dịch vụ"), cung cấp Dịch vụ thay mặt chúng tôi, thực hiện các dịch vụ liên quan đến Dịch vụ hoặc hỗ trợ chúng tôi phân tích cách sử dụng Dịch vụ của chúng tôi.`,

  ourpolicies_serviceprovider_content2: `Các bên thứ ba này chỉ có quyền truy cập vào Dữ liệu Cá nhân của bạn để thực hiện các nhiệm vụ này thay mặt chúng tôi và có nghĩa vụ không tiết lộ hoặc sử dụng nó cho bất kỳ mục đích nào khác.`,

  ourpolicies_serviceprovider_analytics: `phân tích`,

  ourpolicies_serviceprovider_analytics_content: `Chúng tôi có thể sử dụng Nhà cung cấp dịch vụ bên thứ ba để theo dõi và phân tích việc sử dụng Dịch vụ của chúng tôi.`,

  ourpolicies_serviceprovider_ganalytics: `Google Analytics`,

  ourpolicies_serviceprovider_ganalytics_content1: `Google Analytics là một dịch vụ phân tích trang web được cung cấp bởi Google để theo dõi và báo cáo lưu lượng truy cập trang web. Google sử dụng dữ liệu được thu thập để theo dõi và giám sát việc sử dụng Dịch vụ của chúng tôi. Dữ liệu này được chia sẻ với các dịch vụ khác của Google. Google có thể sử dụng dữ liệu được thu thập để bối cảnh hóa và cá nhân hóa quảng cáo của mạng quảng cáo của riêng mình.`,

  ourpolicies_serviceprovider_ganalytics_content2: `Để biết thêm thông tin về các quy tắc thực hành quyền riêng tư của Google, vui lòng truy cập trang web Điều khoản về quyền riêng tư của Google: https://polaho.google.com.vn/privacy?hl=vi`,

  ourpolicies_serviceprovider_firebase: `Căn cứ hỏa lực`,

  ourpolicies_serviceprovider_firebase_content1: `Firebase là một dịch vụ phân tích được cung cấp bởi Google Inc.`,

  ourpolicies_serviceprovider_firebase_content4: `Bạn có thể từ chối một số tính năng Firebase nhất định thông qua cài đặt thiết bị di động, chẳng hạn như cài đặt quảng cáo thiết bị của bạn hoặc bằng cách làm theo hướng dẫn do Google cung cấp trong Chính sách bảo mật của họ: https://polaho.google.com.vn/privacy?hl=vi`,

  ourpolicies_serviceprovider_firebase_content2: `Chúng tôi cũng khuyến khích bạn xem lại chính sách của Google để bảo vệ dữ liệu của bạn: https://support.google.com/analyticspcs/6004245.`,

  ourpolicies_serviceprovider_firebase_content3: `Để biết thêm thông tin về loại thông tin mà Firebase thu thập, vui lòng truy cập trang web Điều khoản bảo mật của Google: https://polaho.google.com.vn/privacy?hl=vi`,

  ourpolicies_serviceprovider_payments: `Thanh toán`,

  ourpolicies_serviceprovider_payment_content1: `Chúng tôi có thể cung cấp các sản phẩm và / hoặc dịch vụ phải trả tiền trong Dịch vụ. Trong trường hợp đó, chúng tôi sử dụng dịch vụ của bên thứ ba để xử lý thanh toán (ví dụ: bộ xử lý thanh toán).`,

  ourpolicies_serviceprovider_payment_content2: `Chúng tôi sẽ không lưu trữ hoặc thu thập chi tiết thẻ thanh toán của bạn. Thông tin đó được cung cấp trực tiếp cho bộ xử lý thanh toán bên thứ ba của chúng tôi, việc sử dụng thông tin cá nhân của bạn chịu sự điều chỉnh của Chính sách bảo mật của họ. Các bộ xử lý thanh toán này tuân thủ các tiêu chuẩn do PCI-DSS đặt ra do Hội đồng tiêu chuẩn bảo mật PCI quản lý, đây là nỗ lực chung của các thương hiệu như Visa, MasterCard, American Express và Discover. Yêu cầu PCI-DSS giúp đảm bảo xử lý an toàn thông tin thanh toán.`,

  ourpolicies_serviceprovider_payment_content3: `Các bộ xử lý thanh toán chúng tôi làm việc là:`,

  ourpolicies_serviceprovider_stripe: `Vạch sọc`,

  ourpolicies_serviceprovider_stripe_content: `Chính sách bảo mật của họ có thể được xem tại https://stripe.com/us quyền riêng tư`,

  ourpolicies_linksto_title: `Liên kết đến các trang web khác`,

  ourpolicies_linksto_content1: `Dịch vụ của chúng tôi có thể chứa các liên kết đến các trang web khác không do chúng tôi điều hành. Nếu bạn nhấp vào liên kết của bên thứ ba, bạn sẽ được chuyển đến trang web của bên thứ ba đó. Chúng tôi khuyên bạn nên xem lại Chính sách quyền riêng tư của mỗi trang web bạn truy cập.`,

  ourpolicies_linksto_content2: `Chúng tôi không kiểm soát và không chịu trách nhiệm về nội dung, chính sách bảo mật hoặc thông lệ của bất kỳ trang web hoặc dịch vụ bên thứ ba nào.`,

  ourpolicies_children_title: `Quyền riêng tư của trẻ em`,

  ourpolicies_children_content1: `Dịch vụ của chúng tôi không giải quyết bất cứ ai dưới 18 tuổi ("Trẻ em").`,

  ourpolicies_children_content2: `Chúng tôi không cố ý thu thập thông tin cá nhân từ bất kỳ ai dưới 18 tuổi. Nếu bạn là cha mẹ hoặc người giám hộ và bạn biết rằng Con bạn đã cung cấp cho chúng tôi Dữ liệu Cá nhân, vui lòng liên hệ với chúng tôi. Nếu chúng tôi biết rằng chúng tôi đã thu thập Dữ liệu Cá nhân từ trẻ em mà không cần xác minh về sự đồng ý của cha mẹ, chúng tôi sẽ thực hiện các bước để xóa thông tin đó khỏi máy chủ của chúng tôi.`,

  ourpolicies_changes_title: `Thay đổi chính sách bảo mật này`,

  ourpolicies_changes_content1: `Chúng tôi có thể cập nhật Chính sách bảo mật của chúng tôi theo thời gian. Chúng tôi sẽ thông báo cho bạn về bất kỳ thay đổi nào bằng cách đăng Chính sách bảo mật mới trên trang này.`,

  ourpolicies_changes_content2: `Chúng tôi sẽ cho bạn biết qua email và / hoặc thông báo nổi bật về Dịch vụ của chúng tôi, trước khi thay đổi có hiệu lực và cập nhật "ngày hiệu lực" ở đầu Chính sách bảo mật này.`,

  ourpolicies_changes_content3: `Bạn nên xem lại Chính sách quyền riêng tư này định kỳ để biết mọi thay đổi. Thay đổi đối với Chính sách quyền riêng tư này có hiệu lực khi chúng được đăng trên trang này.`,

  ourpolicies_contactus_title: `Liên hệ chúng tôi`,

  ourpolicies_contactus_content1: `Nếu bạn có bất kỳ câu hỏi nào về Chính sách quyền riêng tư này, vui lòng liên hệ với chúng tôi:`,

  ourpolicies_contactus_content2: `Bằng email:`,

  //Registration page

  dob_validation: "Tuổi tối thiểu là 16",

  register_regi_title: "đăng ký tài khoản",

  register_organization_label: "tên tổ chức{star}",
  profile_name_label: "Tên{star}",

  register_dob_error: "*Ngày sinh là bắt buộc",

  register_age_error: "*Vui lòng chọn độ tuổi của bạn.",

  date_invalid_error: "Vui lòng nhập ngày hợp lệ.",

  register_organization_error: "*Tên tổ chức là bắt buộc",
  profile_name_error: "*Tên là bắt buộc",

  register_organization_type_label: "Loại hình tổ chức{star}",
  profile_type_label: "Loại hình{star}",

  register_organization_title_label: "Tên tổ chức{star}",
  profile_title_label: "Tiêu đề{star}",

  register_organization_title_error: "*Tiêu đề là bắt buộc",

  register_browse_label: "Duyệt...",

  register_upload_label: "Tải lên Logo",

  register_upload_error: "*Vui lòng chọn Logo",

  register_are_you_label: "Bạn là một{star}",

  register_are_you_resident: "Thường trú tại Hoa Kỳ",

  register_are_you_citizen: "Công dân Hoa Kỳ",

  register_are_you_prefer: "không thích trả lời",

  register_are_you_error: "*Vui lòng chọn Loại",

  register_organization_type_error: "*Vui lòng chọn Loại tổ chức",
  profile_type_error: "*Vui lòng chọn loại",

  register_your_title_label: "Tiêu đề{star}",

  register_your_title_list1: "Ông.",

  register_your_title_list2: "Cô.",

  register_your_title_list3: "Bà.",

  register_your_title_list4: "Tiến sĩ",

  register_your_title_list5: "CÔ.",

  register_your_title_error: "*Vui lòng chọn Tiêu đề của bạn",

  register_name_label: "Tên đầu tiên{star}",

  register_name_error1: "*Tên là bắt buộc",

  register_name_error2: "Cần có giới hạn 15-20 văn bản cho phép.",

  register_last_name_label: "Họ{star}",

  register_last_name_error1: "*Họ là bắt buộc",

  register_last_name_error2: "Cần có giới hạn 15-20 văn bản cho phép.",

  register_ethinicicty_label: "Dân tộc{star}",

  register_ethinicicty_error: "*Vui lòng chọn một Dân tộc",

  register_email_label: "E-mail{star}",

  register_email_error1: "*Email thì cần thiết",

  register_email_error2: "Vui lòng nhập địa chỉ email hợp lệ",

  register_mobile_label: "Số điện thoại{star}",

  register_mobile_error1: "*Số điện thoại di động là bắt buộc",

  register_apartment_label: "Căn hộ / Suite{star}",

  register_apartment_error: "*Căn hộ / Suite là bắt buộc",

  register_gender_label: "Giới tính{star}",

  register_gender_type1: "Nam giới",

  register_gender_type2: "Giống cái",

  register_gender_type3: "Khác",

  register_gender_error: "*Vui lòng chọn giới tính",

  register_birth_label: "Ngày sinh{star}",

  register_street_label: "Địa chỉ đường phố{star}",

  register_street_error: "*Addess đường phố là bắt buộc",

  register_country_label: "Quốc gia{star}",

  register_country_error: "*Vui lòng chọn quốc gia",

  register_state_label: "Tiểu bang{star}",

  register_state_error: "*Vui lòng chọn bang",

  register_city_label: "Thành phố{star}",

  register_county_label: "quận{star}",

  register_city_error: "*Vui lòng chọn thành phố",

  register_zip_label: "Mã Bưu Chính{star}",

  register_zip_error: "*Trường Mã Zip là bắt buộc",

  register_zip2_label: "Mã +4",

  register_password_label: "Mật khẩu{star}",

  register_password_error1: "*Trường mật khẩu là bắt buộc",

  register_password_error2:
    "Mật khẩu phải chứa ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường và số.",

  register_terms1_label: "tôi đồng ý với",

  register_terms2_label: "điều khoản sử dụng",

  register_terms3_label: "và",

  register_terms4_label: "Chính sách bảo mật",

  register_terms_error:
    "*Vui lòng cho biết rằng bạn chấp nhận Điều khoản và Chính sách bảo mật",

  register_already_account: "Bạn co săn san để tạo một tai khoản,",

  register_signin_label: "Đăng nhập",

  register_or_signin_label: "hoặc Đăng nhập bằng",

  register_registerbutton_label: "Đăng ký",

  register_organization_type1: "Thành phố",

  register_organization_type2: "Chính quyền",

  register_organization_type3: "Thành phố",

  register_organization_type4: "Khu vực cải thiện kinh doanh",

  register_organization_type5: "Hãy nghĩ rằng xe tăng",

  register_organization_type6: "Ed cao hơn",

  register_ethinicity_type1: "Người Mỹ gốc Phi",

  register_ethinicity_type2: "Người Mỹ da đỏ hoặc thổ dân Alaska",

  register_ethinicity_type3: "Đảo châu Á hoặc Thái Bình Dương",

  register_ethinicity_type4: "Da trắng",

  register_ethinicity_type5: "Tây Ban Nha hoặc La tinh",

  register_ethinicity_type6:
    "Người Hawaii bản địa hoặc người dân đảo Thái Bình Dương khác",

  register_ethinicity_type7: "Đa chủng tộc",

  register_ethinicity_type8: "Khác",

  register_ethinicity_type9: "không thích trả lời",

  register_profile_change_password: "Đổi mật khẩu",

  register_create_password_change: "Thay đổi mật khẩu của bạn",

  register_create_password_create: "tạo mật khẩu",

  register_create_password_old: "Mật khẩu cũ*",

  register_create_password_new: "mật khẩu mới*",

  register_create_password_confirm: "Xác nhận mật khẩu*",

  register_create_password_not_match: "*Mật khẩu không khớp",

  register_create_password_cotinue: "Tiếp tục",

  register_collegues_add_collegues: "Thêm đồng nghiệp",

  register_department_label: "Phòng{star}",

  register_department_error: "*Bộ phận là bắt buộc",

  register_member_no_colleagues: "Không có đồng nghiệp nào được thêm vào",

  register_member_colleagues_list: "Danh sách đồng nghiệp",
  register_member_colleague_list: "Danh sách đồng nghiệp",

  register_member_delete: "Xóa bỏ",

  register_profile_upload_resident: "Tải lên dữ liệu thường trú",

  register_profile_batch_upload: "Tải lên hàng loạt",

  register_profile_resident_details: "Mẫu chi tiết cư trú",

  //Login page

  login_signin_label: "Đăng nhập",

  login_email_label: "E-mail",

  login_email_error1: "*Email thì cần thiết",

  login_email_error2: "*Vui lòng nhập địa chỉ email hợp lệ",

  login_password_label: "Mật khẩu",

  login_password_error1: "*Trường mật khẩu là bắt buộc",

  login_password_error2:
    "Mật khẩu phải chứa ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường và số.",

  login_checkbox_label: "Nhớ tôi",

  login_loginbutton_label: "Đăng nhập",

  login_orsignin_label: "hoặc Đăng nhập bằng",

  login_forgot_password_label: "Quên mật khẩu?",

  login_registration_label1: "Không có tài khoản?",

  login_resident_label1: "Tạo tài khoản thường trú",

  login_resident_label2: "Tạo tài khoản",

  login_organization_label1: "Tạo tài khoản tổ chức",

  login_forgot_password_submit: "Gửi đi",

  //404 Not found Page and thank you page

  notfound_thankyou_content1: "Để chọn thường dân,",

  notfound_thankyou_content2:
    "Xin vui lòng kiểm tra email của bạn. Chúng tôi đã gửi đến",

  notfound_thankyou_content3: "cho bạn một email để xác nhận.",

  notfound_thankyou_content4: "Trở về nhà",

  //header banner

  header_banner_about: "trong khoảng",

  header_banner_contactus: "liên hệ chúng tôi",

  header_banner_service: "Dịch vụ",

  header_banner_outpolicies: "Chính sách của chúng tôi",

  header_banner_project: "Dự án",

  header_banner_thought_leadership: "Tư duy lãnh đạo",

  header_banner_how_to: "Cách làm",

  header_home_banner: "KẾT NỐI CHÍNH PHỦ VÀ CỘNG ĐỒNG QUA CÁC NỀN TẢNG ĐỔI MỚI",

  header_banner_colleagues: "Đồng nghiệp",

  header_banner_change_password: "Đổi mật khẩu",

  header_banner_create_password: "Tạo mật khẩu",

  header_banner_survey: "Khảo sát",

  header_banner_what_is_civy: "CIVY LÀ GÌ",

  header_banner_qr_code_management: "Quản lý mã QR",

  //header.........................

  header_home: "TRANG CHỦ",

  header_core: "Lõi 19",

  header_KIOSK: "Ki-ốt",

  header_service: "DỊCH VỤ",

  header_project: "DỰ ÁN",

  header_project_engage: "THUÊ",

  header_thought_leadership: "NGHE LÃNH ĐẠO",

  header_about: "TRONG KHOẢNG",

  header_login: "ĐĂNG NHẬP",

  // header_language1: "Tiếng Anh",

  // header_language2: "người Pháp",

  // header_language3: "người Tây Ban Nha",

  // header_language4: "Tiếng Hindi",

  // header_language5: "Quan thoại",

  // header_language6: "người Ý",

  // header_language7: "Tiếng Việt",

  header_language1: "EN",

  header_language2: "FR",

  header_language3: "ES",

  header_language4: "HI",

  header_language5: "ZH",

  header_language6: "IT",

  header_language7: "VI",

  header_header_drawer_welcome: "Chào mừng bạn,",

  header_header_drawer_admin_profile: "Hồ sơ quản trị",

  header_header_drawer_profile: "Hồ sơ",

  header_header_drawer_colleagues: "Đồng nghiệp",

  header_header_drawer_op_ed: "OP-ED",

  header_header_drawer_how_to: "Cách làm",

  header_header_drawer_logout: "Đăng xuất",

  header_what_is_civy: "CIVY LÀ GÌ",

  header_qr_code_management: "Quản lý mã QR",

  //footer........................

  footer_contactus_title: "Liên hệ chúng tôi",

  footer_contactus_content1:
    "Liên hệ với Civily để tìm hiểu cách bạn có thể kích hoạt sự tham gia của công dân tốt hơn cho các dự án phát triển cộng đồng của bạn cho cư dân trên tất cả các nhân khẩu học.",

  footer_media: "Phương tiện truyền thông",

  footer_terms_title: "Điều khoản sử dụng",

  footer_privacy_title: "Chính sách bảo mật",

  footer_google_translate_message: `Civily sử dụng Google Dịch để chuyển đổi từ tiếng Anh sang các ngôn ngữ khác có sẵn trong nền tảng. Đôi khi, Google Dịch có thể không cung cấp bản dịch chính xác. Đối với bất kỳ câu hỏi, xin vui lòng liên hệ với chúng tôi tại`,

  // blog page..............

  blog_details_author_title: "Tác giả",

  blog_details_author_title_star: "Tác giả{star}",

  blog_details_posted_title: "Đăng trong:",

  blog_details_latest_article_title: "bài báo mới nhất",

  blog_details_op_ed_title: "Công dân OP-ED",

  blog_addblog_new_blog: "Thêm OP-ED mới",

  blog_addblog_title: "Tiêu đề",

  blog_addblog_description_title: "Sự miêu tả",

  blog_addblog_browse_title: "Duyệt...",

  blog_addblog_addimage_title: "Thêm hình ảnh..",

  blog_addblog_submit_title: "Thêm OP-ED",

  blog_editblog_op_ed_title: "Chỉnh sửa OP-ED",

  blog_common_error: "*Lĩnh vực này là bắt buộc",

  commonn_update_button: "Cập nhật",

  blog_title_error: "Tiêu đề là bắt buộc",

  blog_desc_error: "*Mô tả là bắt buộc",

  blog_author_error: "*Tác giả là bắt buộc",

  blog_image_error: "Hình ảnh là bắt buộc",

  valid_image_error: "*Hình ảnh phải ở dạng .png, .jpeg, .jpg",

  //project page.........................

  project_filter_project_type1: "Dự án mới",

  project_filter_project_type2: "Dự án hiện có",

  project_filter_project_type3: "Sửa chữa / Bảo trì",

  project_filter_project_type4: "Biến cố",

  project_filter_project_type5: "Thảm họa",

  project_filter_project_category1: "Cây xanh",

  project_filter_project_category2: "Phí cầu đường",

  project_filter_project_category3: "Tiện ích",

  project_filter_project_category4: "Đường",

  project_filter_project_category5: "Đường xa lộ",

  project_filter_project_category6: "Tòa nhà thương mại",

  project_filter_project_category7: "Xây dựng khu dân cư",

  project_filter_project_category8: "Quê hương",

  project_filter_project_category9: "Tòa nhà câu lạc bộ cộng đồng",

  project_filter_project_category10: "Công trình nhỏ",

  project_filter_city_zipcode: "QUẬN, THÀNH PHỐ & ZIP",

  project_filter_range: "Phạm vi",

  project_filter_range1: "dặm",

  project_filter_project_type: "Loại dự án",

  project_filter_project_type_star: "Loại dự án{star}",

  project_filter_project_category: "Hạng mục dự án",

  project_filter_project_category_star: "Hạng mục dự án{star}",

  project_location: "Vị trí{star}",

  project_street_address: "Địa chỉ đường phố",

  project_filter_add_new_project: "Thêm dự án mới",

  project_filter_reset: "Cài lại",

  project_newproject_organization_label: "Phòng tổ chức",

  project_newproject_organization_label_star: "Phòng tổ chức{star}",

  project_newproject_common_error: "*Lĩnh vực này là bắt buộc",

  project_newproject_street_label: "đường phố",

  project_newproject_country_label: "Quốc gia",

  project_newproject_state_label: "Tiểu bang",

  project_newproject_city_label: "Thành phố",

  project_newproject_zipcode_label: "Mã Bưu Chính",

  project_newproject_areacode_label: "Mã +4",

  project_newproject_start_date: "Ngày bắt đầu dự án",

  project_newproject_end_date: "Ngày kết thúc dự án",

  project_newproject_cancel: "Hủy bỏ",

  project_projectfom_add_project: "Thêm dự án",

  publish: "Công bố",

  project_newproject_continue: "Tiếp tục",

  project_projectlist_category_label: "thể loại:",

  project_projectlist_type_label: "Kiểu:",

  project_projectlist_view_label: "Xem Kế hoạch",

  project_projectlist_no_project: "Không có dự án nào được thêm vào",

  project_editproject_edit: "Chỉnh sửa dự án",

  project_newproject_title_label: "Tên dự án",

  project_newproject_description: "mô tả dự án",

  project_newproject_description_star: "mô tả dự án{star}",

  project_newproject_add_images: "Thêm hình ảnh..",

  project_newproject_add_File: "Tải lên tệp PDF...",

  project_commentbox_join_label: "Tham gia cuộc trò chuyện",

  project_commentbox_nocomment_label: "Không có bình luận nào được thêm vào",

  project_commentbox_comment_label: "{commentlength} bình luận",

  project_details_location: "Vị trí",

  project_details_posted: "Đăng qua",

  project_details_share: "Chia sẻ trên:",

  project_details_last_update: "Cập nhật lần cuối vào",

  project_details_likes: "Tổng số lượt thích:",

  project_details_question: "Thêm câu hỏi",

  project_details_question_list: "Danh sách câu hỏi",

  project_details_no_question: "Không có câu hỏi nào",

  project_details_add_answer: "Thêm câu trả lời",

  project_details_add_answer_here: "Thêm câu trả lời của bạn ở đây...",

  project_facts_and_figures_label:
    "Cập nhật từ thành phố và nhân viên chính phủ",

  project_gallery_label: "Thư viện dự án",

  qr_code_label: "Tạo mã QR",

  //Survey page............

  survey_select_questionType: "Chọn loại câu hỏi",

  survey_questionType_content:
    "Thông tin chi tiết cho dự án của bạn đã được tải lên thành công trên Civily. Vui lòng chọn loại câu hỏi bạn muốn hỏi cư dân.",

  survey_questionType_type_a: "Loại A",

  survey_questionType_type_que1:
    "1. Vui lòng thêm đánh giá của bạn cho khu vực chơi trẻ em.",

  survey_write_here_label: "viết một cái gì đó ở đây...",

  survey_questionType_create_survey: "Tạo khảo sát",

  survey_questionType_type_b: "Câu hỏi trắc nghiệm",

  survey_questionType_type_que2:
    "1. Bạn có muốn thêm một khu vui chơi trẻ em trong khu vườn công cộng không?",

  survey_questionType_type_b_yes: "Đúng",

  survey_questionType_type_b_no: "Không",

  survey_comment_box: "Câu hỏi kết thúc mở (Hộp bình luận)",

  survey_radio_question_label: "Câu hỏi",

  survey_discard_label: "Vứt bỏ",

  survey_submit_label: "Thêm vào",

  survey_add_question_label: "Thêm câu hỏi",

  survey_back_label: "Trở lại",

  survey_question_list_label: "Danh sách câu hỏi",

  dialog_delete_message: "Bạn có chắc chắn muốn xóa",

  delete_lable: "Xóa bỏ",

  cancel_label: "Hủy bỏ",

  comment: "bình luận",

  /* Validation message */

  departmaent_required: "*Tên bộ phận là bắt buộc",

  project_type_required: "Vui lòng chọn loại dự án",

  project_category_required: "*Vui lòng chọn Danh mục dự án",

  location_required: "*Địa điểm là cần thiết",

  full_address_required: "*Vui lòng nhập địa chỉ đầy đủ",

  only_usa_required: "*Thêm địa chỉ chỉ cho Hoa Kỳ",

  project_title_requied: "*Yêu cầu tên dự án",

  project_desc_required: "*Yêu cầu tên dự án",

  project_image_required: "Hình ảnh là bắt buộc",

  export_button: "Xuất sang Excel",

  export_project: "Dự án xuất khẩu",

  export_project_data: "Xuất dữ liệu dự án",

  from: "Từ",

  to: "Đến",

  project_required: "*Vui lòng chọn dự án",

  date_range: "Phạm vi ngày",

  edit_question: "Chỉnh sửa câu hỏi",

  quetion_labele: "Câu hỏi",

  qustion_required: "*Trường câu hỏi là bắt buộc",

  click_edit: "Nhấn vào đây để chỉnh sửa hồ sơ",

  back_label: "Trở lại",

  save_label: "Tiết kiệm",

  draft_label: "Bản thảo",

  upload_label: "TẢI LÊN",

  attachment_label: "Tài liệu đính kèm",

  rating_type_question: "Câu hỏi loại đánh giá",

  survey_questionType_type_que3: "Hãy đánh giá dự án này",

  allow_cookie_text:
    "Chúng tôi sử dụng cookie để tối ưu hóa trải nghiệm của bạn, phân tích lưu lượng truy cập và cá nhân hóa nội dung. Để tìm hiểu thêm, vui lòng truy cập Chính sách cookie của chúng tôi. Bằng cách sử dụng trang web của chúng tôi mà không vô hiệu hóa cookie, bạn đồng ý với việc chúng tôi sử dụng chúng.",

  allow_btn: "Cho phép",

  decline_btn: "Từ chối",

  cookie_policy: "Chính sách cookie",

  /* What is civy page */

  civy_intro_title1: "Các tính năng của nền tảng",

  civy_intro1_point1:
    "Các thành phố và chính phủ truy cập nền tảng thông qua một URL chuyên dụng, ví dụ: {link}",

  civy_intro1_point2:
    "Khách hàng có tùy chọn liên kết nền tảng của chúng tôi với các trang truyền thông xã hội của nó (LinkedIn, Facebook & Twitter) để tạo ra chiều sâu và phạm vi tiếp cận rộng hơn nhằm tăng cường sự tham gia của cộng đồng.",

  civy_intro1_point3: "Nền tảng bao gồm nhiều ngôn ngữ.",

  civy_intro1_point4:
    "Dữ liệu được lưu trữ trên dịch vụ đám mây của Civy và bảo mật và quyền riêng tư dữ liệu được duy trì bằng các cuộc kiểm tra mạng nửa năm một lần theo các quy định về quyền riêng tư và dữ liệu của Hoa Kỳ.",

  civy_intro1_point5:
    "Civy không tham gia với các nhà quảng cáo bên thứ ba, do đó cả khách hàng và thành phố có thể duy trì sự tin tưởng rằng họ sẽ không nhận được bất kỳ email hoặc khuyến mãi không mong muốn nào.",

  civy_intro1_point6:
    "Nền tảng có thể truy cập trên thiết bị di động, ở mọi nơi trên thế giới; và Email và thông báo SMS cho tất cả người dùng đã đăng ký.",

  civy_intro1_point7:
    "Mã QR quét cho các dự án (được quét bởi cư dân để cung cấp phản hồi nhanh chóng).",

  civy_intro1_point8:
    "Truy cập vào các báo cáo có thể tải xuống liên quan đến thành phố của họ, cho tất cả các dự án hoặc các dự án được chọn.",

  civy_intro2_title: "Lý do tổ chức đăng ký",

  civy_intro2_point1:
    "Tạo tài khoản quản trị viên có biểu tượng riêng của thành phố và thêm tối đa 20 đồng nghiệp.",

  civy_intro2_point2:
    "Dữ liệu được tạo bởi khách hàng và phản hồi của cư dân thuộc sở hữu của khách hàng.",

  civy_intro2_point3:
    "Thông tin phản hồi từ cư dân được gửi lại trực tiếp cho người tạo lập dự án; ví dụ, phản hồi của cư dân đối với dự án do Công trình công cộng tạo ra được chuyển hướng trở lại các công trình công cộng.",

  civy_intro2_point4:
    "Các dự án có thể được tạo bằng cách sử dụng một hoặc nhiều mã zip; cấp quận đến cấp thị xã địa phương; và được chia sẻ qua Civy và các kênh truyền thông xã hội được liên kết của bạn. Điều này đảm bảo rằng phản hồi của cư dân được liên kết trực tiếp với mã zip của cư dân (các dự án được đăng bởi các dự án thành phố New York chỉ có thể được phản hồi bởi cư dân New York).",

  civy_intro2_point5: "Có thể tải xuống báo cáo.",

  civy_intro3_title: "Lý do cư dân đăng ký",

  civy_intro3_point1:
    "Để đảm bảo hồ sơ của bạn được liên kết với mã vùng bạn đang sống và bạn sẽ có thể trả lời các câu hỏi do thành phố của bạn đăng.",

  civy_intro3_point2:
    "Để đảm bảo rằng các câu trả lời của bạn là do bạn trực tiếp cung cấp. Civy không liên kết, thu thập hoặc tổng hợp dữ liệu từ bất kỳ tài khoản mạng xã hội nào khác của bạn. Civy chỉ ghi lại câu trả lời của bạn mà bạn đã cung cấp trong nền tảng của Civy và những câu trả lời này chỉ hiển thị để các quan chức thành phố sử dụng nền tảng này xem xét.",

  civy_intro3_point3:
    "Các câu trả lời hoặc nhận xét của bạn sẽ không hiển thị với những người bạn cùng cư trú.",

  civy_intro3_point4:
    'Bạn không cần phải đăng ký trên Civy với tư cách là "ẩn danh" vì các câu trả lời của bạn là bí mật và chỉ có quan chức thành phố chịu trách nhiệm về dự án mới hiển thị.',

  what_is_civy_policies_title: "CHÍNH SÁCH",

  what_is_civy_policies_content: "CHÍNH SÁCH BẢO MẬT VÀ DỮ LIỆU",

  //QR code Management page

  qr_code_svg: "Tạo dưới dạng SVG",

  qr_code_png: "Tạo dưới dạng PNG",

  qr_code_jpeg: "Tạo dưới dạng JPEG",

  qr_code: "Mã QR",
};
