import { GET_CITIZEN } from "./citizenAction";
const initialState = {
  items: [],
  editItem: []
};

export default function citizenReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CITIZEN:
      return {
        ...state,
        items: action.payload
      };
    // case EDIT_CITIZEN:
    //     return {
    //         ...state,
    //         editItem: action.payload
    //     }
    default:
      return state;
  }
}
