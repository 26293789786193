import axios from "axios";
import { displayNotification } from "../../../store/action";
export const CREATE_QUESTION = "CREATE_QUESTION";
export const CREATE_QUESTION_REQUEST = "CREATE_QUESTION_REQUEST";
export const CREATE_QUESTION_ERROR = "CREATE_QUESTION_ERROR";
export const GET_QUESTION = "GET_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS";
export const UPDATE_QUESTION_REQUEST = "UPDATE_QUESTION_REQUEST";
export const UPDATE_QUESTION_FAILURE = "UPDATE_QUESTION_FAILURE";

export const getQuestionData = payload => ({
  type: GET_QUESTION,
  payload
});
export const getDeleteQuestion = payload => ({
  type: DELETE_QUESTION,
  payload
});
export const updateQuestionSuccess = payload => ({
  type: UPDATE_QUESTION_SUCCESS,
  payload
});
export const updateQuestionRequest = payload => ({
  type: UPDATE_QUESTION_REQUEST
});
export const updateQuestionFailure = payload => ({
  type: UPDATE_QUESTION_FAILURE,
  payload
});

export const getQuestion = body => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/questions/${body}/questionsList`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    return axios
      .get(apiUrl, config)
      .then(response => {
        dispatch(getQuestionData(response.data.questions));
      })
      .catch(error => {
        throw error;
      });
  };
};
export const deleteQuestion = body => {
  const apiUrl = process.env.REACT_APP_API_HOST + `/api/v1/questions/${body}`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return (dispatch, getState) => {
    const { items } = getState().questions;
    const deleteIndex = items.findIndex(v => v._id === body);
    items.splice(deleteIndex, 1);
    return axios
      .delete(apiUrl, config)
      .then(response => {
        if (response.status === 200) {
          dispatch(getQuestionData([...items]));
          dispatch(
            displayNotification({
              open: true,
              message:
                response && response.data
                  ? "Your Question is deleted successfully"
                  : "Something went wrong",
              duration: 3000,
              severity: "success"
            })
          );
          // dispatch(getDeleteQuestion(response.data));
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const createQuestionData = payload => ({
  type: CREATE_QUESTION,
  payload
});
export const createQuestionDataRequest = () => ({
  type: CREATE_QUESTION_REQUEST
});
export const createQuestionDataError = payload => ({
  type: CREATE_QUESTION_ERROR,
  payload
});
export const createQuestion = (body, callback) => {
  const apiUrl = process.env.REACT_APP_API_HOST + `/api/v1/questions/create`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    dispatch(createQuestionDataRequest())
    return axios
      .post(apiUrl, body, config)
      .then(response => {
        if (response.status === 200) {
          dispatch(createQuestionData(response.data));
          callback(response.data);
          dispatch(
            displayNotification({
              open: true,
              message:
                response && response.data
                  ? "Survey question has been created successfully."
                  : "Something went wrong",
              duration: 3000,
              severity: "success"
            })
          );
        }
      })
      .catch(error => {
        dispatch(createQuestionDataError())
        dispatch(
          displayNotification({
            open: true,
            message:
              error.response && error.response.data
                ? error.response.data.message
                : "Something went wrong",
            duration: 3000,
            severity: "error"
          })
        );
      });
  };
};

export const editQuestion = (body, id, callback) => {
  const apiUrl =
    process.env.REACT_APP_API_HOST + `/api/v1/questions/${id}/update`;
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    dispatch(updateQuestionRequest());
    return axios
      .put(apiUrl, body, config)
      .then(response => {
        dispatch(updateQuestionSuccess(response.data));
        callback(response.data.question);
        dispatch(
          displayNotification({
            open: true,
            message:
              response && response.data
                ? "Your question has been succesfully updated."
                : "Something went wrong",
            duration: 3000,
            severity: "success"
          })
        );
      })
      .catch(error => {
        dispatch(updateQuestionFailure(error));
        throw error;
      });
  };
};
