import React, { Suspense, lazy } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserHistory } from "history";
import { isArray } from "lodash-es";
import ReactGA from "react-ga";
import { IntlProvider } from "react-intl";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "@config/theme";
import "./App.css";
import Token from "@util/token";
import {
  define_en,
  define_es,
  define_zhHans,
  define_fr,
  define_hi,
  define_it,
  define_vi,
} from "@util/locale";
// import TagManager from "react-gtm-module";
import IdealTimer from "@util/idealTime";
import ScrollToTop from "./ScrollToTop";
import Notify from "./components/Notification";
import { makeStyles } from "@material-ui/core/styles";
import whiteLogo from "./assests/whiteLogo/whiteLogo.png";
import { getProjectCategories } from "store/action";
// const Landing = lazy(() => import("./pages/Landing"));
const Registration = lazy(() => import("./pages/Registration"));
const Login = lazy(() => import("./pages/Login"));
// const About = lazy(() => import("./pages/About"));
const NotFound = lazy(() => import("./pages/404NotFound"));
const ThankYou = lazy(() => import("./pages/ThankYou"));
const RegisterThank = lazy(() => import("./pages/ThankYou/RegisterThankyou"));
// const Services = lazy(() => import("./pages/Services"));
const LegalPolicy = lazy(() => import("./pages/LegalPolicy"));
const Project = lazy(() => import("./pages/Project"));
const ProjectListing = lazy(() => import("./pages/Project/projectListing"));
const ContactUs = lazy(() => import("./pages/Contact-us"));
const BlogDetails = lazy(() => import("./pages/Blogs/BlogDetails"));
const BlogList = lazy(() => import("./pages/Blogs/BlogList"));
const Survey = lazy(() => import("./pages/Survey"));
const PrivateRouting = lazy(() => import("./pages/Login/privateRoute"));
const Profile = lazy(() => import("./pages/User"));
const Member = lazy(() => import("./pages/Member"));
const CreatePassword = lazy(() => import("./pages/Member/CreatePassword"));
const ForgotPassword = lazy(() => import("./pages/Login/forgot"));
const ResetPassword = lazy(() => import("./pages/Login/reset"));
const ExportProject = lazy(() => import("./pages/Project/export"));
// const projectForm = lazy(() => import("./pages/Project/projectForm"));
// const EditOpEdModal = lazy(() => import("./pages/Blogs/EditOpEdModal"));
// const EditProjectForm = lazy(() =>
//   import("./pages/Project/EditProject/editForm")
// );
const HowTo = lazy(() => import("./pages/HowTo"));
const WhatIsCivy = lazy(() => import("./pages/WhatIsCivy"));
const QRCodeManagement = lazy(() => import("./pages/QRCodeManagement"));
const SelfCheckKiosk = lazy(() => import("./pages/SelfCheckKiosk"));
// const Core19 = lazy(() => import("./pages/Core19"));
const AddProject = lazy(() => import("./pages/Project/AddProject"));
const EditProject = lazy(() => import("./pages/Project/EditProject"));
// const Faq = lazy(() => import("./pages/FAQ"));
const history = createBrowserHistory();

// const tagManagerArgs = {
//   gtmId: `${process.env.REACT_APP_GA}`,
//   dataLayerName: "PageDataLayer"
// };

// TagManager.initialize(tagManagerArgs);
ReactGA.initialize(`${process.env.REACT_APP_GA}`);

const messages = {
  en: define_en,
  es: define_es,
  fr: define_fr,
  hi: define_hi,
  zhs: define_zhHans,
  // zht: define_zhHant
  it: define_it,
  vi: define_vi,
};
const useStyles = makeStyles(() => ({
  fullPageLoader: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    background: "#252f6a",
    "& >img": {
      animation: " 1.8s infinite heartbeat",
    },
  },
}));

export default function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  React.useEffect(() => {
    // Pageview: Google analytics
    if (Token.getCookie("closeCookiePopup") === "allow") {
      ReactGA.pageview(
        `${history.location.pathname}${history.location.search}${history.location.hash}`
      );
      history.listen((location, action) => {
        const { historyWatch } = window;
        if (historyWatch && isArray(historyWatch)) {
          historyWatch.push({
            action,
            URL: `${location.pathname}${location.search}${location.hash}`,
          });
        } else {
          window.historyWatch = [];
        }
        ReactGA.pageview(
          `${location.pathname}${location.search}${location.hash}`
        );
      });
    }
  }, []);

  React.useEffect(() => {
    dispatch(getProjectCategories());
  }, [dispatch]);

  const lang = useSelector(({ common }) => common.locale);

  return (
    <MuiThemeProvider theme={theme}>
      <Notify />
      <IntlProvider locale={lang} messages={messages[lang]}>
        <Router history={history}>
          <IdealTimer />
          <ScrollToTop />
          <Suspense
            fallback={
              <div className={classes.fullPageLoader}>
                <img width="200" src={whiteLogo} alt="Civy logo" />
              </div>
            }
          >
            <Switch>
              {/* <Route exact path="/" component={Landing} login /> */}
              <Route exact path="/">
                <Redirect to="/projects" />
              </Route>
              <Route
                exact
                path="/register/organization"
                component={() => <Registration loginType="Organization" />}
              />
              <Route
                exact
                path="/register/citizen"
                component={() => <Registration loginType="Resident" />}
              />
              {/* <Route exact path="/about" component={About} /> */}
              {/* <Route exact path="/services" component={Services} /> */}
              <Route exact path="/login" component={Login} />
              <Route exact path="/thanks" component={ThankYou} />
              <Route exact path="/thankyou" component={RegisterThank} />
              <Route exact path="/reset/:id" component={ResetPassword} />
              <Route
                exact
                path="/thought-leadership/:id"
                component={BlogDetails}
              />
              <Route exact path="/thought-leadership" component={BlogList} />
              {/* <PrivateRouting
                exact
                path="/thought-leadership/:id/edit"
                component={EditOpEdModal}
              /> */}
              <Route exact path="/privacy" component={LegalPolicy} />
              <PrivateRouting exact path="/project/:id" component={Project} />
              <Route exact path="/projects" component={ProjectListing} />
              <Route exact path="/contact-us" component={ContactUs} />
              <PrivateRouting
                exact
                path="/project/:id/survey"
                component={Survey}
              />
              {/* <PrivateRouting
                exact
                path={"/project/new/data/:id"}
                component={projectForm}
              />
              <PrivateRouting
                exact
                path={"/project/:id/edit"}
                component={EditProjectForm}
              /> */}
              <PrivateRouting exact path="/profile" component={Profile} />
              <PrivateRouting
                exact
                path="/changepassword/user/:id"
                component={CreatePassword}
              />
              <PrivateRouting exact path="/member" component={Member} />
              <PrivateRouting exact path="/how-to" component={HowTo} />
              <PrivateRouting
                exact
                path="/export/project"
                component={ExportProject}
              />
              <Route
                exact
                path="/self-check-kiosk"
                component={SelfCheckKiosk}
              />
              {/* <Route exact path="/core19" component={Core19} /> */}
              <Route exact path="/forgot" component={ForgotPassword} />
              <Route
                exact
                path="/verifiedEmail/:id"
                component={CreatePassword}
              />
              <Route exact path="/what-is-civy" component={WhatIsCivy} />
              <PrivateRouting
                exact
                path="/add-project"
                component={AddProject}
              />
              <PrivateRouting
                exact
                path="/edit-project/:id"
                component={EditProject}
              />
              <PrivateRouting
                exact
                path="/qr-code-management"
                component={QRCodeManagement}
              />
              {/* <Route exact path="/faq" component={Faq} /> */}

              <Route exact path="*" component={NotFound} />
            </Switch>
          </Suspense>
        </Router>
      </IntlProvider>
    </MuiThemeProvider>
  );
}
