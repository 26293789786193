import {
  BLOGS_SUCCESS,
  BLOGS_ERROR,
  BLOGS_REQUEST,
  BLOG_DETAILS_SUCCESS,
  BLOG_DETAILS_ERROR,
  BLOG_DETAILS_REQUEST,
  ADD_BLOG_REQUEST,
  ADD_BLOG_DETAILS,
  ADD_BLOG_ERROR,
  ADD_IMAGE_REQUEST,
  ADD_IMAGE,
  ADD_IMAGE_ERROR,
  BLOGS_EDIT_SUCCESS,
  BLOGS_EDIT_ERROR,
  BLOGS_DELETE_REQUEST,
  BLOGS_DELETE_SUCCESS,
  BLOGS_DELETE_ERROR,
  REMOVE_LAST_DATA
} from "./blogAction";
const initialState = {
  items: [],
  loading: false,
  details: [],
  addBlog: null,
  editBlog: null,
  delete: null
};
export default function blogReducer(state = initialState, action) {
  switch (action.type) {
    case REMOVE_LAST_DATA:
      return {
        ...state
      };
    case BLOGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload
      };
    case BLOGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case BLOG_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case BLOG_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: action.payload
      };
    case BLOG_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case ADD_BLOG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_BLOG_DETAILS:
      return {
        ...state,
        loading: false,
        addBlog: action.payload
      };
    case ADD_BLOG_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_IMAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_IMAGE:
      return {
        ...state,
        loading: false,
        items: [...state.items, action.payload]
      };
    case ADD_IMAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case BLOGS_EDIT_SUCCESS:
      return {
        ...state,
        editBlog: action.payload
      };
    case BLOGS_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case BLOGS_DELETE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case BLOGS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        delete: action.payload
      };
    case BLOGS_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
