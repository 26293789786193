import axios from "axios";
export const GET_CITIZEN = "GET_CITIZEN";
export const EDIT_CITIZEN = "EDIT_CITIZEN";

export const getCitizenData = payload => ({
  type: GET_CITIZEN,
  payload
});

export const getCitizen = () => {
  const apiUrl = process.env.REACT_APP_API_HOST + "/api/v1/users/me";
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.userToken}`
    }
  };
  return dispatch => {
    return axios
      .get(apiUrl, config)
      .then(response => {
        dispatch(getCitizenData(response?.data?.user));
      })
      .catch(error => {
        throw error;
      });
  };
};
