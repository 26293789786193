import { 
  GET_ORGANIZATION,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_ERROR,
  EDIT_ORGANIZATION_SUCCESS,
  EDIT_ORGANIZATION_ERROR,
  EDIT_ORGANIZATION_REQUEST,
  EDIT_ORGANIZATION_LOGO_SUCCESS,
  EDIT_ORGANIZATION_LOGO_REQUEST,
  EDIT_ORGANIZATION_LOGO_ERROR
 } from "./organizationAction";
const initialState = {
  items: [],
  edit:null,
  loading:false,
  image:null
};

export default function citizenReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORGANIZATION:
      return {
        ...state,
        items: action.payload
      };
    case EDIT_PROFILE_REQUEST:
      return {
        ...state,
        loading:true
      };
    case EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        loading:false,
        edit: action.payload
      };
    case EDIT_PROFILE_ERROR:
      return {
        ...state,
        loading:false,
        error: action.payload
      };
    case EDIT_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading:true
      };
    case EDIT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading:false,
        edit: action.payload
      };
    case EDIT_ORGANIZATION_ERROR:
      return {
        ...state,
        loading:false,
        error: action.payload
      };
    case EDIT_ORGANIZATION_LOGO_REQUEST:
      return {
        ...state,
        loading:true
      };
    case EDIT_ORGANIZATION_LOGO_SUCCESS:
      return {
        ...state,
        loading:false,
        image: action.payload
      };
    case EDIT_ORGANIZATION_LOGO_ERROR:
      return {
        ...state,
        loading:false,
        error: action.payload
      };
    default:
      return state;
  }
}
