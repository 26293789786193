import {
  CREATE_QUESTION,
  GET_QUESTION,
  DELETE_QUESTION,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_REQUEST,
  UPDATE_QUESTION_FAILURE,
  CREATE_QUESTION_REQUEST,
  CREATE_QUESTION_ERROR
} from "./questionAction";
const initialState = {
  items: [],
  create: null,
  delete: null,
  question: null,
  loading: false
};

export default function citizenReducer(state = initialState, action) {
  switch (action.type) {
    case GET_QUESTION:
      return {
        ...state,
        items: action.payload
      };
    case CREATE_QUESTION:
      return {
        ...state,
        create: action.payload,
        loading:false
      };
    case CREATE_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CREATE_QUESTION_ERROR:
      return {
        ...state,
        loading:false,
        error: action.payload
      };
    case DELETE_QUESTION:
      return {
        ...state,
        delete: action.payload
      };
    case UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        question: action.payload,
        loading: false
      };
    case UPDATE_QUESTION_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case UPDATE_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
