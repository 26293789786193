export const define_zhHans = {
  //landing page casestudies component

  landing_caseStudies1:
    "Civy Beta在洛杉矶格伦代尔（Glendale）测试了街道美化计划。根据该市收到的评论，可以确认的是，直到该项目开始并可以看到之前，居民们才对该项目进行了充分的咨询。 Civy旨在从所有当前和即将进行的项目中消除这种情况。",

  landing_caseStudies2:
    "一家大型政府基础设施机构计划使用Civy进行下一个外部推广计划。该机构将询问他们所服务的居民，有关他们正在提议的新项目的问题。同时，他们还将使用Civy与正在报告所有当前正在进行的基础设施项目问题的人员保持联系。他们认为，一个独立的，具有移动功能的平台将改善参与度，Civy可以简单地工作，具有多种语言选项，这一事实与大多数人有关。",

  landing_caseStudies3:
    "最近的一种情况可以为您提供更好的视角，假设您是市政官员或政府机构或感兴趣的居民。您位于洛杉矶。您可以在“ Civy”信息中心或全球其他任何城市（例如，澳大利亚悉尼）中输入城市名称。您将能够看到悉尼在做什么，可以按项目类别应用过滤器。它将促进全球教育和学习",

  landing_caseStudies4:
    "作为加利福尼亚州纽波特海滩的居民，我注意到缺少户外健身站。这些在阳光，海洋和绿色植物丰富的其他许多国家非常普遍。使用智能手机，我在Civy（题为“户外健身站”）中创建了一个新项目，标记了位置，添加了一些示例图片，添加了对该项目案例的简短描述，并与我的当地市政部门和Civy网络共享了该项目。目的是让居民认可该想法或提供评论或其他建议；同时，市政部门正在接收该居民的反馈。这样一来，他们就有动力采取行动，并在下一次社区扩展中与人民一起发展，得到人们的认可并建立起这种氛围。",

  landing_caseStudies_content:
    "一些实用的示例说明Civy如何在您的平均每天中受益。",

  landing_caseStudies_title: "实例探究",

  //landing page statistics component

  landing_statistics_title: "統計",

  landing_statistics_content:
    "为了完全实现可持续发展，我们必须使进步公平和基础广泛，并使人们成为这一进程的积极参与者。",

  landing_statistics48: "的成年人直接参加公民团体或活动",

  landing_statistics68: "18至34岁的居民报告说，他们感到与政府脱节。",

  //landing page testimonial component

  landing_testimonial_title: "感言",

  landing_testimonial_content: "阅读我们最新客户的推荐信。",

  landing_testimonial1:
    "我希望我在需要全州和地方利益相关者参与的项目中遇到这个问题。这本来可以减轻压力，并帮助我们有效地接触社区。",

  landing_testimonial1_author: "阿拉娜",

  landing_testimonial1_postname: "-前公用事业局外展和通讯总监",

  landing_testimonial2: "Civy可以一次解决参与过程中的三个角色-简化工作流程。",

  landing_testimonial2_author: "埃里克",

  landing_testimonial2_postname: "-新泽西州Freeholder Township的立法助手",

  landing_testimonial3:
    "对于政府软件以及市政当局与公众的互动，Civy是很长一段时间以来最令人振奋的发展之一。自由持有人是主要城市",

  landing_testimonial3_author: "史考特",

  landing_testimonial3_postname: "-国会参谋长",

  landing_testimonial4:
    "Civy的在线互动平台易于使用，并在设计时充分考虑了城市，政府和居民的需求。它正在解决我们在城市和民选官员中遇到的主要痛点，并使居民更容易在安全可靠的环境中与我们互动。该平台补充并增强了我们现有的推广策略，旨在提高参与率和公平性。",

  landing_testimonial4_author: "特蕾莎·布拉内拉",

  landing_testimonial4_postname: "-新泽西州布鲁克劳恩市长",

  //landing page services component

  landing_servicesA_header: "扩大对项目的公众宣传。",

  landing_servicesA_content:
    "一个单一的平台，可为民间项目提供最广泛的覆盖范围，多种语言和人口统计信息。",

  landing_servicesB_header: "隐私和数据安全。",

  landing_servicesB_content:
    "我们的平台包括其他隐私保护措施，以保护居民的反应，只有城市和政府官员才能看到。",

  landing_servicesC_header: "高效便捷。",

  landing_servicesC_content: "对于项目的Civy反馈就像发送文本消息一样容易。",

  landing_servicesD_header: "综合分析。",

  landing_servicesD_content:
    "分析不同人口统计的居民反馈，并应用分析以更快地进行决策，批准和未来项目规划。",

  landing_service_title: "服务",

  landing_service_content: "Civy帮助城市和政府提高公民参与度。",

  landing_ServiceSection_maincontent:
    "Civy显示按州，城市，邮政编码列出的政府和城市的项目；并提出居民可以回答的问题。 ",

  //landing page project component

  landing_project_title: "专案",

  landing_project_content: "我们在各个城市的当前项目清单。",

  landing_project_short_description: "项目简介",

  landing_project_description_link: "查看更多",

  landing_project_startdate: "开始日期",

  landing_project_enddate: "结束日期",

  //landing page benefit component

  landing_benefit1_title: "移动平台",

  landing_benefit1_content: "Civy是一个方便的移动平台，可从任何地方轻松访问。",

  landing_benefit2_title: "居民免费",

  landing_benefit2_content:
    "Civy让居民免费注册，回答问题和进行调查，并帮助城市和政府改善项目计划和交付。",

  landing_benefit3_title: "社交媒体联系",

  landing_benefit3_content:
    "Civy与社交媒体平台的链接可以提高参与率和人口统计深度。",

  landing_benefit4_title: "安全可靠",

  landing_benefit4_content: "居民的回复是机密的，Civy不与第三者共享居民数据。",

  landing_benefit_title: "好处",

  landing_benefit_content:
    "Civy提供了多种功能来利用广泛的公众参与和参与，从而有助于改善治理和发展成果。",

  //landing page about component

  landing_about_title: "关于CIVY",

  landing_about_content:
    "我们帮助城市和政府在整个公民项目中提高利益相关者的参与度和公众参与度。",

  landing_about_maincontent:
    "Civy是居民参与和分享城市和政府对当前和即将进行的公共项目的反馈的一种简单而安全的方式。一个专门的在线平台，可覆盖更广泛的受众。来自所有人群的利益相关者都可以方便，高效地参与并向其城市和政府机构提供反馈。 ",

  landing_readmore: "阅读更多",

  //landing page contact us component

  landing_contactus_address_title: "地址",

  landing_contactus_address: "Civy Inc.",

  landing_contactus_address_post:
    "柏樹街 160 號，加利福尼亞州奧蘭治，92866 美國",

  contactus_address_street: "柏樹街 160 號，",

  contactus_address_code: "加利福尼亞州奧蘭治，92866",

  contactus_address_use: "美国",

  landing_contactus_address_tel: "电话:",

  landing_contactus_usertype1: "居民",

  landing_contactus_usertype2: "市",

  landing_contactus_usertype3: "政府机构",

  landing_contactus_usertype4: "大学",

  landing_contactus_usertype5: "其他",

  landing_contactus_title: "保持联系",

  landing_contactus_name: "名称*",

  landing_contactus_email: "电子邮件*",

  landing_contactus_select: "你是*",

  landing_contactus_feedback: "反馈类型*",

  landing_contactus_feedback_good: "好",

  landing_contactus_feedback_bad: "坏",

  landing_contactus_feedback_other: "其他",

  landing_contactus_feedback_message: "你的信息*",

  landing_contactus_send_button: "发信息",

  landing_contactus_name_error: "*姓名为必填项",

  landing_contactus_email_error: "*必须填写电子邮件",

  landing_contactus_email_error2: "*请输入有效的电子邮件地址",

  landing_contactus_select_error: "*请选择一个选项",

  landing_contactus_message_error: "必填",

  landing_contactus_capcha_error: "*请确认验证码复选框我不是机器人",

  select_survey_visibility: "选择调查可见性",

  select_survey_visibility_private: "私人的",

  select_survey_visibility_public: "上市",

  // services page

  services_casestudy1_title: "城市与政府",

  services_casestudy1_content:
    "一个易于使用的安全平台，可以吸引居民参与并加快公民项目的智慧发展。",

  services_casestudy2_title: "居民",

  services_casestudy2_content:
    "一个移动平台，可接收通知并与您的城市共享反馈，以指导公民项目的设计和开发。",

  services_casestudy_title: "案例分析",

  services_casestudy_content: "城市与政府如何通过Civy与居民联系",

  services_integratedservices_title: "综合服务：",

  services_introservice1_title: "是什么使民事不同",

  services_introservice1_point1:
    "使城市和政府能够创建自己的数据，汇总数据并控制自己的叙述。",

  services_introservice1_point2:
    "通过与其他社交媒体渠道（如Facebook，Twitter和LinkedIn）的链接来增加和改善公众影响。",

  services_introservice1_point3:
    "居民回复的完全保密性，仅对订购CIvy的城市或政府可见。数据归城市或政府所有，Civy不参与第三方销售。",

  services_introservice1_point4:
    "将项目数据下载并存储在您自己的服务器上。为了遵守隐私法规，Civy在客户标记为项目完成三个月后删除了数据。",

  services_introservice1_point5:
    "您可以通过独特的品牌选择将Civy标记为自己的平台。",

  services_introservice1_point6:
    "Civy还提供其他服务，例如3D Holographic和GIS Mapping，以及在计划，设计和营销项目与城市发展中的应用。",

  services_introservice2_title: "城市和政府的产品功能。",

  services_introservice2_point1:
    "城市和政府在Civy平台上创建帐户，并链接其自己的社交媒体渠道。",

  services_introservice2_point2:
    "将多个用户添加到具有已定义用户角色的帐户中，例如Public Works总监。",

  services_introservice2_point3: "创建项目，上载与项目愿景有关的描述和图片。。",

  services_introservice2_point4: "跨不同渠道分享，并提出问题供居民回答。",

  services_introservice2_point5:
    "在我们的多种语言功能的支持下，收集并分析响应，捕获社区中的更多人口统计信息。",

  services_introservice2_point6: "利用分析加快决策，执行和结果的速度。",

  services_introservice1_content:
    "Civy是面向城市，政府和居民的互动平台，可让您创建，汇总和存储数据。",

  services_introservice2_content:
    "Civy提供了一个易于使用的平台来促进公民参与。",

  // about page

  about_history1_title: "构想",

  about_history1_content:
    "在执行了多个针对全球基础设施，能源和技术的项目后，我们意识到尽管政府，城市官员和社区有最好的意愿，但他们总是抱怨缺乏协商；而像开发商这样的私人利益者担心，如果他们与社区互动过多，他们的商业目标将会受到负面影响。另一方面，社区感到向他们提供了涂糖衣的商业计划和项目投资建议，但是在设计阶段并未向他们咨询，或者经常被视为不了解他们在说什么的非专家。在社区进行公民发展项目时，居民常常会闻所未闻。",

  about_history2_title: "引发",

  about_history2_content:
    "为了解决这个问题，我们可视化了一个通用平台，该平台的项目创意来自城市和政府官员，并直接呈现给居民和利益相关者以征求反馈。然后，前者可以根据利益相关者（居民）的反馈参与，分析并缩小最佳行动方案。政府和市政府可以直接向人们解释哪些是可以实现的，哪些是不能实现的，列出短期内可能遇到的潜力以及可能遇到的复杂情况，详细说明开发过程中涉及的财务状况，并在征求有关方面的适当咨询后进行。所有合作者都迅速采取行动并实施了这些项目。",

  about_history3_title: "公式",

  about_history3_content:
    "出于明确的目标和决心，我们创建了一个概念平台，重点解决与城市和政府（市长，议员，立法会议员，城市经理，部门主管，营销团队，技术团队和社区团体）在发现阶段遇到的难题。该概念平台将信息分析工具与社交媒体方面相结合，以提供广泛的宣传并与居民互动，参与所有公民项目的开发，并获得了客户的认可。主要目标是减少用于公民项目的时间，成本和资源，并在保持数据隐私和安全的同时，扩大所有公众和公众的参与范围并提高包容性。",

  about_history4_title: "实作",

  about_history4_content:
    "从2019年开始，我们实施了Civy这个平台平台，可帮助城市和政府改善各个公民项目之间的公共联系。该平台通过吸引千禧一代居民和所有人口统计领域的老年人参与来征求反馈，同时减少了使用过时和过时的方法（例如该平台可为所有公民项目提供学习，参与和向利益相关者诚实反馈的前所未有的便利；该平台在多个社交媒体渠道，网络安全和云托管解决方案中的嵌入式一键式共享，还提供了数据隐私，后台生产力的提高，并使城市和政府能够集中精力进行战略思考。",

  about_history_title: "雪佛兰的历史",

  about_history_since: "以来",

  about_vision_title: "我们的愿景",

  about_vision_content:
    "创建一个吸引城市，政府，居民和利益相关者的参与循环，并打造一个更智慧的星球。",

  about_mission_title: "我们的任务",

  about_mission_content1:
    "Civy通过了解他们的痛点，了解可以改进的地方并帮助使用我们专有的平台促进更好的讨论，从而帮助城市和政府改善公民项目之间的公众联系和利益相关者的参与。我们实现这一目标的理念是“回基础”的方法，要求人们听取他们的反馈意见，并帮助我们的客户（城市和政府）根据反馈意见采取行动。",

  about_interview_title: "面试",

  about_interview_content:
    "Civy创始人Amar Flora在直播电视上接受了采访，讨论了Civy及其帮助城市和政府的目标",

  about_interview_maincontent:
    "观看Civy创始人Amar Flora在直播电视上解释Civy如何使决策者能够使用用户的数据做出更快的决策，采取英特尔行动，并简单地将“蓝天”思维与他们的居民联系起来；所有这些都是实时的，未经过滤的，并且是在独立平台上进行的。",

  about_ourteam_title: "我们的队伍",

  about_ourteam_content:
    "我们是一群热衷于GovTech，智慧城市和利益相关方参与的人。",

  about_ourteam_person1_name: "阿玛·弗洛拉 (MBA, GAICD, GCCM, CPA)",

  about_ourteam_person1_title: "联合创始人兼首席执行官",

  about_ourteam_person1_content:
    "富有远见的领导者和企业家，对高等教育，智慧城市和政府运营充满热情。 Amar与他人共同创立了两家私营初创公司，建立了全球团队和合作者，并在董事会任职，并领导了一家大学初创公司的建立和运营，并获得了2000万美元的私人资金。 Amar出售服务并与各行各业的客户进行咨询，以提供有意义的影响和价值创造。 Amar具有敏锐的能力，可以发现战略和运营机会并推动创新。 Amar拥有MBA学位，变更管理和公司治理方面的研究生以及美国和澳大利亚顶级机构的CPA认证。",

  about_ourteam_person2_name: "萨布丽娜 上层 (MS, PMP)",

  about_ourteam_person2_title: "联合创始人兼首席运营官",

  about_ourteam_person2_content:
    "萨布丽娜（Sabrina）与Civy一起提供高质量的经验，并成功执行了私营和政府部门的多个启动和研究项目。她的创造力和创新思维使她能够设计并为客户创造价值。此外，Sabrina在管理美国国防部签约组织的政府合同方面拥有丰富的经验。萨布丽娜（Sabrina）拥有亲身尝试与城市或政府机构接触以取得成果的第一手经验。这些经历激发了她的热情和创造力，以帮助城市和政府促进与居民的互动。 Sabrina拥有U.C.的神经科学理学学士学位。戴维斯来自加州州立大学北岭分校的科学，生物学硕士，并获得PMP认证。",

  about_ourteam_person3_name: "张金红 (CPA, MBA, CGMA)",

  about_ourteam_person3_title: "首席财务官",

  about_ourteam_person3_content:
    "张金宏（Jinhong Zhang）在商业和财务规划与管理方面拥有20多年的经验，担任领导职务，包括总裁CFO，财务总监以及大型企业组织顾问。 Jinhong的职业生涯始于《财富》全球500强公司，他分别管理由世界银行和亚洲开发银行资助的项目，并在客户方监督PWC和KPMG的审计和税收业务。",

  about_ourteam_person4_name: "苏克图·莫迪",

  about_ourteam_person4_title: "首席技术官",

  about_ourteam_person4_content: `Suketu被《福布斯》印度杂志（Forbes India）评为2019年印度100位杰出人物经理，并荣获最佳城市基础设施设计和智能电子政务。他是一位经验丰富的商业领袖和技术专家，在为顶级跨国咨询公司，政府和政府机构提供ICT咨询方面拥有丰富的经验。担任Civy首席技术官的PSU。 Suketu拥有超过19年的ICT咨询和ICT基础设施网络基础设施和智能服务实施经验，为大型城市/校园和智能建筑服务设计。 Suketu已与美国，中东，新加坡和印度的客户合作。`,

  about_ourteam_description1:
    "我们热衷于帮助城市和政府促进创新，以改善居民状况。",

  about_ourteam_description2:
    "Infixzon是Civy的独家合作伙伴，是一家咨询，云和网络安全服务公司，由拥有数十年经验的行业资深人士领导。 Infixzon服务于美国，亚太地区和中东地区，为公共部门，医疗保健，金融服务，制药和制造业等垂直行业设计解决方案。",

  about_ourteam_description3:
    "Twish Technologies是一支由专业团队组成的团队，在基础设施政策，项目可行性研究，城市级基础设施，智慧社区和智慧城市的集成和实施策略方面拥有多年领导才能。他们的团队包括一名组织心理学家，代表城市和政府关注社区参与。",

  //Terms and conditions page

  ourpolicies_term_of_use_title: "使用条款",

  ourpolicies_terms_title: "条款和条件",

  ourpolicies_terms_last_updated:
    "上次更新时间：{year}年{monthNumber}月{day}日",

  ourpolicies_terms_content1: `在使用https://www.civy.co网站和Civy运营的Civy移动应用程序（一起或单独称为“服务”）之前，请仔细阅读这些条款和条件（“条款”，“条款和条件”）。 Inc（“我们”，“我们”或“我们的”）。`,

  ourpolicies_terms_content2:
    "您对服务的访问和使用以您接受并遵守这些条款为条件。这些条款适用于所有访问者，用户和希望访问或使用服务的其他人。",

  ourpolicies_terms_content3:
    "通过访问或使用服务，您同意受这些条款的约束。如果您不同意条款的任何部分，则您无权访问该服务。",

  ourpolicies_terms_Communications_title: "通讯技术",

  ourpolicies_terms_Communications_content1:
    "通过在我们的服务上创建帐户，即表示您同意订阅新闻通讯，市场营销或促销材料以及我们可能发送的其他信息。但是，您可以按照我们发送的任何电子邮件中的退订链接或说明，选择不接收来自我们的任何或所有这些通信。",

  ourpolicies_terms_subscription_title: "订阅内容",

  ourpolicies_terms_subscription_content1: `服务的某些部分按订阅计费（“订阅”）。我们会定期定期向您收费（“结算周期”）。计费周期是按月或按年度设置的，具体取决于您在购买订阅时选择的订阅计划的类型。`,

  ourpolicies_terms_subscription_content2:
    "在每个计费周期结束时，除非您取消订阅或Civy Inc取消订阅，否则订阅将在完全相同的条件下自动续订。您可以通过在线帐户管理页面或联系Civy Inc客户支持团队来取消订阅续订。",

  ourpolicies_terms_subscription_content3:
    "必须使用有效的付款方式（包括信用卡或PayPal）来处理您的订购付款。您应向Civy Inc提供准确，完整的账单信息，包括全名，地址，州，邮政编码，电话号码以及有效的付款方式信息。提交此类付款信息，即表示您自动授权Civy Inc.将您的帐户产生的所有订购费用收取给任何此类付款工具。",

  ourpolicies_terms_subscription_content4:
    "如果由于任何原因导致自动开票失败，Civy Inc将开具电子发票，指示您必须在特定的截止日期之前手动进行，并按照发票上指示的开票期限全额付款。",

  ourpolicies_terms_feechanges_title: "费用变更",

  ourpolicies_terms_feechanges_content1:
    "Civy Inc.可全权酌情决定随时修改有关认购的认购费用。任何订阅费用的更改都将在当前的结算周期结束时生效。",

  ourpolicies_terms_feechanges_content2:
    "Civy Inc将就订购费用的任何变更向您提供合理的事先通知，使您有机会在此类变更生效之前终止订购。",

  ourpolicies_terms_feechanges_content3:
    "订阅费变更生效后，您继续使用服务即构成您同意支付修改后的订阅费金额。",

  ourpolicies_terms_refund_title: "退款额",

  ourpolicies_terms_refund_content:
    "除非法律要求，否则已付费的订阅费概不退还。",

  ourpolicies_terms_content_title: "内容",

  ourpolicies_terms_content_description1: `我们的服务允许您发布，链接，存储，共享以及以其他方式提供某些信息，文本，图形，视频或其他材料（“内容”）。您对在服务上或通过服务发布的内容负责，包括其合法性，可靠性和适当性。`,

  ourpolicies_terms_content_description2:
    "通过在“服务”上或通过“服务”发布内容，您表示并保证：（i）内容属于您（您拥有），并且/或者您有权使用它，并且有权授予我们以下内容中提供的权利和许可这些条款，以及（ii）在“服务”上或通过“服务”发布您的内容，并不侵犯任何个人或实体的隐私权，公开权，版权，合同权或任何其他权利。我们保留终止任何发现侵犯版权的人的帐户的权利。",

  ourpolicies_terms_content_description3:
    "您保留对在“服务”上或通过“服务”提交，发布或显示的任何“内容”的所有权利，并且您有责任保护这些权利。对于您或服务上或通过服务发布的任何内容，我们不承担任何责任，也不承担任何责任。但是，通过使用服务发布内容，即表示您授予我们使用，修改，执行，显示，复制和在服务上或通过服务分发此类内容的权利和许可。",

  ourpolicies_terms_content_description4:
    "Civy Inc.有权（但没有义务）监视和编辑用户提供的所有内容。",

  ourpolicies_terms_content_description5:
    "此外，在本服务上或通过本服务发现的内容是Civy Inc的财产或经许可使用。未经我们事先明确的书面许可，您不得出于商业目的或个人利益目的，全部或部分分发，修改，传输，重用，下载，转发，复制或使用所述内容。",

  ourpolicies_terms_account_title: "帐目",

  ourpolicies_terms_account_content1:
    "当您在我们这里创建帐户时，您必须保证您已年满18岁，并且您提供给我们的信息在任何时候都是准确，完整和最新的。信息不正确，不完整或过时可能会导致您在服务上的帐户立即终止。",

  ourpolicies_terms_account_content2:
    "您负责维护您的帐户和密码的机密性，包括但不限于访问您的计算机和/或帐户的限制。您同意对在您的帐户和/或密码下发生的任何和所有活动或行为承担责任，无论您的密码是使用我们的服务还是第三方服务。您必须在得知任何安全漏洞或未经授权使用您的帐户后立即通知我们。",

  ourpolicies_terms_account_content3:
    "未经适当的授权，您不得将他人或实体的名称或合法使用的名称或商标作为用户名使用，而该名称或商标应受您以外的他人或实体的任何权利的约束。您不得将任何令人反感，粗俗或淫秽的名称用作用户名。",

  ourpolicies_terms_Intellectual_title: "知识产权",

  ourpolicies_terms_Intellectual_content1:
    "该服务及其原始内容（不包括用户提供的内容），功能和特性是并且将仍然是Civy Inc及其许可方的专有财产。本服务受美国和外国的版权，商标和其他法律保护。未经Civy Inc.事先书面许可，不得将我们的商标和商业外观与任何产品或服务结合使用。",

  ourpolicies_terms_links_title: "链接到其他网站",

  ourpolicies_terms_links_content1:
    "我们的服务可能包含指向非Civy Inc拥有或控制的第三方网站或服务的链接",

  ourpolicies_terms_links_content2:
    "Civy Inc.无法控制，也不对任何第三方网站或服务的内容，隐私政策或惯例承担任何责任。我们不保证任何这些实体/个人或其网站提供的产品。",

  ourpolicies_terms_links_content3:
    "您承认并同意，Civy Inc对因使用或依赖于或通过其获得的任何此类内容，商品或服务而引起或被指称的任何原因或与之相关的任何损害或损失，概不负责。任何此类第三方网站或服务。",

  ourpolicies_terms_links_content4:
    "我们强烈建议您阅读您访问的任何第三方网站或服务的条款和条件以及隐私政策。",

  ourpolicies_terms_termination_title: "终止",

  ourpolicies_terms_termination_content1:
    "我们可以出于任何原因（包括但不限于违反本条款），出于任何原因，根据我们的全权酌情决定权，在没有事先通知或承担任何责任的情况下，立即终止或暂停您的帐户并禁止访问该服务。",

  ourpolicies_terms_termination_content2:
    "如果您想终止您的帐户，则可以简单地停止使用服务。",

  ourpolicies_terms_termination_content3:
    "本条款的所有因其性质应在终止后继续有效的条款应在终止后继续有效，包括但不限于所有权条款，担保免责声明，赔偿和责任限制。",

  ourpolicies_terms_Indemnification_title: "保障",

  ourpolicies_terms_Indemnification_content1:
    "您同意就任何和所有索赔，损害，义务，损失，负债，成本或债务，以及对任何索赔，损害赔偿，义务，损失，负债，成本或债务的捍卫，赔偿和使Civy Inc及其被许可方和许可方及其雇员，承包商，代理商，管理人员和董事免受损害，以及a）您或任何使用您的帐户和密码的人对您使用和访问本服务所产生或产生的费用（包括但不限于律师费）； b）违反这些条款，或c）发布在服务上的内容。",

  ourpolicies_terms_limitation_title: "责任限制",

  ourpolicies_terms_limitation_content1:
    "在任何情况下，Civy Inc或其董事，雇员，合伙人，代理商，供应商或分支机构均不对任何间接，偶然，特殊，结果性或惩罚性损害负责，包括但不限于利润损失，数据，使用，商誉或其他无形损失，是由于",

  ourpolicies_terms_limitation_list1: "您访问或使用或无法访问或使用服务",

  ourpolicies_terms_limitation_list2: "服务中任何第三方的任何行为或内容",

  ourpolicies_terms_limitation_list3: "从服务获得的任何内容",

  ourpolicies_terms_limitation_list4:
    "无论基于保修，合同，侵权（包括过失）或任何其他法律理论，未经授权地访问，使用或更改您的传输或内容，无论是否已通知我们此类损坏的可能性，以及是否已采取补救措施发现本文的第四个方面没有达到其基本目的。",

  ourpolicies_terms_disclaimer_title: "免责声明",

  ourpolicies_terms_disclaimer_content1:
    "您对服务的使用自负风险。该服务是按“原样”和“按可用”提供的。提供的服务没有任何形式的明示或暗示保证，包括但不限于对适销性，针对特定目的的适用性，不侵权或执行过程的暗示保证。",

  ourpolicies_terms_disclaimer_content2:
    "Civy Inc及其子公司，关联公司及其许可人不保证",

  ourpolicies_terms_disclaimer_list1:
    "该服务将在任何特定时间或地点不间断，安全或可用地运行",

  ourpolicies_terms_disclaimer_list2: "任何错误或缺陷将得到纠正",

  ourpolicies_terms_disclaimer_list3: "该服务没有病毒或其他有害组件",

  ourpolicies_terms_disclaimer_list4: "使用服务的结果将满足您的要求。",

  ourpolicies_terms_exclusion_title: "排除项目",

  ourpolicies_terms_exclusion_content1:
    "某些司法管辖区不允许排除某些担保，也不允许排除或限制对间接损失或偶然损失的责任，因此上述限制可能不适用于您。",

  ourpolicies_terms_governing_title: "准据法",

  ourpolicies_terms_governing_content1:
    "这些条款应根据美国加利福尼亚州的法律进行管理和解释，不考虑其法律冲突规定。",

  ourpolicies_terms_governing_content2:
    "我们未能执行任何权利或本条款的规定不会被视为对这些权利的放弃。如果法院认为本条款的任何规定无效或无法执行，则本条款的其余规定将继续有效。这些条款构成了我们之间有关服务的完整协议，并取代并取代了我们之间就服务可能达成的任何先前协议。",

  ourpolicies_terms_changes_title: "变化",

  ourpolicies_terms_changes_content1:
    "我们保留自行决定随时修改或替换这些条款的权利。如果有重大修改，我们将在新条款生效之前至少提前30天通知您。构成重大变更的内容将由我们自行决定。",

  ourpolicies_terms_changes_content2:
    "在任何修订生效后继续访问或使用我们的服务，即表示您同意受修订条款的约束。如果您不同意新条款，则不再被授权使用该服务。",

  ourpolicies_terms_contactus_title: "联系我们",

  ourpolicies_terms_contactus_content:
    "如果您对这些条款有任何疑问，请通过以下方式与我们联系",

  //Privacy policies page

  ourpolicies_policy_title: "隐私政策",

  ourpolicies_policy_date: "生效日期：{year}年{monthNumber}月{day}日",

  ourpolicies_policy_content1: `Civy Inc.（“我们”，“我们”或“我们的”）经营https://www.civy.co	
    网站和Civy移动应用程序（以下简称“	
    “服务”）。`,

  ourpolicies_policy_content2:
    " 本页告知您我们在使用我们的服务时收集，使用和披露个人数据的政策以及与该数据相关的选择。",

  ourpolicies_policy_content3:
    " 我们使用您的数据来提供和改善服务。使用服务即表示您同意按照本政策收集和使用信息。除非本隐私政策另有定义，否则本隐私政策中使用的术语与我们的条款和条件具有相同的含义。",

  ourpolicies_definitions_title: "定义",

  ourpolicies_service_title: "服务",

  ourpolicies_service_content: `服务是指https://www.civy.co网站和Civy mobile Civy Inc.运营的应用程序`,

  ourpolicies_personaldata_title: "个人资料",

  ourpolicies_personaldata_content:
    "个人数据是指可以从这些数据（或从我们拥有或可能拥有的那些信息和其他信息）中识别出的在世个人的数据。",

  ourpolicies_usagedata_title: "使用数据",

  ourpolicies_usagedata_content:
    "使用数据是通过使用服务或从服务基础结构本身自动生成的数据（例如，页面访问的持续时间）。",

  ourpolicies_cookie_title: "Cookies",

  ourpolicies_cookie_content:
    "Cookies是存储在您的设备（计算机或移动设备）上的小文件。",

  ourpolicies_datacontroller_title: "数据控制器",

  ourpolicies_datacontroller_content1:
    "数据控制者是指自然人或法人（单独或共同或与其他人共同确定）处理或将要处理任何个人信息的目的和方式。",

  ourpolicies_datacontroller_content2:
    "就本隐私政策而言，我们是您的个人数据的数据控制者。",

  ourpolicies_dataprocessor_title: "数据处理器（或服务提供商）",

  ourpolicies_dataprocessor_content1:
    "数据处理器（或服务提供商）是指代表数据控制器处理数据的任何自然人或法人。",

  ourpolicies_dataprocessor_content2:
    "我们可能会使用各种服务提供商的服务，以便更有效地处理您的数据。",

  ourpolicies_datasubject_title: "数据主体（或用户）",

  ourpolicies_datasubject_content:
    "数据主体是使用我们服务的任何在世个人，并且是个人数据的主体。",

  ourpolicies_information_title: "信息收集与使用",

  ourpolicies_information_content:
    "我们出于各种目的收集几种不同类型的信息，以向您提供和改善我们的服务。",

  ourpolicies_typeofdata_title: "收集的数据类型",

  ourpolicies_typeofdata_personal_title: "个人资料",

  ourpolicies_typeofdata_personal_content1: `在使用我们的服务时，我们可能会要求您向我们提供某些 可用于联系或识别的 身份信息 识别您的身份（“个人数据”）。个人身份信息可能 包括但不限于：`,

  ourpolicies_typeofdata_personal_list1: `电子邮件地址`,

  ourpolicies_typeofdata_personal_list2: `名和姓`,

  ourpolicies_typeofdata_personal_list3: `电话号码`,

  ourpolicies_typeofdata_personal_list4: `地址，州，省，邮政编码/城市`,

  ourpolicies_typeofdata_personal_list5: `Cookie和使用情况数据`,

  ourpolicies_typeofdata_personal_content2: `我们可能会使用您的个人数据与您联系，以获取时事通讯，营销或促销材料以及您可能感兴趣的其他信息。您可以按照退订链接或我们发送的任何电子邮件中提供的说明，选择不接收来自我们的任何或所有这些通信。`,

  ourpolicies_typeofdata_usage_title: `使用数据`,

  ourpolicies_typeofdata_usage_content1: `每当您访问我们的服务或通过移动设备或通过移动设备访问服务时，我们也可能会收集您的浏览器发送的信息（“使用数据”）。`,

  ourpolicies_typeofdata_usage_content2: `此使用情况数据可能包括诸如计算机的Internet协议地址（例如IP地址），浏览器类型，浏览器版本，您访问的“服务”页面，访问的时间和日期，在这些页面上花费的时间，唯一性等信息。设备标识符和其他诊断数据。`,

  ourpolicies_typeofdata_usage_content3: `当您通过移动设备访问服务时，此使用情况数据可能包括诸如您使用的移动设备的类型，移动设备的唯一ID，移动设备的IP地址，移动操作系统，移动Internet的类型之类的信息。您使用的浏览器，唯一的设备标识符和其他诊断数据。`,

  ourpolicies_typeofdata_location_title: `位置资料`,

  ourpolicies_typeofdata_location_content1: `如果您允许我们使用和存储有关您的位置的信息（“位置数据”）。我们使用这些数据来提供我们服务的功能，以改善和定制我们的服务。`,

  ourpolicies_typeofdata_location_content2: `您可以随时通过设备设置启用或禁用位置服务。`,

  ourpolicies_typeofdata_tracking_title: `跟踪Cookies数据`,

  ourpolicies_typeofdata_tracking_content1: `我们使用Cookie和类似的跟踪技术来跟踪我们服务上的活动，并且我们保留某些信息。`,

  ourpolicies_typeofdata_tracking_content2: `Cookies是具有少量数据的文件，其中可能包含匿名唯一标识符。 Cookies从网站发送到您的浏览器并存储在您的设备上。还使用其他跟踪技术，例如信标，标签和脚本来收集和跟踪信息以及改善和分析我们的服务。`,

  ourpolicies_typeofdata_tracking_content3: `您可以指示浏览器拒绝所有cookie或指示何时发送cookie。但是，如果您不接受Cookie，则可能无法使用我们服务的某些部分。`,

  ourpolicies_typeofdata_cookiesex_title: `我们使用的Cookie的示例：`,

  ourpolicies_typeofdata_cookiesex_session: "会话Cookie。",

  ourpolicies_typeofdata_cookiesex_session_content: `我们使用会话Cookie来运行我们的服务。`,

  ourpolicies_typeofdata_cookiesex_preference: "偏好Cookie。",

  ourpolicies_typeofdata_cookiesex_preference_content: `我们使用首选项Cookie来记住您的首选项和各种设置。`,

  ourpolicies_typeofdata_cookiesex_security: `安全性Cookie。`,

  ourpolicies_typeofdata_cookiesex_security_content: `我们出于安全目的使用安全Cookies。`,

  ourpolicies_typeofdata_use_title: `资料使用`,

  ourpolicies_typeofdata_use_content: `Civy Inc.将收集的数据用于各种目的：`,

  ourpolicies_typeofdata_use_list1: `提供和维护我们的服务`,

  ourpolicies_typeofdata_use_list2: `通知您有关我们服务的更改`,

  ourpolicies_typeofdata_use_list3: `为了使您能够选择参与我们服务的互动功能`,

  ourpolicies_typeofdata_use_list4: `提供客户支持`,

  ourpolicies_typeofdata_use_list5: `收集分析或有价值的信息，以便我们改善服务质量`,

  ourpolicies_typeofdata_use_list8: `监控我们服务的使用情况`,

  ourpolicies_typeofdata_use_list6: `检测，预防和解决技术问题`,

  ourpolicies_typeofdata_use_list7: `为您提供有关我们提供的其他商品，服务和事件的新闻，特价和一般信息，这些信息类似于您已经购买或查询的商品，服务和事件，除非您选择不接收此类信息`,

  ourpolicies_typeofdata_legalbasis_title: `根据通用数据保护条例（GDPR）处理个人数据的法律依据`,

  ourpolicies_typeofdata_legalbasis_content1: `如果您来自欧洲经济区（EEA），Civy Inc.收集和使用本隐私政策中所述的个人信息的法律依据取决于我们收集的个人数据以及收集信息的特定环境。`,

  ourpolicies_typeofdata_legalbasis_content2: `Civy Inc.可能会处理您的个人数据，因为：`,

  ourpolicies_typeofdata_legalbasis_list1: `我们需要与您签订合同`,

  ourpolicies_typeofdata_legalbasis_list2: `您已允许我们这样做`,

  ourpolicies_typeofdata_legalbasis_list3: `处理符合我们的合法利益，不会被您的权利所覆盖`,

  ourpolicies_typeofdata_legalbasis_list4: `用于付款处理`,

  ourpolicies_typeofdata_legalbasis_list5: `遵守法律`,

  ourpolicies_typeofdata_retention_title: `资料保留`,

  ourpolicies_typeofdata_retention_content1: `Civy Inc.仅在出于本隐私政策规定的目的所必需的时间内保留您的个人数据。我们将在遵守我们的法律义务（例如，如果我们需要保留您的数据以遵守适用法律）的必要范围内保留和使用您的个人数据，解决争端并执行我们的法律协议和政策。`,

  ourpolicies_typeofdata_retention_content2: `Civy Inc.还将保留使用数据以用于内部分析。使用数据通常会保留较短的时间，除非该数据用于增强安全性或改善我们服务的功能，或者法律上有义务将这些数据保留更长的时间。`,

  ourpolicies_typeofdata_transfer_title: `资料传输`,

  ourpolicies_typeofdata_transfer_content1: `您的信息（包括个人数据）可能会转移到您的州，省，国家或其他政府辖区之外的计算机上并在这些计算机上维护，在这些计算机上，数据保护法可能与您所在辖区的法律不同。`,

  ourpolicies_typeofdata_transfer_content2: `如果您位于美国境外并选择向我们提供信息，请注意，我们会将包括个人数据在内的数据传输到美国并在美国进行处理。`,

  ourpolicies_typeofdata_transfer_content3: `您同意本隐私政策并随后提交此类信息，即表示您同意该转移。`,

  ourpolicies_typeofdata_transfer_content4: `Civy Inc.将采取合理必要的所有步骤，以确保按照本隐私政策安全地处理您的数据，并且除非有适当的控制措施，包括您的个人信息，否则不会将您的个人数据转移到组织或国家。您的数据和其他个人信息的安全性。`,

  ourpolicies_disclosure_title: `资料披露`,

  ourpolicies_disclosure_law_title: `执法披露 `,

  ourpolicies_disclosure_law_content: `在某些情况下，如果法律要求或响应公共机构（例如法院或政府机构）的有效要求，Civy Inc.可能需要披露您的个人数据。`,

  ourpolicies_disclosure_requirement_title: `法律要求`,

  ourpolicies_disclosure_requirement_content: `Civy Inc.可能真诚地认为您的个人数据会泄露您的个人数据，以便：`,

  ourpolicies_disclosure_requirement_list1: `遵守法律义务`,

  ourpolicies_disclosure_requirement_list2: `保护和捍卫Civy Inc.的权利或财产。`,

  ourpolicies_disclosure_requirement_list3: `防止或调查与服务有关的可能的不当行为`,

  ourpolicies_disclosure_requirement_list4: `为了保护服务用户或公众的人身安全`,

  ourpolicies_disclosure_requirement_list5: `防止法律责任`,

  ourpolicies_security_title: `资料安全`,

  ourpolicies_security_content: `数据的安全性对我们很重要，但请记住，互联网上的传输方法或电子存储方法都不是100％安全的。尽管我们努力使用商业上可接受的方法来保护您的个人数据，但我们不能保证其绝对安全。`,

  ourpolicies_security_policy: `我们的政策–在美国境内的一般性适用，包括加利福尼亚州的特定政策。`,

  ourpolicies_security_track: `根据《加利福尼亚在线保护法》（CalOPPA），“请勿跟踪”信号`,

  ourpolicies_security_track_content1: `我们不支持“不追踪”（“ DNT”）。您可以在网络浏览器中设置“不跟踪”偏好设置，以通知网站您不想被跟踪。`,

  ourpolicies_security_track_content2: `您可以通过访问网络浏览器的“首选项”或“设置”页面来启用或禁用“不跟踪”。`,

  ourpolicies_security_privacy_policy: `我们遵守加州消费者隐私法（CCPA）的政策`,

  ourpolicies_security_privacy_policy1: `知情权：`,

  ourpolicies_security_privacy_policy1_content: `居民有权要求我们披露我们收集，使用，共享或出售的个人信息。`,

  ourpolicies_security_privacy_policy2: `删除权：`,

  ourpolicies_security_privacy_policy2_content: `居民有权要求我们和我们的服务提供商删除我们在您身上拥有的个人信息。`,

  ourpolicies_security_privacy_policy3: `退出权：`,

  ourpolicies_security_privacy_policy3_content: `如果我们“出售” CCPA定义的个人信息，居民有权指示我们停止出售其个人信息。请注意，只有包括美国政府区域（城市，市政当局，议会，政府机构，特殊地区和注册居民）的Civy客户才能访问Civy平台上收集的数据。 Civy不出售或不打算将数据出售给广告商或私人的第三方 公司。`,

  ourpolicies_security_privacy_policy4: `不歧视权：`,

  ourpolicies_security_privacy_policy4_content: `当居民行使CCPA规定的隐私权时，我们可能不会在价格或服务方面歧视居民。`,

  ourpolicies_security_protection_title: `根据通用数据保护条例（GDPR）的数据保护权利`,

  ourpolicies_security_protection_content1: `如果您是欧洲经济区（EEA）的居民，则拥有某些数据保护权利。Civy Inc.旨在采取合理的步骤，使您能够更正，修改，删除或限制对您的个人数据的使用。`,

  ourpolicies_security_protection_content2: `如果您希望了解我们持有的关于您的个人数据，并且希望将其从我们的系统中删除，请与我们联系。`,

  ourpolicies_security_protection_content3: `在某些情况下，您具有以下数据保护权利：`,

  ourpolicies_security_access_title: `访问，更新或删除我们拥有的有关您的信息的权利。`,

  ourpolicies_security_access_content1: `只要有可能，您都可以在帐户设置部分直接访问，更新或请求删除您的个人数据。如果您自己无法执行这些操作，请与我们联系以为您提供帮助。`,

  ourpolicies_security_rectification_title: `整改权。`,

  ourpolicies_security_rectification_content: `如果您的信息不正确或不完整，您有权对其进行更正。`,

  ourpolicies_security_object_title: `反对权。`,

  ourpolicies_security_object_content: `您有权反对我们处理您的个人数据。`,

  ourpolicies_security_restriction_title: `限制权。`,

  ourpolicies_security_restriction_content: `您有权要求我们限制对您的个人信息的处理。`,

  ourpolicies_security_portability_title: `数据可移植性的权利。`,

  ourpolicies_security_portability_content: `您有权以结构化，机器可读且常用的格式获得我们拥有的有关您的信息的副本。`,

  ourpolicies_security_withdraw_title: `撤回同意的权利。`,

  ourpolicies_security_withdraw_content: `在Civy Inc.依靠您的同意处理您的个人信息时，您也有权随时撤回您的同意。`,

  ourpolicies_security_protection_content4: `请注意，我们可能会要求您在回应此类请求之前验证您的身份。`,

  ourpolicies_security_protection_content5: `您有权向数据保护局投诉我们收集和使用您的个人数据。有关更多信息，请联系您在欧洲经济区（EEA）的本地数据保护机构。`,

  ourpolicies_serviceprovider: `服务供应商`,

  ourpolicies_serviceprovider_content1: `我们可能会雇用第三方公司和个人来促进我们的服务（“服务提供者”），代表我们提供服务，执行与服务相关的服务或协助我们分析服务的使用方式。`,

  ourpolicies_serviceprovider_content2: `这些第三方仅能代表我们执行这些任务而访问您的个人数据，并且有义务不将其披露或用于任何其他目的。`,

  ourpolicies_serviceprovider_analytics: `分析工具`,

  ourpolicies_serviceprovider_analytics_content: `我们可能会使用第三方服务提供商来监视和分析我们对服务的使用。`,

  ourpolicies_serviceprovider_ganalytics: `谷歌分析`,

  ourpolicies_serviceprovider_ganalytics_content1: `Google Analytics（分析）是Google提供的一项网络分析服务，可跟踪和报告网站流量。 Google使用收集到的数据来跟踪和监视我们对服务的使用。此数据与其他Google服务共享。 Google可能会使用收集到的数据来对其自己的广告网络的广告进行上下文化和个性化。`,

  ourpolicies_serviceprovider_ganalytics_content2: `有关Google隐私惯例的更多信息，请访问Google隐私条款网页：https://policies.google.com/privacy?hl=zh-CN`,

  ourpolicies_serviceprovider_firebase: `火力基地`,

  ourpolicies_serviceprovider_firebase_content1: `Firebase是Google Inc.提供的一项分析服务。`,

  ourpolicies_serviceprovider_firebase_content4: `您可以通过移动设备设置（例如设备广告设置）或按照Google在其隐私权政策中提供的说明，退出某些Firebase功能：https://policies.google.com/privacy?hl=zh_CN`,

  ourpolicies_serviceprovider_firebase_content2: `我们还建议您查看Google的数据保护政策：https：//support.google.com/analytics/answer/6004245。`,

  ourpolicies_serviceprovider_firebase_content3: `有关Firebase信息类型的更多信息 收集，请访问Google隐私条款网页：https://policies.google.com/privacy?hl=zh-CN`,

  ourpolicies_serviceprovider_payments: `付款方式`,

  ourpolicies_serviceprovider_payment_content1: `我们可能会在服务内提供付费产品和/或服务。在这种情况下，我们将使用第三方服务进行付款处理（例如付款处理程序）。`,

  ourpolicies_serviceprovider_payment_content2: `我们不会存储或收集您的支付卡详细信息。该信息将直接提供给我们的第三方付款处理者，他们对您的个人信息的使用受其隐私政策的约束。这些付款处理器遵守由PCI安全标准委员会管理的PCI-DSS设定的标准，这是Visa MasterCard，American Express和Discover等品牌的共同努力。 PCI-DSS要求有助于确保安全处理付款信息。`,

  ourpolicies_serviceprovider_payment_content3: `我们与之合作的付款处理器是：`,

  ourpolicies_serviceprovider_stripe: `Stripe`,

  ourpolicies_serviceprovider_stripe_content: `可以在https://stripe.com/cn上查看其隐私政策。`,

  ourpolicies_linksto_title: `链接到其他网站`,

  ourpolicies_linksto_content1: `我们的服务可能包含指向其他非我们运营网站的链接。如果单击第三方链接，您将被定向到该第三方的网站。我们强烈建议您查看您访问的每个网站的隐私政策。`,

  ourpolicies_linksto_content2: `我们对任何第三方网站或服务的内容，隐私政策或做法概不负责，也不承担任何责任。`,

  ourpolicies_children_title: `儿童隐私`,

  ourpolicies_children_content1: `我们的服务不针对18岁以下的任何人（“儿童”）。`,

  ourpolicies_children_content2: `我们不会有意收集未满18岁的任何人的个人身份信息。如果您是父母或监护人，并且您知道自己的孩子向我们提供了个人数据，请与我们联系。如果我们知道我们是在未经父母同意的情况下从孩子那里收集个人数据的，则我们将采取措施从服务器中删除该信息。`,

  ourpolicies_changes_title: `本隐私政策的变更`,

  ourpolicies_changes_content1: `我们可能会不时更新我们的隐私政策。我们会通过在此页面上发布新的隐私政策来通知您任何更改。`,

  ourpolicies_changes_content2: `在更改生效之前，我们将通过电子邮件和/或服务的重要通知告知您，并在本隐私政策的顶部更新“生效日期”。`,

  ourpolicies_changes_content3: `建议您定期阅读本隐私政策以进行任何更改。此隐私政策的更改在此页面上发布后即生效。`,

  ourpolicies_contactus_title: `联系我们`,

  ourpolicies_contactus_content1: `如果您对此隐私政策有任何疑问，请联系我们：`,

  ourpolicies_contactus_content2: `通过电子邮件：`,

  //Registration page
  dob_validation: "年齡至少應為16歲",

  register_regi_title: "注册账户",

  register_organization_label: "机构名称{star}",
  profile_name_label: "姓名{star}",

  register_dob_error: "*需要出生日期",

  register_age_error: "*请选择您的年龄范围",

  register_organization_error: "*必须填写机构名称",
  profile_name_error: "*姓名为必填项",

  date_invalid_error: "请输入有效日期。",

  register_organization_type_label: "组织类型{star}",
  profile_type_label: "类型{star}",

  register_organization_type_error: "*请选择组织类型",
  profile_type_error: "*请选择类型",

  register_organization_title_label: "机构名称{star}",
  profile_title_label: "标题{star}",

  register_organization_title_error: "*标题为必填项",

  register_browse_label: "浏览...",

  register_upload_label: "上传徽标",

  register_upload_error: "*请选择徽标",

  register_are_you_label: "你是不是一位{star}",

  register_are_you_resident: "美国居民",

  register_are_you_prefer: "不想回答",

  register_are_you_citizen: "美国公民",

  register_are_you_error: "*请选择类型",

  register_your_title_label: "标题{star}",

  register_your_title_list1: "先生.",

  register_your_title_list2: "小姐.",

  register_your_title_list3: "太太.",

  register_your_title_list4: "博士",

  register_your_title_list5: "小姐",

  register_your_title_error: "*请选择您的标题",

  register_name_label: "拳头名称{star}",

  register_name_error1: "*名字为必填项",

  register_name_error2: "最多应允许15-20个文字。",

  register_last_name_label: "姓{star}",

  register_last_name_error1: "*必须填写姓氏",

  register_last_name_error2: "最多应允许15-20个文字。",

  register_ethinicicty_label: "种族{star}",

  register_ethinicicty_error: "*请选择一个民族",

  register_email_label: "电子邮件{star}",

  register_email_error1: "*需要电子邮件",

  register_email_error2: "请输入有效的电子邮件地址",

  register_mobile_label: "手机号码{star}",

  register_mobile_error1: "*手机号码为必填项",

  register_gender_label: "性别{star}",

  register_gender_type1: "男",

  register_gender_type2: "女",

  register_gender_type3: "其他",

  register_gender_error: "*请选择性别",

  register_birth_label: "出生日期{star}",

  register_street_label: "街道地址{star}",

  register_street_error: "*必填项",

  register_apartment_label: "公寓套房{star}",

  register_apartment_error: "*公寓/套房为必填项",

  register_country_label: "国家{star}",

  register_country_error: "*请选择国家",

  register_state_label: "州{star}",

  register_state_error: "*请选择州",

  register_city_label: "市{star}",

  register_county_label: "縣{star}",

  register_city_error: "*请选择城市",

  register_zip_label: "邮政编码{star}",

  register_zip_error: "*邮政编码为必填项",

  register_zip2_label: "+4码",

  register_password_label: "密码{star}",

  register_password_error1: "*密码为必填项",

  register_password_error2:
    "密码必须至少包含8个字符，包括大写，小写字母和数字。",

  register_terms1_label: "我同意",

  register_terms2_label: "使用条款",

  register_terms3_label: "和",

  register_terms4_label: "隐私政策",

  register_terms_error: "*请表明您接受条款和隐私政策",

  register_already_account: "已经有一个帐户,",

  register_signin_label: "登入",

  register_or_signin_label: "或使用",

  register_registerbutton_label: "寄存器",

  register_organization_type1: "市",

  register_organization_type2: "政府",

  register_organization_type3: "市政的",

  register_organization_type4: "商业改善区",

  register_organization_type5: "智囊团",

  register_organization_type6: "高等教育",

  register_ethinicity_type1: "非裔美国人",

  register_ethinicity_type2: "美洲印第安人或阿拉斯加原住民",

  register_ethinicity_type3: "亚洲或太平洋岛民",

  register_ethinicity_type4: "高加索人",

  register_ethinicity_type5: "西班牙裔或拉丁裔",

  register_ethinicity_type6: "夏威夷原住民或其他太平洋岛民",

  register_ethinicity_type7: "多种族",

  register_ethinicity_type8: "其他",

  register_ethinicity_type9: "不想回答",

  register_profile_change_password: "更改密码",

  register_create_password_change: "更改您的密码",

  register_create_password_create: "创建你的密码",

  register_create_password_old: "旧密码*",

  register_create_password_new: "新密码*",

  register_create_password_confirm: "确认密码*",

  register_create_password_not_match: "*密码不匹配",

  register_create_password_cotinue: "继续",

  register_collegues_add_collegues: "添加同事",

  register_department_label: "部门{star}",

  register_department_error: "*部门是必需的",

  register_member_no_colleagues: "未添加任何同事",

  register_member_colleagues_list: "同事名单",
  register_member_colleague_list: "同事名单",

  register_member_delete: "删除",

  register_profile_upload_resident: "上传居民数据",

  register_profile_batch_upload: "批量上传",

  register_profile_resident_details: "居民详细信息模板",

  //Login page

  login_signin_label: "登入",

  login_email_label: "电子邮件",

  login_email_error1: "*必须填写电子邮件",

  login_email_error2: "*请输入有效的电子邮件地址",

  login_password_label: "密码",

  login_password_error1: "*密码为必填项",

  login_password_error2: "密码必须至少包含8个字符，包括大写，小写字母和数字。",

  login_checkbox_label: "记得我",

  login_loginbutton_label: "登录",

  login_orsignin_label: "或使用",

  login_forgot_password_label: "忘记密码？",

  login_registration_label1: "还没有帐号？",

  login_resident_label1: "创建居民账户",

  login_resident_label2: "创建账户",

  login_organization_label1: "创建组织帐户",

  login_forgot_password_submit: "提交",

  //404 Not found Page and thank you page

  notfound_thankyou_content1: "选择雪佛兰",

  notfound_thankyou_content2: "请检查您的电子邮件。我们已发送至",

  notfound_thankyou_content3: "您会收到一封确认电子邮件。",

  notfound_thankyou_content4: "回到首页",

  //header banner

  header_banner_about: "关于",

  header_banner_contactus: "联系我们",

  header_banner_service: "服务",

  header_banner_outpolicies: "我们的政策",

  header_banner_project: "项目",

  header_banner_thought_leadership: "精神领袖",

  header_banner_how_to: "如何",

  header_home_banner: "通過創新平台聯繫政府和社區",

  header_banner_colleagues: "同事",

  header_banner_change_password: "更改密码",

  header_banner_create_password: "创建密码",

  header_banner_survey: "调查",

  header_banner_what_is_civy: "什么是平民",

  header_banner_qr_code_management: "QR码管理",

  //header.........................

  header_home: "家",

  header_core: "核心19",

  header_KIOSK: "亭",

  header_service: "服务",

  header_project: "工程项目",

  header_project_engage: "从事",

  header_thought_leadership: "精神领袖",

  header_about: "关于",

  header_login: "登录",

  // header_language1: "英语",

  // header_language2: "法文",

  // header_language3: "西班牙文",

  // header_language4: "印地语",

  // header_language5: "普通话",

  // header_language6: "义大利文",

  // header_language7: "越南文",

  header_language1: "EN",

  header_language2: "FR",

  header_language3: "ES",

  header_language4: "HI",

  header_language5: "ZH",

  header_language6: "IT",

  header_language7: "VI",

  header_header_drawer_welcome: "欢迎,",

  header_header_drawer_admin_profile: "管理员个人资料",

  header_header_drawer_profile: "轮廓",

  header_header_drawer_colleagues: "同事",

  header_header_drawer_op_ed: "操作编辑",

  header_header_drawer_how_to: "如何",

  header_header_drawer_logout: "登出",

  header_what_is_civy: "什么是平民",

  header_qr_code_management: "QR码管理",

  //footer........................

  footer_contactus_title: "联系我们",

  footer_contactus_content1:
    "请与Civy联系，以了解如何为所有人口统计的居民促进社区发展项目的公民参与。",

  footer_media: "媒体",

  footer_terms_title: "使用条款",

  footer_privacy_title: "隐私政策",

  footer_google_translate_message: `Civy使用Google翻译将英语转换为平台中可用的其他语言。有时，Google翻译可能无法提供准确的翻译。如有任何疑问，请通过以下方式与我们联系`,

  // blog details page..............

  blog_details_author_title: "作者",

  blog_details_author_title_star: "作者{star}",

  blog_details_posted_title: "发表于：",

  blog_details_latest_article_title: "最新文章",

  blog_details_op_ed_title: "平民操作编辑",

  blog_addblog_new_blog: "添加新的操作编辑",

  blog_addblog_title: "标题",

  blog_addblog_description_title: "描述",

  blog_addblog_browse_title: "浏览...",

  blog_addblog_addimage_title: "添加图片..",

  blog_addblog_submit_title: "添加操作編輯",

  blog_editblog_op_ed_title: "编辑操作",

  blog_common_error: "*这是必填栏",

  blog_title_error: "标题为必填项",

  blog_desc_error: "*说明为必填项",

  blog_author_error: "*需要作者",

  blog_image_error: "图片为必填项",

  valid_image_error: "*图片必须为.png，.jpeg，.jpg",

  //project page.........................

  project_filter_project_type1: "新项目",

  project_filter_project_type2: "现有项目",

  project_filter_project_type3: "维修/保养",

  project_filter_project_type4: "事件",

  project_filter_project_type5: "灾害",

  project_filter_project_category1: "绿化",

  project_filter_project_category2: "通行费",

  project_filter_project_category3: "实用工具",

  project_filter_project_category4: "道路",

  project_filter_project_category5: "高速公路",

  project_filter_project_category6: "商业建筑",

  project_filter_project_category7: "住宅楼",

  project_filter_project_category8: "故土",

  project_filter_project_category9: "社区会所建筑",

  project_filter_project_category10: "小型工程",

  project_filter_city_zipcode: "县，市和邮编",

  project_filter_range: "范围",

  project_filter_range1: "英里",

  project_filter_project_type: "项目类型",

  project_filter_project_type_star: "项目类型{star}",

  project_filter_project_category: "项目类别",

  project_filter_project_category_star: "项目类别{star}",

  project_location: "位置{star}",

  project_street_address: "街道地址",

  project_filter_add_new_project: "添加新项目",

  project_filter_reset: "重启",

  project_newproject_organization_label: "组织部",

  project_newproject_organization_label_star: "组织部{star}",

  project_newproject_common_error: "*这是必填栏",

  project_newproject_street_label: "街",

  project_newproject_country_label: "国家",

  project_newproject_state_label: "州",

  project_newproject_city_label: "市",

  project_newproject_zipcode_label: "邮政编码",

  project_newproject_areacode_label: "+4码",

  project_newproject_start_date: "项目开始日期",

  project_newproject_end_date: "项目结束日期",

  project_newproject_cancel: "取消",

  project_projectfom_add_project: "新增专案",

  publish: "发布",

  project_newproject_continue: "继续",

  project_projectlist_category_label: "类别：",

  project_projectlist_type_label: "类型:",

  project_projectlist_view_label: "查看专案",

  project_projectlist_no_project: "未添加任何项目",

  project_editproject_edit: "编辑专案",

  project_newproject_title_label: "项目名称",

  project_newproject_description: "项目介绍",

  project_newproject_description_star: "项目介绍{star}",

  project_newproject_add_images: "添加图片..",

  project_newproject_add_File: "上载PDF档案...",

  project_commentbox_join_label: "加入对话",

  project_commentbox_nocomment_label: "未添加评论",

  project_commentbox_comment_label: "{commentlength} 评论",

  project_details_location: "位置",

  project_details_posted: "发表于",

  project_details_share: "共享：",

  project_details_last_update: "上次更新时间",

  project_details_likes: "总赞：",

  project_details_question: "添加问题",

  project_details_question_list: "问题清单",

  project_details_no_question: "没有添加问题",

  project_details_add_answer: "添加答案",

  project_details_add_answer_here: "在这里添加答案...",

  project_facts_and_figures_label: "市政府官員的最新消息",

  project_gallery_label: "项目图库",

  qr_code_label: "产生QR码",

  //Survey page............

  survey_select_questionType: "选择问题类型",

  survey_questionType_content:
    "您的项目的详细信息已成功上传到Civy。请选择您要问居民的问题类型。",

  survey_questionType_type_a: "A型",

  survey_questionType_type_que1: "1.请添加您对儿童游乐区的评价。",

  survey_write_here_label: "在这里写点东西...",

  survey_questionType_create_survey: "创建调查",

  survey_questionType_type_b: "多项选择题",

  survey_questionType_type_que2: "1.您想在公共花园中增加一个儿童游乐区吗？",

  survey_questionType_type_b_yes: "是",

  survey_questionType_type_b_no: "没有",

  survey_comment_box: "开放式问题（评论框）",

  survey_radio_question_label: "问题",

  survey_discard_label: "丢弃",

  survey_submit_label: "加",

  survey_add_question_label: "添加问题",

  survey_back_label: "背部",

  survey_question_list_label: "问题清单",

  dialog_delete_message: "您確定要刪除嗎",

  delete_lable: "刪除",

  cancel_label: "取消",

  comment: "評論",

  /* Validation message */

  departmaent_required: "*部門名稱為必填項",

  project_type_required: "*請選擇項目類型",

  project_category_required: "*請選擇項目類別",

  location_required: "*必須填寫位置",

  full_address_required: "*請輸入完整地址",

  only_usa_required: "*僅添加美國地址",

  project_title_requied: "*需要項目名稱",

  project_desc_required: "*需要項目描述",

  project_image_required: "*圖片為必填項",
  commonn_update_button: "更新資料",

  export_button: "導出到Excel",

  export_project_data: "导出项目数据",

  export_project: "出口项目",

  from: "从",

  to: "至",

  date_range: "日期范围",

  project_required: "*请选择项目",

  edit_question: "编辑问题",

  quetion_labele: "题",

  qustion_required: "*问题字段为必填项",

  click_edit: "点击编辑个人资料",

  back_label: "背部",

  save_label: "保存",

  draft_label: "草稿",

  upload_label: "上载",

  attachment_label: "附件",

  rating_type_question: "评分类型问题",

  survey_questionType_type_que3: "请评价这个项目",

  allow_cookie_text:
    "我们使用曲奇饼来优化您的体验，分析流量并个性化内容。 要了解更多信息，请访问我们的曲奇饼政策。 在不禁用曲奇饼的情况下使用我们的网站，即表示您同意我们对曲奇饼的使用",

  allow_btn: "允许",

  decline_btn: "下降",

  cookie_policy: "曲奇饼 政策",

  /* What is civy page */

  civy_intro_title1: "平台功能",

  civy_intro1_point1: "城市和政府通过专用URL（例如{link}）访问平台。",

  civy_intro1_point2:
    "客户可以选择将我们的平台链接到其社交媒体页面（LinkedIn，Facebook和Twitter），以实现更广泛的深度和影响力，以增加社区参与度。",

  civy_intro1_point3: "平台包括多种语言。",

  civy_intro1_point4:
    "数据存储在Civy的云服务中，并且根据美国数据和隐私法规通过半年一次的网络审核维护数据安全和隐私。",

  civy_intro1_point5:
    "Civy不与第三方广告商合作，因此客户和城市都可以保持信心，他们不会收到任何未经请求的电子邮件或促销信息。",

  civy_intro1_point6:
    "该平台可在全球任何地方移动访问；以及所有注册用户的电子邮件和短信通知。",

  civy_intro1_point7: "QR Code扫描项目（由居民扫描以提供快速响应）。",

  civy_intro1_point8:
    "对于所有项目或选定的项目，访问与其城市有关的可下载报告。",

  civy_intro2_title: "组织注册的原因",

  civy_intro2_point1: "创建带有城市自己徽标的管理员帐户，并最多添加20位同事。",

  civy_intro2_point2: "客户创建的数据和居民反馈归客户所有。",

  civy_intro2_point3:
    "居民的反馈意见直接反馈给项目创建者；例如，由“公共工程”创建的项目的居民反馈将直接反馈给“公共工程”。",

  civy_intro2_point4:
    "可以使用单个或多个邮政编码创建项目。县级到当地城镇级；并通过Civy和您链接的社交媒体渠道共享。这样可以确保居民的反馈直接链接到居民的邮政编码（纽约市项目发布的项目只能由纽约居民响应）。",

  civy_intro2_point5: "能够下载报告。",

  civy_intro3_title: "居民注册的原因",

  civy_intro3_point1:
    "为了确保您的个人资料链接到您居住的邮政编码，您将能够回答所在城市发布的问题。",

  civy_intro3_point2:
    "确保您直接提供您的回复。 Civy不会从您的任何其他社交媒体帐户链接，收集或汇总数据。 Civy仅记录您在Civy平台上提供的回复，并且只有城市官员使用该平台才能查看这些回复。",

  civy_intro3_point3: "您的回复或评论对其他居民不可见。",

  civy_intro3_point4:
    "您不必以“匿名”身份在Civy上注册，因为您的回复是机密的，只有负责该项目的市政官员才能看到。",

  what_is_civy_policies_title: "政策规定",

  what_is_civy_policies_content: "隐私和数据政策",

  //QR code Management page

  qr_code_svg: "生成为SVG",

  qr_code_png: "生成为PNG",

  qr_code_jpeg: "生成为JPEG",

  qr_code: "二维码",
};
