import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  FORGOT_SUCCESS,
  FORGOT_ERROR,
  FORGOT_REQUEST,
  GET_RESET,
  SET_RESET_SUCCESS,
  SET_RESET_REQUEST,
  SET_RESET_ERROR
} from "./action";
const initialState = {
  items: [],
  forgot: null,
  loading: false,
  getReset: null,
  setReset: null,
  loggedIn: false
};
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        items: action.payload
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case FORGOT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FORGOT_SUCCESS:
      return {
        ...state,
        loading: false,
        forgot: action.payload
      };
    case FORGOT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_RESET:
      return {
        ...state,
        getReset: action.payload
      };
    case SET_RESET_SUCCESS:
      return {
        ...state,
        loading:false,
        setReset: action.payload
      };
    case SET_RESET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case SET_RESET_REQUEST:
      return {
        ...state,
        loading:true
      };

    default:
      return state;
  }
}
