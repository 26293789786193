export const define_en = {
  //landing page casestudies component

  landing_caseStudies1:
    "Civy beta-tested a street beautification program in Glendale, LA. Based on the comments the city received about, it was confirmed that residents weren’t fully consulted about the project until the project started and became visible. Civy aims to eliminate this scenario from all current and upcoming projects.",

  landing_caseStudies2:
    "A large government infrastructure agency plans to use Civy to conduct their next external outreach program. The agency will ask residents they serve, questions about new projects they are proposing. At the same time, they will also use Civy to keep in touch with individuals who are reporting issues for all current infrastructure projects underway. They felt that an independent mobile-enabled platform will improve the engagement and the fact that Civy works simplistically, with various language options relates to a majority of the people.",

  landing_caseStudies3:
    "A recent scenario to offer you a better perspective, imagine you are city official or government agency or an interested resident. You are based in Los Angeles. You can type the name of your city in the Dashboard of Civy, or any other city across the world (let’s say Sydney, Australia). You will be able to see what Sydney is doing, you can apply filters by project category. It will foster global education and learnings.",

  landing_caseStudies4:
    "As a resident of Newport Beach in California, I noticed a lack of outdoor fitness stations. These are very common in many other countries where sun, ocean, and greenery are in abundance. Using my smartphone, I created a new project in Civy (titled Outdoor Fitness Station), marked the location, added some example pictures, added a brief description of the project case, and shared it with my local city department and Civy network. The aim is to have residents endorse the idea or provide comments or other suggestions; and in the meantime, the city department is receiving this residents’ feedback. This gives them the impetus to act and bring it up with the people at their next community outreach, get people’s endorsement and build it.",

  landing_caseStudies_content:
    "Some practical examples of how Civy can be beneficial in your average day to day.",

  landing_caseStudies_title: "CASE STUDIES",

  //landing page statistics component

  landing_statistics_title: "STATISTIC",

  landing_statistics_content:
    "To completely bring forth sustainable development we must make progress equitable and broad-based and enable people to become active participants in that process.",

  landing_statistics48:
    "of adults directly take part in a civic group or activity",

  landing_statistics68:
    "of 18 to 34 year old residents reported that they felt disconnected from government.",

  //landing page testimonial component

  landing_testimonial_title: "Testimonials",

  landing_testimonial_content: "Read testimonials of our latest clients.",

  landing_testimonial1:
    "I wish I had had this when we worked on projects that required state-wide and local stakeholder outreach. This would have taken some stress off and helped us effectively reach out to communities. ",

  landing_testimonial1_author: "Alana",

  landing_testimonial1_postname:
    "- Former Outreach and Communications Director, State Utilities Authority",

  landing_testimonial2:
    "Civy can tackle three roles in the engagement process at once - stream lining your work.",

  landing_testimonial2_author: "Eric",

  landing_testimonial2_postname:
    "- Legislative Aide to Freeholder Township, New Jersey",

  landing_testimonial3:
    "Civy is one of the first exciting developments in a long time for government software and how municipalities interact with the public. Freeholder is a major municipality ",

  landing_testimonial3_author: "Scott",

  landing_testimonial3_postname: "- Chief of Staff, State Assemblyman",

  landing_testimonial4:
    "Civy’s online engagement platform is easy-to-use and designed with cities, governments and residents in mind. It is addressing major pain-points we experience as cities and elected-officials, and makes it easier for our residents to engage with us in a safe & secure environment. The platform complements and enhances our existing outreach strategies, and designed to increase particpation rates and equity.",

  landing_testimonial4_author: "Theresa Branella",

  landing_testimonial4_postname: "-Mayor, Brooklawn, New Jersey",

  //landing page services component

  landing_servicesA_header: "Wider public outreach for projects.",

  landing_servicesA_content:
    "A single platform that provides the widest reach, multiple languages and demographic depth for civic projects.",

  landing_servicesB_header: "Privacy and Data Security.",

  landing_servicesB_content:
    "Our platform includes additional privacy measures to protect resident responses, which are only visible to city and government officials.",

  landing_servicesC_header: "Efficient & convenient.",

  landing_servicesC_content:
    "Feedback on Civy for a project is as easy as sending a text message.",

  landing_servicesD_header: "Comprehensive analysis.",

  landing_servicesD_content:
    "Analyze resident feedback across different demographics and apply analytics for faster decision-making, approvals, and future project planning.",

  landing_service_title: "SERVICES",

  landing_service_content:
    "Civy helps cities and governments improve civic engagement.",

  landing_ServiceSection_maincontent:
    "Civy displays projects by governments and cities listed by state, city, zip code; and ask questions that residents can answer.",

  //landing page project component

  landing_project_title: "Projects",

  landing_project_content: "A list of our current projects in various cities.",

  landing_project_short_description: "Project Short Description",

  landing_project_startdate: "Start Date ",

  landing_project_enddate: "End Date ",

  landing_project_description_link: "View More",

  //landing page benefit component

  landing_benefit1_title: "Mobile Platform",

  landing_benefit1_content:
    "Civy is a convenient mobile platform easily accessible from anywhere.",

  landing_benefit2_title: "Free For Residents",

  landing_benefit2_content:
    "Civy is free for residents to register, answer questions and surveys, and help cities and governments improve their project planning and delivery.",

  landing_benefit3_title: "Social Media Linkages",

  landing_benefit3_content:
    "Civy links with social media platforms to increase participation rates and demographic depth.",

  landing_benefit4_title: "Safe & Secure",

  landing_benefit4_content:
    "Resident responses are confidential and Civy does not share resident data with 3rd parties.",

  landing_benefit_title: "BENEFITS",

  landing_benefit_content:
    "Civy provides multiple features to leverage widespread public engagement and participation that contribute to improved governance and development outcomes. ",

  //landing page about component

  landing_about_title: "About CIVY",

  landing_about_content:
    "We help cities and governments improve stakeholder engagement and public outreach across civic projects.",

  landing_about_maincontent:
    "Civy is an easy and secure way for residents to participate and share feedback on current and upcoming public projects by cities and governments. A dedicated online platform that reaches a wider audience. Stakeholders from all demographics engage and provide feedback to their cities and government agencies, with convenience and efficiency. ",

  landing_readmore: "Read more",

  //landing page contact us component

  landing_contactus_address_title: "Address",

  landing_contactus_address: "Civy Inc.",

  landing_contactus_address_post: "160 S Cypress St, Orange, CA, 92866 USA",

  contactus_address_street: "160 S Cypress St,",

  contactus_address_code: "Orange, CA, 92866",

  contactus_address_use: "USA",

  landing_contactus_address_tel: "Tel:",

  landing_contactus_usertype1: "Resident",

  landing_contactus_usertype2: "City",

  landing_contactus_usertype3: "Government Agency",

  landing_contactus_usertype4: "University",

  landing_contactus_usertype5: "Other",

  landing_contactus_title: "GET IN TOUCH",

  landing_contactus_name: "Name*",

  landing_contactus_email: "Email*",

  landing_contactus_select: "Are You*",

  landing_contactus_feedback: "Type of Feedback*",

  landing_contactus_feedback_good: "Good",

  landing_contactus_feedback_bad: "Bad",

  landing_contactus_feedback_other: "Other",

  landing_contactus_feedback_message: "Your message*",

  landing_contactus_send_button: "Send Message",

  landing_contactus_name_error: "*Name is required",

  landing_contactus_email_error: "*Email is required",

  landing_contactus_email_error2: "*Please Enter Valid Email address",

  landing_contactus_select_error: "*Please select one option",

  landing_contactus_message_error: "*Message is required",

  landing_contactus_capcha_error:
    "*Please validate captcha checkbox I'm not a robot",

  // Self check KIOSK page
  kiosk_intro1_title: "Better Way to Fight.",

  kiosk_intro1_content: "Temperature | SpO2 | Sanitizer | UV-C",

  kiosk_intro1_point1:
    'Current catastrophic situation made us realize the importance of hygiene for safe environment.To rejuvenate the idea of hygiene AKCMed presents a smart detoxifying unit "SELFCHECK" for fighting the global pandemic.',

  kiosk_intro1_point2:
    'Touchless "SELFCHECK" automatically measures body temperature in seconds."SELFCHECK" is invaluable solution for quick detection of illnesses and reduce the spread of bacteria & viruses, it vets to premises such as Schools, Malls, Restaurants, Factories, Railway stations, Airports and Corporate offices.',

  kiosk_intro1_point3:
    "Kiosk fetures a touch-less UV-C Box to disinfect bag, cell phone and keys in less than 10 Seconds. UV-C Box kills 99% Viruses and Bacteria within 10 seconds on exposed surface.",

  kiosk_intro2_title: "Purpose",

  kiosk_intro2_content: "Early recognition and management of COVID-19",

  kiosk_intro2_point1:
    "Detects Fever : High Fever indicates syndromes associated with COVID-19",

  kiosk_intro2_point2:
    "Checks Oxygen levels (SpO2) : Oxygen level below 90% indicates syndromes associated with COVID-19",

  kiosk_intro2_point3:
    "Touch-less Hand Sanitizer : Decreases the risk of getting risk and spread of Virus",

  kiosk_intro2_point4:
    "Disinfection Chamber for Bag, Mobile, Keys etc. by UV-C : UV-C kills 99% Virus in 10 seconds thus reducing the spread",

  kiosk_intro2_point5:
    "The Solution displays and records all data at a single location",

  kiosk_intro3_title: "Use Areas Features",

  kiosk_intro3_point1: "Touch-less fully automated kiosk",

  kiosk_intro3_point2:
    "Detects body temperature of user standing in front of device",

  kiosk_intro3_point3: "Temperature detection in 1 second",

  kiosk_intro3_point4: "Blood Oxygen & Heartbeat measurement",

  kiosk_intro3_point5: "Set your own acceptable temperature thresholds",

  kiosk_intro3_point6:
    "Verbal Warning / Success message plus LED traffic light system",

  kiosk_intro3_point7: "User-friendly Verbal Instructions",

  kiosk_intro3_point8: "UV-C Box kills 90% Virus / Bacteria",

  kiosk_intro3_point9: "Compact design Ultra-slim footprint",

  kiosk_intro3_point10: "Built-in PC to support the application needs",

  kiosk_intro4_title: "How it works",

  kiosk_intro4_point1: "Open UV-C box door by waving hands in front of it.",

  kiosk_intro4_point2:
    "Place your luggage and cell phone at the specified location in UV-C Box",

  kiosk_intro4_point3: "UV-C cleaning will start & stop automatically.",

  kiosk_intro4_point4:
    "A BEEP sound at the end of process indicates bag can be removed",

  kiosk_intro4_point5: "Remove your luggage and cell phone from UV-C Box",

  kiosk_intro4_point6:
    "Stand on the marked location facing towards the monitor screen & follow verbal instructions",

  kiosk_intro4_point7:
    "Align face in the grid as displayed on the screen and ensure that Red dot points at your Forehead",

  kiosk_intro4_point8:
    "If Green indication : Proceed to next step i.e. Pulse Oximete",

  kiosk_intro4_point9:
    "If Red indication with buzzer indicates abnormal body parameters : Step aside for further investigation",

  kiosk_intro4_point10:
    "Put your finger on the Pulse Oximeter & wait till Blood Oxygen-content & heartbeat is displayed on the screen ",

  kiosk_intro4_point11:
    "If Green indication : Proceed to next step i.e Sanitize your hands",

  kiosk_intro4_point12:
    "If Red indication with buzzer indicates abnormal body parameters : Step aside for further investigation",

  kiosk_intro4_point13: "Disinfect your hand in contact less hand sanitizer",

  kiosk_intro4_point14: "Good to Go",

  kiosk_intro5_title: "Frequently Asked Question ?",

  kiosk_intro5_point1:
    "Q1. Does it measure temperature in Celsius or Fahrenheit? ",

  kiosk_intro5_point2:
    "Q2. Can we use this equipment in Indoor and Outdoor both sides?",

  kiosk_intro5_point3:
    "Q3. How much time does it take to complete the procedure?",

  kiosk_intro5_point4: "Q4. Is it electrically operated?",

  kiosk_intro5_point5:
    "Q5. How accurate is the data displayed? Can this data be used for critical medical assessment?",

  kiosk_intro5_point6:
    "Q6. Is this unit self-supported or does it require grouting?",

  kiosk_intro5_point7: "Q7. Is this unit maintenance free?",

  kiosk_intro5_point:
    "Q8. Can the height be adjusted as per anthropometric data of the region?",

  kiosk_intro5_point9: "Q9. Is it available in different finishes?",

  kiosk_intro5_point10: "Q10. Is it touch free?",

  kiosk_intro5_point11:
    "Q11. Who will do the assembly & commissioning of this product?",

  select_survey_visibility: "Select Survey Visibility",

  select_survey_visibility_private: "Private",

  select_survey_visibility_public: "Public",

  // services page

  services_casestudy1_title: "CITIES & GOVERNMENTS",

  services_casestudy1_content:
    "An easy to use secure platform to engage the residents and accelerate smarter development for civic projects.",

  services_casestudy2_title: "RESIDENTS",

  services_casestudy2_content:
    "A mobile platform to receive notifications and share feedback with your cities to guide the design and development of civic projects.",

  services_casestudy_title: "CASE STUDY",

  services_casestudy_content:
    "How Cities & Governments connect with Residencts via Civy",

  services_integratedservices_title: "Integrated Services:",

  services_introservice1_title: "WHAT MAKES CIVY DIFFERENT",

  services_introservice1_point1:
    "Enables cities and governments to create their own data, aggregate data and control their own narrative.",

  services_introservice1_point2:
    "Increase and improve public outreach by linking with other social media channels such as Facebook, Twitter, and LinkedIn.",

  services_introservice1_point3:
    "Complete confidentiality of resident responses, which are only visible to city or governments subscribing to CIvy. Data is owned by cities or governments and Civy does not engage in third-party sales.",

  services_introservice1_point4:
    "Download and store project data on your own servers. To comply with privacy regulation, Civy removes data 3-months after the project is marked completed by the clients.",

  services_introservice1_point5:
    "Option to white-label Civy as your own platform with your unique branding.  ",

  services_introservice1_point6:
    "Civy also offers additional services such as 3D Holographic and GIS Mapping with applications in planning, designing, and marketing project and city developments.",

  services_introservice2_title:
    "Product Functionality for Cities & Governments.",

  services_introservice2_point1:
    "Cities and governments create an account on Civy platform and link their own social media channels.",

  services_introservice2_point2:
    "Add multiple users to the account with defined user roles such as Director of Public Works.",

  services_introservice2_point3:
    "Create projects, upload descriptions, and pictures pertaining to the vision of the project.",

  services_introservice2_point4:
    "Share across different channels and ask questions for residents to answer.",

  services_introservice2_point5:
    "Gather and analyze responses, capture more demographics in the community supported by our multiple language capabilities.",

  services_introservice2_point6:
    "Utilize analytics for faster decision-making, execution and outcome.",

  services_introservice1_content:
    "Civy is an engagement platform for cities, governments & residents, and enables you to create, aggregate, and store your data.",

  services_introservice2_content:
    "Civy provides an easy-to-use platform to foster civic engagement.",

  // about page

  about_history1_title: "Ideation",

  about_history1_content:
    "After executing multiple projects focused on infrastructure, energy and technology across the globe we realized that governments, cities officials and communities, despite their best intentions, always complained of lack of consultation; whereas private interests such as developers worry that their commercial objectives will be negatively impacted if they engage with communities too much. Communities on the other hand felt that they were provided sugar-coated business plans and project investment proposals, but they are not consulted in the design phase or often treated as non-experts who do not know what they are talking about. It is a common occurrence for residents to feel unheard during civic development projects in their communities.",

  about_history2_title: "Initiation",

  about_history2_content:
    "To resolve this, we visualized a common platform where project ideas are sourced from city and government officials, and directly presented to residents and stakeholders for feedback. The former can then engage, analyze and narrow down the best course of action based on feedback from stakeholders (residents). The government and city authorities could explain directly to the people what amongst those can be accomplished and what cannot, listing the potentials as well as the complications that might be faced in the short term, elaborate the financials involved in the development and upon proper consultation from all collaborators act and implement the projects swiftly.",

  about_history3_title: "Formulation",

  about_history3_content:
    "With clear purpose & resolve, we created the concept platform focusing on addressing the pain-points during our discovery phase with cities and governments (mayors, councilpersons, legislative assemblypersons, city managers, department directors, marketing teams, technology teams and community groups). The concept platform combining information analytics tool with social media aspects to provide widespread outreach and engage with the residents for all civic project developments, has obtained client validation. The key goals were to reduce the time, cost and resources that are at disposal for a civic project and increase public outreach and increased inclusivity for all stakeholders whilst maintaining data privacy and security. ",

  about_history4_title: "Implementation",

  about_history4_content:
    "Starting in 2019, we implemented Civy, a platform to help cities and governments improve public outreach across civic projects. The platform solicits feedback by engaging residents from millennials to seniors of all demographics, meanwhile reducing the need for using outdated & legacy methods such as old-fashioned townhalls. The platform provides unprecedented convenience to learn, engage and provide honest feedback to stakeholders for all civic projects. The platform’s embedded one-click share across multiple social media channels, cybersecurity, and cloud hosting solutions also provide data privacy, back-office productivity gains and enables cities and governments to focus on strategic thinking.",

  about_history_title: "History of civy",

  about_history_since: "since",

  about_vision_title: "Our Vision",

  about_vision_content:
    "To create an engagement loop which captures cities, governments, residents, and stakeholders, and build a smarter planet.",

  about_mission_title: "Our Mission",

  about_mission_content1:
    "Civy helps cities and governments improve public outreach and stakeholder engagement across civic projects by understanding their pain points, understanding what can be improved and help facilitate a better discussion using our proprietary platform. Our philosophy to accomplish this is a ‘back-to-basics’ approach of asking people, listening to their feedback, and help our clients (cities and governments) act on this feedback.",

  about_interview_title: "interview",

  about_interview_content:
    "Amar Flora, Founder of Civy was interviewed on live TV to discuss Civy and its goal of helping cities and governments",

  about_interview_maincontent:
    "Watch Civy founder Amar Flora explain on live television how Civy enables decision-makers to use the data from users to make faster decisions, act on intel, and simply connect in ‘blue-sky’ thinking with their residents; all in real-time, unfiltered and on an independent platform.",

  about_ourteam_title: "OUR TEAM",

  about_ourteam_content:
    "We are a group of people passionate about GovTech, Smart Cities and stakeholder engagement.",

  about_ourteam_person1_name: "Amar Flora (MBA, GAICD, GCCM, CPA)",

  about_ourteam_person1_title: "Co-Founder and CEO",

  about_ourteam_person1_content:
    "A visionary leader and an entrepreneur with a passion for higher education, smart cities, and government operations. Amar has co-founded two privately-owned startups, building global teams and collaborators, served on boards, and led the establishment and operations of a University startup seeded with private funding of $20M. Amar has sold services and consulted with clients across various industries, delivering meaningful impact and value creation. Amar possesses a keen ability to identify strategic & operational opportunities, and drive innovation. Amar holds an MBA, post-graduates in Change Management and  Corporate Governance, and CPA certification from top  institutions in the US and Australia.",

  about_ourteam_person2_name: "Sabrina Uppal (MS, PMP)",

  about_ourteam_person2_title: "Co-Founder and COO",

  about_ourteam_person2_content:
    "Sabrina joins Civy with quality experiences, and successfully executing multiple startup and research projects across private and government sectors. Her creative flair coupled with innovative mindset, enables her to design and deliver value for customers. Additionally, Sabrina possesses extensive experience in managing government contracts for organizations contracted by US Department of Defense. Sabrina has firsthand experience of trying to engage her city or a government agency to achieve an outcome. These experiences drive her passion and creativity to help cities and governments foster high-impact engagement with residents. Sabrina holds Bachelor of Science, Neuroscience from U.C. Davis; Master of Science, Biology from Cal State Northridge and is PMP Certified.",

  about_ourteam_person3_name: "Jinhong Zhang (CPA, MBA, CGMA)",

  about_ourteam_person3_title: "CFO",

  about_ourteam_person3_content:
    "Jinhong Zhang has over 20 years of experience in business and financial planning and management, servingleadership roles as president CFO, Finance Director, and consultant in large to entrepreneurial organizations. Jinhong started his career with a Fortune Global 500 company, where he managed projects financed by World Bank and Asia Development each and oversaw auditing and tax engagement with PWC and KPMG at the client side.",

  about_ourteam_person4_name: "Suketu Modi",

  about_ourteam_person4_title: "CTO",

  about_ourteam_person4_content: `Recognized among 100 Great People Managers of India 2019 by Forbes India, and awarded Best Infrastructure Designing and Smart e-Governance for Cities, Suketu is a seasoned business leader & technocrat with a vast experience in ICT consulting for top multinational consulting firms, governments, and PSUs who serves as the CTO for Civy. Suketu holds 19+ years of ICT consulting and Implementation experience of ICT Infrastructure Networks Infrastructure and Smart Services designing for large scale City/Campus and Smart Building Services. Suketu has worked with clients and customers across the US, Middle East, Singapore, and India.`,

  about_ourteam_description1:
    "We are passionate about helping cities and governments foster innovation for betterment of residents.",

  about_ourteam_description2:
    "An exclusive partner to Civy, Infixzon is a Consulting, Cloud and Cyber Security services company led by industry veterans with multiple decades of experience. Infixzon serves across USA, APAC, and Mid-East regions, designing solutions for verticals like Public Sector, Healthcare, Financial Services, Pharmaceuticals and Manufacturing.",

  about_ourteam_description3:
    "Twish Technologies is a team of professionals with years of leadership in infrastructure policies, project feasibility studies, integration & implementation strategy for city level infrastructures, smart communities and smart cities. Their team includes an Organizational Psychologist focusing on community engagement on behalf of cities and governments.",

  //Terms and conditions page

  ourpolicies_term_of_use_title: "TERMS OF USE",

  ourpolicies_terms_title: "Terms And Conditions",

  ourpolicies_terms_content1:
    'Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the https://www.civy.co website and the Civy mobile application (together, or individually, the "Service") operated by Civy Inc ("us", "we", or "our").',

  ourpolicies_terms_content2:
    "Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who wish to access or use the Service.",

  ourpolicies_terms_content3:
    "By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you do not have permission to access the Service.",

  ourpolicies_terms_Communications_title: "Communications",

  ourpolicies_terms_Communications_content1:
    "By creating an Account on our service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.",

  ourpolicies_terms_subscription_title: "Subscriptions",

  ourpolicies_terms_subscription_content1:
    'Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.',

  ourpolicies_terms_subscription_content2:
    "At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or Civy Inc cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting Civy Inc customer support team.",

  ourpolicies_terms_subscription_content3:
    "A valid payment method, including credit card or PayPal, is required to process the payment for your Subscription. You shall provide Civy Inc with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize Civy Inc to charge all Subscription fees incurred through your account to any such payment instruments.",

  ourpolicies_terms_subscription_content4:
    "Should automatic billing fail to occur for any reason, Civy Inc will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.",

  ourpolicies_terms_feechanges_title: "Fee Changes",

  ourpolicies_terms_feechanges_content1:
    "Civy Inc, in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.",

  ourpolicies_terms_feechanges_content2:
    "Civy Inc will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.",

  ourpolicies_terms_feechanges_content3:
    "Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.",

  ourpolicies_terms_refund_title: "Refunds",

  ourpolicies_terms_refund_content:
    "Except when required by law, paid Subscription fees are non-refundable.",

  ourpolicies_terms_content_title: "Content",

  ourpolicies_terms_content_description1:
    'Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the Content that you post on or through the Service, including its legality, reliability, and appropriateness.',

  ourpolicies_terms_content_description2:
    "By posting Content on or through the Service, You represent and warrant that: (i) the Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.",

  ourpolicies_terms_content_description3:
    "You retain any and all of your rights to any Content you submit, post or display on or through the Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through the Service. However, by posting Content using the Service you grant us the right and license to use, modify, perform, display, reproduce, and distribute such Content on and through the Service.",

  ourpolicies_terms_content_description4:
    "Civy Inc has the right but not the obligation to monitor and edit all Content provided by users.",

  ourpolicies_terms_content_description5:
    "In addition, Content found on or through this Service are the property of Civy Inc or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.",

  ourpolicies_terms_account_title: "Accounts",

  ourpolicies_terms_account_content1:
    "When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service.",

  ourpolicies_terms_account_content2:
    "You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.",

  ourpolicies_terms_account_content3:
    "You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.",

  ourpolicies_terms_Intellectual_title: "Intellectual Property",

  ourpolicies_terms_Intellectual_content1:
    "The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Civy Inc and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Civy Inc.",

  ourpolicies_terms_links_title: "Links To Other Web Sites",

  ourpolicies_terms_links_content1:
    "Our Service may contain links to third party web sites or services that are not owned or controlled by Civy Inc",

  ourpolicies_terms_links_content2:
    "Civy Inc has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.",

  ourpolicies_terms_links_content3:
    "You acknowledge and agree that Civy Inc shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third party web sites or services.",

  ourpolicies_terms_links_content4:
    "We strongly advise you to read the terms and conditions and privacy policies of any third party web sites or services that you visit.",

  ourpolicies_terms_termination_title: "Termination",

  ourpolicies_terms_termination_content1:
    "We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.",

  ourpolicies_terms_termination_content2:
    "If you wish to terminate your account, you may simply discontinue using the Service.",

  ourpolicies_terms_termination_content3:
    "All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.",

  ourpolicies_terms_Indemnification_title: "Indemnification",

  ourpolicies_terms_Indemnification_content1:
    "You agree to defend, indemnify and hold harmless Civy Inc and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password; b) a breach of these Terms, or c) Content posted on the Service.",

  ourpolicies_terms_limitation_title: "Limitation Of Liability",

  ourpolicies_terms_limitation_content1:
    "In no event shall Civy Inc, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from",

  ourpolicies_terms_limitation_list1:
    "Your access to or use of or inability to access or use the Service",

  ourpolicies_terms_limitation_list2:
    "Any conduct or content of any third party on the Service",

  ourpolicies_terms_limitation_list3: "Any content obtained from the Service",

  ourpolicies_terms_limitation_list4:
    "Unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.",

  ourpolicies_terms_disclaimer_title: "Disclaimer",

  ourpolicies_terms_disclaimer_content1:
    'Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.',

  ourpolicies_terms_disclaimer_content2:
    "Civy Inc its subsidiaries, affiliates, and its licensors do not warrant that",

  ourpolicies_terms_disclaimer_list1:
    "The Service will function uninterrupted, secure or available at any particular time or location",

  ourpolicies_terms_disclaimer_list2: "Any errors or defects will be corrected",

  ourpolicies_terms_disclaimer_list3:
    "The Service is free of viruses or other harmful components",

  ourpolicies_terms_disclaimer_list4:
    "The results of using the Service will meet your requirements.",

  ourpolicies_terms_exclusion_title: "Exclusions",

  ourpolicies_terms_exclusion_content1:
    "Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.",

  ourpolicies_terms_governing_title: "Governing Law",

  ourpolicies_terms_governing_content1:
    "These Terms shall be governed and construed in accordance with the laws of California, United States, without regard to its conflict of law provisions.",

  ourpolicies_terms_governing_content2:
    "Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.",

  ourpolicies_terms_changes_title: "Changes",

  ourpolicies_terms_changes_content1:
    "We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.",

  ourpolicies_terms_changes_content2:
    "By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.",

  ourpolicies_terms_contactus_title: "Contact Us",

  ourpolicies_terms_contactus_content:
    "If you have any questions about these Terms, please contact us at",

  //Privacy policies page

  ourpolicies_policy_title: "Privacy Policy",

  ourpolicies_policy_date: "Effective date: {month} {day}, {year}",

  ourpolicies_policy_content1: `Civy Inc. ("us", "we", or "our") operates the https://www.civy.co
      website and the Civy mobile application (hereinafter referred to as the
      "Service").`,

  ourpolicies_policy_content2:
    " This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.",

  ourpolicies_policy_content3:
    " We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwis defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.",

  ourpolicies_definitions_title: "Definitions",

  ourpolicies_service_title: "Service",

  ourpolicies_service_content: `Service means the https://www.civy.co website and the Civy mobile
    application operated by Civy Inc.`,

  ourpolicies_personaldata_title: "Personal Data",

  ourpolicies_personaldata_content:
    "Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).",

  ourpolicies_usagedata_title: "Usage Data",

  ourpolicies_usagedata_content:
    "Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).",

  ourpolicies_cookie_title: "Cookies",

  ourpolicies_cookie_content:
    "Cookies are small files stored on your device (computer or mobile device).",

  ourpolicies_datacontroller_title: "Data Controller",

  ourpolicies_datacontroller_content1:
    "Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed.",

  ourpolicies_datacontroller_content2:
    "For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.",

  ourpolicies_dataprocessor_title: "Data Processors (or Service Providers)",

  ourpolicies_dataprocessor_content1:
    "Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller.",

  ourpolicies_dataprocessor_content2:
    "We may use the services of various Service Providers in order to process your data more effectively.",

  ourpolicies_datasubject_title: "Data Subject (or User)",

  ourpolicies_datasubject_content:
    "Data Subject is any living individual who is using our Service and is the subject of Personal Data.",

  ourpolicies_information_title: "Information Collection and Use",

  ourpolicies_information_content:
    "We collect several different types of information for various purposes to provide and improve our Service to you.",

  ourpolicies_typeofdata_title: "Types of Data Collected",

  ourpolicies_typeofdata_personal_title: "Personal Data",

  ourpolicies_typeofdata_personal_content1: `While using our Service, we may ask you to provide us with certain
      personally identifiable information that can be used to contact or
      identify you ("Personal Data"). Personally identifiable information may
      include, but is not limited to:`,

  ourpolicies_typeofdata_personal_list1: `Email address`,

  ourpolicies_typeofdata_personal_list2: `First name and last name`,

  ourpolicies_typeofdata_personal_list3: `Phone number`,

  ourpolicies_typeofdata_personal_list4: `Address, State, Province, ZIP/Postal code, City`,

  ourpolicies_typeofdata_personal_list5: `Cookies and Usage Data`,

  ourpolicies_typeofdata_personal_content2: `We may use your Personal Data to contact you with newsletters, marketing
      or promotional materials and other information that may be of interest
      to you. You may opt out of receiving any, or all, of these
      communications from us by following the unsubscribe link or the
      instructions provided in any email we send.`,

  ourpolicies_typeofdata_usage_title: `Usage Data`,

  ourpolicies_typeofdata_usage_content1: `We may also collect information that your browser sends whenever you
      visit our Service or when you access the Service by or through a mobile
      device ("Usage Data").`,

  ourpolicies_typeofdata_usage_content2: `This Usage Data may include information such as your computer's Internet
      Protocol address (e.g. IP address), browser type, browser version, the
      pages of our Service that you visit, the time and date of your visit,
      the time spent on those pages, unique device identifiers and other
      diagnostic data.`,

  ourpolicies_typeofdata_usage_content3: `When you access the Service with a mobile device, this Usage Data may
      include information such as the type of mobile device you use, your
      mobile device unique ID, the IP address of your mobile device, your
      mobile operating system, the type of mobile Internet browser you use,
      unique device identifiers and other diagnostic data.`,

  ourpolicies_typeofdata_location_title: `Location Data`,

  ourpolicies_typeofdata_location_content1: `We may use and store information about your location if you give us
      permission to do so ("Location Data"). We use this data to provide
      features of our Service, to improve and customise our Service.`,

  ourpolicies_typeofdata_location_content2: `You can enable or disable location services when you use our Service at
      any time by way of your device settings.`,

  ourpolicies_typeofdata_tracking_title: `Tracking Cookies Data`,

  ourpolicies_typeofdata_tracking_content1: `We use cookies and similar tracking technologies to track the activity
      on our Service and we hold certain information.`,

  ourpolicies_typeofdata_tracking_content2: `Cookies are files with a small amount of data which may include an
      anonymous unique identifier. Cookies are sent to your browser from a
      website and stored on your device. Other tracking technologies are also
      used such as beacons, tags and scripts to collect and track information
      and to improve and analyse our Service.`,

  ourpolicies_typeofdata_tracking_content3: `You can instruct your browser to refuse all cookies or to indicate when
      a cookie is being sent. However, if you do not accept cookies, you may
      not be able to use some portions of our Service.`,

  ourpolicies_typeofdata_cookiesex_title: `Examples of Cookies we use:`,

  ourpolicies_typeofdata_cookiesex_session: "Session Cookies.",

  ourpolicies_typeofdata_cookiesex_session_content: `We use Session Cookies to operate our Service.`,

  ourpolicies_typeofdata_cookiesex_preference: "Preference Cookies.",

  ourpolicies_typeofdata_cookiesex_preference_content: `We use Preference Cookies to remember your preferences and
      various settings.`,

  ourpolicies_typeofdata_cookiesex_security: `Security Cookies.`,

  ourpolicies_typeofdata_cookiesex_security_content: `We use Security Cookies for security purposes.`,

  ourpolicies_typeofdata_use_title: `Use of Data`,

  ourpolicies_typeofdata_use_content: `Civy Inc. uses the collected data for various purposes:`,

  ourpolicies_typeofdata_use_list1: `To provide and maintain our Service`,

  ourpolicies_typeofdata_use_list2: `To notify you about changes to our Service`,

  ourpolicies_typeofdata_use_list3: `To allow you to participate in interactive features of our
      Service when you choose to do so`,

  ourpolicies_typeofdata_use_list4: `To provide customer support`,

  ourpolicies_typeofdata_use_list5: `To gather analysis or valuable information so that we can
      improve our Service`,

  ourpolicies_typeofdata_use_list8: `To monitor the usage of our Service`,

  ourpolicies_typeofdata_use_list6: `To detect, prevent and address technical issues`,

  ourpolicies_typeofdata_use_list7: `To provide you with news, special offers and general information
      about other goods, services and events which we offer that are
      similar to those that you have already purchased or enquired
      about unless you have opted not to receive such information`,

  ourpolicies_typeofdata_legalbasis_title: `Legal Basis for Processing Personal Data under the General Data
      Protection Regulation (GDPR)`,

  ourpolicies_typeofdata_legalbasis_content1: `If you are from the European Economic Area (EEA), Civy Inc. legal basis
      for collecting and using the personal information described in this
      Privacy Policy depends on the Personal Data we collect and the specific
      context in which we collect it.`,

  ourpolicies_typeofdata_legalbasis_content2: `Civy Inc. may process your Personal Data because:`,

  ourpolicies_typeofdata_legalbasis_list1: `We need to perform a contract with you`,

  ourpolicies_typeofdata_legalbasis_list2: `You have given us permission to do so`,

  ourpolicies_typeofdata_legalbasis_list3: `The processing is in our legitimate interests and it is not
      overridden by your rights`,

  ourpolicies_typeofdata_legalbasis_list4: `For payment processing purposes`,

  ourpolicies_typeofdata_legalbasis_list5: `To comply with the law`,

  ourpolicies_typeofdata_retention_title: `Retention of Data`,

  ourpolicies_typeofdata_retention_content1: `Civy Inc. will retain your Personal Data only for as long as is
  necessary for the purposes set out in this Privacy Policy. We will
  retain and use your Personal Data to the extent necessary to comply with
  our legal obligations (for example, if we are required to retain your
  data to comply with applicable laws), resolve disputes and enforce our
  legal agreements and policies.`,

  ourpolicies_typeofdata_retention_content2: `Civy Inc. will also retain Usage Data for internal analysis purposes.
  Usage Data is generally retained for a shorter period of time, except
  when this data is used to strengthen the security or to improve the
  functionality of our Service, or we are legally obligated to retain this
  data for longer periods.`,

  ourpolicies_typeofdata_transfer_title: `Transfer of Data`,

  ourpolicies_typeofdata_transfer_content1: `Your information, including Personal Data, may be transferred to - and
  maintained on - computers located outside of your state, province,
  country or other governmental jurisdiction where the data protection
  laws may differ from those of your jurisdiction.`,

  ourpolicies_typeofdata_transfer_content2: `If you are located outside United States and choose to provide
  information to us, please note that we transfer the data, including
  Personal Data, to United States and process it there.`,

  ourpolicies_typeofdata_transfer_content3: `Your consent to this Privacy Policy followed by your submission of such
  information represents your agreement to that transfer.`,

  ourpolicies_typeofdata_transfer_content4: `Civy Inc. will take all the steps reasonably necessary to ensure that
  your data is treated securely and in accordance with this Privacy Policy
  and no transfer of your Personal Data will take place to an organisation
  or a country unless there are adequate controls in place including the
  security of your data and other personal information.`,

  ourpolicies_disclosure_title: `Disclosure of Data`,

  ourpolicies_disclosure_law_title: `Disclosure for Law Enforcement `,

  ourpolicies_disclosure_law_content: `Under certain circumstances, Civy Inc. may be required to disclose your
  Personal Data if required to do so by law or in response to valid
  requests by public authorities (e.g. a court or a government agency).`,

  ourpolicies_disclosure_requirement_title: `Legal Requirements`,

  ourpolicies_disclosure_requirement_content: `Civy Inc. may disclose your Personal Data in the good faith belief that such action is necessary to:`,

  ourpolicies_disclosure_requirement_list1: `To comply with a legal obligation`,

  ourpolicies_disclosure_requirement_list2: `To protect and defend the rights or property of Civy Inc.`,

  ourpolicies_disclosure_requirement_list3: `To prevent or investigate possible wrongdoing in connection with
    the Service`,

  ourpolicies_disclosure_requirement_list4: `To protect the personal safety of users of the Service or the
    public`,

  ourpolicies_disclosure_requirement_list5: `To protect against legal liability`,

  ourpolicies_security_title: `Security of Data`,

  ourpolicies_security_content: `The security of your data is important to us but remember that no method
    of transmission over the Internet or method of electronic storage is
    100% secure. While we strive to use commercially acceptable means to
    protect your Personal Data, we cannot guarantee its absolute security.`,

  ourpolicies_security_policy: `Our Policy – General application across United States of America,
    including policies specific to the State of California.`,

  ourpolicies_security_track: `"Do Not Track" Signals under the California Online Protection Act
    (CalOPPA)`,

  ourpolicies_security_track_content1: `We do not support Do Not Track ("DNT"). Do Not Track is a preference you
    can set in your web browser to inform websites that you do not want to
    be tracked.`,

  ourpolicies_security_track_content2: `You can enable or disable Do Not Track by visiting the Preferences or
    Settings page of your web browser.`,

  ourpolicies_security_privacy_policy: `Our policy to comply with California Consumer Privacy Act (CCPA)`,

  ourpolicies_security_privacy_policy1: `Right to know:`,

  ourpolicies_security_privacy_policy1_content: `Residents have the right to request that we disclose what personal
    information is collected, used, shared or sold by us.`,

  ourpolicies_security_privacy_policy2: `Right to delete:`,

  ourpolicies_security_privacy_policy2_content: `Residents have the right to request that we, and our service providers,
    delete the personal information we have on you.`,

  ourpolicies_security_privacy_policy3: `Right to opt-out:`,

  ourpolicies_security_privacy_policy3_content: `Residents have the right to direct us to cease the sale of their
    personal information, in the event we “sell” personal information as
    that term is defined by the CCPA. Please note data collected on Civy’s
    platform is only accessible by Civy’s clients including government areas
    of United States (cities, municipals, councils, government agencies,
    special districts, and registered residents). Civy does not sell or does
    not intend to sell data to third-parties such as advertisers or private
    companies.`,

  ourpolicies_security_privacy_policy4: `Right to non-discrimination:`,

  ourpolicies_security_privacy_policy4_content: `We may not discriminate against residents in terms of price or service
    when they exercise their privacy rights under the CCPA.`,

  ourpolicies_security_protection_title: `Your Data Protection Rights under the General Data Protection Regulation
    (GDPR)`,

  ourpolicies_security_protection_content1: `If you are a resident of the European Economic Area (EEA), you have
    certain data protection rights. Civy Inc. aims to take reasonable steps
    to allow you to correct, amend, delete or limit the use of your Personal
    Data.`,

  ourpolicies_security_protection_content2: `If you wish to be informed about what Personal Data we hold about you
    and if you want it to be removed from our systems, please contact us.`,

  ourpolicies_security_protection_content3: `In certain circumstances, you have the following data protection rights:`,

  ourpolicies_security_access_title: `The right to access, update or delete the information we have on you.`,

  ourpolicies_security_access_content1: `Whenever made possible, you can access, update or request deletion of
    your Personal Data directly within your account settings section. If you
    are unable to perform these actions yourself, please contact us to
    assist you.`,

  ourpolicies_security_rectification_title: `The right of rectification.`,

  ourpolicies_security_rectification_content: `you have the right to have your information rectified if that
    information is inaccurate or incomplete.`,

  ourpolicies_security_object_title: `The right to object.`,

  ourpolicies_security_object_content: `You have the right to object to our processing of your Personal Data.`,

  ourpolicies_security_restriction_title: `The right of restriction.`,

  ourpolicies_security_restriction_content: `You have the right to request that we restrict the processing of your
    personal information.`,

  ourpolicies_security_portability_title: `The right to data portability.`,

  ourpolicies_security_portability_content: `You have the right to be provided with a copy of the information we have
    on you in a structured, machine-readable and commonly used format.`,

  ourpolicies_security_withdraw_title: `The right to withdraw consent.`,

  ourpolicies_security_withdraw_content: `You also have the right to withdraw your consent at any time where Civy
    Inc. relied on your consent to process your personal information.`,

  ourpolicies_security_protection_content4: `Please note that we may ask you to verify your identity before
    responding to such requests.`,

  ourpolicies_security_protection_content5: `You have the right to complain to a Data Protection Authority about our
    collection and use of your Personal Data. For more information, please
    contact your local data protection authority in the European Economic
    Area (EEA).`,

  ourpolicies_serviceprovider: `Service Providers`,

  ourpolicies_serviceprovider_content1: `We may employ third party companies and individuals to facilitate our
    Service ("Service Providers"), provide the Service on our behalf,
    perform Service-related services or assist us in analysing how our
    Service is used.`,

  ourpolicies_serviceprovider_content2: `These third parties have access to your Personal Data only to perform
    these tasks on our behalf and are obligated not to disclose or use it
    for any other purpose.`,

  ourpolicies_serviceprovider_analytics: `Analytics`,

  ourpolicies_serviceprovider_analytics_content: `We may use third-party Service Providers to monitor and analyse the use
    of our Service.`,

  ourpolicies_serviceprovider_ganalytics: `Google Analytics`,

  ourpolicies_serviceprovider_ganalytics_content1: `Google Analytics is a web analytics service offered by Google that
    tracks and reports website traffic. Google uses the data collected to
    track and monitor the use of our Service. This data is shared with other
    Google services. Google may use the collected data to contextualise and
    personalise the ads of its own advertising network.`,

  ourpolicies_serviceprovider_ganalytics_content2: `For more information on the privacy practices of Google, please
    visit the Google Privacy Terms web page:
    https://policies.google.com/privacy?hl=en`,

  ourpolicies_serviceprovider_firebase: `Firebase`,

  ourpolicies_serviceprovider_firebase_content1: `Firebase is an analytics service provided by Google Inc.`,

  ourpolicies_serviceprovider_firebase_content4: `You may opt-out of certain Firebase features through your mobile
    device settings, such as your device advertising settings or by
    following the instructions provided by Google in their Privacy Policy:
    https://policies.google.com/privacy?hl=en`,

  ourpolicies_serviceprovider_firebase_content2: `We also encourage you to review the Google's policy for safeguarding
    your data: https://support.google.com/analytics/answer/6004245.`,

  ourpolicies_serviceprovider_firebase_content3: `For more information on what type of information Firebase
    collects, please visit the Google Privacy Terms web page:
    https://policies.google.com/privacy?hl=en`,

  ourpolicies_serviceprovider_payments: `Payments`,

  ourpolicies_serviceprovider_payment_content1: `We may provide paid products and/or services within the Service. In that
    case, we use third-party services for payment processing (e.g. payment
    processors).`,

  ourpolicies_serviceprovider_payment_content2: `We will not store or collect your payment card details. That information
    is provided directly to our third-party payment processors whose use of
    your personal information is governed by their Privacy Policy. These
    payment processors adhere to the standards set by PCI-DSS as managed by
    the PCI Security Standards Council, which is a joint effort of brands
    like Visa, MasterCard, American Express and Discover. PCI-DSS
    requirements help ensure the secure handling of payment information.`,

  ourpolicies_serviceprovider_payment_content3: `The payment processors we work with are:`,

  ourpolicies_serviceprovider_stripe: `Stripe`,

  ourpolicies_serviceprovider_stripe_content: `Their Privacy Policy can be viewed at https://stripe.com/us/privacy`,

  ourpolicies_linksto_title: `Links to Other Sites`,

  ourpolicies_linksto_content1: `Our Service may contain links to other sites that are not operated by
    us. If you click a third party link, you will be directed to that third
    party's site. We strongly advise you to review the Privacy Policy of
    every site you visit.`,

  ourpolicies_linksto_content2: `We have no control over and assume no responsibility for the content,
    privacy policies or practices of any third party sites or services.`,

  ourpolicies_children_title: `Children's Privacy`,

  ourpolicies_children_content1: `Our Service does not address anyone under the age of 18 ("Children").`,

  ourpolicies_children_content2: `We do not knowingly collect personally identifiable information from
    anyone under the age of 18. If you are a parent or guardian and you are
    aware that your Child has provided us with Personal Data, please contact
    us. If we become aware that we have collected Personal Data from
    children without verification of parental consent, we take steps to
    remove that information from our servers.`,

  ourpolicies_changes_title: `Changes to This Privacy Policy`,

  ourpolicies_changes_content1: `We may update our Privacy Policy from time to time. We will notify you
    of any changes by posting the new Privacy Policy on this page.`,

  ourpolicies_changes_content2: `We will let you know via email and/or a prominent notice on our Service,
    prior to the change becoming effective and update the "effective date"
    at the top of this Privacy Policy.`,

  ourpolicies_changes_content3: `You are advised to review this Privacy Policy periodically for any
    changes. Changes to this Privacy Policy are effective when they are
    posted on this page.`,

  ourpolicies_contactus_title: `Contact Us`,

  ourpolicies_contactus_content1: `If you have any questions about this Privacy Policy, please contact us:`,

  ourpolicies_contactus_content2: `By email:`,

  //Registration page

  register_regi_title: "Register Account",

  register_organization_label: "Organization Name{star}",
  profile_name_label: "Name{star}",

  register_organization_title: "Edit Profile",

  register_organization_error: "*Organization Name is required",
  profile_name_error: "*Name is required",

  register_organization_type_label: "Organization Type{star}",
  profile_type_label: "Type{star}",

  register_organization_title_label: "Organization Title{star}",
  profile_title_label: "Title{star}",

  register_organization_title_error: "*Title is required",

  register_browse_label: "Browse...",
  register_browse_label_1: "Upload Logo...",

  register_upload_label: "Upload Logo",

  register_upload_error: "*Please Select Logo",

  register_are_you_label: "Are you a{star}",

  register_are_you_resident: "US Resident",

  register_are_you_citizen: "US Citizen",

  register_are_you_prefer: "Prefer not to answer",

  register_are_you_error: "*Please select Type",

  register_organization_type_error: "*Please Select Organization type",
  profile_type_error: "*Please Select type",

  register_your_title_label: "Title{star}",

  register_your_title_list1: "Mr.",

  register_your_title_list2: "Miss.",

  register_your_title_list3: "Mrs.",

  register_your_title_list4: "Dr.",

  register_your_title_list5: "Ms.",

  register_your_title_error: "*Please select Your Title",

  register_name_label: "First Name{star}",

  register_name_error1: "*First Name is required",

  register_name_error2: "There should be limit of 15-20 text allow.",

  register_last_name_label: "Last Name{star}",

  register_last_name_error1: "*Last Name is required",

  register_last_name_error2: "There should be limit of 15-20 text allow.",

  register_ethinicicty_label: "Ethnicity{star}",

  register_ethinicicty_error: "*Please select one Ethinicity",

  register_email_label: "Email{star}",

  register_email_error1: "*Email is required",

  register_email_error2: "Please Enter Valid Email address",

  register_mobile_label: "Mobile Number{star}",

  register_mobile_error1: "*Mobile Number is required",

  register_apartment_label: "Apartment/Suite{star}",

  register_apartment_error: "*Apartment/Suite is required",

  register_gender_label: "Gender{star}",

  register_gender_type1: "Male",

  register_gender_type2: "Female",

  register_gender_type3: "Other",

  register_gender_error: "*Please select Gender",

  register_dob_error: "*Date of Birth is required",

  register_age_error: "*Please select your Age range.",

  date_invalid_error: "Please Enter Valid Date.",

  register_birth_label: "Date of birth{star}",

  register_age_label: "Age range{star}",

  register_street_label: "Street Address{star}",

  register_street_error: "*Street Addess is required",

  register_country_label: "Country{star}",

  register_country_error: "*Please Select Country",

  register_state_label: "State{star}",

  register_state_error: "*Please Select State",

  register_city_label: "City{star}",

  register_county_label: "County{star}",

  register_city_error: "*Please Select City",

  register_zip_label: "Zip Code{star}",

  register_zip_error: "*Zip Code field is required",

  register_zip2_label: "+4 Code",

  register_password_label: "Password{star}",

  register_password_error1: "*Password field is required",

  register_password_error2:
    "Passwords must contain at least 8 characters, including uppercase, lowercase letters and numbers.",

  dob_validation: "Age should be atleast 16",

  register_terms1_label: "I agree to the",

  register_terms2_label: "terms of use",

  register_terms3_label: "and",

  register_terms4_label: "privacy policy",

  register_terms_error:
    "*Please indicate that you accept the Terms and Privacy Policy",

  register_already_account: "Already have an account,",

  register_signin_label: "Sign-in",

  register_or_signin_label: "or Sign in with",

  register_registerbutton_label: "Register",

  register_organization_type1: "City",

  register_organization_type2: "Government",

  register_organization_type3: "Municipal",

  register_organization_type4: "Business Improvement Districts",

  register_organization_type5: "Think Tank",

  register_organization_type6: "Higher-Ed",

  register_ethinicity_type1: "African-American",

  register_ethinicity_type2: "American Indian or Alaska Native",

  register_ethinicity_type3: "Asian or Pacific Islander",

  register_ethinicity_type4: "Caucasian",

  register_ethinicity_type5: "Hispanic or Latino",

  register_ethinicity_type6: "Native Hawaiians or Other Pacific Islander",

  register_ethinicity_type7: "Multiracial",

  register_ethinicity_type8: "Other",

  register_ethinicity_type9: "Prefer not to answer",

  register_profile_change_password: "Change Password",

  register_create_password_change: "Change your password",

  register_create_password_create: "Create your password",

  register_create_password_old: "Old Password*",

  register_create_password_new: "New Password*",

  register_create_password_confirm: "Confirm Password*",

  register_create_password_not_match: "*Password do not match",

  register_create_password_cotinue: "Continue",

  register_collegues_add_collegues: "Add Colleague",

  register_department_label: "Department{star}",

  register_department_error: "*Department is required",

  register_member_no_colleagues: "No Colleagues Added",

  register_member_colleagues_list: "Colleagues List",
  register_member_colleague_list: "Colleague List",

  register_member_delete: "Delete",

  register_profile_upload_resident: "Upload Resident Data",

  register_profile_batch_upload: "Batch Upload",

  register_profile_resident_details: "Resident Details Template",

  //Login page

  login_signin_label: "Sign in",

  login_email_label: "Email",

  login_email_error1: "*Email is required",

  login_email_error2: "*Please Enter Valid Email address",

  login_password_label: "Password",

  login_password_error1: "*Password field is required",

  login_password_error2:
    "Passwords must contain at least 8 characters, including uppercase, lowercase letters and numbers.",

  login_checkbox_label: "Remember Me",

  login_loginbutton_label: "Login",

  login_orsignin_label: "or Sign in with",

  login_forgot_password_label: "Forgot Password?",

  login_registration_label1: "Don't have an account ?",

  login_resident_label1: "Create Resident Account",

  login_resident_label2: "Create Account",

  login_organization_label1: "Create Organization Account",

  login_forgot_password_submit: "Submit",

  //404 Not found Page and thank you page

  notfound_thankyou_content1: "For Choosing Civy,",

  notfound_thankyou_content2: "Please check your email. We have sent to",

  notfound_thankyou_content3: "you an email for confirmation.",

  notfound_thankyou_content4: "Back to Home",

  //header banner

  header_banner_about: "about",

  header_banner_contactus: "contact us",

  header_banner_selfcheckkiosk: "Self Check KIOSK",

  header_banner_service: "Services",

  header_banner_outpolicies: "Our Policies",

  header_banner_project: "Project",

  header_banner_thought_leadership: "Thought Leadership",
  header_banner_how_to: "How-to",

  header_home_banner:
    "CONNECTING GOVERNMENT AND COMMUNITIES THROUGH INNOVATIVE PLATFORMS",

  header_banner_colleagues: "Colleague",

  header_banner_change_password: "Change Password",

  header_banner_create_password: "Create Password",

  header_banner_survey: "Survey",

  header_banner_what_is_civy: "WHAT IS CIVY",

  header_banner_qr_code_management: "QR Code Management",

  header_banner_faq: "FAQ ?",

  //header.........................

  header_home: "HOME",

  header_core: "CORE 19",

  header_KIOSK: "KIOSK",

  header_service: "SERVICES",

  header_project: "PROJECTS",

  header_project_engage: "ENGAGE",

  header_thought_leadership: "THOUGHT LEADERSHIP",

  header_about: "ABOUT",

  header_login: "LOGIN",

  // header_language1: "English",
  header_language1: "EN",

  header_language2: "FR",
  // header_language2: "French",

  header_language3: "ES",
  // header_language3: "Spanish",

  header_language4: "HI",
  // header_language4: "Hindi",

  header_language5: "ZH",
  // header_language5: "Mandarin",

  header_language6: "IT",
  // header_language6: "Italian",

  header_language7: "VI",
  // header_language7: "Vietnamese",

  header_header_drawer_welcome: "Welcome,",

  header_header_drawer_admin_profile: "Admin Profile",

  header_header_drawer_profile: "Profile",

  header_header_drawer_colleagues: "Colleagues",

  header_header_drawer_op_ed: "OP-ED",

  header_header_drawer_how_to: "How-to",

  header_header_drawer_logout: "Logout",

  header_what_is_civy: "WHAT IS CIVY",

  header_qr_code_management: "QR Code Management",

  header_faq: "FAQ?",

  //footer........................

  footer_contactus_title: "Contact Us",

  footer_contactus_content1:
    "Contact Civy to learn how you can enable better civic engagement for your community development projects for residents across all demographics.",

  footer_media: "Media",

  footer_terms_title: "Terms of Use",

  footer_privacy_title: "Privacy Policy",

  footer_google_translate_message: `Civy uses Google Translate to convert from English to other languages available in the platform. At times, Google Translate may not provide exact translation. For any queries, please contact us at`,

  // blog page..............

  blog_details_author_title: "Author",

  blog_details_author_title_star: "Author{star}",

  blog_details_posted_title: "Posted in:",

  blog_details_latest_article_title: "Latest Article",

  blog_details_op_ed_title: "Civy OP-ED",

  blog_addblog_new_blog: "Add New OP-ED",

  blog_addblog_title: "Title{star}",

  blog_addblog_description_title: "Description",

  blog_addblog_browse_title: "Browse...",

  blog_addblog_addimage_title: "Add Image..",

  blog_addblog_submit_title: "Add OP-ED",

  blog_editblog_op_ed_title: "Edit OP-ED",

  commonn_update_button: "Update",

  blog_common_error: "*This field is required",

  blog_title_error: "*Title is required",

  blog_desc_error: "*Description is required",

  blog_author_error: "*Author is required",

  blog_image_error: "*Image is required",

  valid_image_error: "*Images must be in .png, .jpeg, .jpg",

  //project page.........................

  project_filter_project_type1: "New project",

  project_filter_project_type2: "Existing project",

  project_filter_project_type3: "Repairs/Maintainence",

  project_filter_project_type4: "Event",

  project_filter_project_type5: "Disaster",

  project_filter_project_category1: "Greenery",

  project_filter_project_category2: "Toll charges",

  project_filter_project_category3: "Utilities",

  project_filter_project_category4: "Roads",

  project_filter_project_category5: "Highways",

  project_filter_project_category6: "Commercial building",

  project_filter_project_category7: "Residential building",

  project_filter_project_category8: "Native land",

  project_filter_project_category9: "Community club buildings",

  project_filter_project_category10: "Minor works",

  project_filter_city_zipcode: "COUNTY, CITY & ZIP",

  project_filter_range: "Range",

  project_filter_range1: "miles",

  project_filter_project_type: "Project Type",

  project_filter_project_type_star: "Project Type{star}",

  project_filter_project_category: "Project Category",

  project_filter_project_category_star: "Project Category{star}",

  project_location: "Location{star}",

  project_street_address: "Street Address",

  project_filter_add_new_project: "Add New Project",

  project_filter_reset: "Reset",

  project_newproject_organization_label: "Organization Department",

  project_newproject_organization_label_star: "Organization Department{star}",

  project_newproject_common_error: "*This field is required",

  project_newproject_street_label: "Street",

  project_newproject_country_label: "Country",

  project_newproject_state_label: "State",

  project_newproject_city_label: "City",

  project_newproject_zipcode_label: "Zip Code",

  project_newproject_areacode_label: "+4 Code",

  project_newproject_start_date: "Project Start Date",

  project_newproject_end_date: "Project End Date",

  project_newproject_cancel: "Cancel",

  project_projectfom_add_project: "Add Project",

  publish: "Publish",

  project_newproject_continue: "Continue",

  project_projectlist_category_label: "Category:",

  project_projectlist_type_label: "Type:",

  project_projectlist_view_label: "View Project",

  project_projectlist_no_project: "No Project Found",

  project_editproject_edit: "Edit Project",

  project_newproject_title_label: "Project Title{star}",

  project_newproject_description: "Project Description",

  project_newproject_description_star: "Project Description{star}",

  project_newproject_add_images: "Add Images..",

  project_newproject_add_File: "Upload PDF Files...",

  project_commentbox_join_label: "Join the conversation",

  project_commentbox_nocomment_label: "No Comments Yet",

  project_commentbox_comment_label: "{commentlength} comments",

  project_details_location: "Location",

  project_details_posted: "Posted over",

  project_details_share: "Share across:",

  project_details_last_update: "Last updated on",

  project_details_likes: "Total Likes:",

  project_details_question: "Add Question",

  project_details_question_list: "Question List",

  project_details_no_question: "No Question Added",

  project_details_add_answer: "Add Answer",

  project_details_add_answer_here: "Add your answer here...",

  project_facts_and_figures_label: "Updates from the city & government staff",

  project_gallery_label: "Project Gallery",

  qr_code_label: "Generate QR Code",

  //Survey page............

  survey_select_questionType: "Select Question type",

  survey_questionType_content:
    "Details for your project have been successfully uploaded on Civy. Please select the type of questions you wish to ask the residents.",

  survey_questionType_type_a: "Type A",

  survey_questionType_type_que1:
    "1. Please add your review for the children’s play area.",

  survey_write_here_label: "write something here...",

  survey_questionType_create_survey: "Create Survey",

  survey_questionType_type_b: "Multiple Choice Questions",

  survey_questionType_type_que2:
    "1. Would you like to add a children’s play area in the public garden?",

  survey_questionType_type_b_yes: "Yes",

  survey_questionType_type_b_no: "No",

  survey_comment_box: "Open Ended Question (Comment Box)",

  survey_radio_question_label: "Questions",

  survey_discard_label: "Discard",

  survey_submit_label: "ADD",

  survey_add_question_label: "Add question",

  survey_back_label: "Back",

  survey_question_list_label: "Question List",

  dialog_delete_message: "Are you sure you wish to delete",

  delete_lable: "Delete",

  cancel_label: "Cancel",

  comment: "Comment",

  /* Validation message */

  departmaent_required: "*Department name is required",

  project_type_required: "*Please select Project type",

  project_category_required: "*Please select Project Category",

  project_required: "*Please select Project",

  location_required: "*Location is required",

  full_address_required: "*Please enter the full address",

  valid_address_required: "*Please enter a valid location",

  only_usa_required: "*Add an address for USA only",

  project_title_requied: "*Project title required",

  project_desc_required: "*Project Description required",

  project_image_required: "*Image is required",

  export_button: "Export to Excel",

  export_project_data: "Export Project Data",

  export_project: "Export Project",

  from: "From",

  to: "To",

  date_range: "Date Range",

  edit_question: "Edit Question",

  quetion_labele: "Question",

  qustion_required: "*Question field is required",

  delete_question: "Delete Question",

  delete_comment: "Delete Comment",

  delete_project: "Delete Project",

  click_edit: "Click to Edit Profile",

  back_label: "Back",

  save_label: "Save",

  draft_label: "Draft",

  upload_label: "UPLOAD",

  attachment_label: "Attachments",

  rating_type_question: "Rating Type Questions",

  survey_questionType_type_que3: "Please Rate this project",

  allow_cookie_text:
    "We use cookies to optimize your experience, analyze traffic, and personalize content. To learn more, please visit our Cookie Policy. By using our website without disabling cookies, you consent to our use of them.",

  allow_btn: "Allow",

  decline_btn: "Decline",

  cookie_policy: "Cookie Policy",

  /* What is civy page */

  civy_intro_title1: "Features of the platform",

  civy_intro1_point1:
    "Cities & governments access the platform via a dedicated URL, for example, {link}",

  civy_intro1_point2:
    "Client has the option to link our platform to its social media pages (LinkedIn, Facebook & Twitter) to enable broader depth and reach to increase community participation.",

  civy_intro1_point3: "Platform includes multiple languages.",

  civy_intro1_point4:
    "Data is stored on Civy’s cloud service, and data security & privacy is maintained with semi-annual cyber audits in accordance with US data & privacy regulations.",

  civy_intro1_point5:
    "Civy does not engage with third-party advertisers therefore both clients and cities can maintain confidence they will not receive any unsolicited emails or promotions.",

  civy_intro1_point6:
    "Platform is mobile accessible, anywhere around the world; and Email and SMS notifications for all registered users.",

  civy_intro1_point7:
    "QR Code scans for projects (scanned by residents to provide quick responses).",

  civy_intro1_point8:
    "Access to downloadable reports relating to their city, for all projects or selected projects.",

  civy_intro2_title: "Reasons for organizations to register",

  civy_intro2_point1:
    "Create an administrator account with city’s own logo and add up to 20 colleagues.",

  civy_intro2_point2:
    "Data created by the client and resident feedback is owned by the client.",

  civy_intro2_point3:
    "Feedback from resident is directly sourced back to the project creator; for example, resident feedback for project created by Public Works is directed back to public works.",

  civy_intro2_point4:
    "Projects can be created using single or multiple zip codes; county level to a local town level; and shared via Civy and your linked social media channels. This ensures that feedback by residents is directly linked to the zip code of the resident (projects posted by New York city projects can only be responded to by New York residents).",

  civy_intro2_point5: "Able to download reports.",

  civy_intro3_title: "Reasons for residents to register",

  civy_intro3_point1:
    "To ensure your profile is linked to the zip code you live in, and you will be able to answer the questions posted by your city.",

  civy_intro3_point2:
    "To ensure that your responses are directly provided by you. Civy does not link, collect or aggregate data from any of your other social media accounts. Civy only records your responses you have provided in Civy’s platform, and these responses are only visible for review by the city officials using the platform.",

  civy_intro3_point3:
    "Your responses or comments are not visible to your fellow residents.",

  civy_intro3_point4:
    "You don’t have to register on Civy as ‘anonymous’ since your responses are confidential and only visible by the city official responsible for the project.",

  what_is_civy_policies_title: "POLICIES",

  what_is_civy_policies_content: "PRIVACY AND DATA POLICIES",

  //QR code Management page

  qr_code_svg: "Generate as SVG",

  qr_code_png: "Generate as PNG",

  qr_code_jpeg: "Generate as JPEG",

  qr_code: "QR Code",

  //FAQ...............................

  faq_intro1_title: "Civy, Inc.",

  faq_intro1_point1: "Q1. What is Civy, Inc? What services do we provide? ",
};
