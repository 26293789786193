export const define_fr = {
  //landing page casestudies component

  landing_caseStudies1:
    "Civy a testé un programme d'embellissement de la rue à Glendale, LA. Sur la base des commentaires reçus de la ville, il a été confirmé que les habitants n’avaient pas été pleinement consultés sur le projet avant le début du projet et sa visibilité. Civy vise à éliminer ce scénario de tous les projets en cours et à venir.",

  landing_caseStudies2:
    "Une grande agence gouvernementale d'infrastructure prévoit d'utiliser Civy pour mener son prochain programme de sensibilisation externe. L'agence posera aux résidents qu'ils servent, des questions sur les nouveaux projets qu'ils proposent. Dans le même temps, ils utiliseront également Civy pour rester en contact avec les personnes qui signalent des problèmes pour tous les projets d'infrastructure en cours. Ils ont estimé qu'une plate-forme mobile indépendante améliorera l'engagement et le fait que Civy fonctionne de manière simpliste, avec diverses options linguistiques concerne la majorité des gens.",

  landing_caseStudies3:
    "Un scénario récent pour vous offrir une meilleure perspective, imaginez que vous êtes un fonctionnaire de la ville ou une agence gouvernementale ou un résident intéressé. Vous êtes basé à Los Angeles. Vous pouvez saisir le nom de votre ville dans le Dashboard of Civy, ou dans n'importe quelle autre ville à travers le monde (disons Sydney, Australie). Vous pourrez voir ce que fait Sydney, vous pouvez appliquer des filtres par catégorie de projet. Il favorisera l'éducation et les apprentissages mondiaux.",

  landing_caseStudies4:
    "En tant que résident de Newport Beach en Californie, j'ai remarqué un manque de stations de fitness en plein air. Celles-ci sont très courantes dans de nombreux autres pays où le soleil, l'océan et la verdure sont abondants. À l'aide de mon smartphone, j'ai créé un nouveau projet à Civy (intitulé Outdoor Fitness Station), marqué l'emplacement, ajouté quelques exemples d'images, ajouté une brève description du cas du projet et partagé avec mon service municipal local et le réseau Civy. L'objectif est que les résidents approuvent l'idée ou fournissent des commentaires ou d'autres suggestions; et en attendant, le département de la ville reçoit les commentaires de ces résidents. Cela leur donne l'impulsion nécessaire pour agir et en parler avec les gens lors de leur prochaine sensibilisation communautaire, obtenir l'approbation des gens et la construire.",

  landing_caseStudies_content:
    "Quelques exemples pratiques de la façon dont Civy peut être bénéfique au quotidien.",

  landing_caseStudies_title: "ÉTUDES DE CAS",

  //landing page statistics component

  landing_statistics_title: "STATISTIQUE",

  landing_statistics_content:
    "Pour concrétiser complètement le développement durable, nous devons faire des progrès équitables et généralisés et permettre aux gens de participer activement à ce processus.",

  landing_statistics48:
    "des adultes participent directement à un groupe ou à une activité civique",

  landing_statistics68:
    "des résidents de 18 à 34 ans ont indiqué qu'ils se sentaient déconnectés du gouvernement.",

  //landing page testimonial component

  landing_testimonial_title: "Témoignages",

  landing_testimonial_content: "Lisez les témoignages de nos derniers clients.",

  landing_testimonial1:
    "Je souhaiterais l'avoir eu lorsque nous avons travaillé sur des projets qui nécessitaient une sensibilisation des parties prenantes à l'échelle de l'État et des collectivités locales. Cela aurait atténué le stress et nous a aidés à atteindre efficacement les communautés. ",

  landing_testimonial1_author: "Alana",

  landing_testimonial1_postname:
    "- Ancien directeur de la sensibilisation et des communications, State Utilities Authority",

  landing_testimonial2:
    "Civy peut assumer trois rôles dans le processus d'engagement à la fois - en alignant votre travail.",

  landing_testimonial2_author: "Eric",

  landing_testimonial2_postname:
    "- Aide législative à Freeholder Township, New Jersey",

  landing_testimonial3:
    "Civy est l'un des premiers développements passionnants depuis longtemps pour les logiciels gouvernementaux et la façon dont les municipalités interagissent avec le public. Freeholder est une grande municipalité",

  landing_testimonial3_author: "Scott",

  landing_testimonial3_postname: "- Chef de cabinet, Député d'État",

  landing_testimonial4:
    "La plateforme d'engagement en ligne de Civy est facile à utiliser et conçue pour les villes, les gouvernements et les résidents. Il s'attaque aux principaux problèmes que nous connaissons en tant que villes et élus, et permet à nos résidents de s'engager plus facilement avec nous dans un environnement sûr et sécurisé. La plateforme complète et améliore nos stratégies de sensibilisation existantes, et conçue pour augmenter les taux de participation et l'équité.",

  landing_testimonial4_author: "Theresa Branella",

  landing_testimonial4_postname: "-Mayor, Brooklawn, New Jersey",

  //landing page services component

  landing_servicesA_header: "Sensibilisation plus large du public aux projets.",

  landing_servicesA_content:
    "Une plate-forme unique qui offre la plus large portée, plusieurs langues et une profondeur démographique pour les projets civiques.",

  landing_servicesB_header: "Confidentialité et sécurité des données.",

  landing_servicesB_content:
    "Notre plateforme comprend des mesures de confidentialité supplémentaires pour protéger les réponses des résidents, qui ne sont visibles que par les autorités municipales et gouvernementales.",

  landing_servicesC_header: "Efficace et pratique.",

  landing_servicesC_content:
    "La rétroaction sur Civy pour un projet est aussi simple que l'envoi d'un message texte.",

  landing_servicesD_header: "Analyse complète.",

  landing_servicesD_content:
    "Analysez les commentaires des résidents à travers différentes données démographiques et appliquez des analyses pour une prise de décision, des approbations et une planification de projet plus rapides.",

  landing_service_title: "PRESTATIONS DE SERVICE",

  landing_service_content:
    "Civy aide les villes et les gouvernements à améliorer l'engagement civique.",

  landing_ServiceSection_maincontent:
    "Civy affiche les projets des gouvernements et des villes classés par état, ville, code postal; et poser des questions auxquelles les résidents peuvent répondre. ",

  //landing page project component

  landing_project_title: "Projets",

  landing_project_content:
    "Une liste de nos projets en cours dans différentes villes.",

  landing_project_short_description: "Description courte du projet",

  landing_project_description_link: "Voir plus",

  landing_project_startdate: "Date de début",

  landing_project_enddate: "Date de fin",

  //landing page benefit component

  landing_benefit1_title: "Plateforme mobile",

  landing_benefit1_content:
    "Civy est une plateforme mobile pratique facilement accessible de partout.",

  landing_benefit2_title: "Gratuit pour les résidents",

  landing_benefit2_content:
    "Civy est libre pour les résidents de s'inscrire, de répondre aux questions et aux sondages, et d'aider les villes et les gouvernements à améliorer la planification et la livraison de leurs projets.",

  landing_benefit3_title: "Liens avec les médias sociaux",

  landing_benefit3_content:
    "Liens civiques avec les plateformes de médias sociaux pour augmenter les taux de participation et la profondeur démographique.",

  landing_benefit4_title: "Coffre-fort sécurisé",

  landing_benefit4_content:
    "Les réponses des résidents sont confidentielles et Civy ne partage pas les données des résidents avec des tiers.",

  landing_benefit_title: "AVANTAGES",

  landing_benefit_content:
    "Civy offre de multiples fonctionnalités pour tirer parti de l'engagement et de la participation du public à grande échelle qui contribuent à améliorer la gouvernance et les résultats de développement.",

  //landing page about component

  landing_about_title: "À propos de CIVY",

  landing_about_content:
    "Nous aidons les villes et les gouvernements à améliorer l'engagement des parties prenantes et la sensibilisation du public à travers des projets civiques.",

  landing_about_maincontent:
    "Civy est un moyen facile et sécurisé pour les résidents de participer et de partager leurs commentaires sur les projets publics actuels et à venir par les villes et les gouvernements. Une plateforme en ligne dédiée qui atteint un public plus large. Les parties prenantes de toutes les données démographiques s'engagent et fournissent des commentaires à leurs villes et agences gouvernementales, avec commodité et efficacité.",

  landing_readmore: "Lire la suite",

  //landing page contact us component

  landing_contactus_address_title: "Adresse",

  landing_contactus_address: "Civy Inc.",

  landing_contactus_address_post:
    "160, rue Cypress S, Orange, Californie, 92866 États-Unis",

  contactus_address_street: "160, rue Cypress S,",

  contactus_address_code: "Orange, Californie, 92866",

  contactus_address_use: "Etats-Unis",

  landing_contactus_address_tel: "Tél:",

  landing_contactus_usertype1: "Résidente",

  landing_contactus_usertype2: "Ville",

  landing_contactus_usertype3: "Organisme gouvernemental",

  landing_contactus_usertype4: "Université",

  landing_contactus_usertype5: "Autre",

  landing_contactus_title: "ENTRER EN CONTACT",

  landing_contactus_name: "Nom*",

  landing_contactus_email: "Email*",

  landing_contactus_select: "Es-tu*",

  landing_contactus_feedback: "Type de rétroaction*",

  landing_contactus_feedback_good: "Bien",

  landing_contactus_feedback_bad: "Mauvaise",

  landing_contactus_feedback_other: "Autre",

  landing_contactus_feedback_message: "Votre message*",

  landing_contactus_send_button: "Envoyer le message",

  landing_contactus_name_error: "*Le nom est requis",

  landing_contactus_email_error: "* L'email est requis",

  landing_contactus_email_error2: "* Veuillez saisir une adresse e-mail valide",

  landing_contactus_select_error: "*Veuillez sélectionner une option",

  landing_contactus_message_error: "* Un message est requis",

  landing_contactus_capcha_error:
    "* Veuillez valider la case Captcha Je ne suis pas un robot",

  select_survey_visibility: "Sélectionnez la visibilité de l'enquête",

  select_survey_visibility_private: "Privée",

  select_survey_visibility_public: "Publique",

  // services page

  services_casestudy1_title: "VILLES ET GOUVERNEMENTS",

  services_casestudy1_content:
    "Une plate-forme sécurisée facile à utiliser pour impliquer les résidents et accélérer le développement plus intelligent des projets civiques.",

  services_casestudy2_title: "RÉSIDENTES",

  services_casestudy2_content:
    "Une plate-forme mobile pour recevoir des notifications et partager des commentaires avec vos villes pour guider la conception et le développement de projets civiques.",

  services_casestudy_title: "ÉTUDE DE CAS",

  services_casestudy_content:
    "Comment les villes et les gouvernements se connectent avec Residencts via Civy",

  services_integratedservices_title: "Services intégrés:",

  services_introservice1_title: "CE QUI REND CIVY DIFFÉRENT",

  services_introservice1_point1:
    "Permet aux villes et aux gouvernements de créer leurs propres données, d'agréger des données et de contrôler leur propre narration.",

  services_introservice1_point2:
    "Augmentez et améliorez la sensibilisation du public en établissant des liens avec d'autres canaux de médias sociaux tels que Facebook, Twitter et LinkedIn.",

  services_introservice1_point3:
    "Confidentialité complète des réponses des résidents, qui ne sont visibles que par la ville ou les gouvernements abonnés à CIvy. Les données sont la propriété des villes ou des gouvernements et Civy ne s'engage pas dans des ventes à des tiers.",

  services_introservice1_point4:
    "Téléchargez et stockez les données du projet sur vos propres serveurs. Pour se conformer à la réglementation sur la confidentialité, Civy supprime les données 3 mois après la fin du projet par les clients.",

  services_introservice1_point5:
    "Option de marque blanche Civy comme votre propre plate-forme avec votre marque unique.",

  services_introservice1_point6:
    "Civy offre également des services supplémentaires tels que la cartographie holographique 3D et SIG avec des applications dans la planification, la conception et la commercialisation de projets et de développements urbains.",

  services_introservice2_title:
    "Fonctionnalité du produit pour les villes et les gouvernements.",

  services_introservice2_point1:
    "Les villes et les gouvernements créent un compte sur la plateforme Civy et relient leurs propres canaux de médias sociaux.",

  services_introservice2_point2:
    "Ajoutez plusieurs utilisateurs au compte avec des rôles d'utilisateur définis tels que directeur des travaux publics.",

  services_introservice2_point3:
    "Créez des projets, téléchargez des descriptions, et des images relatives à la vision du projet.",

  services_introservice2_point4:
    "Partagez sur différents canaux et posez des questions aux résidents.",

  services_introservice2_point5:
    "Rassemblez et analysez les réponses, capturez plus de données démographiques dans la communauté grâce à nos capacités multilingues.",

  services_introservice2_point6:
    "Utilisez l'analyse pour accélérer la prise de décision, l'exécution et les résultats.",

  services_introservice1_content:
    "Civy est une plateforme d'engagement pour les villes, les gouvernements et les résidents, et vous permet de créer, d'agréger et de stocker vos données.",

  services_introservice2_content:
    "Civy fournit une plate-forme facile à utiliser pour favoriser l'engagement civique.",

  // about page

  about_history1_title: "Idéation",

  about_history1_content:
    "Après avoir exécuté plusieurs projets axés sur les infrastructures, l'énergie et la technologie à travers le monde, nous avons réalisé que les gouvernements, les responsables des villes et les communautés, malgré leurs meilleures intentions, se plaignaient toujours du manque de consultation; tandis que les intérêts privés tels que les développeurs craignent que leurs objectifs commerciaux ne soient affectés négativement s'ils s'engagent trop avec les communautés. En revanche, les communautés estiment avoir reçu des plans d'affaires enrobés de sucre et des propositions d'investissement de projet, mais elles ne sont pas consultées en phase de conception ou souvent traitées comme des non-experts qui ne savent pas de quoi elles parlent. Il est courant que les résidents se sentent inconnus lors de projets de développement civique dans leurs communautés.",

  about_history2_title: "Initiation",

  about_history2_content:
    "Pour résoudre ce problème, nous avons visualisé une plate-forme commune où les idées de projets proviennent de la ville et des représentants du gouvernement, et sont directement présentées aux résidents et aux parties prenantes pour commentaires. Les premiers peuvent alors engager, analyser et affiner la meilleure ligne de conduite sur la base des commentaires des parties prenantes (résidents). Le gouvernement et les autorités de la ville pourraient expliquer directement aux gens ce qui peut être accompli et ce qui ne peut pas l'être, énumérer les potentiels ainsi que les complications qui pourraient être rencontrées à court terme, élaborer les finances impliquées dans le développement et après consultation appropriée de tous les collaborateurs agissent et mettent en œuvre les projets rapidement.",

  about_history3_title: "Formulation",

  about_history3_content:
    "Avec un objectif et une résolution clairs, nous avons créé la plate-forme conceptuelle axée sur la résolution des problèmes lors de notre phase de découverte avec les villes et les gouvernements (maires, conseillers municipaux, représentants des assemblées législatives, directeurs de ville, directeurs de département, équipes marketing, équipes technologiques et groupes communautaires). La plate-forme conceptuelle combinant un outil d'analyse de l'information avec les aspects des médias sociaux pour fournir une large diffusion et engager les résidents pour tous les développements de projets civiques, a obtenu la validation du client. Les principaux objectifs étaient de réduire le temps, les coûts et les ressources à la disposition d'un projet civique et d'accroître la sensibilisation du public et l'inclusion accrue de toutes les parties prenantes tout en préservant la confidentialité et la sécurité des données.",

  about_history4_title: "la mise en oeuvre",

  about_history4_content:
    "À partir de 2019, nous avons mis en œuvre Civy, une plateforme pour aider les villes et les gouvernements à améliorer la sensibilisation du public à travers des projets civiques. La plateforme sollicite des commentaires en impliquant les résidents de la génération Y aux seniors de toutes les données démographiques, tout en réduisant le besoin d'utiliser des méthodes obsolètes et héritées telles que hôtels de ville à l'ancienne. La plate-forme offre une commodité sans précédent pour apprendre, engager et fournir une rétroaction honnête aux parties prenantes pour tous les projets civiques. Le partage en un clic intégré de la plate-forme sur plusieurs canaux de médias sociaux, la cybersécurité et les solutions d'hébergement cloud assurent également la confidentialité des données, les gains de productivité du back-office et permettent aux villes et aux gouvernements de se concentrer sur la réflexion stratégique.",

  about_history_title: "Histoire de civy",

  about_history_since: "depuis",

  about_vision_title: "Notre vision",

  about_vision_content:
    "Pour créer une boucle d'engagement qui capture les villes, les gouvernements, les résidents et les parties prenantes, et construire une planète plus intelligente.",

  about_mission_title: "Notre mission",

  about_mission_content1:
    "Civy aide les villes et les gouvernements à améliorer la sensibilisation du public et l'engagement des parties prenantes à travers les projets civiques en comprenant leurs points faibles, en comprenant ce qui peut être amélioré et en facilitant une meilleure discussion en utilisant notre plateforme propriétaire. Notre philosophie pour y parvenir est une approche de «retour aux sources» consistant à demander aux gens, à écouter leurs commentaires et à aider nos clients (villes et gouvernements) à réagir à ces commentaires.",

  about_interview_title: "entretien",

  about_interview_content:
    "Amar Flora, fondateur de Civy, a été interviewé à la télévision en direct pour discuter de Civy et de son objectif d'aider les villes et les gouvernements",

  about_interview_maincontent:
    "Regardez le fondateur de Civy, Amar Flora, expliquer à la télévision en direct comment Civy permet aux décideurs d’utiliser les données des utilisateurs pour prendre des décisions plus rapidement, agir sur des informations et simplement se connecter en pensant «ciel bleu» avec leurs résidents; le tout en temps réel, non filtré et sur une plateforme indépendante ",

  about_ourteam_title: "NOTRE ÉQUIPE",

  about_ourteam_content:
    "Nous sommes un groupe de personnes passionnées par GovTech, les villes intelligentes et l'engagement des parties prenantes.",

  about_ourteam_person1_name: "Amar Flora (MBA, GAICD, GCCM, CPA)",

  about_ourteam_person1_title: "Co-fondateur et PDG",

  about_ourteam_person1_content:
    "Un leader visionnaire et un entrepreneur passionné par l'enseignement supérieur, les villes intelligentes et les opérations gouvernementales. Amar a cofondé deux startups privées, créant des équipes et des collaborateurs mondiaux, a siégé à des conseils d'administration et dirigé la création et les opérations d'une startup universitaire avec un financement privé de 20 millions de dollars. Amar a vendu des services et consulté des clients de divers secteurs, offrant un impact significatif et une création de valeur. Amar possède une grande capacité à identifier les opportunités stratégiques et opérationnelles et à stimuler l'innovation. Amar est titulaire d'un MBA, de diplômés en gestion du changement et en gouvernance d'entreprise et d'une certification CPA délivrée par les meilleures institutions des États-Unis et d'Australie.",

  about_ourteam_person2_name: "Sabrina Uppal (MS, PMP)",

  about_ourteam_person2_title: "Co-fondateur et COO",

  about_ourteam_person2_content:
    "Sabrina rejoint Civy avec des expériences de qualité et a réussi à exécuter plusieurs projets de démarrage et de recherche dans les secteurs privé et gouvernemental. Son flair créatif associé à un état d'esprit innovant, lui permet de concevoir et d'offrir de la valeur aux clients. En outre, Sabrina possède une vaste expérience dans la gestion des contrats gouvernementaux pour les organisations sous contrat avec le département américain de la Défense. Sabrina a une expérience directe d'essayer d'engager sa ville ou une agence gouvernementale pour obtenir un résultat. Ces expériences stimulent sa passion et sa créativité pour aider les villes et les gouvernements à favoriser un engagement à fort impact auprès des résidents. Sabrina est titulaire d'un baccalauréat ès sciences, neurosciences de l'U.C. Davis; Master of Science, Biology de Cal State Northridge et est certifié PMP.",

  about_ourteam_person3_name: "Jinhong Zhang (CPA, MBA, CGMA)",

  about_ourteam_person3_title: "CFO",

  about_ourteam_person3_content:
    "Jinhong Zhang a plus de 20 ans d'expérience dans la planification et la gestion des affaires et des finances, occupant des postes de direction en tant que président directeur financier, directeur financier et consultant dans les grandes entreprises. Jinhong a commencé sa carrière dans une entreprise Fortune Global 500, où il a géré des projets financés par la Banque mondiale et Asia Development chacun et a supervisé l'audit et l'engagement fiscal avec PWC et KPMG côté client.",

  about_ourteam_person4_name: "Suketu Modi",

  about_ourteam_person4_title: "CTO",

  about_ourteam_person4_content: `Reconnu parmi les 100 Great People Managers of India 2019 par Forbes India, et récompensé comme la meilleure conception d'infrastructure et e-gouvernance intelligente pour les villes, Suketu est un chef d'entreprise chevronné et technocrate avec une vaste expérience dans le conseil en TIC pour les meilleures sociétés de conseil multinationales, les gouvernements et PSUs qui sert de CTO pour Civy. Suketu détient plus de 19 ans d'expérience dans le conseil et la mise en œuvre des TIC dans les infrastructures de réseaux d'infrastructure TIC et la conception de services intelligents pour les services à grande échelle de ville / campus et de bâtiments intelligents. Suketu a travaillé avec des clients et des clients aux États-Unis, au Moyen-Orient, à Singapour et en Inde.`,

  about_ourteam_description1:
    "Nous sommes passionnés d'aider les villes et les gouvernements à favoriser l'innovation pour l'amélioration des résidents.",

  about_ourteam_description2:
    "Partenaire exclusif de Civy, Infixzon est une société de services de conseil, de cloud et de cybersécurité dirigée par des vétérans de l'industrie ayant plusieurs décennies d'expérience. Infixzon dessert les États-Unis, l'APAC et les régions du Moyen-Orient, concevant des solutions pour des secteurs verticaux comme le secteur public, les soins de santé, les services financiers, les produits pharmaceutiques et la fabrication.",

  about_ourteam_description3:
    "Twish Technologies est une équipe de professionnels avec des années de leadership dans les politiques d'infrastructure, les études de faisabilité de projet, la stratégie d'intégration et de mise en œuvre pour les infrastructures au niveau de la ville, les communautés intelligentes et les villes intelligentes. Leur équipe comprend un psychologue organisationnel qui se concentre sur l'engagement communautaire au nom des villes et des gouvernements.",

  //Terms and conditions page

  ourpolicies_term_of_use_title: "CONDITIONS D'UTILISATION",

  ourpolicies_terms_title: "Termes et conditions",

  ourpolicies_terms_last_updated: "Dernière mise à jour: {day} {month} {year}",

  ourpolicies_terms_content1: `Veuillez lire attentivement ces Conditions générales ("Conditions", "Conditions générales") avant d'utiliser le site Web https://www.civy.co et l'application mobile Civy (ensemble ou individuellement, le "Service") exploité par Civy Inc («nous», «nous» ou «notre»).`,

  ourpolicies_terms_content2:
    "Votre accès et votre utilisation du Service sont conditionnés par votre acceptation et votre respect des présentes Conditions. Ces Conditions s'appliquent à tous les visiteurs, utilisateurs et autres qui souhaitent accéder ou utiliser le Service.",

  ourpolicies_terms_content3:
    "En accédant ou en utilisant le Service, vous acceptez d'être lié par ces Conditions. Si vous n'êtes pas d'accord avec une partie des conditions, vous n'êtes pas autorisé à accéder au service.",

  ourpolicies_terms_Communications_title: "Les communications",

  ourpolicies_terms_Communications_content1:
    "En créant un compte sur notre service, vous acceptez de vous abonner aux newsletters, au matériel marketing ou promotionnel et aux autres informations que nous pouvons envoyer. Cependant, vous pouvez refuser de recevoir une partie ou la totalité de ces communications de notre part en suivant le lien de désabonnement ou les instructions fournies dans tout e-mail que nous envoyons.",

  ourpolicies_terms_subscription_title: "Subscriptions",

  ourpolicies_terms_subscription_content1: `Certaines parties du Service sont facturées par abonnement ("Abonnement (s)"). Vous serez facturé à l'avance sur une base récurrente et périodique ("Cycle de facturation"). Les cycles de facturation sont définis sur une base mensuelle ou annuelle, selon le type de plan d'abonnement que vous sélectionnez lors de l'achat d'un abonnement.`,

  ourpolicies_terms_subscription_content2:
    "À la fin de chaque cycle de facturation, votre abonnement sera automatiquement renouvelé dans les mêmes conditions, sauf si vous l'annulez ou que Civy Inc l'annule. Vous pouvez annuler votre renouvellement d'abonnement via votre page de gestion de compte en ligne ou en contactant l'équipe de support client de Civy Inc.",

  ourpolicies_terms_subscription_content3:
    "Un mode de paiement valide, comprenant une carte de crédit ou PayPal, est requis pour traiter le paiement de votre abonnement. Vous devez fournir à Civy Inc des informations de facturation exactes et complètes, y compris le nom complet, l'adresse, l'état, le code postal, le numéro de téléphone et des informations valides sur le mode de paiement. En soumettant ces informations de paiement, vous autorisez automatiquement Civy Inc à facturer tous les frais d'abonnement encourus via votre compte à ces instruments de paiement.",

  ourpolicies_terms_subscription_content4:
    "Si la facturation automatique ne se produit pas pour une raison quelconque, Civy Inc émettra une facture électronique indiquant que vous devez procéder manuellement, dans une certaine date limite, avec le paiement intégral correspondant à la période de facturation indiquée sur la facture.",

  ourpolicies_terms_feechanges_title: "Modifications des frais",

  ourpolicies_terms_feechanges_content1:
    "Civy Inc, à sa seule discrétion et à tout moment, peut modifier les frais d'abonnement pour les abonnements. Tout changement de frais d'abonnement entrera en vigueur à la fin du cycle de facturation alors en cours.",

  ourpolicies_terms_feechanges_content2:
    "Civy Inc vous fournira un avis préalable raisonnable de tout changement dans les frais d'abonnement pour vous donner la possibilité de résilier votre abonnement avant que ce changement ne prenne effet.",

  ourpolicies_terms_feechanges_content3:
    "Votre utilisation continue du Service après l'entrée en vigueur du changement des frais d'abonnement constitue votre accord de payer le montant des frais d'abonnement modifié.",

  ourpolicies_terms_refund_title: "Remboursements",

  ourpolicies_terms_refund_content:
    "Sauf lorsque la loi l'exige, les frais d'abonnement payés ne sont pas remboursables.",

  ourpolicies_terms_content_title: "Contenu",

  ourpolicies_terms_content_description1: `Notre service vous permet de publier, de lier, de stocker, de partager et de rendre autrement disponible certaines informations, textes, graphiques, vidéos ou autre matériel ("Contenu"). Vous êtes responsable du Contenu que vous publiez sur ou via le Service, y compris sa légalité, sa fiabilité et sa pertinence.`,

  ourpolicies_terms_content_description2:
    "En publiant du contenu sur ou via le service, vous déclarez et garantissez que: (i) le contenu vous appartient (vous en êtes propriétaire) et / ou vous avez le droit de l'utiliser et le droit de nous accorder les droits et la licence tels que prévus dans ces Conditions, et (ii) que la publication de votre Contenu sur ou via le Service ne viole pas les droits à la vie privée, les droits de publicité, les droits d'auteur, les droits contractuels ou tout autre droit de toute personne ou entité. Nous nous réservons le droit de résilier le compte de toute personne jugée en violation d'un droit d'auteur.",

  ourpolicies_terms_content_description3:
    "Vous conservez tous vos droits sur tout Contenu que vous soumettez, publiez ou affichez sur ou via le Service et vous êtes responsable de la protection de ces droits. Nous n'assumons aucune responsabilité et n'assumons aucune responsabilité pour le Contenu que vous ou tout tiers publiez sur ou via le Service. Cependant, en publiant du contenu en utilisant le service, vous nous accordez le droit et la licence d'utiliser, de modifier, d'exécuter, d'afficher, de reproduire et de distribuer ce contenu sur et via le service.",

  ourpolicies_terms_content_description4:
    "Civy Inc a le droit mais pas l'obligation de surveiller et de modifier tout le contenu fourni par les utilisateurs.",

  ourpolicies_terms_content_description5:
    "De plus, le contenu trouvé sur ou via ce service est la propriété de Civy Inc ou utilisé avec permission. Vous ne pouvez pas distribuer, modifier, transmettre, réutiliser, télécharger, republier, copier ou utiliser ledit Contenu, en tout ou en partie, à des fins commerciales ou à des fins personnelles, sans l'autorisation écrite expresse préalable de notre part.",

  ourpolicies_terms_account_title: "Comptes",

  ourpolicies_terms_account_content1:
    "Lorsque vous créez un compte chez nous, vous garantissez que vous avez plus de 18 ans et que les informations que vous nous fournissez sont exactes, complètes et à jour à tout moment. Des informations inexactes, incomplètes ou obsolètes peuvent entraîner la résiliation immédiate de votre compte sur le Service.",

  ourpolicies_terms_account_content2:
    "Vous êtes responsable du maintien de la confidentialité de votre compte et de votre mot de passe, y compris, mais sans s'y limiter, la restriction d'accès à votre ordinateur et / ou compte. Vous acceptez d'accepter la responsabilité de toutes les activités ou actions qui se produisent sous votre compte et / ou mot de passe, que votre mot de passe soit avec notre service ou un service tiers. Vous devez nous informer immédiatement dès que vous avez connaissance d'une violation de la sécurité ou d'une utilisation non autorisée de votre compte.",

  ourpolicies_terms_account_content3:
    "Vous ne pouvez pas utiliser comme nom d'utilisateur le nom d'une autre personne ou entité ou qui n'est pas légalement disponible à l'utilisation, un nom ou une marque qui est soumis à des droits d'une autre personne ou entité autre que vous, sans autorisation appropriée. Vous ne pouvez pas utiliser comme nom d'utilisateur un nom offensant, vulgaire ou obscène.",

  ourpolicies_terms_Intellectual_title: "Propriété intellectuelle",

  ourpolicies_terms_Intellectual_content1:
    "Le Service et son contenu d'origine (à l'exclusion du Contenu fourni par les utilisateurs), les caractéristiques et les fonctionnalités sont et resteront la propriété exclusive de Civy Inc et de ses concédants de licence. Le Service est protégé par le droit d'auteur, les marques de commerce et d'autres lois des États-Unis et des pays étrangers. Nos marques de commerce et nos marques commerciales ne peuvent être utilisées en relation avec un produit ou service sans le consentement écrit préalable de Civy Inc.",

  ourpolicies_terms_links_title: "Liens vers d'autres sites Web",

  ourpolicies_terms_links_content1:
    "Notre service peut contenir des liens vers des sites Web ou des services tiers qui ne sont pas détenus ou contrôlés par Civy Inc",

  ourpolicies_terms_links_content2:
    "Civy Inc n'a aucun contrôle sur, et n'assume aucune responsabilité pour le contenu, les politiques de confidentialité ou les pratiques de tout site Web ou service tiers. Nous ne garantissons pas les offres de ces entités / individus ou de leurs sites Web.",

  ourpolicies_terms_links_content3:
    "Vous reconnaissez et acceptez que Civy Inc ne sera pas tenu responsable, directement ou indirectement, de tout dommage ou perte causé ou présumé être causé par ou en relation avec l'utilisation ou la dépendance à l'égard de tels contenus, biens ou services disponibles sur ou via ces sites Web ou services tiers.",

  ourpolicies_terms_links_content4:
    "Nous vous conseillons fortement de lire les termes et conditions et les politiques de confidentialité des sites Web ou services tiers que vous visitez.",

  ourpolicies_terms_termination_title: "Résiliation",

  ourpolicies_terms_termination_content1:
    "Nous pouvons résilier ou suspendre votre compte et bloquer l'accès au Service immédiatement, sans préavis ni responsabilité, à notre seule discrétion, pour quelque raison que ce soit et sans limitation, y compris, mais sans s'y limiter, une violation des Conditions.",

  ourpolicies_terms_termination_content2:
    "Si vous souhaitez résilier votre compte, vous pouvez simplement cesser d'utiliser le Service.",

  ourpolicies_terms_termination_content3:
    "Toutes les dispositions des Conditions qui, par leur nature, devraient survivre à la résiliation survivront à la résiliation, y compris, sans s'y limiter, les dispositions relatives à la propriété, les exclusions de garantie, l'indemnité et les limitations de responsabilité.",

  ourpolicies_terms_Indemnification_title: "Indemnité",

  ourpolicies_terms_Indemnification_content1:
    "Vous acceptez de défendre, d'indemniser et de dégager Civy Inc et son licencié et ses concédants de licence, ainsi que leurs employés, sous-traitants, agents, dirigeants et administrateurs, contre et contre toutes réclamations, dommages, obligations, pertes, responsabilités, coûts ou dettes, et les dépenses (y compris, mais sans s'y limiter, les honoraires d'avocat), résultant de ou découlant de a) votre utilisation et accès au Service, par vous ou toute personne utilisant votre compte et votre mot de passe; b) une violation des présentes conditions, ou c) du contenu publié sur le service.",

  ourpolicies_terms_limitation_title: "Limitation de responsabilité",

  ourpolicies_terms_limitation_content1:
    "En aucun cas, Civy Inc, ni ses administrateurs, employés, partenaires, agents, fournisseurs ou sociétés affiliées, ne sera responsable des dommages indirects, accessoires, spéciaux, consécutifs ou punitifs, y compris, sans limitation, la perte de bénéfices, les données, l'utilisation, la bonne volonté ou d'autres pertes intangibles résultant de",

  ourpolicies_terms_limitation_list1:
    "Votre accès ou utilisation ou incapacité d'accéder ou d'utiliser le Service",

  ourpolicies_terms_limitation_list2:
    "Tout comportement ou contenu d'un tiers sur le Service",

  ourpolicies_terms_limitation_list3: "Tout contenu obtenu à partir du service",

  ourpolicies_terms_limitation_list4:
    "Accès, utilisation ou modification non autorisés de vos transmissions ou contenus, qu'ils soient basés sur la garantie, le contrat, le délit (y compris la négligence) ou toute autre théorie juridique, que nous ayons été informés ou non de la possibilité de tels dommages, et même si un recours est fixé ci-après est avéré avoir échoué de son but essentiel.",

  ourpolicies_terms_disclaimer_title: "Avertissement",

  ourpolicies_terms_disclaimer_content1: `Votre utilisation du Service est à vos risques et périls. Le Service est fourni «TEL QUEL» et «TEL QUE DISPONIBLE». Le Service est fourni sans garantie d'aucune sorte, expresse ou implicite, y compris, mais sans s'y limiter, les garanties implicites de qualité marchande, d'adéquation à un usage particulier, de non-contrefaçon ou de performance.`,

  ourpolicies_terms_disclaimer_content2:
    "Civy Inc ses filiales, sociétés affiliées et ses concédants de licence ne garantissent pas que",

  ourpolicies_terms_disclaimer_list1:
    "Le service fonctionnera sans interruption, sécurisé ou disponible à tout moment ou lieu particulier",

  ourpolicies_terms_disclaimer_list2:
    "Toutes les erreurs ou défauts seront corrigés",

  ourpolicies_terms_disclaimer_list3:
    "Le Service est exempt de virus ou d'autres composants nuisibles",

  ourpolicies_terms_disclaimer_list4:
    "Les résultats de l'utilisation du Service répondront à vos besoins.",

  ourpolicies_terms_exclusion_title: "Exclusions",

  ourpolicies_terms_exclusion_content1:
    "Certaines juridictions n'autorisent pas l'exclusion de certaines garanties ou l'exclusion ou la limitation de responsabilité pour les dommages indirects ou accessoires, de sorte que les limitations ci-dessus peuvent ne pas s'appliquer à vous.",

  ourpolicies_terms_governing_title: "Loi applicable",

  ourpolicies_terms_governing_content1:
    "Ces conditions sont régies et interprétées conformément aux lois de la Californie, aux États-Unis, sans égard à ses dispositions en matière de conflit de lois.",

  ourpolicies_terms_governing_content2:
    "Notre incapacité à appliquer tout droit ou disposition des présentes Conditions ne sera pas considéré comme une renonciation à ces droits. Si une disposition des présentes Conditions est jugée invalide ou inapplicable par un tribunal, les autres dispositions des présentes Conditions resteront en vigueur. Ces Conditions constituent l'intégralité de l'accord entre nous concernant notre Service, et remplacent et remplacent tout accord antérieur que nous aurions pu avoir entre nous concernant le Service.",

  ourpolicies_terms_changes_title: "Changements",

  ourpolicies_terms_changes_content1:
    "Nous nous réservons le droit, à notre seule discrétion, de modifier ou de remplacer ces conditions à tout moment. Si une révision est importante, nous fournirons un préavis d'au moins 30 jours avant l'entrée en vigueur de toute nouvelle condition. Ce qui constitue un changement important sera déterminé à notre seule discrétion.",

  ourpolicies_terms_changes_content2:
    "En continuant à accéder ou à utiliser notre service après que les révisions soient entrées en vigueur, vous acceptez d'être lié par les conditions révisées. Si vous n'acceptez pas les nouvelles conditions, vous n'êtes plus autorisé à utiliser le Service.",

  ourpolicies_terms_contactus_title: "Nous contacter",

  ourpolicies_terms_contactus_content:
    "Si vous avez des questions sur ces conditions, veuillez nous contacter à",

  //Privacy policies page

  ourpolicies_policy_title: "Politique de confidentialité",

  ourpolicies_policy_date: "Date d'entrée en vigueur: {day} {month} {year}",

  ourpolicies_policy_content1: `Civy Inc. («nous», «nous» ou «nos») exploite le https://www.civy.co	
    site Web et l'application mobile Civy (ci-après dénommée "Service"). `,

  ourpolicies_policy_content2:
    " Cette page vous informe de nos politiques concernant la collecte, l'utilisation et la divulgation de données personnelles lorsque vous utilisez notre service et les choix que vous avez associés à ces données.",

  ourpolicies_policy_content3:
    " Nous utilisons vos données pour fournir et améliorer le Service. En utilisant le Service, vous acceptez la collecte et l'utilisation d'informations conformément à cette politique. Sauf indication contraire dans la présente politique de confidentialité, les termes utilisés dans cette politique de confidentialité ont la même signification que dans nos conditions générales.",

  ourpolicies_definitions_title: "Définitions",

  ourpolicies_service_title: "Un service",

  ourpolicies_service_content: `Service désigne le site https://www.civy.co et le mobile Civy	
    application exploitée par Civy Inc.`,

  ourpolicies_personaldata_title: "Données personnelles",

  ourpolicies_personaldata_content:
    "Les données personnelles désignent les données sur un individu vivant qui peuvent être identifiées à partir de ces données (ou à partir de celles-ci et d'autres informations en notre possession ou susceptibles d'entrer en notre possession).",

  ourpolicies_usagedata_title: "Données d'utilisation",

  ourpolicies_usagedata_content:
    "Les données d'utilisation sont des données collectées automatiquement, soit générées par l'utilisation du service, soit à partir de l'infrastructure du service elle-même (par exemple, la durée d'une visite de page).",

  ourpolicies_cookie_title: "Cookies",

  ourpolicies_cookie_content:
    "Les cookies sont de petits fichiers stockés sur votre appareil (ordinateur ou appareil mobile).",

  ourpolicies_datacontroller_title: "Contrôleur de données",

  ourpolicies_datacontroller_content1:
    "Contrôleur de données désigne la personne physique ou morale qui (seule ou conjointement ou en commun avec d'autres personnes) détermine les finalités et la manière dont les informations personnelles sont ou doivent être traitées.",

  ourpolicies_datacontroller_content2:
    "Aux fins de la présente politique de confidentialité, nous sommes responsables du traitement de vos données personnelles.",

  ourpolicies_dataprocessor_title:
    "Processeurs de données (ou fournisseurs de services)",

  ourpolicies_dataprocessor_content1:
    "Processeur de données (ou fournisseur de services) désigne toute personne physique ou morale qui traite les données pour le compte du responsable du traitement.",

  ourpolicies_dataprocessor_content2:
    "Nous pouvons utiliser les services de différents fournisseurs de services afin de traiter vos données plus efficacement.",

  ourpolicies_datasubject_title: "Personne concernée (ou utilisateur)",

  ourpolicies_datasubject_content:
    "La personne concernée est toute personne vivante qui utilise notre service et fait l'objet de données personnelles.",

  ourpolicies_information_title: "Collecte et utilisation des informations",

  ourpolicies_information_content:
    "Nous collectons différents types d'informations à des fins diverses pour vous fournir et améliorer notre service.",

  ourpolicies_typeofdata_title: "Types de données collectées",

  ourpolicies_typeofdata_personal_title: "Données personnelles",

  ourpolicies_typeofdata_personal_content1: `Lors de l'utilisation de notre service, nous pouvons vous demander de nous fournir certains informations personnellement identifiables qui peuvent être utilisées pour contacter ou vous identifier ("Données personnelles"). Les informations personnelles identifiables peuvent comprennent, mais sans s'y limiter:`,

  ourpolicies_typeofdata_personal_list1: `Adresse e-mail`,

  ourpolicies_typeofdata_personal_list2: `Prénom et nom`,

  ourpolicies_typeofdata_personal_list3: `Numéro de téléphone`,

  ourpolicies_typeofdata_personal_list4: `Adresse, État, province, code postal, ville`,

  ourpolicies_typeofdata_personal_list5: `Cookies et données d'utilisation`,

  ourpolicies_typeofdata_personal_content2: `Nous pouvons utiliser vos données personnelles pour vous contacter avec des newsletters, du matériel marketing ou promotionnel et d'autres informations susceptibles de vous intéresser. Vous pouvez refuser de recevoir une partie ou la totalité de ces communications de notre part en suivant le lien de désinscription ou les instructions fournies dans tout e-mail que nous envoyons.`,

  ourpolicies_typeofdata_usage_title: `Données d'utilisation`,

  ourpolicies_typeofdata_usage_content1: `Nous pouvons également collecter des informations que votre navigateur envoie chaque fois que vous visitez notre Service ou lorsque vous accédez au Service par ou via un appareil mobile ("Données d'utilisation").`,

  ourpolicies_typeofdata_usage_content2: `Ces données d'utilisation peuvent inclure des informations telles que l'adresse de protocole Internet de votre ordinateur (par exemple l'adresse IP), le type de navigateur, la version du navigateur, les pages de notre service que vous visitez, l'heure et la date de votre visite, le temps passé sur ces pages, unique identificateurs d'appareil et autres données de diagnostic.`,

  ourpolicies_typeofdata_usage_content3: `Lorsque vous accédez au service avec un appareil mobile, ces données d'utilisation peuvent inclure des informations telles que le type d'appareil mobile que vous utilisez, l'ID unique de votre appareil mobile, l'adresse IP de votre appareil mobile, votre système d'exploitation mobile, le type d'Internet mobile. le navigateur que vous utilisez, des identifiants d'appareil uniques et d'autres données de diagnostic.`,

  ourpolicies_typeofdata_location_title: `Données de localisation`,

  ourpolicies_typeofdata_location_content1: `Nous pouvons utiliser et stocker des informations sur votre emplacement si vous nous y autorisez («Données de localisation»). Nous utilisons ces données pour fournir des fonctionnalités de notre service, pour améliorer et personnaliser notre service.`,

  ourpolicies_typeofdata_location_content2: `Vous pouvez activer ou désactiver les services de localisation lorsque vous utilisez notre service à tout moment via les paramètres de votre appareil.`,

  ourpolicies_typeofdata_tracking_title: `Suivi des données des cookies`,

  ourpolicies_typeofdata_tracking_content1: `Nous utilisons des cookies et des technologies de suivi similaires pour suivre l'activité de notre Service et nous détenons certaines informations.`,

  ourpolicies_typeofdata_tracking_content2: `Les cookies sont des fichiers contenant une petite quantité de données pouvant inclure un identifiant unique anonyme. Les cookies sont envoyés à votre navigateur à partir d'un site Web et stockés sur votre appareil. D'autres technologies de suivi sont également utilisées, telles que des balises, des balises et des scripts pour collecter et suivre des informations et pour améliorer et analyser notre service.`,

  ourpolicies_typeofdata_tracking_content3: `Vous pouvez demander à votre navigateur de refuser tous les cookies ou d'indiquer quand un cookie est envoyé. Cependant, si vous n'acceptez pas les cookies, vous ne pourrez peut-être pas utiliser certaines parties de notre service.`,

  ourpolicies_typeofdata_cookiesex_title: `Exemples de cookies que nous utilisons:`,

  ourpolicies_typeofdata_cookiesex_session: "Cookies de session.",

  ourpolicies_typeofdata_cookiesex_session_content: `Nous utilisons des cookies de session pour faire fonctionner notre service.`,

  ourpolicies_typeofdata_cookiesex_preference: "Cookies de préférence.",

  ourpolicies_typeofdata_cookiesex_preference_content: `Nous utilisons des cookies de préférence pour mémoriser vos préférences et divers paramètres.`,

  ourpolicies_typeofdata_cookiesex_security: `Cookies de sécurité.`,

  ourpolicies_typeofdata_cookiesex_security_content: `Nous utilisons des cookies de sécurité à des fins de sécurité.`,

  ourpolicies_typeofdata_use_title: `Utilisation des données`,

  ourpolicies_typeofdata_use_content: `Civy Inc. utilise les données collectées à diverses fins:`,

  ourpolicies_typeofdata_use_list1: `Pour fournir et maintenir notre service`,

  ourpolicies_typeofdata_use_list2: `Pour vous avertir des changements de notre service`,

  ourpolicies_typeofdata_use_list3: `Pour vous permettre de participer aux fonctionnalités interactives de notre service lorsque vous le souhaitez`,

  ourpolicies_typeofdata_use_list4: `Pour fournir un support client`,

  ourpolicies_typeofdata_use_list5: `Pour recueillir des analyses ou des informations précieuses afin que nous puissions améliorer notre service`,

  ourpolicies_typeofdata_use_list8: `Pour surveiller l'utilisation de notre service`,

  ourpolicies_typeofdata_use_list6: `Pour détecter, prévenir et résoudre les problèmes techniques`,

  ourpolicies_typeofdata_use_list7: `Pour vous fournir des nouvelles, des offres spéciales et des informations générales sur d'autres biens, services et événements que nous proposons qui sont similaires à ceux que vous avez déjà achetés ou interrogés, sauf si vous avez choisi de ne pas recevoir ces informations`,

  ourpolicies_typeofdata_legalbasis_title: `Base juridique du traitement des données personnelles en vertu du règlement général sur la protection des données (RGPD)`,

  ourpolicies_typeofdata_legalbasis_content1: `Si vous êtes de l'Espace économique européen (EEE), la base juridique de Civy Inc. pour la collecte et l'utilisation des informations personnelles décrites dans la présente politique de confidentialité dépend des données personnelles que nous collectons et du contexte spécifique dans lequel nous les collectons.`,

  ourpolicies_typeofdata_legalbasis_content2: `Civy Inc. peut traiter vos données personnelles pour les raisons suivantes:`,

  ourpolicies_typeofdata_legalbasis_list1: `Nous devons passer un contrat avec vous`,

  ourpolicies_typeofdata_legalbasis_list2: `Vous nous avez donné la permission de le faire`,

  ourpolicies_typeofdata_legalbasis_list3: `Le traitement est dans notre intérêt légitime et il n'est pas outrepassé par vos droits`,

  ourpolicies_typeofdata_legalbasis_list4: `À des fins de traitement des paiements`,

  ourpolicies_typeofdata_legalbasis_list5: `Pour se conformer à la loi`,

  ourpolicies_typeofdata_retention_title: `Conservation des données`,

  ourpolicies_typeofdata_retention_content1: `Civy Inc. conservera vos données personnelles uniquement aussi longtemps que nécessaire aux fins énoncées dans la présente politique de confidentialité. Nous conserverons et utiliserons vos données personnelles dans la mesure nécessaire pour nous conformer à nos obligations légales (par exemple, si nous sommes tenus de conserver vos données pour se conformer aux lois applicables), résoudre les litiges et appliquer nos accords et politiques juridiques.`,

  ourpolicies_typeofdata_retention_content2: `Civy Inc. conservera également les données d'utilisation à des fins d'analyse interne. Les données d'utilisation sont généralement conservées pendant une période plus courte, sauf lorsque ces données sont utilisées pour renforcer la sécurité ou pour améliorer la fonctionnalité de notre service, ou si nous sommes légalement tenus de conserver ces données pendant des périodes plus longues.`,

  ourpolicies_typeofdata_transfer_title: `Transfert de données`,

  ourpolicies_typeofdata_transfer_content1: `Vos informations, y compris vos données personnelles, peuvent être transférées - et conservées sur - des ordinateurs situés en dehors de votre état, province, pays ou autre juridiction gouvernementale où les lois sur la protection des données peuvent différer de celles de votre juridiction.`,

  ourpolicies_typeofdata_transfer_content2: `Si vous vous trouvez en dehors des États-Unis et choisissez de nous fournir des informations, veuillez noter que nous transférons les données, y compris les données personnelles, aux États-Unis et les traitons là-bas.`,

  ourpolicies_typeofdata_transfer_content3: `Votre consentement à cette politique de confidentialité suivi de votre soumission de ces informations représente votre accord pour ce transfert.`,

  ourpolicies_typeofdata_transfer_content4: `Civy Inc. prendra toutes les mesures raisonnablement nécessaires pour garantir que vos données sont traitées en toute sécurité et conformément à la présente politique de confidentialité et aucun transfert de vos données personnelles n'aura lieu vers une organisation ou un pays à moins qu'il y ait des contrôles adéquats en place, y compris la la sécurité de vos données et autres informations personnelles.`,

  ourpolicies_disclosure_title: `Divulgation des données`,

  ourpolicies_disclosure_law_title: `Divulgation pour l'application de la loi `,

  ourpolicies_disclosure_law_content: `Dans certaines circonstances, Civy Inc. peut être tenue de divulguer vos données personnelles si la loi l'exige ou en réponse à des demandes valides des autorités publiques (par exemple un tribunal ou un organisme gouvernemental).`,

  ourpolicies_disclosure_requirement_title: `Exigences légales`,

  ourpolicies_disclosure_requirement_content: `Civy Inc. peut divulguer vos données personnelles en croyant de bonne foi qu'une telle action est nécessaire pour:`,

  ourpolicies_disclosure_requirement_list1: `Pour se conformer à une obligation légale`,

  ourpolicies_disclosure_requirement_list2: `Pour protéger et défendre les droits ou la propriété de Civy Inc.`,

  ourpolicies_disclosure_requirement_list3: `Pour empêcher ou enquêter sur d'éventuels actes répréhensibles liés au Service`,

  ourpolicies_disclosure_requirement_list4: `Pour protéger la sécurité personnelle des utilisateurs du Service ou du public`,

  ourpolicies_disclosure_requirement_list5: `Pour se protéger contre la responsabilité légale`,

  ourpolicies_security_title: `Sécurité des données`,

  ourpolicies_security_content: `La sécurité de vos données est importante pour nous, mais n'oubliez pas qu'aucune méthode de transmission sur Internet ou méthode de stockage électronique n'est sûre à 100%. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos données personnelles, nous ne pouvons pas garantir leur sécurité absolue.`,

  ourpolicies_security_policy: `Notre politique - Application générale à travers les États-Unis d'Amérique, y compris les politiques spécifiques à l'État de Californie.`,

  ourpolicies_security_track: `Signaux «Ne pas suivre» en vertu de la California Online Protection Act (CalOPPA)`,

  ourpolicies_security_track_content1: `Nous ne prenons pas en charge Do Not Track ("DNT"). Ne pas suivre est une préférence que vous pouvez définir dans votre navigateur Web pour informer les sites Web que vous ne souhaitez pas être suivis.`,

  ourpolicies_security_track_content2: `Vous pouvez activer ou désactiver Ne pas suivre en visitant la page Préférences ou Paramètres de votre navigateur Web.`,

  ourpolicies_security_privacy_policy: `Notre politique de conformité à la California Consumer Privacy Act (CCPA)`,

  ourpolicies_security_privacy_policy1: `Le droit de savoir:`,

  ourpolicies_security_privacy_policy1_content: `Les résidents ont le droit de nous demander de divulguer les informations personnelles que nous collectons, utilisons, partageons ou vendons.`,

  ourpolicies_security_privacy_policy2: `Droit de suppression:`,

  ourpolicies_security_privacy_policy2_content: `Les résidents ont le droit de demander que nous, et nos fournisseurs de services, supprimions les informations personnelles que nous avons sur vous.`,

  ourpolicies_security_privacy_policy3: `Droit de retrait:`,

  ourpolicies_security_privacy_policy3_content: `Les résidents ont le droit de nous ordonner de cesser la vente de leurs renseignements personnels, dans le cas où nous «vendons» des renseignements personnels au sens défini par la CCPA. Veuillez noter que les données collectées sur la plateforme Civy ne sont accessibles qu'aux clients de Civy, y compris les zones gouvernementales des États-Unis (villes, municipalités, conseils, agences gouvernementales, districts spéciaux et résidents enregistrés). Civy ne vend pas ou n'a pas l'intention de vendre des données à des tiers tels que des annonceurs ou des particuliers entreprises.`,

  ourpolicies_security_privacy_policy4: `Droit à la non-discrimination:`,

  ourpolicies_security_privacy_policy4_content: `Nous ne pouvons pas discriminer les résidents en termes de prix ou de service lorsqu'ils exercent leurs droits à la vie privée en vertu de la CCPA.`,

  ourpolicies_security_protection_title: `Vos droits à la protection des données en vertu du règlement général sur la protection des données (RGPD)`,

  ourpolicies_security_protection_content1: `Si vous résidez dans l'Espace économique européen (EEE), vous disposez de certains droits en matière de protection des données. Civy Inc. vise à prendre des mesures raisonnables pour vous permettre de corriger, modifier, supprimer ou limiter l'utilisation de vos données personnelles.`,

  ourpolicies_security_protection_content2: `Si vous souhaitez être informé des données personnelles que nous détenons à votre sujet et si vous souhaitez qu'elles soient supprimées de nos systèmes, veuillez nous contacter.`,

  ourpolicies_security_protection_content3: `Dans certaines circonstances, vous disposez des droits de protection des données suivants:`,

  ourpolicies_security_access_title: `Le droit d'accéder, de mettre à jour ou de supprimer les informations que nous détenons sur vous.`,

  ourpolicies_security_access_content1: `Chaque fois que cela est possible, vous pouvez accéder, mettre à jour ou demander la suppression de vos données personnelles directement dans la section des paramètres de votre compte. Si vous ne parvenez pas à effectuer ces actions vous-même, veuillez nous contacter pour vous aider.`,

  ourpolicies_security_rectification_title: `Le droit de rectification.`,

  ourpolicies_security_rectification_content: `vous avez le droit de faire rectifier vos informations si ces informations sont inexactes ou incomplètes.`,

  ourpolicies_security_object_title: `Le droit de s'opposer.`,

  ourpolicies_security_object_content: `Vous avez le droit de vous opposer à notre traitement de vos données personnelles.`,

  ourpolicies_security_restriction_title: `Le droit de restriction.`,

  ourpolicies_security_restriction_content: `Vous avez le droit de nous demander de restreindre le traitement de vos informations personnelles.`,

  ourpolicies_security_portability_title: `Le droit à la portabilité des données.`,

  ourpolicies_security_portability_content: `Vous avez le droit de recevoir une copie des informations que nous avons sur vous dans un format structuré, lisible par machine et couramment utilisé.`,

  ourpolicies_security_withdraw_title: `Le droit de retirer son consentement.`,

  ourpolicies_security_withdraw_content: `Vous avez également le droit de retirer votre consentement à tout moment lorsque Civy Inc. s'est appuyé sur votre consentement pour traiter vos informations personnelles.`,

  ourpolicies_security_protection_content4: `Veuillez noter que nous pouvons vous demander de vérifier votre identité avant de répondre à de telles demandes.`,

  ourpolicies_security_protection_content5: `Vous avez le droit de vous plaindre auprès d'une autorité de protection des données de notre collecte et de l'utilisation de vos données personnelles. Pour plus d'informations, veuillez contacter votre autorité locale de protection des données dans l'Espace économique européen (EEE).`,

  ourpolicies_serviceprovider: `Les fournisseurs de services`,

  ourpolicies_serviceprovider_content1: `Nous pouvons employer des sociétés et des individus tiers pour faciliter notre service («fournisseurs de services»), fournir le service en notre nom, exécuter des services liés au service ou nous aider à analyser la façon dont notre service est utilisé.`,

  ourpolicies_serviceprovider_content2: `Ces tiers ont accès à vos données personnelles uniquement pour effectuer ces tâches en notre nom et sont tenus de ne pas les divulguer ou de les utiliser à d'autres fins.`,

  ourpolicies_serviceprovider_analytics: `Analytique`,

  ourpolicies_serviceprovider_analytics_content: `Nous pouvons utiliser des fournisseurs de services tiers pour surveiller et analyser l'utilisation de notre service.`,

  ourpolicies_serviceprovider_ganalytics: `Google Analytics`,

  ourpolicies_serviceprovider_ganalytics_content1: `Google Analytics est un service d'analyse Web proposé par Google qui suit et signale le trafic sur le site Web. Google utilise les données collectées pour suivre et surveiller l'utilisation de notre service. Ces données sont partagées avec d'autres services Google. Google peut utiliser les données collectées pour contextualiser et personnaliser les annonces de son propre réseau publicitaire.`,

  ourpolicies_serviceprovider_ganalytics_content2: `Pour plus d'informations sur les pratiques de confidentialité de Google, veuillez visiter la page Web des conditions de confidentialité de Google: https://policies.google.com/privacy?hl=en`,

  ourpolicies_serviceprovider_firebase: `Firebase`,

  ourpolicies_serviceprovider_firebase_content1: `Firebase est un service d'analyse fourni par Google Inc.`,

  ourpolicies_serviceprovider_firebase_content4: `Vous pouvez désactiver certaines fonctionnalités de Firebase via les paramètres de votre appareil mobile, tels que les paramètres de publicité de votre appareil ou en suivant les instructions fournies par Google dans sa politique de confidentialité: https://policies.google.com/privacy?hl=en`,

  ourpolicies_serviceprovider_firebase_content2: `Nous vous encourageons également à consulter la politique de Google en matière de protection de vos données: https://support.google.com/analytics/answer/6004245.`,

  ourpolicies_serviceprovider_firebase_content3: `Pour plus d'informations sur le type d'informations Firebase	
    collecte, veuillez visiter la page Web des conditions de confidentialité de Google: https://policies.google.com/privacy?hl=en`,

  ourpolicies_serviceprovider_payments: `Paiements`,

  ourpolicies_serviceprovider_payment_content1: `Nous pouvons fournir des produits et / ou services payants au sein du Service. Dans ce cas, nous utilisons des services tiers pour le traitement des paiements (par exemple, les processeurs de paiement).`,

  ourpolicies_serviceprovider_payment_content2: `Nous ne conserverons ni ne collecterons les détails de votre carte de paiement. Ces informations sont fournies directement à nos processeurs de paiement tiers dont l'utilisation de vos informations personnelles est régie par leur politique de confidentialité. Ces processeurs de paiement respectent les normes établies par PCI-DSS telles que gérées par le PCI Security Standards Council, qui est un effort conjoint de marques comme Visa MasterCard, American Express et Discover. Les exigences PCI-DSS aident à garantir la gestion sécurisée des informations de paiement.`,

  ourpolicies_serviceprovider_payment_content3: `Les processeurs de paiement avec lesquels nous travaillons sont:`,

  ourpolicies_serviceprovider_stripe: `Stripe`,

  ourpolicies_serviceprovider_stripe_content: `Leur politique de confidentialité peut être consultée sur https://stripe.com/us confidentialité`,

  ourpolicies_linksto_title: `Liens vers d'autres sites`,

  ourpolicies_linksto_content1: `Notre service peut contenir des liens vers d'autres sites qui ne sont pas exploités par nous. Si vous cliquez sur un lien tiers, vous serez dirigé vers le site de ce tiers. Nous vous conseillons fortement de consulter la politique de confidentialité de chaque site que vous visitez.`,

  ourpolicies_linksto_content2: `Nous n'avons aucun contrôle sur et n'assumons aucune responsabilité pour le contenu, les politiques de confidentialité ou les pratiques de tout site ou service tiers.`,

  ourpolicies_children_title: `Confidentialité des enfants`,

  ourpolicies_children_content1: `Notre Service ne s'adresse à aucune personne de moins de 18 ans ("Enfants").`,

  ourpolicies_children_content2: `Nous ne collectons pas sciemment d'informations personnelles identifiables auprès de personnes de moins de 18 ans. Si vous êtes un parent ou un tuteur et que vous savez que votre enfant nous a fourni des données personnelles, veuillez nous contacter. Si nous apprenons que nous avons collecté des données personnelles d'enfants sans vérification du consentement parental, nous prenons des mesures pour supprimer ces informations de nos serveurs.`,

  ourpolicies_changes_title: `Modifications de cette politique de confidentialité`,

  ourpolicies_changes_content1: `Nous pouvons mettre à jour notre politique de confidentialité de temps en temps. Nous vous informerons de tout changement en publiant la nouvelle politique de confidentialité sur cette page.`,

  ourpolicies_changes_content2: `Nous vous informerons par e-mail et / ou par un avis bien visible sur notre Service avant que le changement ne devienne effectif et mettrons à jour la "date d'entrée en vigueur" en haut de la présente Politique de confidentialité.`,

  ourpolicies_changes_content3: `Nous vous conseillons de consulter régulièrement cette politique de confidentialité pour tout changement. Les modifications apportées à cette politique de confidentialité sont effectives lorsqu'elles sont publiées sur cette page.`,

  ourpolicies_contactus_title: `Nous contacter`,

  ourpolicies_contactus_content1: `Si vous avez des questions sur cette politique de confidentialité, veuillez nous contacter:`,

  ourpolicies_contactus_content2: `Par email:`,

  //Registration page
  dob_validation: "L'âge doit être d'au moins 16 ans",

  register_regi_title: "Créer un compte",

  register_organization_label: "Nom de l'organisation{star}",
  profile_name_label: "Nom{star}",

  register_dob_error: "*La date de naissance est obligatoire",

  register_age_error: "*Veuillez sélectionner votre tranche d'âge.",

  date_invalid_error: "Veuillez entrer une date valide.",

  register_organization_error: "* Le nom de l'organisation est requis",
  profile_name_error:"*Le nom est requis",

  register_organization_type_label: "Type d'Organisation{star}",
  profile_type_label: "Type{star}",

  register_organization_type_error:
    "* Veuillez sélectionner le type d'organisation",
  profile_type_error: "*Veuillez sélectionner le type",

  register_organization_title_label: "Titre de l'organisation{star}",
  profile_title_label: "Titre{star}",

  register_organization_title_error: "* Le titre est requis",

  register_browse_label: "Feuilleter...",

  register_upload_label: "Télécharger le logo",

  register_upload_error: "* Veuillez sélectionner le logo",

  register_are_you_label: "Es-tu un{star}",

  register_are_you_resident: "Résident américain",

  register_are_you_citizen: "Citoyen américain",

  register_are_you_prefer: "préfère ne pas répondre",

  register_are_you_error: "* Veuillez sélectionner le type",

  register_your_title_label: "titre{star}",

  register_your_title_list1: "m.",

  register_your_title_list2: "Mademoiselle.",

  register_your_title_list3: "Mme.",

  register_your_title_list4: "Dr.",

  register_your_title_list5: "Mme.",

  register_your_title_error: "* Veuillez sélectionner votre titre",

  register_name_label: "Prénom{star}",

  register_name_error1: "*Le prénom est requis",

  register_name_error2: "Il devrait y avoir une limite de 15 à 20 textes.",

  register_last_name_label: "Nom de famille{star}",

  register_last_name_error1: "* Le nom de famille est requis",

  register_last_name_error2: "Il devrait y avoir une limite de 15 à 20 textes.",

  register_ethinicicty_label: "Ethnicité{star}",

  register_ethinicicty_error: "* Veuillez sélectionner une origine ethnique",

  register_email_label: "Email{star}",

  register_email_error1: "* L'e-mail est obligatoire ",

  register_email_error2: "Veuillez saisir une adresse e-mail valide ",

  register_mobile_label: "Numéro de portable{star}",

  register_mobile_error1: "* Le numéro de mobile est requis",

  register_gender_label: "le sexe{star}",

  register_gender_type1: "Masculin",

  register_gender_type2: "Femelle",

  register_gender_type3: "Autre",

  register_gender_error: "* Veuillez sélectionner le sexe",

  register_birth_label: "Date de naissance{star}",

  register_street_label: "Adresse de rue{star}",

  register_street_error: "* Street Addess est requis",

  register_apartment_label: "Appartement / Suite {star}",

  register_apartment_error: "* Appartement / Suite est requis",

  register_country_label: "Pays{star}",

  register_country_error: "* Veuillez sélectionner un pays",

  register_state_label: "Etat{star}",

  register_state_error: "* Veuillez sélectionner l'état",

  register_city_label: "Ville{star}",

  register_county_label: "Comté{star}",

  register_city_error: "* Veuillez sélectionner une ville",

  register_zip_label: "Code postal{star}",

  register_zip_error: "* Le champ Code postal est obligatoire",

  register_zip2_label: "+4 Code",

  register_password_label: "Mot de passe{star}",

  register_password_error1: "* Le champ du mot de passe est obligatoire",

  register_password_error2:
    "Les mots de passe doivent contenir au moins 8 caractères, y compris les majuscules, les minuscules et les chiffres.",

  register_terms1_label: "je suis d'accord avec le",

  register_terms2_label: "conditions d'utilisation",

  register_terms3_label: "et",

  register_terms4_label: "politique de confidentialité",

  register_terms_error:
    "* Veuillez indiquer que vous acceptez les conditions et la politique de confidentialité",

  register_already_account: "Vous avez déjà un compte,",

  register_signin_label: "Se connecter",

  register_or_signin_label: "ou Connectez-vous avec",

  register_registerbutton_label: "S'inscrire",

  register_organization_type1: "Ville",

  register_organization_type2: "Gouvernement",

  register_organization_type3: "Municipal",

  register_organization_type4: "Districts d'amélioration commerciale",

  register_organization_type5: "Think Tank",

  register_organization_type6: "Higher-Ed",

  register_ethinicity_type1: "Afro-américain",

  register_ethinicity_type2: "Indien d'Amérique ou originaire de l'Alaska",

  register_ethinicity_type3: "Insulaire asiatique ou du Pacifique",

  register_ethinicity_type4: "caucasien",

  register_ethinicity_type5: "Hispanique ou Latino",

  register_ethinicity_type6:
    "Hawaïens indigènes ou autre insulaire du Pacifique",

  register_ethinicity_type7: "Multiracial",

  register_ethinicity_type8: "Autre",

  register_ethinicity_type9: "préfère ne pas répondre",

  register_profile_change_password: "Changer le mot de passe",

  register_create_password_change: "changez votre mot de passe",

  register_create_password_create: "Créez votre mot de passe",

  register_create_password_old: "ancien mot de passe*",

  register_create_password_new: "nouveau mot de passe*",

  register_create_password_confirm: "Confirmez le mot de passe*",

  register_create_password_not_match: "* Le mot de passe ne correspond pas",

  register_create_password_cotinue: "Continuer",

  register_collegues_add_collegues: "Ajouter des collègue",

  register_department_label: "département{star}",

  register_department_error:
    "* Le département est requis",

  register_member_no_colleagues: "Aucun collègue ajouté",

  register_member_colleagues_list: "Liste des collègues",
  register_member_colleague_list: "Liste des collègue",

  register_member_delete: "Effacer",

  register_profile_upload_resident: "Télécharger les données des résidents",

  register_profile_batch_upload: "Téléchargement par lots",

  register_profile_resident_details: "Modèle de détails sur le résident",

  //Login page

  login_signin_label: "se connecter",

  login_email_label: "Email",

  login_email_error1: "* L'email est requis",

  login_email_error2: "* Veuillez saisir une adresse e-mail valide",

  login_password_label: "Mot de passe",

  login_password_error1: "* Le champ du mot de passe est obligatoire",

  login_password_error2:
    "Les mots de passe doivent contenir au moins 8 caractères, y compris les majuscules, les minuscules et les chiffres.",

  login_checkbox_label: "Souviens-toi de moi",

  login_loginbutton_label: "S'identifier",

  login_orsignin_label: "ou Connectez-vous avec",

  login_forgot_password_label: "Mot de passe oublié?",

  login_registration_label1: "Vous n'avez pas de compte?",

  login_resident_label1: "Créer un compte résident",

  login_resident_label2: "créer un compte",

  login_organization_label1: "Créer un compte d'organisation",

  login_forgot_password_submit: "Soumettre",

  //404 Not found Page and thank you page

  notfound_thankyou_content1: "Pour avoir choisi Civy,",

  notfound_thankyou_content2:
    "Merci de consulter vos emails. Nous avons envoyé à",

  notfound_thankyou_content3: "vous un e-mail pour confirmation.",

  notfound_thankyou_content4: "De retour à la maison",

  //header banner

  header_banner_about: "sur",

  header_banner_contactus: "Nous contacter",

  header_banner_service: "Prestations de service",

  header_banner_outpolicies: "Nos politiques",

  header_banner_project: "Projet",

  header_banner_thought_leadership: "Leadership éclairé",

  header_banner_how_to: "Comment",

  header_home_banner:
    "CONNECTER LE GOUVERNEMENT ET LES COLLECTIVITÉS À TRAVERS DES PLATEFORMES INNOVANTES",

  header_banner_colleagues: "Collègue",

  header_banner_change_password: "Changer le mot de passe",

  header_banner_create_password: "Créer un mot de passe",

  header_banner_survey: "Sondage",

  header_banner_what_is_civy: "QU'EST-CE QUE CIVY",

  header_banner_qr_code_management: "Gestion des codes QR",

  //header.........................

  header_home: "DOMICILE",

  header_core: "Core 19",

  header_KIOSK: "Kiosque",

  header_service: "PRESTATIONS DE SERVICE",

  header_project: "PROJETS",

  header_project_engage: "ENGAGER",

  header_thought_leadership: "LEADERSHIP PENSÉ",

  header_about: "SUR",

  header_login: "S'IDENTIFIER",

  // header_language1: "Anglais",

  // header_language2: "français",

  // header_language3: "Espagnol",

  // header_language4: "hindi",

  // header_language5: "mandarin",

  // header_language6: "italien",

  // header_language7: "Vietnamienne",
  header_language1: "EN",

  header_language2: "FR",

  header_language3: "ES",

  header_language4: "HI",

  header_language5: "ZH",

  header_language6: "IT",

  header_language7: "VI",

  header_header_drawer_welcome: "Bienvenue,",

  header_header_drawer_admin_profile: "Profil administrateur",

  header_header_drawer_profile: "Profil",

  header_header_drawer_colleagues: "Collègues",

  header_header_drawer_op_ed: "OP-ED",

  header_header_drawer_how_to: "Comment",

  header_header_drawer_logout: "Se déconnecter",

  header_what_is_civy: "QU'EST-CE QUE CIVY",

  header_qr_code_management: "Gestion des codes QR",

  //footer........................

  footer_contactus_title: "Nous contacter",

  footer_contactus_content1:
    "Contactez Civy pour savoir comment vous pouvez permettre un meilleur engagement civique pour vos projets de développement communautaire pour les résidents de toutes les données démographiques.",

  footer_media: "Médias",

  footer_terms_title: "Conditions d'utilisation",

  footer_privacy_title: "Politique de confidentialité",

  footer_google_translate_message: `Civy utilise Google Translate pour convertir de l'anglais vers d'autres langues disponibles sur la plateforme. Parfois, Google Translate peut ne pas fournir de traduction exacte. Pour toute question, veuillez nous contacter à`,

  // blog page..............

  blog_details_author_title: "Auteur",
  blog_details_author_title_star: "Auteur{star}",

  blog_details_posted_title: "Publié dans:",

  blog_details_latest_article_title: "Dernier article",

  blog_details_op_ed_title: "Civy OP-ED",

  blog_addblog_new_blog: "Ajouter un nouvel OP-ED",

  blog_addblog_title: "Titre",

  blog_addblog_description_title: "La description",

  blog_addblog_browse_title: "Feuilleter...",

  blog_addblog_addimage_title: "Ajouter une image..",

  blog_addblog_submit_title: "Ajouter OP-ED",

  blog_editblog_op_ed_title: "Modifier OP-ED",

  blog_common_error: "*Ce champ est requis",

  blog_title_error: "*Le titre est requis",

  blog_desc_error: "*La description est obligatoire",

  blog_author_error: "*L'auteur est requis",

  blog_image_error: "*L'image est requise",

  valid_image_error: "*Les images doivent être en .png, .jpeg, .jpg",

  //project page.........................

  project_filter_project_type1: "Nouveau projet",

  project_filter_project_type2: "Projet existant",

  project_filter_project_type3: "Réparations / Maintenance",

  project_filter_project_type4: "un événement",

  project_filter_project_type5: "Catastrophe",

  project_filter_project_category1: "Verdure",

  project_filter_project_category2: "Frais de péage",

  project_filter_project_category3: "Utilitaires",

  project_filter_project_category4: "Routes",

  project_filter_project_category5: "Autoroutes",

  project_filter_project_category6: "Bâtiment commercial",

  project_filter_project_category7: "Bâtiment résidentiel",

  project_filter_project_category8: "Pays natal",

  project_filter_project_category9: "Bâtiments des clubs communautaires",

  project_filter_project_category10: "Oeuvres mineures",

  project_filter_city_zipcode: "COMTÉ, VILLE ET ZIP",

  project_filter_range: "Intervalle",

  project_filter_range1: "miles",

  project_filter_project_type: "Type de projet",

  project_filter_project_type_star: "Type de projet{star}",

  project_filter_project_category: "Catégorie de projet",

  project_filter_project_category_star: "Catégorie de projet{star}",

  project_location: "Emplacement{star}",

  project_street_address: "Adresse de rue",

  project_filter_add_new_project: "Ajouter un nouveau projet",

  project_filter_reset: "Réinitialiser",

  project_newproject_organization_label: "Département Organisation",

  project_newproject_organization_label_star: "Département Organisation{star}",

  project_newproject_common_error: "*Ce champ est requis",

  project_newproject_street_label: "rue",

  project_newproject_country_label: "Pays",

  project_newproject_state_label: "Etat",

  project_newproject_city_label: "Ville",

  project_newproject_zipcode_label: "Code postal",

  project_newproject_areacode_label: "+4 Code",

  project_newproject_start_date: "Date de début du projet",

  project_newproject_end_date: "Date de fin du projet",

  project_newproject_cancel: "Annuler",

  project_projectfom_add_project: "Ajouter un projet",

  publish: "Publier",

  project_newproject_continue: "Continuer",

  project_projectlist_category_label: "Catégorie:",

  project_projectlist_type_label: "Type:",

  project_projectlist_view_label: "Voir le projet",

  project_projectlist_no_project: "Aucun projet ajouté",

  project_editproject_edit: "Modifier le projet",

  project_newproject_title_label: "Titre du projet",

  project_newproject_description: "Description du projet",

  project_newproject_description_star: "Description du projet{star}",

  project_newproject_add_images: "Ajouter des images..",

  project_newproject_add_File: "Télécharger des fichiers PDF...",

  project_commentbox_join_label: "Rejoindre la conversation",

  project_commentbox_nocomment_label: "Aucun commentaire ajouté",

  project_commentbox_comment_label: "{commentlength} commentaires",

  project_details_location: "Emplacement",

  project_details_posted: "Posté sur",

  project_details_share: "Partager sur:",

  project_details_last_update: "Dernière mise à jour le",

  project_details_likes: "Total des J'aime:",

  project_details_question: "Ajouter une question",

  project_details_question_list: "Liste de questions",

  project_details_no_question: "Aucune question ajoutée",

  project_details_add_answer: "Ajouter une réponse",

  project_details_add_answer_here: "Ajoutez votre réponse ici ...",

  project_facts_and_figures_label:
    "Mises à jour du personnel de la ville et du gouvernement",

  project_gallery_label: "Galerie de projets",

  qr_code_label: "Générer un code QR",

  //Survey page............

  survey_select_questionType: "Sélectionnez le type de question",

  survey_questionType_content:
    "Les détails de votre projet ont été téléchargés avec succès sur Civy. Veuillez sélectionner le type de questions que vous souhaitez poser aux résidents.",

  survey_questionType_type_a: "Type A",

  survey_questionType_type_que1:
    "1. Veuillez ajouter votre avis sur l'aire de jeux pour enfants.",

  survey_write_here_label: "écris quelque chose ici ...",

  survey_questionType_create_survey: "Créer une enquête",

  survey_questionType_type_b: "Questions à choix multiple",

  survey_questionType_type_que2:
    "1. Souhaitez-vous ajouter une aire de jeux pour enfants dans le jardin public?",

  survey_questionType_type_b_yes: "Oui",

  survey_questionType_type_b_no: "Non",

  survey_comment_box: "Question ouverte (boîte de commentaires)",

  survey_radio_question_label: "Des questions",

  survey_discard_label: "Jeter",

  survey_submit_label: "Ajouter",

  survey_add_question_label: "Ajouter une question",

  survey_back_label: "Retour",

  survey_question_list_label: "Liste de questions",

  dialog_delete_message: "Voulez-vous vraiment supprimer",

  delete_lable: "Supprimer",

  cancel_label: "Annuler",

  comment: "Commentaire",

  /* Validation message */

  departmaent_required: "*Le nom du département est requis",

  project_type_required: "*Veuillez sélectionner le type de projet",

  project_category_required: "*Veuillez sélectionner la catégorie de projet",

  location_required: "*L'emplacement est obligatoire",

  full_address_required: "*Veuillez saisir l'adresse complète",

  only_usa_required: "*Ajouter une adresse pour les États-Unis uniquement",

  project_title_requied: "*Titre du projet requis",

  project_desc_required: "*Description du projet requise",

  project_image_required: "L'image est requise",

  commonn_update_button: "Mise à jour",

  export_button: "Exporter vers Excel",

  export_project: "Projet d'exportation",

  export_project_data: "Exporter les données du projet",

  from: "De",

  to: "À",

  project_required: "*Veuillez sélectionner un projet",

  date_range: "Plage de dates",

  edit_question: "Modifier la question",

  quetion_labele: "Question",

  qustion_required: "*Le champ de question est obligatoire",

  click_edit: "Cliquez pour modifier le profil",

  back_label: "Arrière",

  save_label: "Enregistrer",

  draft_label: "Brouillon",

  upload_label: "TÉLÉCHARGER",

  attachment_label: "Pièces jointes",

  rating_type_question: "Questions sur le type de notation",

  survey_questionType_type_que3: "Veuillez noter ce projet",

  allow_cookie_text:
    "Nous utilisons des cookies pour optimiser votre expérience, analyser le trafic et personnaliser le contenu. Pour en savoir plus, veuillez consulter notre politique sur les cookies. En utilisant notre site Web sans désactiver les cookies, vous consentez à ce que nous les utilisions.",

  allow_btn: "Autoriser",

  cookie_policy: "Politique relative aux cookies",

  decline_btn: "Déclin",

  /* What is civy page */

  civy_intro_title1: "Caractéristiques de la plateforme",

  civy_intro1_point1:
    "Les villes et gouvernements accèdent à la plateforme via une URL dédiée, par exemple {link}",

  civy_intro1_point2:
    "Le client a la possibilité de lier notre plate-forme à ses pages de médias sociaux (LinkedIn, Facebook et Twitter) pour permettre une profondeur et une portée plus larges afin d'augmenter la participation de la communauté.",

  civy_intro1_point3: "La plate-forme comprend plusieurs langues.",

  civy_intro1_point4:
    "Les données sont stockées sur le service cloud de Civy, et la sécurité et la confidentialité des données sont maintenues grâce à des cyberaudits semestriels conformément aux réglementations américaines sur les données et la confidentialité.",

  civy_intro1_point5:
    "Civy n'interagit pas avec des annonceurs tiers, par conséquent, les clients et les villes peuvent garder la certitude qu'ils ne recevront aucun e-mail ou promotion non sollicité.",

  civy_intro1_point6:
    "La plate-forme est accessible aux mobiles, partout dans le monde; et notifications par e-mail et SMS pour tous les utilisateurs enregistrés.",

  civy_intro1_point7:
    "Scanner le code QR pour les projets (scanné par les résidents pour fournir des réponses rapides).",

  civy_intro1_point8:
    "Accès aux rapports téléchargeables relatifs à leur ville, pour tous les projets ou projets sélectionnés.",

  civy_intro2_title: "Raisons pour lesquelles les organisations s'inscrivent",

  civy_intro2_point1:
    "Créez un compte administrateur avec le logo de la ville et ajoutez jusqu'à 20 collègues.",

  civy_intro2_point2:
    "Les données créées par le client et les commentaires des résidents appartiennent au client.",

  civy_intro2_point3:
    "Les commentaires du résident proviennent directement du créateur du projet; par exemple, les commentaires des résidents pour un projet créé par Travaux publics sont renvoyés aux travaux publics.",

  civy_intro2_point4:
    "Les projets peuvent être créés en utilisant un ou plusieurs codes postaux; niveau du comté au niveau de la ville locale; et partagé via Civy et vos canaux de médias sociaux liés. Cela garantit que les commentaires des résidents sont directement liés au code postal du résident (les projets publiés par les projets de la ville de New York ne peuvent être traités que par les résidents de New York).",

  civy_intro2_point5: "Capable de télécharger des rapports.",

  civy_intro3_title: "Raisons pour lesquelles les résidents s'inscrivent",

  civy_intro3_point1:
    "Pour vous assurer que votre profil est lié au code postal dans lequel vous vivez, vous serez en mesure de répondre aux questions posées par votre ville.",

  civy_intro3_point2:
    "Pour vous assurer que vos réponses sont directement fournies par vous. Civy ne lie, ne collecte ni ne rassemble les données de l'un de vos autres comptes de médias sociaux. Civy enregistre uniquement vos réponses que vous avez fournies sur la plateforme de Civy, et ces réponses ne sont visibles que pour examen par les responsables de la ville utilisant la plateforme.",

  civy_intro3_point3:
    "Vos réponses ou commentaires ne sont pas visibles par vos concitoyens.",

  civy_intro3_point4:
    "Vous n’avez pas à vous inscrire sur Civy en tant qu ’« anonyme »puisque vos réponses sont confidentielles et ne sont visibles que par le responsable municipal responsable du projet.",

  what_is_civy_policies_title: "STRATÉGIES",

  what_is_civy_policies_content: "POLITIQUES DE CONFIDENTIALITÉ ET DE DONNÉES",

  //QR code Management page

  qr_code_svg: "Générer en SVG",

  qr_code_png: "Générer au format PNG",

  qr_code_jpeg: "Générer au format JPEG",

  qr_code: "QR Code",
};
